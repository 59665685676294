@import "variable.scss";

.activity-popup-container {
  max-width: 700px !important;
  width: 700px !important;
  margin-top: 150px !important;
  min-height: 400px;
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  // max-height: initial !important;
  overflow-y: inherit !important;
}

.apc-top-container {
  width: 100%;
  // height: 160px;
  border-radius: 10px 10px 0 0;
  background: $actPopupTopBg;
  padding: 0 12px 0 12px;
  .apc-upper-top-holder {
    .apc-name-area {
      .apc-client-logo {
        border-radius: 4px;
        .sb-avatar__text {
          span {
            font-size: 13.7px;
            padding-left: 0.6px;
          }
          border-radius: 4px;
        }
      }
    }
  }
}
.apc-upper-top-holder {
  width: 100%;
  height: 54px;
  border-bottom: 1px solid rgba(174, 174, 174, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.apc-name-area {
  width: 94%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
// .client-logo {
//   display: flex;
//   flex-direction: row;
//   margin-left: 12px;
//   width: 30px;
//   height: 30px;
//   margin-right: 15px;
//   img {
//     border-radius: 4px;
//   }
.apc-client-logo {
  display: flex;
  flex-direction: row;
  // margin-left: 12px;
  width: 30px;
  height: 30px;
  margin-right: 11px;
  img {
    border-radius: 4px;
    object-fit: cover;
    width: 100%;
  }
  .sb-avatar__text {
    font-family: Roboto;
    font-size: 13.7px;
    font-weight: 500;
    width: 30px !important;
    height: 30px !important;
    border-radius: 4px !important;

    div {
      span {
        font-size: 13.7px;
        font-weight: 500;
      }
    }
  }
}
.apc-activity-title {
  font: normal 17px $mainFont;
  color: $attachmentHeading;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 390px;
  &.not-a-part-of-card {
    width: 445px;
  }
}
.menu-options.wp {
  // margin: 0 20px;
  margin: 0 7px 0 24px;
}
.apc-controls-area {
  // width:calc(100% - 430px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.apc-close-control {
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 14px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.apc-dot-holder {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 0 25px 0 0;
  margin: 0 10px 0 0;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.apc-options-block {
  min-width: 153px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  position: absolute;
  top: 21px;
  // right: -118px;
  right: -130px;
  padding: 10px 0px;
  z-index: 999;
  &.with-archive-pop {
    right: -204px;
  }
  .deleteflow-box {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.apc-options-list {
  font-family: $mainFont;
  font-size: 14px;
  color: $successMsg;
  height: 30px;
  padding: 5px 10px;
  &:hover {
    background: rgba(153, 153, 153, 0.15);
  }
  cursor: pointer;
}
.apc-tab-container {
  width: 100%;
  background: $whiteColor;
  border-radius: 0 0 10px 10px;
  // margin-bottom: 100px;
}
.apc-tab-link-container {
  width: 100%;
  border-bottom: 1px solid $attachmentBorder;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  &.without-attach {
    justify-content: flex-start;
    .search-icon-holder {
      display: flex;
      justify-content: center;
      cursor: pointer;
      margin-left: 12px;
      margin-right: 70px;
      width: 30px;
      border-radius: 50%;
      height: 30px;
      margin-top: 7px;
      &:hover {
        background: #f0f0f0;
      }
      img {
        width: fit-content;
      }
    }
  }
}
.apc-tab-link-holder {
  width: 540px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  &.without-attach {
    width: 470px;
  }
}
.apc-tab-link-items-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &.apc-active {
    position: relative;
    .apc-tab-link-title {
      color: $blueBg;
    }
    // CSS for old activity inner
    // &:after {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 2px;
    //   background: $blueBg;
    //   bottom: -1px;
    //   left: 0;
    // }
    // CSS for new activity inner
    &:after {
      content: "";
      position: absolute;
      width: 200px;
      height: 1px;
      background: $blueBg;
      bottom: -1px;
      left: -40px;
    }
    //---------------------
  }
}
.apc-tab-link-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px 0 0;
  img {
    max-width: 100%;
    min-width: 100%;
  }
  .act-inner-notify-dot {
    position: absolute;
    width: 8px;
    height: 8px;
    top: -1px;
    right: -2px;
    background: #ef5350;
    border-radius: 50%;
    &.tab-active-dot {
      top: -2px;
    }
  }
  &.comments {
    img {
      width: 21px;
      height: 22px;
    }
    .tab-active {
      height: 20px;
    }
    .tab-notify-dot {
      width: 24px;
      height: 23px;
      margin-bottom: 2px;
    }
  }
}
.apc-tab-link-title {
  font: normal 14px $mainFont;
  color: $attachmentHeading;
}
.apc-tab-content-container {
  width: 100%;
}
.apc-tab-content-holder {
  width: 100%;
}

.activity-dialog-body {
  padding: 10px 0 6px 0;
  .title-block {
    width: 100%;
    .proj-title {
      // font-size: 15px; old
      font-size: 12px;
      font-family: roboto;
      color: #999999;
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .timer-wrap {
      display: flex;
      align-items: center;
      margin: 5px 0;
      justify-content: space-between;
      .your-wrap {
        display: flex;
        margin-right: 0;
      }
      &.due-date {
        color: #959595;
        .your-due-date {
          margin-right: 0;
          &.delayed {
            font-family: $sideFont;
            font-size: 12px;
            font-weight: 300;
            color: #ff0000;
            // margin-right: 15px;
          }
        }
      }

      .act_due_on_delayed {
        font-family: $sideFont;
        font-size: 12px;
        font-weight: 300;
        color: #ff0000;
        margin-right: 15px;
        img {
          position: relative;
          top: 2px;
        }
      }

      .act_due_on_on_target {
        display: flex;
        font-size: 12px;
        font-weight: 300;
        color: #959595;
        // margin-right: 15px; old
        margin-right: 6px;
        font-family: $sideFont;
      }
      .separator {
        color: #959595;
      }
      div {
        font-family: roboto;
        font-size: 12px;
        font-weight: 300;
        // margin-right: 15px; old
        margin-right: 6px;
      }
      .timer-dur {
        align-items: center;
        margin-right: 0; // new
        span {
          position: relative;
          top: 0px;
          // left: 3px; old
        }
      }
    }
  }

  .user-details-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    .user-block {
      display: flex;
      align-items: center;
      width: 75%;
      flex-wrap: wrap;

      .user-add {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        cursor: pointer;
        margin-bottom: 5px;
      }
      .user_img_holder {
        padding: 1px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 25px !important;
        height: 25px !important;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 50%;
        position: relative;
        overflow: unset;
        .not-wp-user {
          opacity: 0.5;
        }
        img {
          border-radius: 50%;
          width: 35px;
          height: 35px;
          object-fit: cover;
        }

        .sb-avatar {
          width: 100% !important;
          height: 100% !important;

          .sb-avatar__text {
            width: 23px !important;
            height: 23px !important;
            border-radius: 50%;
            // border: solid 1px #0090e9;
            div {
              font-size: 13px !important;
              cursor: default;
            }
          }
        }

        .user-img-delete {
          width: 13px;
          height: 13px;
          position: absolute;
          right: 0px;
          top: -4px;
          cursor: pointer;
          display: none;
        }
        &:hover {
          .user-img-delete {
            width: 13px;
            height: 13px;
            position: absolute;
            right: 0px;
            top: -7px;
            cursor: pointer;
            display: block;
          }
        }
      }
      .workspace-owner {
        img {
          // border: 1px solid $blueBg;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          object-fit: cover;
          box-sizing: border-box;
        }
        &:hover {
          .user-img-delete {
            width: 13px;
            height: 13px;
            position: absolute !important;
            right: 0px;
            top: -7px;
            cursor: pointer;
            display: block;
          }
        }
      }
    }
    .timer-block {
      .timer {
        display: flex;
        .timer-outer-div {
          margin: 5px 10px 0 0;
          .total-time {
            position: absolute;
            top: 70px;
            right: 25px;
            color: #757575;
            font-size: 12px;
            &.total-timer-running {
              position: absolute;
              right: 25px;
            }
          }
        }
      }
      display: flex;
    }
  }
}
.md-avatar {
  width: 100%;
  height: 100%;
}

.activity-popup-container-parent {
  height: initial !important;
}

.act_owner_img_holder {
  padding: 1px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // width: 25px !important;
  // height: 25px !important;
  width: 27px; // addded for new activity design
  height: 27px; // addded for new activity design
  margin-right: 8px;
  margin-top: 0px;
  margin-left: 8px;
  border-radius: 50%;
  position: relative;
  overflow: unset;
  cursor: pointer;
  img {
    border-radius: 50%;
    border: 1px solid #0090e9;
    width: 25px;
    height: 25px;
    object-fit: cover;
    box-sizing: border-box;
  }
  .sb-avatar {
    width: 100% !important;
    height: 100% !important;
    margin-top: -2px;
    .sb-avatar__text {
      width: 25px !important;
      height: 25px !important;
      border-radius: 50%;
      border: solid 1px #0090e9;
      div {
        font-size: 13px !important;
        cursor: default;
      }
    }
  }
}

.act-search-container {
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  .edit-board-search-holder {
    border-radius: 0;
    .edit-board-search-control {
      border-radius: 0;
      padding-left: 50px;
      width: 50%;
    }
    .edit-search-icon-holder {
      left: 20px;
      top: 53.55%;
      img {
        width: fit-content;
      }
    }
  }
  .tab-dropdown-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }
  .apc-dot-holder {
    // width: 27.32px;
    margin-right: 12px;
    margin-top: 13px;
    .apc-options-block {
      right: -132px;
      .apc-options-list {
        &.active {
          background: #fff;
          position: relative;
          &:hover {
            background: rgba(153, 153, 153, 0.15);
          }
          &::after {
            box-sizing: border-box;
            transform: rotate(45deg);
            position: absolute;
            right: 17px;
            top: 8px;
            display: table;
            width: 5px;
            height: 11px;
            border: 2px solid #494949;
            border-top: 0;
            border-left: 0;
            content: "";
          }
        }
      }
    }
  }
  .search-close {
    width: 14.4px;
    display: flex;
    margin-right: 20px;
    cursor: pointer;
  }
}

.act-inner-overview-holder {
  color: #959595;
  font-size: 12px;
  margin-bottom: 10px;
  .opener {
    cursor: pointer;
    width: fit-content;
    img {
      margin: 0 2px 1px;
    }
  }
  .overview-text {
    margin-top: 5px;
    word-break: break-word;
    max-height: 80px;
    overflow: auto;
    &::-webkit-scrollbar {
      margin-top: 10px;
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: rgba(153, 153, 153, 0.5);
      border-radius: 3px;
      margin-right: 3px;
    }
  }
}

.change-act-owner-title {
  margin: 15px 15px 0 15px;
  border-bottom: 1px solid #aeaeae;
  padding-bottom: 15px;
  color: #494949;
  font-size: 14px;
}

.lock-unlock-act {
  cursor: pointer;
  margin-right: 10px;
  margin-left: 6px;
  img {
    margin-top: 4px;
    width: 18px;
  }
}
