@import "variable.scss";

.tab-activity {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-right: 20px;
  font-family: $mainFont;
}

.tab_activity {
  float: left;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 10px;
  margin: 5px 1px;
  height: 27px;
  width: max-content;
  text-align: center;
  margin-top: 4px;
  cursor: pointer;
  &:nth-last-child(2) {
    margin-right: 20px;
  }
}

.tab_dashboard {
  float: left;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 10px;
  margin: 5px 1px;
  height: 27px;
  width: max-content;
  text-align: center;
  margin-top: 4px;
  cursor: pointer;
}

.active-tab {
  font-weight: 600;
  border-bottom: 1px solid #fff !important;
  pointer-events: none;
  padding-bottom: 4px;
}

.active_tab {
  //text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
  //padding: 4px 10px;
  //margin: 4px 0px 8px 0px;
  //margin-top: 4px;
  border-radius: 13px;
  //height: 27px;
}

.general-page-search-box-holder {
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;
  width: 430px;
  height: 31px;

  .project-search {
    display: flex;
    margin-top: -5px;
    margin-left: 20px;
    margin-right: 15px;

    img {
      cursor: pointer;
    }
  }
  .project-search-parent {
    display: flex;
    position: relative;
    width: 220px;
    height: 31px;
    margin-top: -4px;
    > img {
      width: 12px;
      height: 12px;
      position: absolute;
      // right: 75px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      margin: 0;
    }
  }

  .general-page-search-icon {
    display: flex;
    // margin-right: 10px;
  }
  .general-page-search-icon img {
    cursor: pointer;
  }

  .general-page-search-box-parent {
    width: 88%;
    display: flex;
    position: relative;
    margin-top: -4px;
  }
  .general-page-search-box-parent img {
    cursor: pointer;
  }

  .general-page-search-box {
    width: 100%;
    font: normal 15px roboto;
    color: #fff;
    border: 0;
    border-bottom: 1px solid #fff;
    height: 31px;
    background: none;
    padding: 0px;
    padding-right: 20px;
  }
}

.proj-access-req-btn-holder {
  align-self: center;
  > div {
    position: relative;
  }
}

.project-request-btn {
  width: 162px;
  height: 25px;
  //opacity: 0.5;
  border-radius: 10px;
  // border: solid 1px #fff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  background: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}
