@import "variable.scss";

.proj-edit-performa {
  display: flex;
  align-items: center;
  padding-top: 1px;
  .performa-wrapper {
    display: flex;
    align-items: center;
    a:hover {
      color: #fff;
      cursor: pointer;
    }
    .hours {
      a {
        font-size: 18px;
      }
    }
  }
}

.edit-project-detail-container {
  background-size: cover !important;
  .ppl-settings-container {
    height: 100% !important;
  }
  .back-arrow-container {
    background-color: inherit;
    width: 221px;
    .back-button-profile {
      font-size: 20px;
      padding-left: 8px;
      font-weight: normal;
    }
  }
  .ppl-settings-control-container {
    width: 100% !important;
    height: calc(100vh - 110px) !important;
    background: #fff;
    .edit-proj-scroll {
      > div:first-child {
        height: inherit;
      }
    }
  }
  .ppl-settings-control-outer-holder {
    width: 100%;
    height: calc(100% - 70px);
    margin: 25px 0 0 0;
    padding: 0 0 25px 25px;
    border-bottom: 1px solid #d6d6d6;
    .img-container {
      width: 64px;
      height: 64px;
      border-radius: 10px;
      .add-logo {
        cursor: pointer;
        width: inherit;
        height: inherit;
        font-family: Roboto;
        font-size: 10px;
        color: #757575;
        background-color: #f0f0f0;
        text-align: center;
        padding-top: 39%;
        border-radius: 10px;
      }
      img {
        border-radius: 10px;
        width: 64px;
        height: 64px;
        cursor: pointer;
        object-fit: cover;
      }
      &:hover {
        //opacity: 0.2;
        // background-color: rgba(0, 144, 233, 0.9);
        position: relative;
        .update-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          border-radius: 10px;
          background-color: rgba(0, 144, 233, 0.9);
          height: 100%;
          width: 100%;
          color: white;
          cursor: pointer;
        }
        // img {
        //   display: none;
        // }
      }
    }
    .status-holder {
      width: fit-content;
      min-width: 157px;
      // background-position-x: 136px;
      background-position: right 10px bottom 50%;
      padding: 0 50px 0 10px;
    }
    .project-estimated {
      width: 100px;
      border: none;
      border-bottom: 1px solid #d6d6d6;
      text-align: left;
      line-height: 26px;
      font-size: 15px;
      color: $successMsg;
      &::-webkit-input-placeholder {
        color: $controlLabelColor;
      }
    }
    .engagement-type-project {
      .proj-settings-ddown {
        height: fit-content;
        min-height: 40px;
        max-height: 200px;
        top: 32px;
      }
    }
  }
}

.duration-holder {
  margin-bottom: 5px !important ;
}

.owner-ps {
  font: normal 15px/20px $mainFont;
}
