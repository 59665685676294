/*End of Notification*/
@import "variable.scss";

.container-act {
  .notification {
    margin: 0;
  }
}

.notification-icon-holder {
  margin-left: 30px;
  margin-right: 30px;
}

.notification-icon {
  cursor: pointer !important;
  position: relative;

  img {
    width: 25px;
    height: 28px;
  }

  .button-badge {
    height: 10px;
    width: 10px;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
    /* Position the badge within the relatively positioned button */
  }

  .button__badge_visibility {
    opacity: 0;
    visibility: hidden;
  }
}

.notification {
  background: $lightBorder;
  height: calc(100vh - 120px) !important;
  position: fixed;
  top: 87px;
  right: 23px;
  width: 408px;
  z-index: 99;
  border-radius: 10px;
  padding: 10px 0px 0 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;

  .notification-wrapper {
    overflow: hidden;
    clear: both;
    border-bottom: 1px solid rgba(174, 174, 174, 0.25);
    padding: 12px 10px;
    margin-right: 10px;
    &.on-search {
      padding-left: 0;
    }
  }

  div#mCSB_1_container {
    width: 100% !important;
  }

  .head {
    float: left;

    .icon-bell {
      margin-right: 6px;
      display: none;
    }

    h2 {
      font-size: 20px;
      font-weight: 300;
      color: #0090e9;
      margin: 0;
      line-height: 0.8;
      padding: 0 !important;
    }

    img {
      width: 16px;
      float: right;
      position: absolute;
      right: 20px;
      top: 33px;
      display: none;
      cursor: pointer;
    }
  }

  .tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;

    li {
      display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      font-weight: normal;
      color: $snow_gtd;
      padding: 0;

      &:last-child {
        margin-right: 0;
      }

      a,
      span {
        color: #494949 !important;
        font-weight: normal;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
      }
    }

    li.active {
      background: none;
      a,
      span {
        color: #0090e9 !important;
        font-weight: 600;
        // border-bottom: 1px solid #494949;
      }
    }
  }

  .notification-scroll {
    height: calc(100% - 57px);
    overflow-y: scroll;

    .mCSB_scrollTools {
      .mCSB_dragger {
        right: 2px;
      }
    }
  }

  .tab-body {
    // overflow-y: hidden;
    margin: 10px 0 0 0;
    padding-right: 10px;
    position: relative;
    height: calc(100vh - 188px);

    &::-webkit-scrollbar {
      width: 20px;
      background: transparent;
      margin-bottom: 50px;
      opacity: 0.4;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
      width: 20px;
      background: #999999;
      border-radius: 2px;
      padding-right: 10px;
      margin-bottom: 100px !important;
      opacity: 0.4 !important;
    }
    .tab-body:hover {
      overflow: auto !important;
    }
    .tab-content {
      width: 100%;
      padding: 0px 0 0 0;
      // padding: 10px 0;

      .no-notify {
        color: $attachmentColor;
        font-size: 16px;
        text-align: center;
        &.more-margin {
          margin-top: 40px;
        }
      }

      .notify-card {
        background: #fff;
        border-radius: 10px;
        margin: 0 0 10px 0;

        &:last-child {
          margin-bottom: 0;
        }

        .right-comment-notification-title {
          display: flex;
          background: #f9f9f9;
          padding: 13px 7px;
          border-radius: 10px;
          clear: both;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          position: relative;
          overflow: hidden;

          .right-notfi-title-img {
            float: left;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            // background: #aeaeae;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
            .sb-avatar__text {
              width: 25px !important;
              height: 25px !important;
              > div {
                font-size: 13px !important;
              }
            }
          }

          .right-notfi-title-des {
            float: left;
            width: calc(100% - 35px);
          }
        }

        .right-comment-desc-you {
          clear: both;
          overflow: visible !important;
          padding: 15px 10px 15px 0;
          .right-comments-reply-container {
            overflow-y: visible;
            .notify-comment-text-area.notify-comment-text-area--multiLine {
              margin-left: 5px;
              width: 275px;
              background-color: #fff;

              // min-height: 29px;
            }
          }

          .right-comment-notfi-icon {
            float: left;
            width: 52px;
            margin-right: 0;

            img {
              padding: 5px 0 0 10px;
              width: 32px;
              height: 26px;
            }
          }

          .right-comment-notifi-desc {
            width: calc(100% - 62px);
            float: left;
            padding-bottom: 10px;
            // border-bottom: 1px rgba(174, 174, 174, 0.25) solid;
            margin-bottom: 5px;
            .notifi-comments-desc-holder {
              .description {
                li {
                  color: #494949;
                  font-size: 15px;
                  word-wrap: break-word;
                  margin-bottom: 0px;
                }
                p {
                  margin-top: 10px;
                  span {
                    color: #0090e9;
                  }
                  &:first-child {
                    margin-top: 0px;
                  }
                }
                > ul,
                ol {
                  margin-left: -20px;
                  margin-bottom: 0px;
                }
                > ol {
                  margin-left: -22px;
                }
              }
            }
          }
        }

        p {
          font-size: 15px;
          color: #494949;
          word-wrap: break-word;
          margin-bottom: 0px;
          margin-top: 0;

          span.activity-title {
            color: $blueBg;
            font-size: 15px;
          }
        }

        &:last-child {
          p.post-time {
            border: none;
            margin-bottom: 0;
          }
        }

        p.card-title {
          color: #808080;
          width: 290px;

          span {
            font-weight: normal;
            color: #808080;
          }

          span.activity-title {
            color: $blueBg;
            font-size: 15px;
          }
        }

        p.description {
          span {
            font-weight: 600;
            color: $primary_gtd;
          }

          span.desc-msg {
            font-weight: 200;
            color: $font_light;
            white-space: pre-line;
          }
        }

        p.post-time {
          color: $font_notify_grey;
          padding-bottom: 0;
          padding-top: 0;
          font-size: 11px;
        }
        &:hover {
          .notifi-flag {
            display: block;
          }
        }
      }
    }
  }

  .notification_container {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 16px;
    margin-bottom: 26px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c0c0c0;

    .left {
      img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin-left: 10px;

        &.default {
          border-radius: 0;
        }
      }
    }

    .right {
      margin-left: 13px;

      .top {
        display: flex;
        flex-direction: row;

        .username {
          font-family: $source-light;
          font-size: 14px;
          font-weight: bold;
        }

        .description {
          font-family: $source-light;
          font-size: 14px;
          color: #404041;
          margin-left: 5px;
        }
      }

      .bottom {
        .time-ago {
          font-family: $source-light;
          font-size: 13px;
          font-weight: 600;
          color: #828384;
          padding: 7px;
        }
      }
    }
  }
}
.notify-card-time-categ {
  font: normal 13px/25px roboto;
  color: #808080;
  margin-top: 15px;
  margin-bottom: 7px;
}
.all-noti {
  p.description {
    color: #494949 !important;
    font-weight: 600;
  }
}

.notifyStyle {
  width: 408px;
  z-index: 1000;
  right: 10px;
  top: unset !important;

  a {
    font-size: 16px;
  }
}

.notification-alert-icon {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid $whiteColor;
  background: $attachmentError;
  position: absolute;
  right: 2px;
  top: 3px;
}

.notify-read-unread {
  width: 10px;
  height: 10px;
  background: $blueBg;
  border-radius: 50%;
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  z-index: 1 !important;

  &.read {
    background: $controlLabelColor !important;
    display: none;
  }

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.notify-card-container {
  width: 100%;

  .notify-card-holder:last-child {
    .notify-temp-holder {
      .attachment {
        .post-time {
          margin-bottom: 0;
        }
      }
    }
  }
}

.notify-card-holder {
  width: 100%;
  margin-bottom: 10px;
}

.global-show-all {
  width: 378px;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  color: #757574;
  white-space: nowrap;
  .hr-line {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    margin-right: 11px;
    background: #d6d6d6;
  }
  .show-all-txt {
    cursor: pointer;
  }
}

.notify-card-time-categ {
  font: normal 13px $mainFont !important;
  color: $attachmentColor;
  padding-bottom: 2px;
}

.notify-card-others {
  width: 378px !important;
  background: $attachmentBg;
  border-radius: 10px;
  position: relative;
  min-height: 90px;
  margin-bottom: 10px;
  &.attachment {
    &:hover {
      .notifi-flag {
        display: block !important;
      }
    }

    .notify-card-right {
      padding-top: 10px;
    }

    .notify-card-left {
      background: transparent;
    }
  }

  &.like {
    background: $whiteColor;
  }

  &.member {
    .notify-card-title {
      span {
        color: $successMsg;
      }
    }
  }
}

.notify-card-left {
  width: 40px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: $whiteColor;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.with-user {
    justify-content: flex-start;
    padding-top: 15px;

    .notify-user-img {
      display: block;
      margin-bottom: 15px;
      .sb-avatar__text {
        width: 20px !important;
        height: 20px !important;
        > div {
          font-size: 11px !important;
        }
      }
    }
  }
}
.notify-card-title {
  font: normal 15px/18px $mainFont;
  color: $attachmentColor;
  margin-left: 35px;
  span {
    // color: $blueBg;
    word-wrap: break-word;

    &.user-removed {
      color: $successMsg;
    }
  }
}
.notify-card-right {
  width: calc(100% - 100px);
  height: 100%;
  margin: 0 0 0 52px;
  padding: 15px 0 0;
  .notify-card-title {
    font: normal 15px/18px $mainFont;
    color: $attachmentColor;
    margin-left: 0;

    span {
      // color: $blueBg;
      word-wrap: break-word;

      &.user-removed {
        color: $successMsg;
      }
    }
    &.timesheet {
      color: #494949;
      width: auto;
      .time-spent {
        font-weight: 600;
      }
    }
  }
  .update-timesheet {
    font-size: 15px;
    color: #0090e9;
    margin-top: 5px;
  }
}

.notify-user-img {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
  display: none;

  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: top;
  }
}

.notify-type-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
  }
}

.notify-card-posted-time {
  font: normal 11px/13px $mainFont;
  color: $attachmentColor;
  margin: 5px 0 0 0;
}

.notify-show-more {
  font: normal 15px/20px $mainFont;
  color: $blueBg;
  width: 100%;
  border-top: 1px solid rgba(174, 174, 174, 0.25);
  text-align: center;
  padding: 7px 0 0 0;
  // margin-bottom: 30px;
  &.no-border {
    border: 0;
  }

  span {
    width: 96px;
    padding: 0px 18px 0 0;
    background: url(../img/svg/notify-showmore.svg) no-repeat right 9px;
    cursor: pointer;
    &.no-notification {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.opened {
  opacity: 0.5;
}

.no-notification-holder {
  width: 100%;
  font: normal 14px/20px $mainFont;
  color: $attachmentColor;
  text-align: center;
}

.archive-loader.notification-loader {
  position: absolute;
  z-index: 999;
}

.post-time,
.notify-card-posted-time {
  color: #818997;
  padding-bottom: 0;
  padding-top: 0;
  font-size: 11px;
}

.user-member {
  img {
    width: 18px;
    height: 20px;
  }
}

.notify-temp-holder {
  width: 378px !important;
  margin-bottom: 10px;
  &:hover {
    .notifi-flag {
      display: block !important;
    }
    .notify-read-unread {
      &.read {
        display: block !important;
        background: $controlLabelColor !important;
      }
    }
  }
}

.notification
  .tab-body
  .tab-content
  .deleted
  .notify-card
  p.card-title
  span.activity-title {
  color: $successMsg;
}

.notifi-anchor-link {
  font: italic 15px $mainFont;
  color: #457ac4 !important;

  &:hover {
    color: #457ac4 !important;
  }

  &:visited {
    color: #5045c4 !important;
  }
}
.notification-search-icon {
  float: left;
  width: 15px;
  height: 15px;
  margin: 3px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.on-search {
    padding-left: 0;
  }
  cursor: pointer;
}
.notification_search_holder {
  width: 222px;
  height: 20px;
  float: left;
  position: relative;
}

.notification-search-input {
  width: 180px !important;
  height: 20px;
  border: 0;
  color: #000;
  font: normal 18px roboto;
  background: transparent;
  margin-left: 12px;
}

.notification-search-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.notifi-options-holder {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  float: right;
  margin-left: 22px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.global-more-option-holder {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  &:hover {
    background: cornflowerblue;
    // opacity: 0.25;
  }
  img {
    position: absolute;
    top: 3px;
    right: 8px;
  }
}
.notifi-options-ddown-holder {
  width: 150px;
  height: 83px;
  border-radius: 10px;
  background: $whiteColor;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  position: fixed;
  padding: 8px 0;
  right: 38px;
  top: 135px;
  z-index: 999 !important;
  &.global {
    position: absolute;
    top: 20px;
    right: 0px;
    text-align: left;
  }
}
.notifi-options-ddown-option {
  font: normal 14px/30px $mainFont;
  color: $panelHeading;
  &:hover {
    color: $blueBg;
  }
}
.notification .tab-body .tab-content .notify-card:last-child {
  margin-bottom: 10px;
}
.notification .tab-body .tab-content .notify-card p.card-title span {
  font-weight: normal;
  color: #808080;
}
.right-comments-reply-container {
  width: 321px;
  // margin: 8px 0 7px 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  float: right;
  .notify-comment-text-area.notify-comment-text-area--multiLine {
    min-height: 29px !important;
    padding: 10px 10px 0 10px;
    border-radius: 10px !important;
    color: #757575;
    padding-right: 35px;
    resize: none;
    font: 400 13px roboto;
    background-color: #f8f8f8;
    // border: none;
    box-sizing: border-box;
    overflow-y: visible !important;
  }
}
.right-comments-reply-input {
  max-width: 275px !important;
  // min-height: 29px !important;
  border-radius: 10px;
  border: 1px solid rgba(174, 174, 174, 0.25);
  padding: 4px 8px 5px 8px;
  box-sizing: border-box;
  color: #000;
  resize: none;
  &::-webkit-input-placeholder {
    color: $controlLabelColor;
  }
}
.right-comments-reply-input:focus {
  border: 1px solid rgba(174, 174, 174, 0.25);
}
.right-comments-reply-link {
  font: 500 13px/29px $mainFont;
  color: $controlLabelColor;
  margin: 0 0 0 10px;
  cursor: pointer;
  &.active {
    color: $blueBg;
    background: none !important;
  }
}

button,
input,
select,
textarea {
  font-size: 100%;
}
button,
html,
input,
select,
textarea {
  font-family: Roboto, Helvetica Neue, sans-serif;
}
textarea {
  vertical-align: top;
  overflow: auto;
}
.Reply-comment-notification-holder {
  border-top: 1px solid rgba(174, 174, 174, 0.25);
  width: 292px;
  font: 500 15px $mainFont;
  padding: 10px 0 0 0;
  margin: 9px 0 15px 55px;
  color: $panelHeading;
  .description {
    p {
      margin-top: 10px;
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  .highlight {
    font: 500 15px $mainFontSemiBold;
    cursor: pointer;
  }
  .mention-highlight {
    // color: #494949;
    font-weight: 600 !important;
  }
}
.notification .tab-body .tab-content .notify-card p {
  // cursor: pointer;
  color: #494949 !important;
}
.Card_Title {
  width: 330px;
  .like-notify-title {
    margin-left: 45px;
  }
}
.post_time {
  color: #818997;
  padding-bottom: 10px;
  padding-top: 5px;
  font-size: 11px;
}
.comment_post_time {
  color: #818997;
  padding-top: 0;
  font-size: 11px !important;
  padding-left: 10%;
  opacity: 0.6;
  margin-top: 5px !important;
  &.like-notify-time {
    padding-left: 45px;
  }
}
.notification .tab-body {
  padding-right: 0 !important;
  margin: 10px 7px 0 0 !important;
  // overflow: hidden !important;
  margin-top: 0 !important;
}
.notify-card-holder .notifi-flag {
  cursor: pointer !important;
  &:hover {
    display: block !important;
  }
}

.notify-card-title {
  width: 86%;
}
.notification .tab-body:hover {
  // &::-webkit-scrollbar {
  //   display: block;
  // }
  &::-webkit-scrollbar-thumb {
    display: block;
    width: 4px;
    background: #999999;
    border-radius: 2px;
    padding-right: 10px;
    opacity: 0.5 !important;
  }
}
.open {
  opacity: 0.5;
}

.notification .tab-body .tab-content .notify-card p {
  a {
    // color: #808080 !important;
  }
}
.pointer {
  cursor: pointer !important;
}
.notify-comment-text-area__suggestions {
  left: 30px !important;
  // top: 64px !important;
  &.top {
    top: 0 !important;
  }

  ul.notify-comment-text-area__suggestions__list {
    width: 280px;
    opacity: 1;
    border-radius: 10px;
    background-color: #ffffff;
    position: absolute;
    left: 10px;
    // bottom: 84px;
    bottom: 20px;
    padding-top: 15px;
    padding-bottom: 7px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 5px;
    max-height: 272px;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border-bottom: 10px solid #fff;
    border-right: 5px solid #fff;
    border-top: 15px solid #fff;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #999999;
      border-radius: 2px;
      padding-right: 10px;
    }
    li.notify-comment-text-area__suggestions__item:hover {
      background-color: #f0f0f0 !important;
      cursor: pointer !important;
    }
    li.notify-comment-text-area__suggestions__item {
      padding: 9px 15px;
      // margin-bottom: 5px;
      list-style: none;
      cursor: pointer;
      height: 40px;
      .comments-people {
        > div > div {
          width: 30px !important;
          height: 30px !important;
        }
        img.user-photo {
          width: 30px !important;
          height: 30px !important;
          border-radius: 50%;
          margin-right: 15px;
          margin-top: 5px;
          display: inline-block !important;
          object-fit: cover;
        }
        .sb-avatar.sb-avatar--text {
          width: 30px !important;
          height: 30px !important;
          border-radius: 50%;
          margin-right: 15px;
          margin-top: 5px;
          display: inline-block;
          .sb-avatar__text {
            div {
              span {
                top: 0px !important;
                color: #fff;
                span {
                  top: 0px !important;
                }
              }
            }
          }
        }
        .avatarSpan {
          top: -32px;
          left: 45px;
        }

        span {
          position: relative;
          top: -19px;
          height: 18px;
          font-family: roboto;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.4px;
          color: #555;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.avatar {
          span {
            position: relative;
            top: -5px;
            // display: inline-block;
            // width: 90%;
            max-width: 200px;
            height: 18px;
            font-family: roboto;
            font-size: 15px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.4px;
            color: #555;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .mentio-name {
      position: relative;
      top: -18px;
      left: 46px;
      font-family: roboto;
      font-size: 13px;
      color: #555;
      &.avatar {
        position: relative;
        max-width: 200px;
        top: -15px;
        left: 46px;
        font-family: roboto;
        font-size: 13px;
        color: #555;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .avatarMention-name {
      position: relative;
      top: -34px;
      left: 46px;
      font-family: roboto;
      font-size: 13px;
      color: #808080;
    }
    &.notify-comment-text-area__suggestions__item--focused {
      background-color: $calenderHover !important;
    }
  }
}

.right-comment-desc-you {
  overflow: visible;
}

.notify-comment-text-area__input {
  border-radius: 10px;
  padding-left: 7px;
  padding-top: 4px;
  border: 1px solid rgba(174, 174, 174, 0.25);
}
.notify-comment-text-area__input:focus {
  outline: none;
  border: 1px solid rgba(174, 174, 174, 0.25);
}
.notify-comment-text-area.notify-comment-text-area--multiLine {
  overflow-y: visible;
}
.notify-card-others {
  &:hover {
    .notifi-flag {
      display: block !important;
    }
    .notify-read-unread {
      &.read {
        display: block;
      }
    }
  }
}
.notify-card-title {
  width: 84%;
}
textarea::-webkit-input-placeholder {
  color: #aeaeae;
}
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #aeaeae;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #aeaeae;
}

textarea:-ms-input-placeholder {
  color: #aeaeae;
}

textarea::placeholder {
  color: #aeaeae;
}
.notify-comment-text-area__suggestions {
  z-index: 99 !important;
}
li.notify-comment-text-area__suggestions__item:hover {
  cursor: pointer !important;
}

.notify-comment-text-area__suggestions123 {
  left: 80px !important;
  // top: 64px !important;
  &.top {
    top: 0 !important;
  }
}
