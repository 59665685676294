/** Reports **/

@import "variable.scss";
.timesheet_section {
  .filter-form {
    margin: 50px 0;
  }
}

.md-autocomplete-suggestions li {
  overflow: hidden;
  height: 29px;
  color: #757575;
  font: normal 15px/29px $mainFont;
  padding: 0 0 0 15px;
  cursor: pointer;
}

.bg-report {
  background-size: cover !important;
  height: 100%;
  h2 {
    margin: 0;
    padding: 5px 0 0 24px;
    color: $snow_gtd;
    font-size: 24px;
  }
  .report-header {
    display: block;
    h1 {
      text-transform: none;
    }
  }
  .dashboard-anything-filter {
    margin-right: 24px;
    .anything_header {
      display: flex;
      h1 {
        font-family: $mainFont;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0;
      }
      img {
        margin-left: 15px;
        padding: 0 !important;
      }
    }
  }
}

.timesheet_section {
  .form-inline {
    .form-group {
      margin: 0;
      padding: 0;
    }
    .timesheet-search-message {
      color: $snow_gtd;
    }
  }
}

.report-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .project_name {
    padding: 8px;
    font-size: x-large;
    font-weight: bold;
    margin-left: 15px;
  }
}

.report_body {
  background-color: #ffffff;
  .alert {
    padding: 20px;
    font-size: 16px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.admin_report_body {
  background-color: $snow_gtd;
  overflow: auto;
  height: calc(100% - 152px);
  width: 100%;
  margin: 0 auto;
  .alert {
    padding: 20px;
    font-size: 16px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .c3 svg g g.c3-axis.c3-axis-x {
    font: 11px sans-serif;
  }
}

.chart-gauge {
  width: 400px;
  height: 200px;
  margin: 100px auto;
  svg {
    width: 500px;
    height: 300px;
  }
}

.chart-first {
  fill: #9fbd35;
}

.chart-second {
  fill: #f2ba3a;
}

.chart-third {
  fill: #fb3033;
}

.c3-text {
  font-size: 14px;
}

.needle,
.needle-center {
  fill: #000000;
}

.text {
  color: #818181;
  font-size: 16px;
}

.charts {
  width: 50%;
  float: left;
  h3 {
    padding-left: 40px;
  }
}

.bar_chart {
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.table-striped {
  tbody {
    tr:nth-of-type(odd) {
      background-color: #f9f9f9;
      border-bottom: 1px solid #ddd;
    }
  }
}

.row-fluid-report {
  width: 100%;
  height: calc(100% - 276px);
  overflow: auto;
  margin: 0 auto;
  padding: 0 !important;
  background-color: #fff;
  .mCustomScrollBox {
    width: 100%;
    margin: 0 auto;
    overflow: visible;
  }
  .mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 20px 0px 20px 10px;
  }
}

.searchbar-report {
  width: 100%;
  margin: 0 auto;
  position: relative;
  .table-export {
    font: 500 14px roboto;
    letter-spacing: normal;
    background-color: #0090e9;
    position: absolute;
    right: 24px;
    top: 20px;
    width: 105px;
    height: 27px;
    border-radius: 8px;
    text-align: center;
    padding: 6px 4px 5px 0px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    img {
      margin-right: 5px;
      margin-top: -5px;
    }
  }
  .mdt-header {
    height: 64px;
    font: 500 12px robotoMedium;
    line-height: normal;
    letter-spacing: 0px;
    color: #999999;
    border: solid 1px #d6d6d6;
    overflow: hidden;
    display: block;
    max-width: 100%;
    background-color: #fff;
    // .report-table {
    //   width: 80%;
    // }
  }
  .btn {
    font-size: 17px;
    font-weight: 300;
    outline: none;
    outline-offset: 0;
    border: 0 none;
    &:focus {
      outline: none;
      outline-offset: 0;
    }
  }
  .icon_download {
    background-image: url("../img/svg/Export.svg");
    // @extend .icon_bg;
    @include icon_position(66px, auto, auto, auto);
    display: inline-block !important;
  }
  .icon_filter {
    background-image: url("../img/svg/Group_By.svg");
    // @extend .icon_bg;
    @include icon_position(39px, auto, auto, auto);
    float: right;
    padding: 0 28px;
    color: $snow_gtd;
    text-decoration: none;
  }
}

.mCSB_container_wrapper {
  margin-right: 0;
}

.timesheet_gtd {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background: #fff;
  padding: 0;
  margin: 0;
  thead {
    tr {
      th {
        border-bottom: 0;
        font-size: 16px;
        color: $font_dark;
      }
      th.date {
        width: 6%;
      }
      th.client {
        width: 10%;
      }
      th.project {
        width: 12%;
      }
      th.activity {
        width: 15%;
      }
      th.user {
        width: 8%;
      }
      th.description {
        width: 36%;
      }
      th.timetaken {
        width: 10%;
      }
      th.edit {
        width: 3%;
      }
    }
  }
  tbody + tbody {
    border: 0;
  }
  tbody {
    tr:nth-of-type(odd) {
      background-color: #f0f9fe;
      border-bottom: 0 solid #ddd;
    }
    tr {
      td {
        font-size: 16px;
        color: $font_dark;
        border: 0;
      }
      td.date {
        width: 6%;
      }
      td.client {
        width: 10%;
      }
      td.project {
        width: 12%;
      }
      td.activity {
        width: 15%;
      }
      td.user {
        width: 8%;
      }
      td.description {
        width: 36%;
      }
      td.timetaken {
        width: 10%;
      }
      td.edit {
        width: 3%;
      }
    }
  }
}

.toolbar {
  background-color: transparent !important;
  list-style: none;
  margin-top: 15px;
  text-align: center;
  padding-left: 0;
  width: 100%;
  .hours {
    float: left;
  }
  .exposort {
    float: right;
    .sort {
      .caret {
        display: none;
      }
    }
  }
  li {
    display: block;
    float: left;
    &:last-child {
      text-align: right;
      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        min-width: 160px;
        padding: 0;
        margin: 2px 0 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        background-clip: padding-box;
        .divider {
          height: 1px;
          margin: 9px 0;
          overflow: hidden;
          background-color: #e5e5e5;
        }
        li {
          a {
            display: block;
            padding: 3px 15px;
            clear: both;
            font-weight: 400;
            line-height: 1.42857143;
            color: #757575 !important;
            background-color: rgba(153, 153, 153, 0.15);
            white-space: nowrap;
            &:focus {
              text-decoration: none;
              color: #757575 !important;
            }
            &:hover {
              text-decoration: none;
              color: #757575 !important;
            }
          }
        }
        .active {
          a {
            color: #757575 !important;
            text-decoration: none;
            outline: 0;
            &:focus {
              color: #757575 !important;
              text-decoration: none;
              outline: 0;
            }
            &:hover {
              color: #fff !important;
              text-decoration: none;
              outline: 0;
            }
          }
        }
        .disabled {
          a {
            color: #757575 !important;
            &:focus {
              color: #757575 !important;
              text-decoration: none;
              background-color: transparent;
              background-image: none;
              filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
              cursor: not-allowed;
            }
            &:hover {
              color: #757575 !important;
              text-decoration: none;
              background-color: transparent;
              background-image: none;
              filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
              cursor: not-allowed;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(1) {
      text-align: left;
      min-height: 1px;
    }
  }
  .timesheet_header_total {
    font-size: 20px;
    color: $snow_gtd;
    font-weight: 300;
  }
  label {
    color: $snow_gtd;
  }
}

.timesheet_section {
  tags-input {
    .host {
      position: relative;
      margin-top: -5px;
      margin-bottom: 5px;
      height: 100%;
    }
    @include placeholder {
      color: $snow_gtd;
      font-size: 14px;
    }
  }
  .report_search {
    margin-top: 36px;
    padding-left: 5px;
    li {
      display: inline-block;
      padding-right: 5px;
      padding-left: 5px;
      float: left;
    }
    .form_control {
      width: 100%;
      @include placeholder {
        color: $snow_gtd;
        font-size: 14px;
        line-height: 1.3;
      }
      .selectize-input {
        width: 175px;
        border: none;
        padding: 9px 0 7px;
        font-size: 18px;
        border: none;
        background: none !important;
        border-radius: 0;
        box-shadow: none !important;
        border-bottom: 1px solid #e4e4e6;
        color: #fff !important;
        * {
          vertical-align: bottom;
          display: -moz-inline-stack;
          display: inline-block;
          zoom: 1;
          line-height: 1.3;
          margin-top: -3px;
        }
        @include placeholder {
          color: $snow_gtd;
          font-size: 14px;
        }
        div {
          width: 78%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        input {
          font-size: 18px !important;
          height: 20px;
          position: relative !important;
          border: none;
          padding: 8px 12px;
          background: none !important;
          border-radius: 0;
          box-shadow: none !important;
          border-bottom: 1px solid #ffffff;
          color: #ffffff !important;
        }
      }
    }
    .moment-picker-contents {
      .due_on {
        .act_due_on {
          box-shadow: none;
          background: none;
          border: none;
          border-radius: 0;
          padding: 5px 0 6px;
          border-bottom: 1px solid #e4e4e6;
          font-size: 18px !important;
          color: #fff !important;
        }
      }
    }
    .form_control {
      width: 100%;
    }
    .md-button {
      width: 100px;
      font-size: 18px;
      color: #fff !important;
    }
  }
}

.dropdown-menu {
  border-radius: 10px;
  li {
    background-color: #ffffff;
    a {
      color: #757575 !important;
      padding: 3px 15px !important;
    }
  }
  .active {
    background-color: #ffffff !important;
    a {
      color: #757575 !important;
    }
  }
}

md-dialog.report-edit {
  margin-top: 200px;
  margin-bottom: 50px;
  max-width: 700px;
  width: 700px !important;
  min-height: 575px;
  border-radius: 10px;
  background: $whiteColor;
  box-shadow: none;
  padding: 0 10px;
  max-height: none;
  overflow: visible;
}

.report-edit {
  margin-top: 200px;
  margin-bottom: 50px;
  max-width: 700px !important;
  width: 700px !important;
  min-height: 575px;
  max-height: none !important;
  border-radius: 10px;
  background: $whiteColor;
  box-shadow: none;
  padding: 0 10px;
  max-height: none;
  overflow: visible;
  .header-wrap .header-text {
    font: normal 17px roboto !important;
  }
}

.re-title-area {
  width: 100%;
  height: 52px;
  border-bottom: 1px solid $lightBorder;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  .done-link-button {
    border: 0;
    margin-top: 2px;
  }
}

.re-title {
  font: normal 17px $mainFont;
  color: #505050;
}

.re-title-right-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.circular-progress-edit-report {
  color: #0090e9 !important;
  position: absolute;
  bottom: 30px;
  right: 155px;
}

.done-link-button-bottom {
  width: 120px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #dadada;
  border: none;
  float: right;
  color: #fff;
  margin-top: 25px;
  font-family: $mainFont;
  font-size: 18px;
}
.button_active_report {
  background-color: $blueBg !important;
  &:not([disabled]):hover {
    background: $blueBg !important;
  }
}
.popup-close {
  width: 10px;
  height: 15px;
  cursor: pointer;
  &.edit-board {
    height: 29px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.re-controls-container {
  width: 100%;
  padding: 5px 15px 21px;
}

.re-control-holder {
  width: 100%;
  margin: 19px 0 0 0;
  // New activity CSS below this
  &.first-act-title {
    position: relative;
    // > div {
    //   width: inherit;
    //   > label {
    //     ::after {
    //       color: #0090e9;
    //     }
    //   }
    //   input {
    //     padding: 11.5px 14px;
    //   }
    // }
  }
  // New activity CSS above this
  &.for-checkbox {
    margin: 16px 0 0 0;
    &.edit {
      opacity: 0.3;
    }
  }
}
.add-member-activity {
  font: normal 15px/18px $mainFont;
  color: $panelHeading;
  margin: 0 0 10px 0;
}
.edit-board-look {
  md-input-container {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 0px;
    margin: 2px 0 0;
    vertical-align: middle;
  }
  .rd-hours-area {
    md-input-container {
      .md-input {
        text-align: center;
      }
    }
  }
  .md-select-value {
    padding: 0;
    min-height: auto;
  }
  md-select.md-default-theme .md-select-value.md-select-placeholder,
  md-select .md-select-value.md-select-placeholder {
    font: normal 15px $sideFont;
    color: rgba(73, 73, 73, 0.35);
  }
  .md-select-value > span:not(.md-select-icon) .md-text {
    font: normal 15px $sideFont;
    color: $successMsg;
  }
  md-input-container .md-placeholder,
  md-input-container label:not(.md-no-float):not(.md-container-ignore) {
    font: normal 15px $sideFont;
    color: $mdLabel;
    padding-left: 0;
    transform: translate3d(0, 34px, 0);
  }
  md-input-container.md-input-focused:not(.md-input-has-value)
    md-select.md-default-theme
    .md-select-value.md-select-placeholder,
  md-input-container.md-input-focused:not(.md-input-has-value)
    md-select
    .md-select-value.md-select-placeholder {
    color: $successMsg;
  }
  md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value
    label,
  md-input-container:not(.md-input-invalid).md-input-has-value label {
    color: $mdLabel;
    font: normal 10px $sideFont;
  }
  md-select,
  md-input-container .md-input {
    border-bottom: 1px solid $lightBorder !important;
    height: 28px;
    margin-top: 4px;
    padding: 0;
    font: normal 15px $sideFont;
    color: $successMsg;
    &:focus {
      border-bottom: 1px solid $lightBorder;
    }
  }
  md-select.md-default-theme .md-select-value,
  md-select .md-select-value {
    border: 0;
  }
  md-select:not([disabled]):focus .md-select-value {
    color: $successMsg;
  }
  .md-select-value .md-select-icon {
    display: block;
    width: 10px;
    margin: 0;
  }
  .md-select-value .md-select-icon:after {
    content: "";
    width: 10px;
    height: 10px;
    top: auto;
    transform: none;
    background: url(../img/svg/md-select-arrow.svg) no-repeat center center;
  }
  md-select[aria-expanded="true"] {
    .md-select-value .md-select-icon:after {
      transform: rotate(180deg);
    }
  }
  md-input-container.md-input-focused label:not(.md-no-float),
  md-input-container.md-input-has-placeholder label:not(.md-no-float),
  md-input-container.md-input-has-value label:not(.md-no-float) {
    padding: 0;
    transform: translate3d(0, 13px, 0);
    font: normal 10px $sideFont;
  }
  md-input-container.md-input-has-placeholder input {
    &::-webkit-input-placeholder {
      font: normal 15px $sideFont;
      color: rgba(73, 73, 73, 0.35) !important;
    }
  }
  md-input-container.md-input-has-placeholder md-select-label {
    padding: 0;
    transform: translate3d(0, 13px, 0);
    font: normal 10px $sideFont;
    color: rgba(73, 73, 73, 0.5);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    -webkit-box-ordinal-group: 2;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    z-index: 1;
    max-width: 100%;
    transform-origin: left top;
    margin-bottom: 5px;
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: auto;
  }
  md-select:not([disabled]):focus .md-select-value {
    border-bottom-width: 0;
  }
  .md-errors-spacer {
    display: none;
  }
  md-chips.md-default-theme .md-chips,
  md-chips .md-chips {
    border-bottom: 1px solid $lightBorder;
  }
  .md-chips md-chip .md-chip-content span {
    color: $whiteColor;
  }
  .md-chips md-chip {
    background: $panelHeading;
    margin-bottom: 0;
  }
  md-chips.md-default-theme md-chip md-icon,
  md-chips md-chip md-icon {
    color: $whiteColor;
  }
  md-chips.md-default-theme .md-chips .md-chip-input-container input,
  md-chips .md-chips .md-chip-input-container input {
    color: $successMsg;
    &::-webkit-input-placeholder {
      color: $successMsg;
    }
  }
  md-select-value .user-name {
    color: $successMsg;
  }
  .input-group-addon {
    display: none;
  }
  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    background: none;
    box-shadow: none;
  }
  md-input-container.md-default-theme .md-input,
  md-input-container .md-input {
    color: $successMsg;
  }
}

form.edit-report-form {
  overflow: visible;
  padding-bottom: 21px;
}

.custom-dddown-container-md {
  min-width: 650px !important;
  margin-left: 14px;
  md-select-menu {
    margin: 40px 0 0 0;
  }
  .md-container-ignore {
    display: none;
  }
}

md-select-menu {
  max-height: 263px;
}

.custom-dddown-container-md-client {
  min-width: 650px !important;
  margin-left: 14px;
  md-select-menu {
    margin: 60px 0 0 0;
  }
  .md-container-ignore {
    display: none;
  }
}

.selectdemoSelectHeader#select_container_10 {
  min-width: 650px !important;
}

.selectdemoSelectHeader {
  min-width: 285px !important;
  margin-left: 14px;
  md-select-menu {
    margin: 85px 0 0 0;
  }
  .md-container-ignore {
    display: none;
  }
  md-select-menu.md-default-theme md-content md-optgroup,
  md-select-menu md-content md-optgroup {
    width: 100%;
    height: 192px;
    overflow: auto;
    &.exceeded {
      width: calc(100% - 8px);
    }
  }
  md-select-menu.md-default-theme md-content md-optgroup,
  md-select-menu md-content md-optgroup::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  md-select-menu.md-default-theme md-content md-optgroup,
  md-select-menu md-content md-optgroup::-webkit-scrollbar-thumb {
    width: 4px;
    background: $topNaviColor;
    border-radius: 2px;
  }
  md-option {
    height: 43px;
  }
}

.custom-dddown-container-md-client,
.custom-dddown-container-md {
  md-optgroup md-option {
    padding-left: 15px;
    padding-right: 15px;
    height: 29px;
  }
  md-select-menu md-content {
    width: 100%;
    max-height: 175px;
    margin: 4px 0;
  }
  md-select-menu md-content::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }
  md-select-menu md-content::-webkit-scrollbar-thumb {
    width: 4px;
    background: $topNaviColor;
    border-radius: 2px;
  }
  md-select-menu {
    background: $whiteColor;
  }
}

.exceeded {
  .custom-dddown-container-md-client,
  .custom-dddown-container-md {
    md-select-menu md-content {
      width: calc(100% - 4px);
    }
  }
}

.selectdemoSelectHeader md-content._md {
  max-height: auto;
}

.re-mem-hours-area {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.re-member-area {
  width: 305px;
}

.edit-board-look .re-member-area .md-select-value {
  .md-select-icon {
    display: none;
  }
}

.rd-date-area {
  // width: 150px; // commented for new activity design
  // margin-bottom: 9px; // commented for new activity design
  .report-date-label {
    font: normal 10px/11px roboto;
    color: rgba(73, 73, 73, 0.5);
    margin-bottom: 0;
  }
  input {
    // border: none;
    // border-bottom: 1px solid #e3e3e3 !important;
    // height: 28px;
    // padding: 0;
    font: normal 15px roboto;
    // color: #494949;
    cursor: pointer;
  }
}

.rd-tt-time-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  top: 5px;
  &.duration {
    // position: relative;
    position: inherit; // new activity design
    margin-left: 50px;
    .re-tarea-title {
      position: absolute;
      left: 0;
      top: -2px;
    }
  }
}
.report-row {
  display: flex;
  align-items: center; // new activity design
}
.rd-hours-area {
  // width: 40px;
  height: 100%;
  margin: 0 10px 0 0;
  input {
    border: none;
    border-bottom: 1px solid #e3e3e3 !important;
    // height: 28px;
    height: 100%;
    border-radius: 5px;
    // margin-top: 4px;
    padding: 0;
    font: normal 15px roboto;
    width: 100%;
    text-align: center;
  }
}
.report-duration {
  font: normal 15px/11px $mainFont;
  color: rgba(73, 73, 73, 0.5);
  // margin: 5px 15px 0 0;
  margin-right: 15px; // new activity design
}
.rd-hours-txt {
  margin: 0 4px 0 0;
  font: normal 15px $sideFont;
  color: $mdLabel;
  &.min {
    margin: 0;
  }
}

.re-tarea-title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 3px 0;
}

.re-tarea-title {
  font: normal 10px/11px $sideFont;
  color: $mdLabel;
}

.re-tarea {
  width: calc(100% - 25px);
  background: $reTxtarea;
  border-radius: 6px;
  min-height: 75px;
  border: 0;
  padding: 12px;
  font: normal 13px $sideFont;
  color: $successMsg;
  resize: none;
  &::-webkit-input-placeholder {
    color: $mdLabel;
  }
}

md-select.md-default-theme[disabled] .md-select-value,
md-select[disabled] .md-select-value {
  background-image: none;
  opacity: 0.2;
}
.md-chips .md-chip-input-container input {
  &::-webkit-input-placeholder {
    font: normal 15px $sideFont;
    color: rgba(73, 73, 73, 0.35) !important;
  }
}

.md-dialog-container {
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
}
.ngdialog-overlay {
  background: rgba(0, 0, 0, 0.7);
}
.md-dialog-container::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

.md-dialog-container::-webkit-scrollbar-thumb {
  width: 4px;
  background: $topNaviColor;
  border-radius: 2px;
}

.table-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 15px 24px;
  .table-sort {
    span {
      font: normal 16px $mainFont;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
    button {
      background: url("../img/svg/board_arrow.svg") right center no-repeat;
      border: none;
      font: 600 16px $mainFont;
      line-height: normal;
      letter-spacing: normal;
      padding-right: 10px;
      color: #ffffff;
      .caret {
        display: none;
      }
    }
    ul {
      padding: 0 !important;
      height: auto;
      border-radius: 10px !important;
      background-color: #ffffff;
      width: 285px;
      a {
        color: #757575 !important;
        cursor: pointer;
      }
      li {
        border-radius: 10px !important;
        color: #757575 !important;
        background: #ffffff;
        // padding: 3px 15px !important;
        &:last-child {
          &:hover {
            a {
              border-bottom-left-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
            }
          }
        }
      }

      span {
        color: #757575;
        font-size: 15px;
        line-height: 0.9;
        cursor: pointer;
      }
    }
  }
}

.table_export {
  font-family: $mainFont;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 100px;
}

.mdt-header {
  height: 64px;
  font: 500 12px $sideFontMedium;
  line-height: normal;
  letter-spacing: 0px;
  color: #999999;
  border: solid 1px #d6d6d6;
  overflow: hidden;
  .md_date {
    width: 11%;
    padding: 0 0 0 1px;
  }
  .md_time {
    width: 8%;
  }
  .md_client {
    width: 11%;
  }
  .md_project {
    width: 11%;
  }
  .md_activity {
    width: 16%;
  }
  .md_type {
    width: 11%;
  }
  .md_mem {
    width: 11%;
  }
  .md_des {
  }
}

.mdtable-header {
  .custom_row {
    &:hover {
      .edit_img {
        display: block;
        padding: 10px;
      }
    }
  }
  .dropdown-menu > li > a:focus,
  .dropdown-menu > li > a:hover {
    color: #757575 !important;
    text-decoration: none;
    background-color: rgba(153, 153, 153, 0.15);
  }
}

.table-export {
  font: 500 14px $mainFont;
  letter-spacing: normal;
  color: $blueBg;
  position: absolute;
  right: 24px;
  top: 24px;
}

.table-text {
  span {
    font: normal 16px $mainFont;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}

.table_duration {
  font: normal 16px $mainFont;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  span {
    font-weight: 600;
  }
}

.mdt-header {
  height: 64px;
  font: 500 12px $sideFontMedium;
  line-height: normal;
  letter-spacing: 0px;
  color: #999999;
  border: solid 1px #d6d6d6;
  overflow: hidden;
  .md-date {
    width: 11%;
    cursor: pointer;
  }
  .md-time {
    width: 9%;
    cursor: pointer;
  }
  .md-client {
    width: 11%;
  }
  .md-project {
    width: 12%;
    cursor: pointer;
  }
  .md-activity {
    width: 18%;
  }
  .md-type {
    width: 13%;
    cursor: pointer;
  }
  .md-mem {
    width: 13%;
    cursor: pointer;
  }
}

.mdtable-header {
  font-family: $sideFont;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #999999;
  .custom_row {
    border-bottom: 1px solid #f0f0f0 !important;
    border-top: none !important;
    &:hover {
      .edit-img {
        display: block;
      }
    }
    .custom {
      font-family: $sideFont;
      font-size: 12px !important;
      color: #494949 !important;
      font-weight: 400;
      line-height: 16px;
      padding-top: 25px;
      padding-bottom: 25px;
      word-break: break-word;
      vertical-align: text-top;
    }
    .td_date {
      width: 11%;
      word-break: break-word;
    }
    .td_dur {
      width: 8%;
      word-break: break-word;
    }
    .td_client {
      width: 10.9%;
      word-break: break-word;
    }
    .td_project {
      width: 11%;
      word-break: break-word;
    }
    .td_title {
      width: 16.1%;
      word-break: break-word;
    }
    .td_tags {
      width: 11.1%;
      word-break: break-word;
    }
    .td_name {
      width: 11.1%;
      word-break: break-word;
    }
    .td_des {
      word-break: break-word;
      width: 18%;
    }
  }
  table {
    thead {
      display: none;
    }
    caption {
      display: block;
      &:first-child {
        display: none;
      }
    }
  }
}

.timesheet-footer {
  position: relative;
  background-color: #f9f9f9;
  border-top: 1px solid $calenderHover;
  border-bottom: 1px solid $calenderHover;
  height: 50px;
  .total {
    width: 78px;
    height: 14px;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #505050;
    text-align: left !important;
    padding: 15px 0 15px 0px !important;
  }
  .value {
    width: 54px;
    height: 14px;
    font-family: $sideFont;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #505050;
  }
}
.total-durtaion-row {
  width: 100%;
  // background: $attachmentHeading;
  background: #0090e9; // new activity design
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.td-value {
  font: 500 13px $sideFontMedium;
  color: $successMsg;
  padding-left: 24px;
  &.total {
    width: 11%;
    color: #fff;
    font-size: 14px;
  }
}

.wrap-export {
  display: flex;
}

.table-sort-reverse {
  background: url("../img/svg/arrow_up.svg") right center no-repeat !important;
}

.edit-img {
  display: none;
  text-align: center;
}

.filter_arrow {
  margin-bottom: 5px;
}

.txt-trans-none {
  text-transform: none;
}

.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background: rgba(153, 153, 153, 0.15) !important;
}

.dropdown-menu > li > a:hover {
  background: rgba(153, 153, 153, 0.15) !important;
}

.dropdown-menu > .active > a {
  background: transparent !important;
}

.report-table-body-area .mCSB_scrollTools {
  width: 0;
}
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(153, 153, 153, 0.5);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(153, 153, 153, 0.5);
  border-radius: 4px;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(153, 153, 153, 0.5);
}

@media screen and (max-width: 1360px) {
  .row-fluid {
    .mCustomScrollBox {
      width: 100%;
    }
  }
}
.mdt-header {
  table {
    width: 100%;
  }
}

.mdtable-header {
  table {
    width: 100%;
  }
}
.report-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.report-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.act-submit-btn-holder {
  width: 100%;
  margin: 25px 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  &.with-total {
    justify-content: space-between;
  }
  &.change-password-btn {
    width: auto;
    margin: 25px 10px 10px 0;
    &.google {
      margin: 30px 20px 20px 0;
    }
    &.cannot-start-timer {
      justify-content: space-between;
      margin-left: 20px;
      align-items: center;
      .workspace-holder {
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          border-radius: 2px;
          object-fit: cover;
        }
        .sb-avatar {
          .sb-avatar__text {
            border-radius: 2px;
            width: 30px !important;
            height: 30px !important;
          }
        }
        .workspace-name-to-switch {
          margin-left: 10px;
          color: #505050;
          font-size: 14px;
        }
      }
    }
  }
}
.act-submit-btn {
  width: 120px;
  height: 40px;
  background: $blueBg;
  border-radius: 10px;
  text-align: center;
  font: 500 18px/40px $mainFont;
  color: $whiteColor;
  box-shadow: none;
  border: 0;
  &.google {
    width: 110px;
  }
  &.btn-active {
    background: $blueBg !important;
  }
  &:disabled {
    background: #dadada;
  }
}

.active {
  background: $blueBg;
}

.sort-arrow {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: -12px;
    top: 50%;
    width: 6px;
    height: 13px;
    background: none;
  }
  &.down {
    &::after {
      transform: rotate(180deg) translateY(50%);
      background: url(../img/svg/sort-arrow.svg) no-repeat center center;
    }
  }
  &.up {
    &::after {
      transform: rotate(0deg) translateY(-50%);
      background: url(../img/svg/sort-arrow.svg) no-repeat center center;
    }
  }
}
.report-table {
  th {
    height: 64px;
    padding-left: 24px;
    font-weight: normal;
  }
  // th:hover {
  //     .sort-arrow {
  //         &::after {
  //             background: url(../img/svg/sort-arrow.svg) no-repeat center center;
  //         }
  //     }
  // }
}
.report-table-body {
  td {
    padding-left: 24px;
  }
  td.td-edit {
    padding-left: 0;
  }
}
.row-fluid {
  .mCSB_scrollTools {
    right: 20px;
  }
}

.title-when-nc {
  font: normal 10px $sideFont;
  color: rgba(73, 73, 73, 0.5);
  position: relative;
  top: -2px;
}

.custom-table-row {
  height: 100%;
  .mdtable-header {
    font-family: $sideFont;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #999999;
    .custom_row {
      border-bottom: 1px solid #f0f0f0 !important;
      border-top: none !important;
      &.stopped-by-app {
        > td {
          color: #ef5350 !important;
        }
      }
      &:hover {
        .edit-img {
          display: block;
        }
      }
      .custom {
        font-family: $sideFont;
        font-size: 12px !important;
        color: #494949 !important;
        font-weight: 400;
        line-height: 16px;
        padding-top: 25px;
        padding-bottom: 25px;
        word-break: break-word;
        vertical-align: text-top;
      }
      .td_date {
        width: 11%;
        word-break: break-word;
      }
      .td_dur {
        width: 9%;
        word-break: break-word;
      }
      .td_client {
        width: 10.9%;
        word-break: break-word;
      }
      .td_project {
        width: 12%;
        word-break: break-word;
      }
      .td_title {
        width: 18%;
        word-break: break-word;
      }
      .td_tags {
        width: 13%;
        word-break: break-word;
      }
      .td_name {
        width: 13%;
        word-break: break-word;
      }
      .td_des {
        word-break: break-word;
        width: 24%;
        position: relative;
        padding-right: 80px;
        box-sizing: border-box;
        .td-edit {
          padding-left: 0;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          position: absolute;
          right: 55px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background: #f5f5f5;
          }
        }
        .td-delete {
          padding-left: 0;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          position: absolute;
          right: 22px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            background: #f5f5f5;
          }
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
    table {
      thead {
        display: none;
      }
      caption {
        display: block;
        &:first-child {
          display: none;
        }
      }
    }
  }
}

.box-comment {
  textarea {
    color: #000000 !important;
  }
}

.delconfirm {
  width: 245px;
  height: 130px;
  background: #fff;
  position: absolute;
  right: 10px;
  top: 52px;
  border: 1px solid #d6dadc;
  border-bottom-color: #c4c9cc;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 99;
  border-radius: 10px;
  bottom: 30px;

  .pop-over-header {
    height: 36px;
    position: relative;
    margin-bottom: 10px;

    .pop-over-header-title {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #505050;
      display: block;
      line-height: 36px;
      border-bottom: 1px solid #d6dadc;
      margin: 0 10px;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
      font-size: 15px;
      font-weight: 500;
      font-family: $mainFont;
    }
  }

  .del-body {
    padding: 0 10px 10px;

    p {
      font-family: $sideFont;
      font-size: 13px;
      color: #808080;
      margin-bottom: 7px;
    }

    .btn-wrap {
      display: flex;
      justify-content: flex-end;
    }

    .can-del {
      font-family: $sideFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0090e9;
      background: transparent;
      border: none;
      font-size: 15px;
      &:hover {
        background: transparent !important;
      }
    }

    .bt-del {
      background: #ef5350;
      border: none;
      border-radius: 10px;
      color: #fff;
      width: 83px;
      height: 30px;
      font-size: 15px;
      font-family: $sideFont;
      margin-left: 15px;
      &:hover {
        background: #ef5350 !important;
      }
    }
  }
}

.page-right-section {
  &.report {
    position: relative;
  }
}

.bg-report {
  .profile-container {
    top: 78px;
  }
}

.custom-table-row {
  table {
    &:last-child {
      tr:nth-last-child(2) {
        .delconfirm {
          top: -20px;
        }
      }
    }
  }
}

.custom-table-row {
  table {
    tbody {
      tr:last-child {
        .duration-delconfirm {
          top: -84px;
        }
      }
    }
  }
}

.custom-table-row {
  table {
    tbody {
      tr:first-child {
        .delconfirm {
          top: 265px !important;
          position: fixed;
        }
      }
    }
  }
}

.row-fluid {
  &.when-single-row {
    overflow: visible;
    .mdtable-header,
    .mCSB_container,
    .mCSB_container_wrapper,
    .mCustomScrollBox {
      overflow: visible;
    }
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.report-scroll {
  height: calc(100% - 213px) !important;
  background-color: #fff;
  transition: 0.4s;
  > div:first-child {
    height: 100%;
    width: inherit;
  }
}
.with-footer {
  height: calc(100% - 258px) !important;
}

.edit-report-img {
  margin: 0;
}

.report-type-list {
  width: 147px;
  height: 103px;
  margin: 5px 16px 62px 78px;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  position: fixed;
  z-index: 20;
  right: 8px;
  top: 200px;
}

.select-format {
  width: 62px;
  height: 11px;
  margin: 0 10px 8px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
}
.format-download {
  width: 100%;
  height: 30px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #757575;
  padding-left: 10px;
  padding-top: 6px;
  vertical-align: middle;
  cursor: pointer;
  &:focus {
    background-color: #eeeeee;
  }
  &:hover {
    background-color: #eeeeee;
  }
}

/** Reports End **/

.act-title-clr-btn {
  position: absolute;
  right: 10px;
  bottom: 15px;
  cursor: pointer;
}