@import "variable.scss";

.fp-header-area {
  width: 100%;
  margin: 120px 0 0 0;
}

.fp-google-header {
  margin: 194px 0 0 0;
}

.fp-main-heading {
  font: normal 20px/26px $mainFont;
  color: $panelHeading;
  text-align: center;
}

.fp-sub-heading {
  font: normal 15px/23px $mainFont;
  color: $controlLabelColor;
  text-align: center;
  margin: 18px auto 0;
}

.fp-google-sub-heading {
  color: #999999;
}

.rp-controls-container {
  width: 100%;
  margin: 74px 0 0 0;

  &.fp {
    min-height: 72px;
  }

  &.cp {
    margin: 60px 0 0 0;
  }
}

.rp-submit-holder {
  width: 100%;
  margin: 30px 0 0 0;

  &.fp {
    margin: 10px 0 0 0;
  }

  span {
    font: 500 18px/40px $mainFont;
  }
}

.common-submit-btn {
  background-color: $blueBg;
  width: 205px;
  height: 43px;
  border-radius: 10px;
  font: 500 18px $mainFont;
  text-transform: capitalize;
  margin: 0 auto;
  padding: 0 15px;
  display: block;
  color: $whiteColor;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.rf-bottom-area {
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.back-login {
  margin-top: 82px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 15px;
  font-family: $mainFont;
  p {
    margin: 0;
    color: #808080;
    a {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.back-to-login-link {
  font-family: $mainFont;
  font-size: 16px;
  font-weight: 600;
  color: $blueBg;
  cursor: pointer;
  text-align: center;
  display: block;

  &:hover {
    color: $blueBg;
  }
}

.success-msg-fpw {
  font: normal 12px/15px $mainFont;
  color: $successMsg;
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
  white-space: nowrap;
}

.din-receive {
  font-family: $mainFont;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin: 79px 0 0 0;
}

.new-link-sent {
  font-size: 15px !important;
  color: #999999 !important;
}

.reset-new-link {
  margin-top: 194px !important;
}

.res-lin {
  margin: 40px 0 0 0 !important;
}

#loginbox {
  .right {
    .signIn-container {
      .signIn {
        .common-submit-btn {
          background-color: $blueBg;
          width: 205px;
          height: 43px;
          border-radius: 10px;
          font: 600 15px $mainFontSemiBold;
          text-transform: capitalize;
          margin: 0 auto;
          padding: 0 15px;
          display: block;
          color: $whiteColor;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        }
        .rp-resend{
          text-transform: none;
        }
      }
    }
  }
}
