@import "variable.scss";

//// New css

.activity-popup-container {
  overflow: inherit !important;
}
.checklist-container {
  width: 100%;
  padding: 15px 15px;
  min-height: 325px;
  position: relative;
  &.loader-bottom {
    .loader-holder {
      justify-content: flex-end;
    }
  }
  .loading-div {
    // text-align: center;
    justify-content: center;
    svg {
      color: $primary_gtd;
    }
  }
}

.checklist-header-container {
  width: 100%;
  margin-bottom: 25px;
}

.checklist-count {
  margin: 0;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #808080;
}

.checklist-header-container-wrap {
  height: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap-hide-show {
  display: flex;
  &.rearrange-zone {
    padding: 0 5px;
  }
}

.add-new-button {
  cursor: pointer;
  height: 16px;
  font-family: $mainFont;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #0090e9;
  &.cancel-button {
    margin-left: 20px;
  }
  &:hover {
    text-decoration: underline;
  }
}

.hide-completed-button {
  width: 76px;
  height: 14px;
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: right;
  color: #0090e9;
  margin-right: 22px;
  margin-top: 2px;
}

.hide-completed-button-hide {
  width: 0%;
}

.checklist-message {
  font: 300 16px/19px $sideFontLight;
  color: #8b8b8b;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  white-space: nowrap;
}

.checklists {
  transition: background-color 3s ease;
  margin-bottom: 20px;
  ol {
    margin: 0;
    padding-left: 15px;
  }
  &.new-checklist {
    background-color: rgba(0, 144, 233, 0.05);
  }
  &.from-rearrange {
    margin-bottom: 0px;
    // padding: 15px;
    // &.hovering {
    //   div {
    //     display: none;
    //   }
    //   img {
    //     display: inline;
    //   }
    // }
  }
}

.checklist-title-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  //position: relative;
  justify-content: space-between;
  &.checklist-title-container-edit {
    > div {
      width: 100%;
    }
  }
  .checklist-title-container-child {
    display: flex;
    padding: 5px 0;
    width: 98%;
    .checklist-arrow-container {
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 10px;
      &:hover {
        cursor: pointer;
        background-color: rgba(216, 216, 216, 0.2);
      }
    }
    .title-description {
      font-family: roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #505050;
      width: 96%;
      word-break: break-all;
      padding: 0 9px;
    }
  }
  .checklist-title-edit-container {
    .title-edit-options-container {
      width: 153px;
      height: 167px;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
      background-color: #ffffff;
      position: absolute;
      padding: 8px 0;
      z-index: 1;
      .title-edit-options {
        padding: 5px 12px;
        font-family: roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #757575;
        height: 30px;
        text-align: left;
        cursor: pointer;
        &:hover {
          background: rgba(153, 153, 153, 0.15);
        }
      }
    }
  }

  &.rearrange-section {
    border-radius: 5px;
    padding: 10px 5px;
    justify-content: start;
    .title-description {
      margin-left: 20px;
    }
    &.hovering {
      padding: 10px;
      div {
        visibility: hidden;
      }
      img {
        visibility: hidden;
      }
    }
  }
}

.checklistitem-container {
  margin-top: 10px;
  padding: 0 20px;
  //New Activity CSS below this
  padding: 0 0 0 10px;
  //New Activity CSS above this
  // .checklist-progress-bar {
  //   &:last-child {
  //     top:5px;
  //   }
  // }
  &.with-no-items {
    margin-top: auto;
  }
}
.checklist-progress-bar {
  div {
    height: 2px;
    border-radius: 1.5px;
    background: #f0f0f0;
    // position: relative;
    // top:5px;
    div {
      background: rgb(62, 223, 181);
      height: 100%;
    }
  }
}

.delete-img-container {
  visibility: hidden;
  //New Activity CSS below this
  display: flex;
  justify-content: flex-end;
  width: 15%;
  margin-right: 10px;
  //New Activity CSS above this
  .del-inner {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    //New Activity CSS below this
    margin-right: 10px;
    //New Activity CSS above this
    border-radius: 50%;
    &:hover {
      background-color: #f9f9f9;
    }
    &.bookmark {
      margin-right: 0;
    }
  }
  img {
    margin-right: 0 !important;
    margin-top: 0 !important;
    padding: 5px;
    // border-radius: 50%;
  }
  img:hover {
    cursor: pointer;
    padding: 5px;
    // border-radius: 50%;
    // background-color: #f9f9f9;
  }
  &.visible {
    visibility: visible;
  }
}

.title-description {
  font-family: $mainFont;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #757575;
  width: 88%;
  word-break: break-all;
  span {
    width: 32px;
    height: 16px;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #999999;
  }
}

.edit-button {
  font-family: $sideFont;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #0090e9;
  letter-spacing: 0.2px;
  height: 19px;
  margin-right: 22px;
}

.delete-button {
  width: 35px;
  height: 14px;
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: #0090e9;
}

.wrap-delete-arrow {
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  &.pos-rel {
    position: relative;
  }
}

.checklist-title-container-edit {
  .checklist-title-container-edit-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
    .checklist-title-input {
      width: 97%;

      font-family: $mainFont;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #505050;
      border: none;
      background-color: #f6f6f6;
      border-radius: 10px;
      resize: none;
      overflow-y: hidden;
      position: relative;
      word-break: break-word;
      padding: 5px 10px 5px 10px;
    }
  }
  .save-button {
    width: 27px;
    height: 14px;
    font-family: $mainFont;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0090e9;
    background-color: transparent;
    border: none;
    margin: 8px 0 18px 0;
    &:hover {
      background-color: transparent !important;
    }
    &:disabled {
      color: #aeaeae;
    }
  }
  // .enable {
  //   color: #0090e9 !important;
  // }
}

.checklist-title-container-edit {
  width: 100%;

  .checklist-title-container-edit-inner {
    height: 32px;
    .checklist-title-input {
      width: 94%;
    }
  }
}

.checklist-title-edit-container.isEdit {
  // position: relative;
  // top: -18px;
  display: none;
}

.checklist-progress-bar {
  margin: 0 0 0px 28px;
  width: 85%;
  //New Activity CSS below this
  width: 75%;
  //New Activity CSS above this
  display: inline-block;
  .wl-user-percent-holder {
    margin: 0;
  }
  .wl-user-percent-text {
    color: #808080;
  }
  .wl-user-percent-base {
    width: 558px;
  }
  .wl-user-percent-top {
    transition: all 0.2s ease-in;
  }
}

.additem-container {
  width: 100%; //display: flex;
  flex-direction: row;
  padding: 0px 0;
  margin: 0 0 0 5px;

  .oval-image {
    margin-right: 10px;
  }
  .unchecked {
    // height: 25px;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    color: #505050;
    display: flex;
    align-items: center;
    img {
      margin-right: 15px;
      margin-top: 2px;
    }
  }
  .checked {
    // height: 25px;
    // text-decoration: line-through;
    color: #808080;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.36;
    font-style: italic;
    text-align: left;
    word-break: break-word;
    // word-break: break-all;
    // width: 100%;
    display: flex;
    align-items: center;
    img {
      margin-right: 15px;
      margin-top: 2px;
    }
  }
  .checklist-item-edit-container {
    width: 100%;
    position: relative;
    .edit-wrap {
      position: relative;
    }
    .checklist-item-edit {
      width: 92%;
      height: 32px;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #505050;
      margin-bottom: 10px;
      border: none;
      background-color: #f6f6f6;
      border-radius: 10px;
      resize: none;
      overflow-y: hidden;
      position: relative;
      word-break: break-word;
      padding: 5px 10px 5px 10px;
    }
    .delete-checklist-item {
      margin-left: 30px;
      .save-item-button {
        background: transparent;
        border: none;
        color: #0090e9;
        font-size: 12px;
      }
      .delete-item-button {
        background: transparent;
        border: none;
        color: #0090e9;
        font-size: 12px;
      }
    }
  }
}

.add-checklistitems-container {
  margin-left: 38px;
  margin-top: 16px;
  &.with-no-items {
    margin-top: auto;
  }
  .additem-wrap {
    display: flex;
    align-items: center;
    .add-item {
      font-family: $mainFont;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      color: #0090e9;
      margin-left: 8px;
    }
  }
}
.checklist-del-confirm {
  width: 245px;
  height: 155px;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: 25px;
  border: 1px solid #d6dadc;
  border-bottom-color: #c4c9cc;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 999;
  border-radius: 10px;
  .pop-over-header {
    height: 36px;
    position: relative;
    margin-bottom: 13px;
    .pop-over-header-title {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #505050;
      display: block;
      line-height: 36px;
      border-bottom: 1px solid #d6dadc;
      margin: 0 10px;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
      font-weight: 500;
      font-family: $mainFont;
      font-size: 15px;
    }
  }
  .del-body {
    padding: 0 10px 10px;
    p {
      font-family: $sideFont;
      font-size: 13px;
      color: #808080;
      margin-bottom: 30px;
    }
    .btn-wrap {
      display: flex;
      justify-content: flex-end;
    }
    .can-del {
      font-family: $sideFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0090e9;
      background: transparent;
      border: none;
      font-size: 15px;
    }
    .bt-del {
      background: #ef5350;
      border: none;
      border-radius: 10px;
      color: #fff;
      width: 83px;
      font-size: 15px;
      font-family: $sideFont;
      margin-left: 15px;
      height: 30px;
    }
  }
  .del-confirm-cross {
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 260px;
  }
}

.add-item-input {
  margin: 5px 0 0 5px;
  padding: 0 20px;
  .wrap-text-cross {
    display: flex;
    position: relative;
    margin-top: 10px;
    .checklist-textarea-input {
      // width: 97%;
      width: calc(100% - 45px);
      height: auto;
      font-family: roboto;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #757575;
      border: none;
      background-color: #f6f6f6;
      border-radius: 10px;
      resize: none;
      overflow-y: hidden;
      position: relative;
      word-break: break-word;
      margin-left: 8px;
      padding: 5px 10px 5px 10px;

      textarea.checklist-textarea-input__input {
        border: none;
        padding: 5px 10px;
      }

      .checklist-textarea-input__suggestions {
        ul.checklist-textarea-input__suggestions__list {
          width: 280px;
          opacity: 1;
          border-radius: 10px;
          background-color: #ffffff;
          position: absolute;
          left: 10px;
          bottom: 10px;
          padding-top: 15px;
          padding-bottom: 7px;
          overflow-y: scroll;
          overflow-x: hidden;
          padding-left: 5px;
          max-height: 272px;
          // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
          border-bottom: 10px solid #fff;
          border-right: 5px solid #fff;
          border-top: 15px solid #fff;
          &::-webkit-scrollbar {
            width: 4px;
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            width: 4px;
            background: #999999;
            border-radius: 2px;
            padding-right: 10px;
          }
          li.checklist-textarea-input__suggestions__item {
            padding: 9px 15px 7px 15px;
            list-style: none;
            cursor: pointer;
            height: 40px;
            .comments-people {
              > div > div {
                width: 30px !important;
                height: 30px !important;
              }
              .sb-avatar.sb-avatar--text {
                width: 30px !important;
                height: 30px !important;
                border-radius: 50%;
                margin-right: 15px;
                margin-top: 5px;
                display: inline-block;
                .sb-avatar__text {
                  div {
                    span {
                      top: 0px !important;
                      color: #fff;
                      span {
                        top: 0px !important;
                      }
                    }
                  }
                }
              }
              .avatarSpan {
                top: -32px;
                left: 45px;
              }
              img.user-photo {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 15px;
                margin-top: 5px;
                display: inline-block;
                object-fit: cover;
              }

              span {
                position: relative;
                top: -19px;
                // display: inline-block;
                // width: 90%;
                height: 18px;
                font-family: roboto;
                font-size: 15px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.4px;
                color: #555;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &.avatar {
                span {
                  position: relative;
                  top: -5px;
                  // display: inline-block;
                  // width: 90%;
                  max-width: 200px;
                  height: 18px;
                  font-family: roboto;
                  font-size: 15px;
                  font-weight: 500;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: -0.4px;
                  color: #555;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .mentio-name {
              position: relative;
              top: -18px;
              left: 46px;
              font-family: roboto;
              font-size: 13px;
              color: #555;
              &.avatar {
                position: relative;
                max-width: 200px;
                top: -15px;
                left: 46px;
                font-family: roboto;
                font-size: 13px;
                color: #555;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .avatarMention-name {
              position: relative;
              top: -34px;
              left: 46px;
              font-family: roboto;
              font-size: 13px;
              color: #808080;
            }
            &.checklist-textarea-input__suggestions__item--focused {
              background-color: $calenderHover !important;
            }
          }
        }
      }
    }
    .cross {
      margin-left: 10px;
      position: absolute;
      bottom: 10px;
      right: 0;
    }
  }
  .save-button {
    font-family: $mainFont;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #0090e9;
    border: none;
    background-color: transparent;
    margin-top: 8px;
    margin-left: 35px;
    padding: 0;
    &:hover {
      background-color: transparent !important;
    }
    &:disabled {
      color: #aeaeae;
    }
  }

  img.check-round {
    margin: 5px 0 0 0;
  }
}

.item-image-container-wrap {
  display: flex;
  padding: 5px 0;
  width: 100%;
  //New Activity CSS below this
  width: 550px;
  //New Activity CSS above this
  &.checked-item {
    text-decoration: line-through;
  }
  &.blur {
    opacity: 0.25;
  }
}

.undo-btn {
  visibility: hidden;
  font-size: 10px;
  width: 42px;
  height: 15px;
  border-radius: 5px;
  border: none;
  color: #fff;
  background-color: #0090e9;
  &.visible {
    visibility: visible;
  }
}

.clearEdit-title {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.clearEdit-item {
  position: absolute;
  right: 4px;
  bottom: 22px;
}

.edit-checklist-img {
  margin-right: 8px;
}

.dndDraggingSource {
  display: none;
}

.dndDragging {
  cursor: move;
  border-radius: 10px;
}

.dndPlaceholder {
  background-color: #f6f6f6;
  display: block;
  min-height: 42px;
  border-radius: 10px;
}

.clearEdit-title {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.clearEdit-item {
  position: absolute;
  right: 4px;
  bottom: 22px;
}

.edit-checklist-img {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

.checklist-item-div {
  line-height: 1.36;
  text-align: left;
  color: #505050;
  //word-break: break-all;
  width: 92%;
  font-size: 14px;
  font-family: $mainFont;
  word-wrap: break-word;
  display: flex;
  cursor: pointer;
  .checklist-title-render {
    width: 96%;
    height: fit-content;
  }
  .checklist-no-holder {
    max-height: 10px;
    width: 19px;
    //New Activity CSS below this
    width: 20px;
    //New Activity CSS above this
    &.more-than-9 {
      width: 27px;
    }
  }
}

.check-round {
  width: 18px !important;
  height: 18px !important;
}

.progress_circular_checklist_delete {
  md-progress-circular {
    position: absolute;
    z-index: 100;
    left: 50%;
    transform: translatex(-50%);
  }
}

.dnd-lists-checklists {
  min-height: 5px;
  //New Activity CSS below this
  padding-left: 10px;
  &.hovered {
    &:hover {
      background-color: #f6f6f6;
      border-radius: 10px;
    }
  }
  //New Activity CSS above this
  &.getting-dragged {
    // border-radius: 10px;
    // box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.09);
    // background-color: rgba(0, 0, 0, 0.05);
    // padding: 8px 0;
    // margin: 5px 0;
    div {
      visibility: hidden;
    }
  }
}
.checklist-item-div {
  .anchor-link {
    a:link {
      color: #457ac4 !important;
      font-style: italic;
      font-size: 15px;
    }
    a:visited {
      color: #5045c4 !important;
    }
  }
  span {
    font-weight: 600;
    color: #0090e9;
  }
}
.completed-percentage-text {
  display: inline-block;
  position: relative;
  top: 3px;
  margin-left: 15px;
  color: #808080;
  font: normal 12px/14px roboto;
}
.option-img-container {
  cursor: pointer;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.1em;
  line-height: 1.5em;
  &:hover {
    background: #f0f0f0;
  }
}

.allnotify {
  font-size: 14px;
  font-family: roboto;
  color: #494949;
  padding: 0px 15px 10px 15px;
  // margin-bottom: 10px;
  display: block;
  position: sticky;
  background-color: white;
  /* height: 50px; */
  top: 0px;
  z-index: 999;
  // padding: 10px 10px;
  cursor: auto;
}

.checklist-no-holder {
  max-height: 10px;
}

.check-date-picker {
  width: auto !important;
  height: auto !important;
}

.chk-item-due-date {
  font-weight: normal !important;
  position: relative;
  display: inline-flex;
  &.past-date {
    color: #ff0000 !important;
  }
  .user-img-delete {
    right: -10px;
    top: -8px;
  }
  &:hover {
    .user-img-delete {
      display: block;
    }
  }
}

.paste-checklist-options-container {
  width: 153px;
  height: 46px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  position: absolute;
  padding: 8px 0;
  z-index: 1;
  .paste-checklist-options {
    padding: 5px 12px;
    font-family: roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #757575;
    height: 30px;
    text-align: left;
    cursor: pointer;
    &:hover {
      background: rgba(153, 153, 153, 0.15);
    }
  }
}