@import "variable.scss";
// new styles starts here
.comments-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 0;
  min-height: 325px;
}
.ap-comments-title {
  font: normal 14px $mainFont;
  padding: 0 15px;
  color: $attachmentColor;
  margin: 0;
}
.ap-comments-item-container {
  // margin-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid $calenderHover;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 20px;
  transition: background-color 3s ease;
  &.reply-comment {
    margin: 0 0 15px 44px;
    width: calc(100% - 44px);
  }
  .new-comm-attacherror {
    // margin: 0;
  }
  &.new-comment {
    background-color: rgba(0, 144, 233, 0.05);
    // width: calc(100% - 100px);
    // margin-left: 30px;
    // margin-right: 30px;
  }
  //New Activity CSS below this
  &:hover {
    .bookmark-option-holder {
      display: flex;
      .option-holder {
        display: flex;
      }
    }
    .bookmark-img {
      visibility: visible;
    }
  }
  //New Activity CSS above this
}
.ap-comments-item-holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .sb-avatar__text {
    width: 32px !important;
    height: 32px !important;
  }
}
.ap-comments-item-right-holder {
  width: calc(100% - 44px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 0 12px;
}
.ap-user-details-holder {
  display: flex;
  flex-direction: row;
  //New Activity CSS below this
  align-items: center;
  width: 100%;
  place-content: space-between;
  .bookmark-option-holder {
    // display: none;
    display: flex;
    justify-content: space-between;
    width: 41px;
    align-self: center;
    &.hovered {
      display: flex;
    }
    > img {
      cursor: pointer;
      visibility: hidden;
      &.bookmarked-img {
        visibility: visible;
      }
    }
    > div {
      position: relative;
      .option-holder {
        width: 19px;
        height: 19px;
        display: none;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          background: #f0f0f0;
        }
        &.hovered {
          display: flex;
        }
        img {
          width: fit-content;
        }
      }
      .flow-options {
        position: absolute;
        min-width: 129px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
        border: solid 0.3px #dfdfdf;
        background-color: #ffffff;
        color: $successMsg;
        border-radius: 10px;
        padding: 8px 0px;
        font-size: 15px;
        font-family: $mainFont;
        z-index: 99;
        margin-top: 5px;

        p {
          // margin-top: 0;
          margin: 0;
          padding: 5px 10px;
          &:hover {
            background: rgba(153, 153, 153, 0.15);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
        .flow-edit-box {
          padding: 5px 10px;
        }
        .deleteflow-box {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
  //New Activity CSS above this
}
.ap-user-profile-holder {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &.not-wp-user {
    opacity: 0.5;
  }
  &.border {
    border: 0;
  }
  > div > div {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;

    > div > span {
      font-size: 15px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    margin: 0 !important;
    border-radius: 0 !important;
    border: 0;
  }
}
.ap-user-name {
  font: normal 13px $mainFont;
  align-items: center;
  letter-spacing: 0.2px;
  color: $attachmentColor;
  margin-top: 4px;
  text-transform: capitalize;
  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 5px;
    vertical-align: middle;
  }
}
.ap-comment-updatedon {
  font: normal 13px $mainFont;
  letter-spacing: 0.2px;
  color: #b3b3b3;
  text-transform: none;
  &.archived-comm-updated-on {
    margin-left: 15px;
  }
}
.ap-comment-desc-holder {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}
.ap-comment-desc {
  // width: 590px;
  font: normal 15px $mainFont;
  color: $attachmentHeading;
  white-space: pre-line;
  word-wrap: break-word;
  > ul,
  ol {
    margin-left: -20px;
    margin-bottom: 0px;
  }
  > ol {
    margin-left: -22px;
  }
  > p {
    margin-bottom: 0px;
  }
}
.ap-comment-actions-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: 6px 0 0 0;
  position: relative;
  //New Activity CSS below this
  .have-access {
    cursor: pointer;
  }
  > img {
    margin-right: 3px;
  }
  > span {
    margin-right: 20px;
    color: #999999;
  }
  //New Activity CSS above this
}
.ap-comment-action-btn {
  border: none;
  background: transparent;
  font: normal 12px $mainFont;
  color: $blueBg;
  margin: 0 10px 0 0;
  padding: 0;
  &:hover {
    background: none !important;
  }
  &:not([disabled]):hover {
    background: none !important;
  }
}
div[role="dialog"] {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  &#noscrolldialog {
    overflow-y: visible !important;
    overflow-x: visible !important;
  }
  &::-webkit-scrollbar {
    margin-top: 10px;
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #999;
    border-radius: 3px;
    margin-right: 3px;
  }
}
.MuiDialog-scrollPaper-95 {
  align-items: flex-start;
  margin: 40px 0 20px 0 !important;
}
.MuiDialog-paperScrollPaper-99 {
  max-height: none !important;
}
.delconfirm-box-container {
  width: 245px;
  height: 155px;
  background: #fff;
  position: absolute;
  left: 67px;
  top: 20px;
  border: 1px solid #d6dadc;
  border-bottom-color: #c4c9cc;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 99;
  border-radius: 10px;
}
.delconfirm-pup-header {
  height: 36px;
  position: relative;
  margin-bottom: 13px;
}
.delconfirm-title {
  font: 500 15px/36px $mainFont;
  box-sizing: border-box;
  color: $attachmentHeading;
  display: block;
  border-bottom: 1px solid #d6dadc;
  margin: 0 10px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
}
.delconfirm-body {
  box-sizing: border-box;
  padding: 0 10px 10px;
}
.delconfirm-content {
  font: normal 13px $mainFont;
  color: $attachmentColor;
  margin-bottom: 30px;
}
.delconfirm-btn-holder {
  display: flex;
  justify-content: flex-end;
}
.del-confirm-cancel-btn {
  font: normal 15px $mainFont;
  color: $blueBg;
  background: transparent;
  border: none;
  &:not([disabled]):hover {
    background: none !important;
  }
}
.del-confirm-del-btn {
  background: $attachmentError;
  border: none;
  border-radius: 10px;
  color: $whiteColor;
  width: 83px;
  height: 30px;
  font: normal 15px $mainFont;
  margin-left: 15px;
  &:hover {
    background: $attachmentError !important;
  }
}
.empty-comments {
  font: 300 16px/19px robotoLight;
  color: #8b8b8b;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  white-space: nowrap;
}
.add-comment-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px 0;
  padding: 0 15px;
}
.add-comment-box-holder {
  width: 628px;
  &.edit,
  &.reply {
    width: 100%;
  }
  &.reply-to-reply {
    width: 585px;
  }
  .comment-text-area__input {
    border: 0;
    padding: 10px 35px 10px 10px;
    font-size: 13px;
  }
}
.comment-text-area {
  width: 100%;
  max-width: 100%;
  min-height: 68px !important;
  padding: 10px;
  border-radius: 10px;
  color: #757575;
  padding-right: 35px;
  resize: none;
  font: 400 13px roboto;
  background-color: #f8f8f8;
  border: none;
  box-sizing: border-box;
  overflow-y: hidden;
}
// .comment-text-area.comment-text-area--multiLine {
//   overflow-y: auto !important;
// }
.add-comment-btn {
  width: 77px;
  height: 30px;
  border: none;
  color: $whiteColor;
  font: 500 15px $mainFont;
  background: $primary_gtd;
  border-radius: 10px;
  margin: 0 10px 0 0;
  &.disable {
    background: $commentBtnDisable;
    pointer-events: none;
  }
}
.comment-bottom-btn-holder {
  width: 100%;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.comment-bottom-left-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.comment-attachment-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20px;
  margin-top: 2px;
  > div {
    position: relative;
  }
  &.edit {
    justify-content: flex-end;
  }
}
.comment-attachment-info {
  font: normal 11px $mainFont;
  color: $graphDdownHover;
}
.comment-attachment-img-holder {
  height: 16px;
  width: 10px;
  position: relative;
  cursor: pointer;
  background: url(../img/svg/comment-attachment.svg) no-repeat left top;
  background-size: contain;
  &:hover {
    height: 16px;
    width: 10px;
    position: relative;
    cursor: pointer;
    background: url(../img/svg/attachment_blue_active.svg) no-repeat left top;
    background-size: contain;
  }
}
.circular-progress {
  color: $blueBg !important;
}
.comment-edit-box-container {
  width: calc(100% - 44px);
  margin: 0 0 0 12px;
  &.on-reply {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 15px 0 0 12px;
  }
}
.comment-edit-cancel-btn {
  width: 13px;
  height: 13px;
  // margin: 0 25px 0 0;
  background: url(../img/svg/close_new.svg) no-repeat left top;
  cursor: pointer;
}
.loading-div {
  display: flex;
  .upload-percentage {
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: 0.19px;
  }
}
.mention-highlight {
  font-weight: 600;
  color: $blueBg;
}

.notifi-anchor-link {
  font: italic 15px $mainFont;
  color: #457ac4;
  word-break: break-word;
  &:hover {
    color: #457ac4;
  }
}

.new-comm-attacherror {
  font: normal 12px/14px $mainFont;
  color: $attachmentError;
  margin: 0 0 0 10px;
  &.add-act {
    position: fixed;
    font: normal 11px/14px roboto;
    margin: 3px 0 0 100px;
  }
  &.checklist-paste-err {
    position: absolute;
    right: 50px;
  }
}
.picker-dialog-bg {
  z-index: 2000 !important;
}
.picker-dialog {
  z-index: 2000 !important;
}
.comment-text-area__suggestions {
  ul.comment-text-area__suggestions__list {
    width: 280px;
    opacity: 1;
    border-radius: 10px;
    background-color: #ffffff;
    position: absolute;
    left: 10px;
    bottom: 10px;
    padding-top: 15px;
    padding-bottom: 7px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 5px;
    max-height: 272px;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border-bottom: 10px solid #fff;
    border-right: 5px solid #fff;
    border-top: 15px solid #fff;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #999999;
      border-radius: 2px;
      padding-right: 10px;
    }
    li.comment-text-area__suggestions__item {
      padding: 9px 15px 7px 15px;
      list-style: none;
      cursor: pointer;
      height: 40px;
      .comments-people {
        > div > div {
          width: 30px !important;
          height: 30px !important;
        }
        img.user-photo {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 15px;
          margin-top: 5px;
          display: inline-block;
          object-fit: cover;
        }
        .sb-avatar.sb-avatar--text {
          width: 30px !important;
          height: 30px !important;
          border-radius: 50%;
          margin-right: 15px;
          margin-top: 5px;
          display: inline-block;
          .sb-avatar__text {
            div {
              span {
                top: 0px !important;
                color: #fff;
                span {
                  top: 0px !important;
                }
              }
            }
          }
        }
        .avatarSpan {
          top: -32px;
          left: 45px;
        }

        span {
          position: relative;
          top: -19px;
          height: 18px;
          font-family: roboto;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.4px;
          color: #555;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.avatar {
          span {
            position: relative;
            top: -5px;
            // display: inline-block;
            // width: 90%;
            max-width: 200px;
            height: 18px;
            font-family: roboto;
            font-size: 15px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.4px;
            color: #555;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      &.comment-text-area__suggestions__item--focused {
        background: #f0f0f0 !important;
      }
    }
    .mentio-name {
      position: relative;
      top: -18px;
      left: 46px;
      font-family: roboto;
      font-size: 13px;
      color: #555;
      &.avatar {
        position: relative;
        max-width: 200px;
        top: -15px;
        left: 46px;
        font-family: roboto;
        font-size: 13px;
        color: #555;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .avatarMention-name {
      position: relative;
      top: -34px;
      left: 46px;
      font-family: roboto;
      font-size: 13px;
      color: #808080;
    }
  }
}

.RichEditor-root {
  background: #fff;
}

.RichEditor-editor {
  border: 1px solid #ddd;
  cursor: text;
  font-size: 16px;

  width: 100%;
  max-width: 100%;
  min-height: 68px !important;
  border-radius: 10px;
  color: #505050;
  padding: 10px 35px 10px 10px;
  resize: none;
  font: 400 15px roboto;
  background-color: #f8f8f8;
  border: none;
  box-sizing: border-box;
  &.with-att {
    border-radius: 10px 10px 0 0;
  }

  .suggestion.label {
    color: white;
    background-color: #6078f0;
    border-radius: 2px;
    margin-bottom: 1px;
    margin-right: 1px;
    padding: 0 4px;
    display: inline-block;
  }

  .suggestions.overlay {
    position: absolute;
    margin-top: 1.65em;
  }

  .suggestions-list {
    box-shadow: 1px 0px 0.8em fade(black, 10%);
    border: 1px solid #e0e0e0;
    font-size: 0.82em;
    line-height: 1.4em;
    border-radius: 2px;
    background: white;
    overflow: hidden;
    width: 280px;
    opacity: 1;
    border-radius: 10px;
    background-color: #ffffff;
    position: absolute;
    left: -362px;
    bottom: 10px;
    //padding-top: 15px;
    padding-bottom: 7px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 5px;
    max-height: 272px;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    border-bottom: 10px solid #fff;
    border-right: 5px solid #fff;
    border-top: 15px solid #fff;
    z-index: 20;

    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #999999;
      border-radius: 2px;
      padding-right: 10px;
    }

    li {
      padding: 9px 15px 7px 15px;
      list-style: none;
      cursor: pointer;
      height: 40px;
      display: flex;
      flex-direction: column;
      .comments-people {
        > div > div {
          width: 30px !important;
          height: 30px !important;
        }
        img.user-photo {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 15px;
          margin-top: 5px;
          display: inline-block;
          object-fit: cover;
        }
        .sb-avatar.sb-avatar--text {
          width: 30px !important;
          height: 30px !important;
          border-radius: 50%;
          margin-right: 15px;
          margin-top: 5px;
          display: inline-block;
          .sb-avatar__text {
            div {
              span {
                top: 0px !important;
                color: #fff;
                span {
                  top: 0px !important;
                }
              }
            }
          }
        }
        .avatarSpan {
          top: -32px;
          left: 45px;
        }

        span {
          position: relative;
          top: -19px;
          height: 18px;
          font-family: roboto;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -0.4px;
          color: #555;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.avatar {
          span {
            position: relative;
            top: -5px;
            // display: inline-block;
            // width: 90%;
            max-width: 200px;
            height: 18px;
            font-family: roboto;
            font-size: 15px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.4px;
            color: #555;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &.comment-text-area__suggestions__item--focused {
        background: #f0f0f0 !important;
      }
      > .mentio-name {
        position: relative;
        padding-left: 45px;
        font-family: roboto;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.4px;
        color: #555;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: -16px;
      }
    }

    li.selected-user,
    li:hover,
    li:focus {
      cursor: pointer;
      color: #eee;
      background-color: #e7e8eb;
    }
  }
}

.RichEditor-editor .public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 1;
  margin: 0 -15px -15px;
  padding-bottom: 10px;
  margin-left: 1px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
  margin: 0 -15px -15px;
  padding-bottom: 10px;
  position: relative;
  z-index: 1;
  margin-left: 1px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
  position: absolute;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "robot";

  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 7px;
  padding: 0 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.BOLD,
.UNDERLINE,
.ITALIC,
.unordered-list-item,
.ordered-list-item {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 7px;
}

.blocklevel {
  display: flex;
  margin-top: 2px;
}

.RichEditor-activeButton.BOLD,
.RichEditor-activeButton.UNDERLINE,
.RichEditor-activeButton.ITALIC,
.RichEditor-activeButton.ordered-list-item,
.RichEditor-activeButton.unordered-list-item {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background-color: #f0f0f0;
  padding: 7px;
}
.comment-right-sction {
  display: flex;
}

.comment-rict-text {
  display: flex;
  margin-right: 7px;
}

.mentionsymbol {
  padding-left: 12px;
  cursor: pointer;
  margin-top: 8px;
}

.rickBlock {
  cursor: pointer;
  border-radius: 50%;
  object-fit: contain;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #f0f0f0;
  }
  > img {
    padding: 6px;
    margin-top: 3px;
  }
}

.customtoolbar {
  display: flex;
  width: 170px;
  margin-top: 1px;
}

.hide-placeholder .public-DraftEditorPlaceholder-inner {
  display: none;
}

.public-DraftStyleDefault-ul {
  padding-left: 18px;
}
.public-DraftStyleDefault-ol {
  padding-left: 16px;
}

.comm-att-holder {
  &.add-edit {
    background-color: #f8f8f8;
    border-radius: 0 0 10px 10px;
  }
  &.add-comp-act {
    border-radius: 0 0 4px 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-top: none;
  }
}
.ms-ResizeGroup {
  display: none !important;
}

.public-DraftEditor-content {
  > div {
    padding-bottom: 30px;
  }
}

.starting-child {
  color: #eee;
  background-color: #e7e8eb;
}
