@import "variable.scss";

.comm-filter-container {
  width: calc(100% - 50px);
  margin: 10px 24px 18px 30px;
}
.comm-filter-outer-holder {
  width: 100%;
  height: 30px;
  position: relative;
}
.comm-filter-animation-holder {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.ng-hide-remove {
    animation: fadeInDown 0.3s linear;
  }
  &.ng-hide-add {
    animation: fadeOutUp 0.3s linear;
  }
}
.comm-filter-control-tt-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.comm-filter-control-container {
  width: 202px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 30px;
  &.project {
    width: 214px;
  }
  &.members {
    width: 228px;
  }
  &.from {
    width: 202px;
  }
  &.to {
    width: 186px;
  }
}
.comm-filter-control-title {
  font: normal 13px/15px $mainFont;
  color: $whiteColor;
  margin: 0 0 5px 0;
  &.date-title {
    position: absolute;
    top: 84px;
  }
}
.comm-filter-control-holder {
  position: relative;
  width: 160px;
  .project-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    width: 160px;
    height: 25px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    & span {
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .not-admin {
    cursor: default;
    opacity: 0.9;
    & span,
    & img {
      opacity: 0.5;
    }
  }
  .proj-dropdown {
    position: absolute;
    top: 30px;
    border-radius: 10px;
    background: #fff;
    min-width: 136px;
    min-height: 48px;
    max-height: 256px;
    // width: 160px;
    // height: 256px;
    // max-height: 192px;
    // width: 100%;
    z-index: 9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    // cursor: auto;
    overflow: auto;
    .report-proj-scroll {
      > div {
        height: inherit;
      }
    }
    .proj-name {
      height: 29px;
      width: 100%;
      cursor: pointer;
      padding: 0 15px;
      font: normal 15px/29px roboto;
      color: #757575;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        background: rgb(238, 238, 238);
      }
      &:first-child:hover {
        // border-radius: inherit;
      }
    }
    .active-proj {
      background: rgb(238, 238, 238);
    }
  }
  .proj-dropdown::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  .proj-dropdown::-webkit-scrollbar-thumb {
    width: 4px;
    background: lighten($topNaviColor, 20%);
    border-radius: 2px;
    cursor: pointer;
  }
  .member-dropdown {
    position: absolute;
    top: 30px;
    border-radius: 10px;
    background: #fff;
    min-width: 300px;
    max-height: 285px;
    z-index: 9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: auto;
    overflow: auto;
    .demo-select-header {
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14),
        0 0 0 0 rgba(0, 0, 0, 0.12);
      padding-left: 10.667px;
      height: 40px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      width: auto;
      .demo-header-searchbox {
        border: none;
        outline: none;
        height: 100%;
        width: 97%;
        padding: 0 0 0 30px;
      }
    }
    .member-outside {
      max-height: 192px;
      width: 300px;
      overflow-y: auto;
      .user-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        height: 43px;
        cursor: pointer;
        &:hover {
          background: rgb(238, 238, 238);
        }
        > div > div > div {
          width: 35px !important;
          height: 35px !important;
          font-size: 15px;
          border-radius: 0 !important;
          > div > span {
            padding-right: 4.5px;
            padding-bottom: 5px;
          }
        }
      }
      .active-member {
        background: rgb(238, 238, 238);
      }
      .no-results-report-filter {
        text-align: center;
        padding-top: 35px;
        height: 100px;
      }
      .proj-name,
      .activity-type-data {
        height: 29px;
        width: 100%;
        cursor: pointer;
        padding: 0 15px;
        font: normal 15px/29px roboto;
        color: #757575;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover {
          background: rgb(238, 238, 238);
        }
        &:first-child:hover {
          // border-radius: inherit;
        }
      }
      .active-proj {
        background: rgb(238, 238, 238);
      }
    }
    .member-outside::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    .member-outside::-webkit-scrollbar-thumb {
      width: 4px;
      background: lighten($topNaviColor, 20%);
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
.comm-filter-details-holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.ng-enter {
    animation: fadeInUp 0.3s linear;
  }
  &.ng-leave {
    animation: fadeOutDown 0.3s linear;
  }
}
.comm-filter-data-shown {
  font: normal 16px/19px $mainFont;
  color: $whiteColor;
  margin: 0 30px 0 0;
}
.comm-filter-btn {
  width: 100px;
  height: 30px;
  border-radius: 10px;
  background: transparent;
  box-shadow: none;
  border: 1px solid $whiteColor;
  font: 500 15px $mainFont;
  text-align: center;
  color: $whiteColor;
}
.comm-filter-control-container.members md-input-container md-select {
  height: 25px;
}
.filter-view {
  md-input-container {
    margin: 0;
    padding: 0;
    width: 100%;
    md-select.md-default-theme .md-select-value,
    md-select .md-select-value {
      color: $whiteColor;
      font: normal 13px $mainFont;
      padding: 0 0 0 8px;
      border: 0;
      background: $filterCtrlBg;
      border-radius: 10px;
      height: 25px;
      border-bottom: 0 !important;
    }
    .md-select-value .md-select-icon {
      display: block;
      width: 10px;
      margin: 4px 8px 0 0;
    }
    .md-select-value .md-select-icon:after {
      content: "";
      width: 10px;
      height: 10px;
      top: auto;
      transform: none;
      background: url(../img/svg/filter-down-arrow.svg) no-repeat center center;
    }
    md-select[aria-expanded="true"] {
      .md-select-value .md-select-icon:after {
        transform: rotate(180deg);
      }
    }
    md-select:not([disabled]):focus md-select-value {
      color: $whiteColor;
    }
    md-select-value .user-name {
      font: normal 13px $mainFont;
    }
    md-select[disabled] .md-select-value {
      opacity: 1;
      span:not(.md-select-icon) {
        opacity: 0.5;
      }
      .md-select-icon {
        opacity: 0.5;
      }
    }
  }
}
.filter-select {
  top: 194px !important;
  margin-left: 14px;
  md-option {
    height: 29px;
    padding: 0 15px;
    font: normal 15px/29px $mainFont;
    color: $panelHeading;
  }
  md-select-menu md-content md-option[selected] {
    color: $panelHeading;
  }
}
.filter-close {
  width: 14px;
  height: 21px;
  margin: 0 0 0 20px;
}
.filter-calendar-control {
  color: $whiteColor;
  font: normal 13px/25px $mainFont;
  padding: 0 0 0 8px;
  border: 0;
  border-radius: 10px;
  height: 25px;
  width: 100%;
  background: $filterCtrlBg;
  &::after {
    content: "";
    background: url(../img/svg/filter-down-arrow.svg) no-repeat center center;
    width: 14px;
    height: 25px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.up {
    &::after {
      transform: rotate(180deg) translateY(50%);
    }
  }
}
.selectdemoSelectHeader.filter-member {
  top: 195px !important;
  margin-left: 14px;
  md-optgroup md-option {
    padding: 0 15px;
  }
  md-select-menu {
    margin: 0;
  }
  md-content {
    overflow: unset;
  }
  md-select-menu md-content md-optgroup {
    max-height: 192px;
    height: auto;
  }
}
.ovf-hidden {
  overflow: hidden;
}

@keyframes fadeInUp {
  from {
    opacity: 0.6;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0.6;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@media screen and (max-width: 1360px) {
  .comm-filter-control-tt-container {
    width: calc(100% - 34px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .comm-filter-control-container {
    width: 15.5%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    margin-right: 0;
    &.project {
      width: 17.8%;
    }
    &.members {
      width: 19%;
    }
    &.from {
      width: 17%;
    }
    &.to {
      width: 15.8%;
    }
  }
}
@media screen and (max-width: 1280px) {
  .comm-filter-control-container {
    .comm-filter-control-holder {
      width: calc(100% - 42px);
    }
    &.project {
      .comm-filter-control-holder {
        // width: calc(100% - 54px);
        // width: 160px;
      }
    }
    &.members {
      .comm-filter-control-holder {
        width: calc(100% - 67px);
      }
    }
    &.from {
      .comm-filter-control-holder {
        width: calc(100% - 43px);
      }
    }
    &.to {
      .comm-filter-control-holder {
        width: calc(100% - 27px);
      }
    }
  }
  .comm-filter-btn {
    &.apply {
      margin: 0;
    }
  }
}

.report-filter-box {
  display: flex;
  margin-top: 10px;
  .comm-filter-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .comm-filter-outer-holder {
      display: flex;
      //align-items: center;
      width: auto;
    }
    .comm-filter-control-container {
      margin-left: 30px;
      margin-right: 0px;
      width: auto;
    }
    .date-range {
      width: 240px;
      margin-left: 30px;
      height: 25px;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.2);
      .date-img {
        width: 240px;
        background: url(../img/svg/report-calender.svg) no-repeat right center;
        background-size: 14px;
        cursor: pointer;
        background-position-x: 215px;
        padding-left: 8px;
        color: $snow_gtd;
        font-size: 13px;
      }
    }
    .comm-filter-btn {
      margin-left: 30px;
    }
    .btn-filter-apply {
      width: 100px;
      height: 30px;
      border-radius: 10px;
      background: transparent;
      margin-left: 30px;
      box-shadow: none;
      border: 1px solid #fff;
      font: 500 15px roboto;
      text-align: center;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .clear-filter {
      display: block;
      font: 500 15px roboto;
      text-align: center;
      cursor: pointer;
      color: #fff;
      margin-left: auto;
      // width: 31%;
      > span {
        cursor: pointer;
        float: right;
      }
    }
  }
}

.newdesign {
  flex-direction: column;
  align-items: start;
  margin-top: -35px;
}

.active-activityType {
  background: rgb(238, 238, 238);
}
