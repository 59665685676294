/*New css for notification*/
@import "variable.scss";

.attachment-container {
  .reply-btn {
    border: none;
    background-color: transparent;
    font-weight: bold;
    font-size: 13px;
    color: #404041;
  }
}

.template-container {
  .nw-attachment-container {
    width: 100%;
    margin: 14px 0px;
  }
}

.nw-attachment-container {
  width: calc(100%);

  min-height: 325px;
  position: relative;
}

.nw-att-top-holder {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // position: relative;
  min-height: 17px;
  margin-top: 15px;
  width: 100%;
}

.nw-header-txt {
  font: normal 14px/16px $sideFont;
  color: $attachmentColor;
  width: 100%;

  &.add-new {
    color: $blueBg;
    font: 500 14px/16px $sideFontMedium;
    cursor: pointer;
    position: absolute;
    left: 90%;
  }
}

.nw-max-size {
  font: normal 12px/14px $sideFont;
  color: $topNaviColor;
  position: absolute;
  top: 0.8%;
  right: 103px;
  transform: translateY(-50%);
  &.error {
    color: $attachmentError;
  }
}

.nw-att-row-container {
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: flex-start;
  padding: 14px 11px;
  align-items: center;
  transition: background-color 3s ease;
  &.comm-att {
    padding-left: 0;
  }
  &.new-comment {
    background-color: rgba(0, 144, 233, 0.05);
    // width: calc(100% - 90px);
    // padding: 0;
  }
}

.nw-att-row-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 16px 0 0;
  height: 84px;
}

.nw-att-img-holder {
  width: 103px;
  height: 64px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  &.comm-att {
    border-radius: 6px;
    img {
      border-radius: 6px;
    }
  }
}

.nw-att-row-right {
  width: calc(100% - 119px);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $calenderHover;
  padding: 10px 0;
  &.comm-att {
    border-bottom: none;
  }
}

.nw-att-details-holder {
  height: 64px;
}

.nw-att-details-heading {
  font: normal 15px/18px $sideFont;
  color: $attachmentHeading;
}

.nw-att-details-name {
  font: normal 11px/14px $sideFont;
  color: $blueBg;
  margin-bottom: 11px;
  width: 300px;
  margin-top: 3px;
  &.comm-att {
    margin-bottom: 5px;
    margin-top: 0;
    a {
      font: normal 14px/16px roboto;
    }
  }
  &.notify {
    width: 189px;
  }
  a {
    font: normal 11px/14px roboto;
    color: #0090e9;
  }
}

.nw-att-details-time {
  font: normal 12px/14px $sideFont;
  color: $controlLabelColor;
  &.comm-att {
    margin-bottom: 13px;
  }
}

.nw-att-link-container {
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nw-att-size {
  font: normal 12px/14px $sideFont;
  color: $attachmentColor;
}

.nw-att-link {
  font: normal 12px/14px $sideFont;
  color: $blueBg;
  margin: 0 0 0 13px;
  cursor: pointer;
  &.download {
    margin: 0 0 0 43px;
    &:hover {
      color: $blueBg;
    }
    &:focus {
      outline: none;
      color: $blueBg;
    }
  }
  &.comm-att {
    margin: 0;
  }
}

.doc-replacement {
  width: 100%;
  height: 64px;
  border-radius: 6px;
  background: $attachmentBg;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $controlLabelColor;
}

.nw-att-nothing-notifi {
  font: 300 16px/19px $sideFontLight;
  color: $attachmentNotifi;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  white-space: nowrap;
  span {
    font: 500 16px/19px $sideFontMedium;
  }
}

.nw-att-loading-holder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.attachment-loader {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ngdialog::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

.ngdialog::-webkit-scrollbar-thumb {
  width: 4px !important;
  background: $topNaviColor;
  border-radius: 4px;
}
.ngdialog:hover::-webkit-scrollbar-thumb {
  background: $topNaviColor;
}

.attachmentpopviews {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  .attachmentimage {
    max-height: calc(100% - 100px);
    max-width: calc(100% - 100px);
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.closeattachmentpopview {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 5;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.notimage {
  // pointer-events: none;
  background-color: #f9f9f9;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    width: 100%;
    height: 64px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spanDisplay {
    color: #aeaeae;
    font-size: 14px;
  }
  &.comm-att {
    background-color: #d3eeff;
    .spanDisplay {
      color: #0090e9;
    }
  }
}

.attach-files-dropdown-container {
  width: 185px;
  height: 190px;
  border-radius: 10px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 20px;
  padding: 15px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  z-index: 9;
  &.comment {
    top: 25px;
  }
  &.attachment {
    right: -114px;
    left: auto;
  }
}
.attch-from-heading {
  widows: 100%;
  position: relative;
  font: normal 10px/16px $mainFont;
  color: $attachmentColor;
  &:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 8px;
    width: 92px;
    height: 1px;
    background: $calenderHover;
  }
}
.attch-from-link {
  font: normal 14px/16px $mainFont;
  color: $panelHeading;
  //  url(../img/svg/settings-dropdown-arrow.svg)
  background: url(../img/svg/attachment-from-computer.svg) no-repeat left center;
  padding: 0 0 0 26px;
  margin: 15px 0 0 0;
  &.from-drive {
    background: url(../img/svg/attachment-from-drive.svg) no-repeat left center;
  }
}
.attachmentBox {
  opacity: 0;
  // position:absolute;
  top: 30px;
  cursor: pointer;
  // font-size: 0;
  display: block;
  position: relative;
  // padiing-bottom: 10px;
  margin-bottom: 0px;
  top: -23px;
}

/*End of Notification*/

.container-act {
  .notification {
    margin: 0;
  }
}
.notification-icon-holder {
  margin-top: 8px;
  margin-left: 30px;
  margin-right: 35px;
}
.notification-icon {
  cursor: pointer;
  position: relative;
  cursor: auto;
  img {
    width: 25px;
    height: 28px;
  }

  .button-badge {
    height: 10px;
    width: 10px;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
    /* Position the badge within the relatively positioned button */
  }

  .button__badge_visibility {
    opacity: 0;
    visibility: hidden;
  }
}

.notification {
  background: #e3e3e3;
  height: calc(100vh - 106px);
  position: fixed;
  top: 87px;
  right: 20px;
  width: 408px;
  z-index: 99;
  border-radius: 10px;
  padding: 10px 0px 0 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: auto;
  .notification-wrapper {
    overflow: hidden;
    clear: both;
    border-bottom: 1px solid rgba(174, 174, 174, 0.25);
    padding: 12px 10px;
    margin-right: 10px;
    &.on-search {
      padding-left: 0;
    }
  }
  div#mCSB_1_container {
    width: 100% !important;
  }
  .head {
    float: left;
    .icon-bell {
      margin-right: 6px;
      display: none;
    }

    h2 {
      font-size: 20px;
      font-weight: 300;
      color: #0090e9;
      margin: 0;
      line-height: 0.8;
      padding: 0 !important;
    }

    img {
      width: 16px;
      float: right;
      position: absolute;
      right: 20px;
      top: 33px;
      display: none;
    }
  }

  .tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;

    li {
      display: inline-block;
      margin-right: 20px;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      padding: 0;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #494949;
        font-weight: normal;
        font-size: 16px;
        cursor: pointer;
        text-decoration: none;
      }
    }

    li.active {
      a {
        color: #0090e9;
        font-weight: 600;
        // border-bottom: 1px solid #494949;
      }
    }
  }
  .notification-scroll {
    height: calc(100% - 57px);
    .mCSB_scrollTools {
      .mCSB_dragger {
        right: 2px;
      }
    }
  }
  .tab-body {
    overflow: auto;
    margin: 10px 0 0 0;
    padding-right: 10px;
    position: relative;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #999999;
      border-radius: 2px;
      padding-right: 10px;
    }
    .tab-content {
      width: 100%;
      padding: 0px 0 0 0;
      // padding: 10px 0;

      .no-notify {
        color: $attachmentColor;
        font-size: 16px;
        text-align: center;
        &.more-margin {
          margin-top: 40px;
        }
      }
      .notify-card {
        background: #fff;
        border-radius: 10px;
        margin: 0 0 10px 0;
        &:last-child {
          margin-bottom: 0;
        }
        .right-comment-notification-title {
          display: flex;
          background: #f9f9f9;
          padding: 13px 7px;
          border-radius: 10px;
          clear: both;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          position: relative;
          overflow: hidden;
          &.comments-notifi {
            display: block;
            p.card-title {
              width: 310px;
              float: left;
            }
            p.post-time {
              margin: 5px 0 0 35px;
            }
          }
          .right-notfi-title-img {
            float: left;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            // background: #aeaeae;
            margin-right: 10px;
            &.like-notify {
              position: relative;
              .like-notify-icon {
                position: absolute;
                top: 12px;
                right: -10px;
                width: 20px;
                height: 20px;
              }
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }
          .right-notfi-title-des {
            float: left;
            width: calc(100% - 35px);
          }
        }
        .right-comment-desc-you {
          clear: both;
          overflow: hidden;
          padding: 15px 10px 8px 0;
          &.like-notify-desc {
            overflow: hidden !important;
          }
          .right-comment-notfi-icon {
            float: left;
            width: 52px;
            margin-right: 0;
            img {
              padding: 5px 0 0 10px;
              width: 22px;
              height: 23px;
            }
          }
          .right-comment-notifi-desc {
            width: calc(100% - 62px);
            float: left;
            p.description {
              span {
                color: $mainFontSemiBold;
                //font: 500 15px $mainFontSemiBold;
              }
            }
          }
        }

        p {
          font-size: 15px;
          color: #494949;
          word-wrap: break-word;
          margin-bottom: 0px;
          span.activity-title {
            color: $blueBg;
            font-size: 15px;
          }
        }

        &:last-child {
          p.post-time {
            border: none;
            margin-bottom: 0;
          }
        }

        p.card-title {
          color: #808080;
          width: 290px;
          span {
            font-weight: normal;
            color: #808080;
          }
          span.activity-title {
            color: $blueBg;
            font-size: 15px;
          }
        }

        p.description {
          span {
            font-weight: 600;
            color: $primary_gtd;
          }
          span.desc-msg {
            font-weight: 200;
            color: $font_light;
            white-space: pre-line;
          }
        }

        p.post-time {
          color: $font_notify_grey;
          padding-bottom: 0;
          padding-top: 0;
          font-size: 11px;
        }
      }
    }
  }

  .notification_container {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 16px;
    margin-bottom: 26px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c0c0c0;

    .left {
      img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin-left: 10px;

        &.default {
          border-radius: 0;
        }
      }
    }

    .right {
      margin-left: 13px;

      .top {
        display: flex;
        flex-direction: row;

        .username {
          font-family: $source-light;
          font-size: 14px;
          font-weight: bold;
        }

        .description {
          font-family: $source-light;
          font-size: 14px;
          color: #404041;
          margin-left: 5px;
        }
      }

      .bottom {
        .time-ago {
          font-family: $source-light;
          font-size: 13px;
          font-weight: 600;
          color: #828384;
          padding: 7px;
        }
      }
    }
  }
}
.all-noti {
  p.description {
    color: #494949 !important;
    font-weight: 600;
  }
}
.notifyStyle {
  width: 408px;
  z-index: 1000;
  right: 10px;
  top: unset !important;
  a {
    font-size: 16px;
  }
}

.notification-alert-icon {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid $whiteColor;
  background: $attachmentError;
  position: absolute;
  right: 2px;
  top: 3px;
}
.notify-read-unread {
  width: 10px;
  height: 10px;
  background: $blueBg;
  border-radius: 50%;
  position: absolute;
  right: 14px;
  top: 14px;
  cursor: pointer;
  z-index: 99;
  &.read {
    background: $controlLabelColor;
  }
  &:before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.notify-card-container {
  width: 100%;
  .notify-card-holder:last-child {
    .notify-temp-holder {
      .attachment {
        .post-time {
          margin-bottom: 0;
        }
      }
    }
  }
}
.notify-card-holder {
  width: 100%;
  margin-bottom: 10px;
  .notifi-flag {
    display: none;
    &.flagged {
      display: block;
    }
  }
  // &:hover {
  //   .notifi-flag {
  //     display:block;
  //   }
  // }
}
.notify-card-time-categ {
  font: normal 13px/25px $mainFont;
  color: $attachmentColor;
}
.notify-card-others {
  width: 100%;
  background: $attachmentBg;
  border-radius: 10px;
  position: relative;
  min-height: 90px;
  &.attachment {
    background: #fff !important;
    min-height: 0;
    .notify-card-right {
      padding-top: 10px;
      .notify-card-title {
        width: auto;
      }
    }
    .notify-card-left {
      background: transparent !important;
    }
  }
  &.like {
    background: $whiteColor;
  }
  &.notify-timer {
    padding-bottom: 14px;
  }
}
.notify-card-left {
  width: 40px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  background: $whiteColor;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.with-user {
    justify-content: flex-start;
    padding-top: 15px;
    .notify-user-img {
      display: block;
      margin-bottom: 15px;
    }
  }
}
.notify-card-right {
  width: calc(100% - 100px);
  height: 100%;
  margin: 0 0 0 52px;
  padding: 15px 0 0;
}
.notify-card-title {
  font: normal 15px/18px $mainFont;
  color: $attachmentColor;
  word-break: break-word;
  span {
    &.user-removed {
      color: $successMsg;
    }
    &.activity-title {
      color: $blueBg;
      font-size: 15px;
    }
  }
}
.notify-user-img {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50%;
  display: none;
  img {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    vertical-align: top;
  }
}
.notify-type-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  img {
    // max-width: 100%;
    width: 11px;
    height: 20px;
  }
  .timesheet-icon {
    width: 20px;
    height: 25px;
  }
}
.notify-card-posted-time {
  font: normal 11px/13px $mainFont;
  color: $attachmentColor;
  margin: 5px 0 0 0;
}
.notify-show-more {
  font: normal 15px/20px $mainFont;
  color: $blueBg;
  width: 100%;
  border-top: 1px solid rgba(174, 174, 174, 0.25);
  text-align: center;
  padding: 7px 0 0 0;
  &.no-border {
    border: 0;
  }
  span {
    width: 96px;
    padding: 0px 18px 0 0;
    background: url(../img/svg/notify-showmore.svg) no-repeat right 9px;
    &.no-notification {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.opened {
  opacity: 0.5;
}
.no-notification-holder {
  width: 100%;
  font: normal 14px/20px $mainFont;
  color: $attachmentColor;
  text-align: center;
}
.archive-loader.notification-loader {
  position: absolute;
  z-index: 999;
}

.post-time,
.notify-card-posted-time {
  margin-top: 10px;
  color: $font_notify_grey;
  font-size: 11px;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.user-member {
  img {
    width: 18px;
    height: 20px;
  }
}
.notify-temp-holder {
  width: calc(100% - 10px);
}

.notification
  .tab-body
  .tab-content
  .deleted
  .notify-card
  p.card-title
  span.activity-title {
  color: $successMsg;
}

.notifi-anchor-link {
  font: italic 15px $mainFont;
  color: #457ac4 !important;
  &:hover {
    color: #457ac4 !important;
  }
  &:visited {
    color: #5045c4 !important;
  }
}
.system-notify-card {
  width: 378px;
  background: #40abee;
  border-radius: 10px;
  margin: 0 0 10px 0;
}
.system-notify-header {
  width: 100%;
  height: 35px;
  background: $blueBg;
  border-radius: 10px 10px 0 0;
  position: relative;
  text-align: center;
  font: 500 15px/35px $sideFontMedium;
  color: $whiteColor;
}
.system-notify-close {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  // right: 13px;
  // top: 13px;
  // width: 12px;
  // height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.req-close {
    right: 10px;
    top: 11px;
  }
}
.system-notify-body {
  padding: 26px 24px 24px 28px;
  .read-more {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    > div {
      width: fit-content;
      cursor: pointer;
      color: #fff;
    }
    img {
      margin-left: 10px;
      margin-bottom: 1.5px;
    }
  }
}
.system-notify-req-body {
  padding: 15px;
  .show-more-req {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    > div {
      width: fit-content;
      cursor: pointer;
    }
    img {
      margin-left: 10px;
      margin-bottom: 1.5px;
    }
  }
}
.system-notify-body-header {
  width: 100%;
  height: 100% !important;
  margin: 0 0 17px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.system-notify-req-body-header {
  display: flex;
  width: 100%;
  height: 100% !important;
  margin: 0 0 25px 0;
}
.system-notify-speaker {
  width: 23px;
  height: 100% !important;
  margin: 0 25px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.system-notify-head-img {
  width: 37px;
  height: 100% !important;
  margin: 0 15px 0 0;
  img {
    border-radius: 50%;
  }
  .sb-avatar__text {
    width: 25px !important;
    height: 25px !important;
    > div {
      font-size: inherit !important;
    }
  }
}
.system-notify-body-heading {
  font: 500 18px $sideFontMedium;
  color: #fff;
}
.system-notify-req-body-heading {
  font: 15px roboto;
  color: #fff;
}
.system-notify-body-content {
  font: normal 15px $mainFont;
  color: $whiteColor;
  padding-left: 48px;
  &.with-ellipsis {
    p:nth-child(2) {
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 20px;
      white-space: nowrap;
    }
  }
  a {
    font-style: italic;
  }
  p {
    white-space: pre-line;
  }
}
.system-notify-req-body-content {
  font: normal 15px $mainFont;
  color: #fff;
  padding-left: 40px;
  .user-container {
    display: flex;
    margin-bottom: 25px;
    .user-img {
      width: 40px;
      height: 40px;
      margin-right: 15px;
      img {
        border-radius: 50%;
        object-fit: cover;
        width: inherit;
        height: inherit;
      }
      .sb-avatar__text {
        width: 40px !important;
        height: 40px !important;
        > div {
          font-size: inherit !important;
        }
      }
    }
    .name-container {
      width: 169px;
      color: #fff !important;
      .user-name {
        color: #fff;
        font-size: 15px;
      }
      .user-email {
        color: #fff;
        letter-spacing: -0.36px;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .add-view-btn {
      align-self: center;
      border-radius: 10px;
      width: 74px;
      height: 30px;
      border: none;
      color: #0090e9;
      font-size: 15px;
      font-weight: 600;
      background: #fff;
      &:hover {
        background: #fff;
      }
    }
  }
}
.system-notify-navi {
  width: 100%;
  margin: 15px 0 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.system-notify-arrow {
  width: 30px;
  height: 20px;
  margin: 0 0 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.system-notify-navi-txt {
  font: 500 15px $sideFontMedium;
  color: $whiteColor;
}
.notification-search-icon {
  float: left;
  width: 15px;
  height: 15px;
  margin: 3px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.on-search {
    padding-left: 0;
  }
}
.notification-search-holder {
  width: 210px;
  height: 20px;
  float: left;
  position: relative;
  margin: 0 0 0 15px;
}
.notification-search-input {
  width: 190px;
  height: 20px;
  border: 0;
  color: #000;
  font: normal 18px $mainFont;
  background: transparent;
  &::-webkit-input-placeholder {
    color: $graphDdownHover;
  }
}
.notification-search-close {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.right-comment-notifi-desc {
  .description {
    a {
      font: italic 15px roboto;
      color: #457ac4 !important;
      &:visited {
        color: #5045c4 !important;
      }
    }
  }
}
.notify-card {
  p.description {
    p {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  span.description {
    p {
      margin-top: 15px;
      display: inline;
      &:first-child {
        margin-top: 0;
      }
      span {
        font: 500 15px $mainFontSemiBold;
      }
    }
  }
}
.notification-read-more-holder {
  width: 136px;
  height: 16px;
  position: absolute;
  bottom: 1px;
  right: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    $whiteColor,
    $whiteColor,
    $whiteColor,
    $whiteColor
  );
  display: none;
  .dots {
    font: normal 15px $sideFontMedium;
    color: $successMsg;
  }
  .read-more {
    font: normal 15px/16px $sideFontMedium;
    color: $blueBg;
    margin: 0 0 0 40px;
  }
}

.notifi-comments-desc-holder {
  width: 100%;
  position: relative;
  overflow: hidden;
  .description {
    color: #494949;
    font-size: 15px;
    word-wrap: break-word;
    > p {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }
    }
    h1 {
      color: #494949;
    }
  }
}
.notifi-flag {
  width: 15px;
  height: 18px;
  background: url(../img/svg/flag-normal.svg) no-repeat center center;
  position: absolute;
  top: 36px;
  right: 10px;
  z-index: 5;
  &.flagged {
    background: url(../img/svg/flag-selected.svg) no-repeat center center;
  }
}
.notifi-options-holder {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  float: right;
  margin-left: 22px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #d8d8d8;
  }
}
.notifi-options-ddown-holder {
  width: 129px;
  // height: 77px;
  height: auto;
  .attach-files-dropdown-container {
    width: 185px;
    height: 110px;

    border-radius: 10px;
    background: $whiteColor;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

    position: fixed;
    padding: 15px;
    right: 30px;
    top: 124px;
    z-index: 999;
  }
  .notifi-options-ddown-option {
    font: normal 14px/30px $mainFont;
    color: $panelHeading;
    padding-left: 10px;
    &:hover {
      // color: $blueBg;
      background-color: rgba(153, 153, 153, 0.15);
    }
  }
  .right-comments-reply-container {
    width: 350px;
    margin: 8px 0 7px 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    float: right;
  }
  .right-comments-reply-input {
    width: 275px;
    height: 29px;
    border-radius: 10px;
    border: 1px solid rgba(174, 174, 174, 0.25);
    padding: 4px 8px 5px 8px;
    box-sizing: border-box;
    color: #000;
    resize: none;
    &::-webkit-input-placeholder {
      color: $controlLabelColor;
    }
  }
  .right-comments-reply-link {
    font: 500 13px/29px $mainFont;
    color: $controlLabelColor;
    margin: 0 0 0 10px;
    &.active {
      color: $blueBg;
    }
  }

  .reply-comment-notification-holder {
    border-top: 1px solid rgba(174, 174, 174, 0.25);
    width: 292px;
    font: 500 15px $mainFont;
    padding: 10px 0 0 0;
    margin: 35px 0 4px 55px;
    color: $panelHeading;
    .highlight {
      font: 500 15px $mainFontSemiBold;
    }
  }
  z-index: 9;
  &.comment {
    top: 25px;
  }
}
.attch-from-heading {
  widows: 100%;
  position: relative;
  font: normal 10px/16px $mainFont;
  color: $attachmentColor;
  padding: 0 15px;
  margin: 0 0 6px 0;
  &:after {
    content: "";
    position: absolute;
    right: 15px;
    top: 8px;
    width: 92px;
    height: 1px;
    background: $calenderHover;
  }
}
.attach-file-size {
  font-family: normal 10px $mainFont;
  font-size: 10px;
  letter-spacing: 0px;
  text-align: right;
  color: #999999;
  height: 13px;
  margin-left: 5px;
}
.attch-from-link-holder {
  width: 100%;
  // margin: 15px 0 0 0;
}
.attch-from-link {
  width: -webkit-fill-available;
  font: normal 14px/16px $mainFont;
  color: $panelHeading;
  background: url(../img/svg/attachment-from-computer.svg) no-repeat 15px center;
  padding: 10px 0 10px 41px;
  cursor: pointer;
  position: relative;
  margin: 0;
  &:hover {
    background-color: #f0f0f0;
  }
  &.from-drive {
    background: url(../img/svg/attachment-from-drive.svg) no-repeat 15px center;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  &.from-dropbox {
    background: url(../img/svg/attachment-from-dropbox.svg) no-repeat 15px
      center;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  &.from-onedrive {
    background: url(../img/svg/onedrive.svg) no-repeat 15px center;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}
.attachmentBox {
  opacity: 0;
  display: block;
  margin-bottom: 0px;
  top: 0;
  left: 0;
  position: absolute;
  width: 0;
  height: 0;
}
.MuiModal-root-24 {
  // z-index: 5 !important;
}
.no-item-container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}
.innerDiv {
  width: 473px;
  height: 201px;
  border-radius: 10px;
  background-color: #ffffff;

  padding: 15px 0px;
  border: 1px solid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.divHeader {
  border-bottom: 2px solid #aeaeae40;
  padding: 0px 0px 15px 20px;
  display: flex;
  justify-content: space-between;
  font-family: $mainFont;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #505050;
  img {
    cursor: pointer;
  }
}
.message {
  width: 100%;
  padding: 14px 20px 0px 20px;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.64;
    letter-spacing: normal;
    color: #757575;
  }
}
.right-side {
  width: 100%;
  margin-left: 74%;
  margin-top: 30px;
  button {
    width: 100px;
    height: 35px;
    object-fit: contain;
    border-radius: 10px;
    background: #0090e9;
    border: none;
    color: #ffffff;
    font-family: $mainFont;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.ReactModalPortal {
  z-index: 2000 !important;
  position: fixed;
}

.attachment-report {
  display: flex;
  width: 134px;
}

.attachment-report-width {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 55px;
}
