@import "./variable.scss";

.navBar-container {
  width: 200px;
  min-width: 200px;
  height: 100vh;
  padding: 35px 0 15px 0;
  background-color: rgba(255, 255, 255, 0.15);
  position: relative;
  transition: all 0.3s ease-in-out;
  &:hover {
    .forward-arrow {
      display: block;
    }
  }
}
.page-right-section {
  width: calc(100% - 200px);
  transition: width 0.6s linear;
  &.on-navbar-closed {
    width: calc(100% - 65px);
  }
  .profile-header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    .profile-container {
      top: 78px;
    }
    .right-flow-profile {
      display: flex;
      .back-arrow-container {
        height: 110px;
        padding: 42px 0 42px 25px;
        display: flex;
        align-items: center;
        background-color: inherit;
        width: 221px;
        img {
          width: 20px;
          height: 16px;
          margin-right: 10px;
          margin-top: 3px;
        }
        .back-button-profile {
          font-family: $mainFont;
          font-size: 20px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          font-size: 20px;
          padding-left: 8px;
        }
      }
    }
  }
}
.navBar-workspace-container {
  margin-bottom: 58px;
  padding: 0 15px;
}

.navBar-workspace-title {
  background-image: url(../img/svg/workspace-ddown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right;
  margin: auto 0;
  // margin-left: 15px;
  width: 135px;
  font-family: roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding-right: 15px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    background-image: url(../img/svg/down-arrow.svg);
  }
}

.active-dropDown {
  background-image: url(../img/svg/arrow_up.svg) !important;
}

.navBar-options {
  padding: 0 15px 0 15px;
  display: flex;
  height: 56px;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 56px;
    left: 0;
    top: 0;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.active {
    background-color: rgba(255, 255, 255, 0.2);
    &:before {
      background: $whiteColor;
    }
  }
}

.navBar-title {
  font-family: $mainFont;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 15px;
  overflow: hidden;
  width: 70px;
  display: block;
  animation: width 0.6s linear;
}

.navBar-img-options {
  min-width: 36px;
  height: 26px;

  img {
    width: 36px;
    height: 26px;
  }
}

.navBar-img-options-workload {
  min-width: 36px;
  height: 26px;

  img {
    width: 19px;
    height: 28px;
    margin-left: 7px;
  }
}

.navBar-img-options-report {
  min-width: 36px;
  height: 26px;

  img {
    width: 25px;
    height: 27px;
    margin-left: 5px;
  }
}

.navBar-img-options-archive {
  min-width: 36px;
}

.navBar-options-footer {
  position: absolute;
  bottom: 60px;
  padding: 0 15px;
}

.forward-arrow {
  display: none;
  position: absolute;
  bottom: 85px;
  right: 10px;
  padding: 6px 11px;
  // padding: 10px;
  border-radius: 15px;

  // background-color: rgba(255, 255, 255, 0.15);
  &:hover {
    display: block;
    background-color: rgba(255, 255, 255, 0.15);
  }
}

.navBar-closedLogo {
  display: none;
}

.navBar-logo {
  display: flex;
  flex-direction: column;
  min-width: 154px;
  img {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  .version-no {
    font-size: 11px;
    color: #ffffff;
    align-self: flex-end;
    opacity: 0.5;
  }
}

.navBar-workspace-img {
  display: block;
  img {
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    margin-right: 15px;
    border-radius: 1.3px;
    object-fit: cover;
  }
  .sb-avatar {
    height: auto !important;
    width: auto !important;
  }
  .sb-avatar--text {
    height: auto !important;
    width: auto !important;
  }
  .sb-avatar__text {
    height: 20px !important;
    width: 20px !important;
    margin-right: 15px;
    border-radius: 1.3px;
    > div {
      font-family: roboto;
      font-size: 10.66667px !important;
    }
  }
}

.closed-navBar {
  width: 65px;
  min-width: 65px;

  .navBar-title {
    width: 0;
    display: none;
  }

  .navBar-logo {
    display: none;
  }

  .navBar-closedLogo {
    display: block;
    font-size: 16px;
    margin-bottom: 15px;
    img {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  .forward-arrow img {
    transform: rotate(180deg);
    right: -5px;
  }

  .navBar-workspace-img {
    display: block;
    border-radius: 1.3px;
    background-image: url(../img/svg/workspace-ddown-arrow.svg);
    background-repeat: no-repeat;
    background-position-x: 25px;
    background-position-y: 7px;
    &.active-close-dropDown {
      background-image: url(../img/svg/arrow_up.svg) !important;
    }
    &:hover {
      background-image: url(../img/svg/down-arrow.svg);
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }

  .navBar-workspace-title {
    display: none;
  }
}

//navBar-workspace-dropdown css

.navBar-workspace-dropdown {
  width: 195px;
  padding: 15px 0 15px 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  position: absolute;
  left: 15px;
  top: 60px;
  z-index: 99;
}

.navBar-workspace-dropdown-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  padding-left: 15px;
}
.not-dispaly {
  display: none !important;
}
.navBar-workspace-dropdown-Name {
  margin: 0;
  font-family: $mainFont;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.navBar-workspace-dropdown-name {
  margin: 0;
  font-family: $mainFont;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  &:hover {
    color: #0090e9;
  }
}

.navBar-workspace-dropdown-identifier {
  margin: 0;
  font-family: $mainFont;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
  width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.navBar-workspace-dropdown-header-left {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: none;

  img {
    border-radius: 2px;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  .sb-avatar--text {
    height: auto !important;
    width: auto !important;
  }
  .sb-avatar__text {
    height: 30px !important;
    width: 30px !important;
    // margin-right: 15px;
    border-radius: 2px;
    > div {
      font-family: roboto;
      font-size: 15px !important;
    }
  }
}

.navBar-workspace-dropdown-header-right {
  margin-left: 10px;
}

.settings-liner {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.settings-text {
  font-family: $mainFont;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
  margin-right: 10px;
  padding-left: 15px;
}

.liner-border {
  width: 133px;
  background-color: #f0f0f0;
  height: 1px;
}
.settings-workspace-navBar {
  // border-bottom: 1px solid #f0f0f0;
}
.settings-workspace-navBar-tabs {
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #505050;
  margin-bottom: 15px;
  padding-left: 15px;
  cursor: pointer;
  &:hover {
    color: #0090e9;
  }
}

.navBar-switch-workspace {
  padding: 15px 0 0 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  height: calc(100vh - 365px);
  // height: 135px;
  max-height: 251px;
  overflow-y: hidden;
}

.navBar-switch-workspace-title {
  font-family: $mainFont;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #808080;
  padding-left: 15px;
  margin-bottom: 15px;
}

.navBar-create {
  margin: 15px 15px 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    .navBar-create-title {
      color: #0090e9;
    }
    .arrow-img {
      display: block;
    }
  }
}

.arrow-img {
  display: none;
  padding-top: 2px;
}

.navBar-create-title {
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #505050;
  margin: 0;
  margin-right: 15px;
  &:hover {
    color: #0090e9;
    .arrow-img {
      display: block;
    }
  }
}
.navBar-workspace-holder {
  display: flex;
  height: 20px;
  cursor: pointer;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  margin-right: 5px;
  width: 2px;
  background: none !important;
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background: $graphDdownHover !important;
  }
}
.workspaces-list-navBar {
  cursor: pointer;
  &:hover {
    .navBar-workspace-dropdown-name {
      color: #0090e9;
    }
  }
}

@media screen and (max-height: 480px) {
  .navBar-container {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .navBar-workspace-container {
      margin-bottom: auto;
    }
    .navBar-options-footer {
      position: initial;
      .forward-arrow {
        position: initial;
        width: fit-content;
        float: right;
      }
    }
  }
}
