
@mixin icon_position($right, $left, $top, $bottom) {
right: $right;
left: $left;
top: $top;
bottom: $bottom;
}
@mixin placeholder {
  ::-webkit-input-placeholder {@content};
  :-moz-placeholder           {@content};
  ::-moz-placeholder          {@content};
  :-ms-input-placeholder      {@content};
}
