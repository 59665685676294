@import "variable.scss";

.add-feature-container {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 60px;
  bottom: 80px;
  z-index: 79;
}

.add-feature-holder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.af-main-holder {
  width: 50px;
  height: 280px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  border-radius: 25px;
  display: none;
}

.af-inner-holder {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;

  &.ng-enter {
    animation: 0.4s ease-out fadeInUpAf;
    opacity: 1;
  }

  &.ng-leave {
    animation: 0.6s ease-out fadeInDownAf;
    opacity: 0;
  }
}

.af-toggle-holder {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 0 0 20px 0;
  cursor: pointer;
  &.no-margin {
    margin: 0;
  }

  &.add {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.af-circular-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $whiteColor;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  &.add-icon {
    transform: rotate(0deg);
    transition: transform 0.1s linear;
  }

  &.close-icon {
    transform: rotate(45deg);
    transition: transform 0.1s linear;
  }

  img {
    max-width: 100%;
    max-height: 100%;

    &.normal {
      display: block;
    }

    &.hover {
      display: none;
    }
  }

  &:hover {
    background: $blueBg;

    img {
      &.normal {
        display: none;
      }

      &.hover {
        display: block;
      }
    }
  }
}

md-tooltip {
  &.af {
    .md-content {
      border-radius: 15px;
      color: $whiteColor !important;
      height: 30px;
      padding-left: 16px;
      padding-right: 16px;
      font: normal 13px/30px $mainFont !important;
    }
  }
}

@keyframes fadeInUpAf {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDownAf {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.report-page-display{
  right: 80px;
}
