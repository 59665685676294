@import "variable.scss";

@font-face {
  font-family: "roboto";
  src: url("../fonts/roboto/roboto-regular-webfont.ttf") format("truetype");
  src: url("../fonts/roboto/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoLight";
  src: url("../fonts/roboto/roboto-light-webfont.ttf") format("truetype");
  src: url("../fonts/roboto/roboto-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoMedium";
  src: url("../fonts/roboto/roboto-medium-webfont.ttf") format("truetype");
  src: url("../fonts/roboto/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoBold";
  src: url("../fonts/roboto/roboto-bold-webfont.ttf") format("truetype");
  src: url("../fonts/roboto/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body,
html {
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  line-height: normal;
}

.container {
  width: 100%;
  margin: 0 auto;
}

:focus {
  outline: none;
  border: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: $mainFont;
  font-size: 13px;
  color: #555;
  background-color: #ffffff;
  overflow: hidden;

  .unslpash-credits {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.25);
    bottom: 0;
    position: fixed;
    right: 20px;
    padding: 10px;
    z-index: 99;

    span {
      margin-right: -4px;
      margin-left: -4px;
    }

    a {
      color: rgba(255, 255, 255, 0.25);
      font-size: 14px;
    }

    a:hover {
      text-decoration: underline;
      color: #ffffff;
    }
  }
}

body::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  width: 4px;
  background: $topNaviColor;
  border-radius: 2px;
}
#root {
  height: 100vh;
}

.contain {
  .prod {
    height: 77px;
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: transparent;
    z-index: 80;
    padding: 24px 0 0 0;
  }

  .dev {
    height: 77px;
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: transparent;
    z-index: 80;
    padding: 24px 0 0 0;
  }

  .local {
    height: 65px;
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: transparent;
    //opacity: .5;
    z-index: 80;
    padding: 30px 0 0 0;
  }
}

.form-group {
  padding: 11px;
  margin: 0;
  vertical-align: middle;
}

.signin_button {
  z-index: 9;
  float: right;
  right: 3%;
  background-color: $primary_gtd !important;
  color: white !important;
}

.user-img-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background: #ccc;
  margin-right: 15px;
  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
}

.project-assign-img {
  width: 35px;
  height: 35px;
  img {
    width: 100%;
    height: 100%;
  }
  > div > div {
    width: 35px !important;
    height: 35px !important;
    padding-bottom: 2px !important;
    padding-right: 0 !important;
    > div {
      font-size: 16px !important;
      > span {
        padding-top: 3px;
        padding-left: 1px;
      }
    }
  }
}

.user-workspace {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  max-width: 133px;
  min-width: 133px;
  width: auto;
  margin-right: 40px;
}

.user-workspace-proj {
  display: block;
  white-space: nowrap;
  max-width: 133px;
  min-width: 133px;
  width: auto;
  margin-right: 40px;
}

.user-add-action {
  font-size: 16px;
  font-family: $mainFont;
  color: #0090e9;
  font-weight: 500;
  position: relative;
  z-index: 99999;
  cursor: pointer;
}

#loginbox {
  top: 0;
  position: absolute;
  left: 0;
  width: 100%;
  //padding-top: 10px;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 9.88% 0 19.47%;

  .logo {
    display: flex;
    margin-top: 10px;

    img {
      display: flex;
      margin: 0 auto;
    }

    .signin_button {
      height: 44px;
    }
  }

  .left {
    float: left;
    display: inline-block;
    text-align: center;
    margin: 0;

    .free {
      font-size: 16px;
      font-weight: 300;
      color: #ffffff;
      margin: 0 0 25px 0;

      span {
        font-weight: 700;
      }
    }

    .top {
      font-size: 28px;
      color: #fff;
      margin: 0 0 0 0px;

      span {
        font-weight: 700;
      }
    }
  }

  .right {
    min-width: 415px;
    max-width: 415px;
    min-height: 578px;
    margin: 0 0 0 0;
    display: flex;

    .signIn-container {
      width: 100%;

      .signIn {
        width: 100%;
        height: 100%;
        padding: 0 12% 0;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 0;
        border: 1px solid $brdrColor;
        box-shadow: none;

        .login-form {
          width: 100%;
          margin: 0 auto;

          .help-block {
            font: normal 13px $mainFont;
            color: #ef5350;
          }

          .error_msg {
            font-size: 12px;
            color: #ed2028;
          }

          .login_block {
            height: 65px;
          }

          .or-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 8px 0;
            .or-hr {
              margin-top: 4px;
              width: 88px;
              height: 1px;
              background: #d6d6d6;
            }
            .or-register {
              text-align: center;
              font-size: 12px;
              margin: 0 5px;
              color: #757575;
            }
          }

          .goog {
            //border: 1px solid $controlLabelColor;
            cursor: pointer;
            border-radius: 10px;
            width: 205px;
            height: 43px;
            margin: 8px auto 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
            &.appl {
              margin: 15px auto 0;
            }
            img {
              display: inline-block;
              float: left;
              margin: 0 20px 0 12px;
            }
            p {
              font: 500 15px robotoMedium;
              color: #808080;
              margin: 0;
            }
          }

          .or {
            margin: 50px 0 40px 0;

            p {
              text-align: center;
            }
          }

          .log_email {
            margin: 0;
            width: 100%;
            margin-bottom: 10px;

            label {
              font: normal 20px $mainFont;
              color: $controlLabelColor;
            }
          }

          .log_pass {
            margin: 0;
            width: 100%;

            label {
              font: normal 20px $mainFont;
              color: $controlLabelColor;
            }
          }

          .forgot-password-login {
            display: block;
            float: right;
            font: normal 14px $mainFont;
            color: $blueBg;
            margin-top: -8px;
            cursor: pointer;
            &:hover {
              text-decoration: none;
            }

            // float: right;
            // font: normal 15px $mainFont;
            // color: $blueBg;
            // display: block;
            // position: absolute;
            // bottom: -5px;
            // z-index: 5;
            // &:hover {
            //   color: $blueBg;
            // }
          }

          .log-log {
            text-align: center;
            margin: 65px 0 0 0;

            .signin {
              width: 205px;
              height: 43px;
              border-radius: 10px;
              font: 500 18px/40px $mainFont;
              color: $whiteColor;
              text-transform: capitalize;
              margin: 0;
              box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
              background-color: $blueBg;
              padding: 0 16px;
            }
          }
          .log-reg {
            text-align: center;
            margin: 47px 0 0 0;

            .for-pass {
              color: #808080;
              float: none;
              margin-bottom: 4px;
              font: normal 15px $mainFont;
            }

            .for-register {
              color: #0090e9;
              font: 500 20px $mainFontSemiBold;
              position: relative;
              margin: 0 auto 0;
              cursor: pointer;

              &:hover {
                text-decoration: none;
              }

              &:before {
                content: "";
                position: absolute;
                width: 30px;
                height: 1px;
                background: lighten($topNaviColor, 20%);
                left: -40px;
                top: 50%;
                transform: translateY(-50%);
              }

              &:after {
                content: "";
                position: absolute;
                width: 30px;
                height: 1px;
                background: lighten($topNaviColor, 20%);
                right: -40px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .goog {
          //border: 1px solid $controlLabelColor;
          cursor: pointer;
          border-radius: 10px;
          width: 205px;
          height: 43px;
          margin: 125px auto 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
          img {
            display: inline-block;
            float: left;
            margin: 0 20px 0 12px;
            width: 18px;
            height: 18px;
          }
          p {
            font: 500 15px robotoMedium;
            color: #808080;
            margin: 0;
          }
        }
        .fp-goog {
          margin: 90px auto 0;
        }
      }
    }
  }
}

/*Header*/
.icon-opacity {
  opacity: 0.5;
}

div.header-main-app {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-grow: 2;
  height: 65px !important;
  padding: 15px 30px 0 0 !important;
  background-color: transparent;
  margin-top: 16px;

  .header-logo {
    display: flex;
    align-items: center;
    flex-grow: 4;
    width: 260px;
    height: 52px;
    margin-top: 0 !important;
    cursor: pointer;

    .logo {
      display: flex;
      align-self: center;
      margin-left: 21px;
      margin-top: 0px;
    }
  }

  .performa {
    // margin-right: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 0 !important;
    .notification-icon-holder {
      margin-left: 30px;
      margin-right: 35px;
      margin-top: 7px;
    }
    .performa-wrapper {
      display: flex;
      .gtd-profile {
        height: 32px;
      }
    }
    .today-yesterday {
      display: flex;
      // margin-right: 35px;
      font-size: 18px;
      font-family: $mainFont;
      align-items: center;
      .today {
        text-align: right;
        cursor: pointer;
      }
      .on-report-page {
        pointer-events: none;
        opacity: 0.5;
      }

      .yesterday {
        text-align: right;
      }

      .hours {
        a {
          font-weight: normal;
          font-size: 18px;
        }
      }
    }

    .performance {
      display: inline-block;
    }
    .dashboard-performance {
      .performance {
        opacity: 0.5;
        cursor: default;
      }
    }
    .dashboard-header {
      margin-left: 25px;
    }
    .user-profile {
      width: 28px;
      height: 28px;
      background-color: #ffffff;
      border: solid 2px #ffffff;
      border-radius: 50%;
      margin-right: 0;
      margin-bottom: 1px;
      object-fit: cover;
      cursor: pointer;
    }

    .profile-container {
      font-family: $mainFont;
      width: 320px;
      height: auto;
      border-radius: 10px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 87px;
      right: 20px;
      z-index: 999;
      overflow: hidden;

      .profile-header {
        color: #000;
        display: flex;
        margin: 16px 20px 5px 20px;
        padding: 0 0 8px 0;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;

        img {
          width: 45px;
          height: 45px;
          object-fit: cover;
          background-color: #ffffff;
          border: solid 2px #ffffff;
          border-radius: 50%;
          margin-right: 15px;
        }

        .sb-avatar {
          .sb-avatar__text {
            width: 45px !important;
            height: 45px !important;
            background-color: #ffffff;
            border: solid 2px #ffffff;
            border-radius: 50%;
            margin-right: 15px;
            div {
              font-size: 17px !important;
              span {
                padding-top: 1px;
                padding-left: 0.5px;
              }
            }
          }
        }

        .user-name {
          font-size: 17px;
          text-align: left;
          color: #494949;
        }

        .user-email {
          font-size: 13px;
          text-align: left;
          color: #757575;
        }
      }

      .profile-body {
        color: #494949;
        font-size: 17px;
        padding-bottom: 2px;

        p {
          margin: 0;

          a {
            color: #494949;
            height: 50px;
            margin: 0;
            padding: 0 20px;
            align-items: center;
            display: flex;
            text-decoration: none;
            cursor: pointer;

            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
      }
    }
  }

  .header_profile {
    display: flex;
    flex-direction: row;

    .user-profile {
      width: 28px;
      height: 28px;
      background-color: #ffffff;
      border: solid 2px #ffffff;
      border-radius: 50%;
      margin-right: 0;
      margin-bottom: 1px;
      object-fit: cover;
      cursor: pointer;
    }

    .notify {
      cursor: pointer;
      width: 28px;
      height: 28px;
    }

    .hamburger-icon {
      display: flex;
      justify-content: center;
      margin: 15px 21px 0 0;
      height: 17px;
      width: 22px;
    }
  }
}

.user-ddown-email {
  font-size: 13px;
  text-align: left;
  color: #757575;
}

.side_nav {
  font-size: 14px;
  min-height: 36px;
  text-align: left;
  width: 100%;
  padding: 15px;

  &:hover {
    background-color: $primary_gtd;
    color: #fff;
  }

  &:hover a {
    color: #fff;
  }

  a {
    color: #2a98f0;
    margin: auto;
    padding-left: 20px;
    text-decoration: none;
  }

  a:active {
    color: #818181;
  }
}

.boards-title {
  background-image: url("../img/svg/board_arrow.svg");
  background-repeat: no-repeat;
  padding-left: 20px;
  background-position: left;
  cursor: pointer;
}

.active-drop {
  background-image: url("../img/svg/arrow_up.svg");
}

.board-name {
  opacity: 0.75;
  font-family: $mainFont;
  font-size: 16px;
  color: #fff;
  cursor: pointer;

  a {
    font-family: $mainFont;
    text-decoration: none;
    color: #ffffff;
  }
}

.active-board {
  border: 1px solid #0090e9;
}

.board {
  display: flex;
  align-items: center;
  margin-left: 25px;
  //margin-top: 30px !important;

  p {
    height: 20px;
    font-family: $mainFont;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-right: 10px;
    //margin-bottom:0;
    //padding-bottom:10px;
  }

  span {
    margin-right: 10px;
    color: #fff !important;
    opacity: 1;
  }

  .board-dropdown {
    width: 330px;
    height: calc(100% - 140px);
    position: absolute;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    top: 128px;
    border-radius: 10px;
    z-index: 999;
    left: 20px;
    max-height: 550px;

    .workspace-scroll {
      height: calc(100% - 50px);
    }

    .search_board {
      width: 87%;
      background-color: #f0f0f0;
      height: 40px;
      border: none;
      border-bottom: 1px solid #aeaeae;
      background-image: url(../img/svg/Search.svg);
      background-repeat: no-repeat;
      padding-left: 43px;
      background-position: 5.5%;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 15px;

      &::-webkit-input-placeholder {
        color: #aeaeae;
      }
    }

    .board-list {
      // overflow-y: scroll;
      height: calc(100% - 55px);
      margin-top: 10px;
      width: calc(100% - 6px);
    }

    .board-list::-webkit-scrollbar {
      margin-top: 10px;
      width: 4px;
      background: transparent;
    }

    .board-list::-webkit-scrollbar-thumb {
      width: 4px;
      background: $topNaviColor;
      border-radius: 2px;
    }

    .boards-cards {
      background-color: #ffffff;
      width: 270px;
      display: inline-block;
      padding: 10px;
      margin: 0 0 10px 10px;
      position: relative;
      float: left;
      border-radius: 8px;
      overflow: hidden;
      height: 80px;

      .menu_options {
        display: flex;
        justify-content: flex-end;

        img {
          width: 14px;
          height: auto;
        }
      }

      .md-title {
        font-size: 16px;
        color: $font_light;
        text-transform: none;
        font-family: $sideFont;
        height: 20px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .activities-num {
        text-align: left;
        font-family: $sideFont;
      }

      .card-content {
        text-align: center;
        //display:flex;
        padding-left: 15px;

        .client-name {
          text-align: left;
          padding-top: 15px;

          .md-title {
            color: #868686 !important;
          }
        }

        .workspace {
          text-align: left;
          padding-top: 22px;

          .md-title {
            color: #868686 !important;
          }
        }

        .img-container {
          width: 40px;
          height: 40px;
          background-size: contain;
          background-repeat: no-repeat;
          text-align: center;
          background-position: center;
          margin-right: 12px;
          display: flex;
          justify-content: flex-start;
          float: left;
          margin-top: 15px;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }

        md-card._md {
          padding: 10px 0 47px;
          overflow: auto;
          width: 248px;
        }
      }
    }
  }
}

a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

/** Reports **/

.timesheet_section {
  .-form {
    margin: 50px 0;
  }
}

.bg-report {
  background: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    no-repeat;
  background-size: cover;
  height: 100%;

  h2 {
    margin: 0;
    padding: 20px 0 0 20px;
    color: $snow_gtd;
    //text-transform: uppercase;
    font-size: 24px;
  }
}

.timesheet_section {
  .form-inline {
    .form-group {
      margin: 0;
      padding: 0;
    }

    .timesheet-search-message {
      color: $snow_gtd;
    }
  }
}

.report-header {
  display: flex;
  align-items: center;
  justify-content: center;

  .project_name {
    padding: 8px;
    font-size: x-large;
    font-weight: bold;
    margin-left: 15px;
  }
}

.report_body {
  background-color: #ffffff;

  .alert {
    padding: 20px;
    font-size: 16px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.admin_report_body {
  background-color: #ffffff;
  overflow: auto;
  height: calc(100% - 152px);

  .alert {
    padding: 20px;
    font-size: 16px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .c3 svg g g.c3-axis.c3-axis-x {
    font: 11px sans-serif;
  }
}

.chart-gauge {
  width: 400px;
  height: 200px;
  margin: 100px auto;

  svg {
    width: 500px;
    height: 300px;
  }
}

.chart-first {
  fill: #9fbd35;
}

.chart-second {
  fill: #f2ba3a;
}

.chart-third {
  fill: #fb3033;
}

.red {
  fill: red !important;
}

.amber {
  fill: #ffc200 !important;
}

.green {
  fill: green !important;
}

.c3-text {
  font-size: 14px;
}

.needle,
.needle-center {
  fill: #000000;
}

.text {
  color: #818181;
  font-size: 16px;
}

.chart_header {
  display: flex;
}

.bar_chart {
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.table-striped {
  tbody {
    tr:nth-of-type(odd) {
      background-color: #f9f9f9;
      border-bottom: 1px solid #ddd;
    }
  }
}

.row-fluid {
  width: 98%;
  height: calc(100% - 288px);
  overflow: auto;
  margin: 0 auto;
  padding: 0 !important;
}

.searchbar {
  width: 98%;
  margin: 0 auto;

  .btn {
    font-size: 17px;
    font-weight: 300;
    outline: none;
    outline-offset: 0;
    border: 0 none;

    &:focus {
      outline: none;
      outline-offset: 0;
    }
  }

  .icon_download {
    background-image: url("../img/svg/Export.svg");
    @include icon_position(56px, auto, 5px, auto);
    display: inline-block !important;
  }

  .icon_filter {
    background-image: url("../img/svg/Group_By.svg");
    @extend .icon_bg;
    @include icon_position(39px, auto, 5px, auto);
    float: right;
    padding: 0 28px;
    color: $snow_gtd;
    text-decoration: none;
  }
}

.mCSB_container_wrapper {
  margin-right: 0;
}

.timesheet_gtd {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background: #fff;
  padding: 0;
  margin: 0;

  thead {
    tr {
      th {
        border-bottom: 0;
        font-size: 16px;
        color: $font_dark;
      }

      th.date {
        width: 6%;
      }

      th.client {
        width: 10%;
      }

      th.project {
        width: 12%;
      }

      th.activity {
        width: 15%;
      }

      th.user {
        width: 8%;
      }

      th.description {
        width: 36%;
      }

      th.timetaken {
        width: 10%;
      }

      th.edit {
        width: 3%;
      }
    }
  }

  tbody + tbody {
    border: 0;
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #f0f9fe;
      border-bottom: 0 solid #ddd;
    }

    tr {
      td {
        font-size: 16px;
        color: $font_dark;
        border: 0;
      }

      td.date {
        width: 6%;
      }

      td.client {
        width: 10%;
      }

      td.project {
        width: 12%;
      }

      td.activity {
        width: 15%;
      }

      td.user {
        width: 8%;
      }

      td.description {
        width: 36%;
      }

      td.timetaken {
        width: 10%;
      }

      td.edit {
        width: 3%;
      }
    }
  }
}

.toolbar {
  background-color: transparent !important;
  list-style: none;
  margin-top: 15px;
  text-align: center;
  padding-left: 0;

  li {
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-color: #000;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      width: 20px;

      .active {
        color: $snow_gtd;
      }

      li {
        display: block !important;
        width: 100%;
        text-align: left;

        a {
          display: block;
          padding: 3px 20px;
          clear: both;
          font-weight: 400;
          line-height: 1.42857143;
          color: $font_dark;
          white-space: nowrap;

          &:hover {
            color: $font_dark;
          }

          &:active {
            color: $snow_gtd;
          }
        }
      }
    }

    display: block;
    float: left;
    /* width: 33.3%;*/
    /* &:last-child {
      text-align: right;
    }

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(1) {
      text-align: left;
      min-height: 1px;
    }*/
  }

  .timesheet_header_total {
    font-size: 20px;
    color: $snow_gtd;
    font-weight: 300;
  }

  label {
    color: $snow_gtd;
  }
}

.timesheet_section {
  tags-input {
    .host {
      position: relative;
      margin-top: -4px;
      margin-bottom: 5px;
      height: 100%;
    }
    @include placeholder {
      color: $snow_gtd;
      font-size: 16px !important;
    }

    .tags {
      padding: 1px;
      overflow: hidden;
      word-wrap: break-word;
      cursor: text;
      border-bottom: 1px solid #ffffff;
      height: 100%;
      width: 100%;
      min-width: 275px;
      max-width: 300px;

      input {
        width: 76px !important;
      }
    }
  }

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  .report_search {
    margin-top: 36px;
    padding-left: 5px;

    li {
      display: inline-block;
      padding-right: 5px;
      padding-left: 5px;
      float: left;
    }

    .selectize-input {
      width: 150px;
      border: none;
      padding: 9px 0 7px;
      background: none !important;
      border-radius: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #ffffff;
      color: #ffffff !important;
      font-size: 14px;
      border: none;
      background: none !important;
      border-radius: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #e4e4e6;
      color: #fff !important;

      @include placeholder {
        color: $snow_gtd;
        font-size: 18px !important;
      }

      .selectize-input.disabled {
        opacity: 0.5;
      }

      input {
        font-size: 16px !important;
        color: #fff !important;
        height: 20px;
        position: relative !important;
      }
    }

    .moment-picker {
      .moment-picker-contents {
        .due_on {
          .act_due_on {
            input {
              @include placeholder {
                color: $snow_gtd;
                font-size: 16px !important;
              }
            }
            box-shadow: none;
            background: none;
            border: none;
            border-radius: 0;
            padding: 5px 0 6px;
            border-bottom: 1px solid #e4e4e6;
            font-size: 18px !important;
            color: #fff !important;
          }
        }
      }
    }

    .form_control {
      width: 100%;
    }

    .add-comment-btn {
      width: 94px;
      height: 40px;
      border: none;
      color: #ffffff;
      font-family: "Open Sans";
      margin-left: 5px;
      background-color: #2fb6fc;
      font-size: 16px;
      font-weight: 500;
    }

    .add-comment-btn[disabled] {
      background-color: #c0c0c0;
    }
  }
}

/** Reports End **/

/*Timesheet*/
.timesheet_section {
  display: flex;
  justify-content: center;
}

.md-primary.md-raised.timesheet_apply_btn.md-button.md-ink-ripple {
  background-color: #2a98f0;
  color: #ffffff;
}

.md-primary.md-raised.timesheet_apply_btn.md-button.md-ink-ripple:hover {
  /*background-color: #2A98F0 !important;*/
  color: #ffffff;
}

.md-primary.md-raised.timesheet_apply_btn.md-button.md-ink-ripple:disabled {
  background-color: #cccccc;
  color: #ffffff;
}

table caption {
  font-weight: 300;
  color: $snow_gtd;
  font-size: 20px;
  margin-top: 27px;
}

.timesheet-search-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  color: $snow_gtd;
}

.timesheet_user_select {
  max-width: 280px;
}

.table {
  th {
    font-size: 18px;
    font-weight: bold;
  }

  td {
    font-size: 16px;
  }
}

.empty_message {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: #ffffff;
}

.reports_edit {
  .timesheet_edit_toolbar {
    background-color: $primary_gtd !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 10px;

    .title {
      width: 90%;
      float: left;
    }

    .close {
      width: 10%;
      opacity: 1;
      float: left;
      text-align: right;
      padding-right: 5px;
    }

    img {
      width: 50%;
    }
  }

  .timesheet_edit_dialog {
    width: auto;
    height: auto;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;

    .date_range {
      padding: 5px;
    }
  }
}

.selectdemoSelectHeader .demo-header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.layout-margin,
.layout-margin > * {
  margin: 0 !important;
}

.selectdemoSelectHeader .demo-select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}

.selectdemoSelectHeader md-content._md {
  max-height: 240px;
}

md-table-container {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  background-color: #fff;
}

.moment-picker .month-view td {
  width: 3em;
}

.moment-picker .day-view td {
  width: 3em;
}

.moment-picker .moment-picker-container {
  width: 24em;
  border-radius: 10px;
}

.md-theme-indigo {
  background-color: #2a98f0;
  height: 90px;

  .hamburger_icon {
    margin: 28px;
    font-size: 30px;
    width: 5%;
  }
}

md-toolbar {
  .md-default-theme:not(.md-menu-toolbar) {
    background-color: rgb(47, 182, 252);
    color: rgba(255, 255, 255, 0.87);
  }
}

md-toolbar:not(.md-menu-toolbar) {
  background-color: rgb(47, 182, 252);
  color: rgba(255, 255, 255, 0.87);
}

// new sidebar scss

.icon-bell {
  background-image: url("../img/svg/notification.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  float: left;
}

.icon_dashboard {
  background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -162px;
}

.icon_admin_activity {
  background-image: url("../img/svg/Hamburger_Sprite_Sheet1.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -12px;
}

.icon_user_activity {
  background-image: url("../img/svg/Hamburger_Sprite_Sheet1.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -12px;
}

.icon_archived {
  background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -40px;
}

.icon_clients {
  background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -100px;
}

.icon_reports {
  background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -70px;
}

.Organization_Icon {
  background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -220px;
}

.icon_logtrack {
  background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -133px;
}

.icon_signout {
  background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
  @extend .icon_bg;
  @include icon_position(auto, auto, auto, auto);
  display: inline-block !important;
  background-position: -10px -190px;
}

.rightbar {
  background: $sidebar_bg;
  height: 100%;
  //padding-top: 20px;
  position: fixed;
  right: -320px;
  width: 0;
  z-index: 200;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;

  .profile {
    background-color: $sidebar_head;
    padding: 15px 0;

    li,
    li:hover {
      background-color: $sidebar_head;
      color: $snow_gtd;
      display: inline-block;

      &:last-child {
        a {
          padding: 0;
        }
      }

      a {
        color: $snow_gtd;
        color: $snow_gtd;
        padding: 20px 20px 20px 30px;
        font-size: 16px;

        img {
          width: 60px;
          border-radius: 50%;
        }

        .username {
          color: $snow_gtd;
          font-size: 16px !important;
          font-weight: normal;
          font-weight: bold;
        }
      }
    }
  }

  .menu {
    margin-bottom: 0;
    padding-left: 0;

    li {
      list-style: none;
      color: $snow_gtd;
      font-weight: 100;
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;

      &:hover {
        color: $sidebar_hover;

        a {
          .icon_dashboard {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -162px;
          }

          .icon_user_activity {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -12px;
          }

          .icon_admin_activity {
            background-image: url("../img/svg/Hamburger_Sprite_Sheet1.svg");
            background-position: -40px -12px;
          }

          .icon_clients {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -100px;
          }

          .icon_archived {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -40px;
          }

          .icon_logtrack {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -133px;
          }

          .icon_signout {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -190px;
          }

          .Organization_Icon {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -221px;
          }

          .icon_reports {
            background-image: url("../img/svg/Hamburger_Spreadsheet.svg");
            background-position: -40px -70px;
          }

          color: $sidebar_hover;
          text-decoration: none;
          display: block;
          font-size: 16px;

          &:active,
          &:focus,
          &:hover,
          &:visited {
            text-decoration: none;
          }
        }
      }

      a {
        color: $snow_gtd;
        font-size: 16px;
        line-height: 23px;
        transition: all 0.2s ease 0s;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        padding: 15px 30px 11px;
        display: block;
        text-decoration: none;

        i {
          position: relative;
          margin-right: 20px;
          margin-left: 0;
          vertical-align: middle;
        }
      }

      .sign_out {
        padding: 10px;
        color: #000000;
        font-size: 12px;
        font-weight: normal;
        line-height: 23px;
        padding-right: 54px !important;
      }
    }
  }
}

.noneStyle {
  width: 320px;
  z-index: 1000;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  right: 0;

  a {
    font-size: 16px;
  }
}

.icon_sm {
  height: 18px;
  width: 4px;
  position: absolute;
  display: block;
  background-repeat: no-repeat;
}

.icon_bg {
  height: 20px;
  width: 20px;
  position: relative;
  display: block;
  background-repeat: no-repeat;
}

// md menu

md-menu-item {
  min-height: 40px;
  height: 40px;
}

md-menu-content {
  padding: 0 !important;
}

.md-primary.md-raised.timesheet_apply_btn.md-button.md-ink-ripple,
.md-primary.md-raised.timesheet_description_btn.md-button.md-ink-ripple {
  background-color: $primary_gtd;
}

.project-popup form .md-toolbar-tools .done-btn button[disabled] {
  cursor: auto;
  color: $controlLabelColor !important;
  span {
    color: $controlLabelColor !important;
  }
}

.create_activity {
  .datepicker_activity {
    display: block;
  }

  .duration_activity {
    width: 100%;
  }
}

.top-home-link {
  top: 25px;
  left: 34px;
  position: absolute;
  background: url(../img/svg/home-bck-arw.svg) no-repeat left center;
  padding: 0 0 0 22px;
  background-position: 0 8px;
  color: $topNaviColor;
  font-family: $mainFont;
  font-weight: 300;
  font-size: 20px;
  cursor: pointer;
  display: block;
  z-index: 2;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $topNaviColor;
  }
}

.playstore-link-holder {
  width: 299px;
  display: flex;
  justify-content: space-between;
  margin: 143px auto 0;
  &.register-links {
    margin-top: 35px;
  }
}

.app-store-img-holder {
  cursor: pointer;
  width: 140px;
  height: 41px;

  &.apple {
    width: 137px;
  }

  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

.login-controls-container {
  width: 100%;
  margin: 80px 0 0 0;
  position: relative;
}

.login-control-holder {
  width: 100%;
  min-height: 56px;

  input {
    &::-webkit-input-placeholder {
      color: #aeaeae;
      font-weight: 300 !important;
      font-family: $mainFont;
    }
  }
}

.common-input-ctrl {
  width: 100%;
  height: 35px;
  border: 0;
  border-bottom: 1px solid $brdrColor;
  font-family: Roboto;
  font-weight: 300;
  font-size: 20px;
  color: $successMsg;
  padding: 0;
  &::-webkit-input-placeholder {
    color: #aeaeae;
    font-weight: 100 !important;
    font-family: $mainFont;
    font-size: 18px;
  }
}

.common-err-msg {
  &.invite-email-err-msg {
    position: absolute;
    top: 34px;
    left: 54px;
    font-size: 12px;
  }
  font: normal 13px/18px $mainFont;
  color: #ef5350;
  margin: 0;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.success {
  color: #6ec500;
}

.new-gtd-logo {
  width: 355px;
  height: 309px;
}

.calendar-arrow {
  width: 6px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.left-face {
    transform: rotate(180deg);
    float: right;
  }

  md-toolbar-tools title img {
    max-width: 100%;
    max-height: 100%;
  }
}

button.md-default-theme[disabled],
button[disabled],
button.md-default-theme.md-raised[disabled],
button.md-raised[disabled],
button.md-default-theme.md-fab[disabled],
button.md-fab[disabled],
button.md-default-theme.md-accent[disabled],
button.md-accent[disabled],
button.md-default-theme.md-warn[disabled],
button.md-warn[disabled] {
  color: $whiteColor;
}

button.md-default-theme.md-raised,
button.md-raised {
  color: $whiteColor;
}

button.md-default-theme.md-raised[disabled],
button.md-raised[disabled],
button.md-default-theme.md-fab[disabled],
button.md-fab[disabled] {
  background-color: rgba(174, 174, 174, 0.5);
  pointer-events: none;
}

button.md-default-theme.md-raised:not([disabled]).md-focused,
button.md-raised:not([disabled]).md-focused {
  background: $blueBg;
  cursor: pointer;
}

button.md-raised:not([disabled]) {
  background: $blueBg;
  cursor: pointer;
}

button.md-default-theme:not([disabled]):hover,
button:not([disabled]):hover {
  cursor: pointer;
  background: $blueBg;
}

img {
  max-width: 100%;
  max-height: 100%;
}
button.md-default-theme[disabled],
button[disabled],
button.md-default-theme.md-raised[disabled],
button.md-raised[disabled],
button.md-default-theme.md-fab[disabled],
button.md-fab[disabled],
button.md-default-theme.md-accent[disabled],
button.md-accent[disabled],
button.md-default-theme.md-warn[disabled],
button.md-warn[disabled] {
  color: $whiteColor;
  border: none;
}
button.md-default-theme.md-raised,
button.md-raised {
  color: $whiteColor;
  border: none;
}
button.md-default-theme.md-raised[disabled],
button.md-raised[disabled],
button.md-default-theme.md-fab[disabled],
button.md-fab[disabled] {
  background-color: rgba(174, 174, 174, 0.5);
  pointer-events: none;
  border: none;
}
button.md-default-theme.md-raised:not([disabled]).md-focused,
button.md-raised:not([disabled]).md-focused {
  background: $blueBg;
  cursor: pointer;
}
button.md-raised:not([disabled]) {
  background: $blueBg;
  cursor: pointer;
}
button.md-default-theme:not([disabled]):hover,
button:not([disabled]):hover {
  cursor: pointer;
  //background:$blueBg !important
}
.page-heading {
  font: normal 24px/31px $mainFont;
  color: $whiteColor;
  margin: 12px 0 0 24px;
}
.profile_container {
  z-index: 99;
}
.remove-link {
  cursor: auto;
}

.errorBox-container {
  width: 260px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 10px;
  position: fixed;
  top: 100px;
  right: 24px;
  z-index: 9999;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
  .inner-container {
    padding: 12px;
    .errorBox-content {
      margin: 0 0 12px 0;
      color: #808080;
      font-family: $sideFont;
      font-size: 13px;
      font-stretch: normal;
      line-height: 1.38;
      letter-spacing: 0.2px;
    }
    .reload {
      width: 83px;
      height: 30px;
      float: right;
      button {
        background-color: #0090e9;
        border: none;
        text-align: center;
        color: #ffffff;
        font-family: $sideFont;
        font-size: 15px;
        font-stretch: normal;
        border-radius: 10px;
        padding: 6px 18px;
      }
    }
  }
}
md-chips.md-default-theme md-chip.md-focused,
md-chips md-chip.md-focused {
  background: $whiteColor;
  color: $successMsg;
  md-icon {
    color: $successMsg;
  }
}

.margin-btw-rows {
  margin-bottom: calc(100vh - 414px);
}

.edit_memact {
  font: normal 10px/11px $sideFont !important;
  color: rgba(73, 73, 73, 0.5) !important;
}

.activities-num {
  p {
    color: #aeaeae;
  }
}

.dash-text {
  opacity: 0.75;
  color: #fff;
}

/*Inline-css */

.myactivity-i {
  .fa-plus {
    font-size: 20px;
    padding: 18px 15px;
    color: #ffffff;
  }
}

.replacement-txt {
  font: normal 15px roboto;
  color: $mdLabel;
  padding: 10px 0 0 0;
  width: 100%;
  display: block;
  height: 34px;
  border-bottom: 1px solid $lightBorder;

  &.no-clients {
    padding: 0;
    height: 24px;
  }
}

.truncate-txt {
  width: 175px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.username_email_block {
  width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.md-input {
  -moz-appearance: textfield !important;
}

.identifier-name {
  font-size: 12px;
  color: #aeaeae;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 177px;
  display: block;
  margin-top: 5px;
}

.board-noresults {
  text-align: center;
  margin-top: 230px;
}

.no-projects {
  margin-top: 149px;
}

.report-opacity {
  opacity: 0.5;
  pointer-events: none;
}

.inactiveAct {
  margin-left: 13px !important;
}
.no-hyperlink {
  pointer-events: none;
}

.gtd-parent-wrapper {
  width: 100%;
  height: 100%;
}

/*MOBILE VIEW */

@media only screen and (max-width: 900px) {
  .high-res {
    display: none !important;
  }

  .low-res {
    display: block !important;
    overflow: auto;
    height: 100%;
  }

  #loginbox,
  .top-home-link {
    display: none !important;
  }

  .gtd-mobile-view {
    max-width: 375px;
    margin: 0 auto;
    text-align: center;
    margin-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .gtd-mobile-view h1 {
    font-size: 24px;
    color: #494949;
    line-height: 30px;
    margin: 50px 0 25px 0;
  }

  .gtd-mobile-view p {
    font-size: 16px;
    line-height: 24px;
    color: #808080;
    margin: 0 0 50px 0;
    &.download-para {
      margin: auto;
      margin-bottom: 25px;
    }
  }

  .mobile-view-apps-icon {
    display: flex;
    flex-direction: column;
  }

  .mobile-view-apps-icon a {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-bottom: 40px;
  }

  .mobile-website a {
    color: #0090e9;
    text-decoration: underline;
    font-size: 17px;
  }

  .mobile-website {
    margin: 10px auto 57px auto;
  }

  ng-view.ng-scope {
    overflow: auto;
    position: relative;
    height: 100%;
    display: block;
  }

  .mobile-view-desc {
    // margin: 0 15px;
  }
}

@media only screen and (max-width: 320px) {
  .mobile-view-desc {
    margin: 0 10px;
  }

  .gtd-mobile-view h1 {
    font-size: 22px;
  }
}

.low-res {
  display: none;
}

.wspace-card-container {
  .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
    display: none !important;
  }
}

.user-Name {
  font-size: 14px;
}

#dialog_title_wrapper {
  display: flex;
  justify-content: space-between;
  // padding: 16px 10px 0px 10px;
  // margin: auto;
  // width: 96%;
  // height: 52px;
  .header-text {
    font-size: 17px;
    color: #494949;
    margin: 0;
    padding: 0;
    float: left;
    font-family: roboto;
    overflow: hidden;
    h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50%;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 30%;
    }
  }
  button:hover {
    background: transparent !important;
    // opacity: 0.5;
  }
  #confirm_top_button {
    display: flex;
    right: 20px;
    bottom: 10px;
    border: 0;
    color: #cccccc;
    font-size: 15px;
    background: transparent;
    font-weight: 500;
  }
  .header-text {
    .client-logo {
      width: 20px !important;
      height: 20px !important;
      margin-right: 10px;
      border-radius: 4px;
      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}
.loader-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background: rgba(0, 0, 0, 0.6);
  z-index: 9;
}
.loader-content {
  font: normal 18px/30px $mainFontSemiBold;
  color: $whiteColor;
  text-align: center;
  white-space: nowrap;
}
.loder-icon-holder {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.ScrollbarsCustom {
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY
    .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background: transparent !important;
  }
  &:hover {
    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY
      .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
      background: rgba(153, 153, 153, 0.5) !important;
    }
  }
}
div {
  box-sizing: border-box;
}
.__react_component_tooltip.type-dark {
  &.general-tooltip {
    height: 22px;
    padding: 5px 8px 5px 8px;
    border-radius: 3px;
    font: normal 10px $mainFont;
    background: #717171;
    &::after {
      border: none;
    }
  }
}

.user-sqimg-icon {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  overflow: hidden;
  // background: #ccc;
  margin-right: 10px;
  .sb-avatar__text {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    border-radius: 4.3px;
    > div > span {
      padding-top: 4px;
      padding-left: 2px;
      > span {
        font-size: 16px;
        font-family: roboto;
        font-weight: 500;
        color: #ffffff;
        padding: 10px;
      }
    }
  }
}
.project-assign-sqrimg {
  width: 35px;
  height: 35px;
  border-radius: 4.3px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.Toastify__toast--default {
  background: $whiteColor;
  color: #494949;
}
.Toastify__close-button {
  visibility: hidden;
}
.Toastify {
  cursor: default;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
  // top: 98px;
  // right: 30px;
  width: auto;
}

.Toastify__toast-body {
  cursor: default;
  margin: 0 0px 0 12px;
  background: url("../img/svg/archive-successful.svg") no-repeat left center;
  text-align: center;
  padding: 18px 0 18px 42px;
  font-size: 14px;
}

.Toastify__toast {
  border-radius: 10px;
  margin-right: 0px;
  min-height: 66px;
  width: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: default;
}

.check-selected {
  left: 18px;
}

.display-none {
  display: none;
}

.gtd-profile {
  height: 30px;
  div {
    .user-profile.sb-avatar {
      width: 32px !important;
      height: 32px !important;
      border: solid 1px #ffffff !important;
      .user-profile {
        width: 30px !important;
        height: 30px !important;
        border: solid 1px #ffffff !important;
        div {
          font-size: 14px !important;
        }
      }
    }
  }
}

.marked-search {
  background-color: yellow;
  color: inherit !important;
  font-weight: inherit !important;
}
