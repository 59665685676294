@import "variable.scss";

.add-users-scroll {
  background: #fff !important;
  &.scroll-height {
    height: calc(100% - 110px) !important;
  }
}

.joinWorkspace-container {
  margin: 0 auto;
  height: 100%;
  background: #fff;
  &.verify-email {
    display: flex;
    flex-flow: column;
  }
  .find-your-workspace {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $panelHeading;
    .find-workspace {
      color: $blueBg;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 600;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .create-find-head {
    height: 60px;
  }
}
.logo-container {
  text-align: center;
  padding: 50px 0 50px 0;
}
.join-container {
  text-align: center;
  margin: 0 auto;
  width: 840px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
}

.container-find {
  text-align: center;
  margin: 0 auto;
  width: 840px;
  min-height: 340px;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  &.more-height {
    // height: 460px;
    height: auto;
  }
}

.from-nav-bar-container-find {
  min-height: 340px;
}

.join-header {
  margin: 35px 0;
  text-align: center;

  &.not-found {
    margin: 118px 0;
  }
  .join-title {
    height: 28px;
    font-family: $mainFont;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
    margin: 0;
  }
}
.join-sub-header {
  text-align: center;
  margin: 0 68px 50px 68px;
  .sub-title {
    height: 38px;
    font-family: $mainFont;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
    margin: 0;
  }
}
.join-line {
  height: 1px;
  border-bottom: 1px solid #aeaeae;
  width: 680px;
  text-align: center;
  margin: 0 auto;
  opacity: 0.25;
}
.choose-workspace {
  text-align: center;
  margin: 0 auto;
  height: 360px;
  overflow-y: auto;
  margin-right: 6px;
  margin-bottom: 20px;

  .mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 0;
  }
  &.pending {
    height: auto;
  }
  &.find-wsp {
    height: 233px;
  }
  &.wp-invites {
    height: auto;
    .join-no-results {
      margin-top: 100px;
    }
  }
  &.join-wsp {
    height: auto;
    overflow-y: initial;
  }
}

.searched-workspace {
  // height: 229px;
  height: auto;
}

.from-navbar-searched-workspace {
  height: 182px;
  &.more-height {
    // height: 229px;
    height: auto;
  }
}

.choose-workspace::-webkit-scrollbar {
  margin-top: 15px;
  width: 4px;
  background: transparent;
}

.choose-workspace::-webkit-scrollbar-thumb {
  width: 4px;
  background: #d8d8d8;
  border-radius: 2px;
  margin-top: 10px;
}
choose-workspace-holder {
  text-align: center;
  margin: 0 auto;
  height: 360px;
  .mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 0;
  }
  &.pending {
    height: auto;
  }
  height: 233px;
}
.choose-workspace-title {
  margin: 50px 0 25px 0;
  height: 24px;
  font-family: $mainFont;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #494949;
}
.join-workspace-list {
  width: 633px;
  text-align: center;
  margin: 0 auto;
}
.join-workspace-list-items {
  display: flex;
  flex-direction: row;
  width: 633px;
  justify-content: space-between;
  height: 115px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  border: 1px solid #f9f9f9;
  border-radius: 10px;
}
.join-left-side {
  margin: 38px 0 37px 36px;
  display: flex;
}
.join-user-img {
  width: 42px;
  height: 42px;
  margin-right: 24px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #f9f9f9;
    object-fit: cover;
  }
  .sb-avatar {
    width: 42px !important;
    height: 42px !important;
    .sb-avatar__text {
      width: 42px !important;
      height: 42px !important;
      border-radius: 10px;
      > div {
        font-family: roboto;
      }
    }
  }
}

.join-user-details {
  text-align: left;
  .user-name {
    margin-bottom: 3px;
    height: 21px;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #868686;
  }
  .user-domain {
    height: 14px;
    font-family: $mainFont;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #aeaeae;
    margin-bottom: 0;
  }
}
.join-right-side {
  margin: 34px 36px 37px 0;
  width: 185px;
  height: 43px;
  &.accept-invite {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      width: 132px;
    }
    .cross-button {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background: #d5d5d5;
      }
      margin-right: 0;
      img {
        width: 10px;
        height: 10px;
      }
    }
  }
  button {
    background-color: #0090e9;
    border: 1px solid #f9f9f9;
    border-radius: 10px;
    font-family: $mainFont;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 11px 30px;
  }
  .request-sent {
    background-color: #99c7e3;
    pointer-events: none;
  }
}
.join-container-footer {
  margin: 20px 0 20px 0;
  .not-expec {
    margin-bottom: 0;
    height: 19px;
    font-family: $mainFont;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #757575;
    .find-workspace {
      height: 19px;
      font-family: $mainFont;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0090e9;
      cursor: pointer;
    }
  }
  &.accept-all-invite {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      background-color: #0090e9;
      width: 170px;
      border: 1px solid #f9f9f9;
      border-radius: 10px;
      font-family: roboto;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding: 11px 30px;
    }
    .not-expec {
      margin-top: 15px;
    }
  }
}
.join-search-container {
  text-align: center;
  padding-right: 20px;
  margin: 0 auto;
  position: relative;
  margin: 70px 0 60px;
}
.search-holder {
  width: 425px;
  border: none;
  border-bottom: 2px solid #d6d6d6;
  padding: 0 0 10px 35px;
  font-family: $mainFont;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #494949;
  padding-right: 13px;
  &:focus {
    border-bottom: 2px solid #d6d6d6;
  }
  &::-webkit-input-placeholder {
    opacity: 0.75;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeaeae;
  }
}
.search-icon-join {
  position: absolute;
}
.cancel-icon-join {
  margin-left: -12px;
  cursor: pointer;
}
.join-no-results {
  margin-top: 0px;
  margin-bottom: 60px;
  &.find {
    margin: 0 0 50px 0;
  }
  .join-no-title {
    margin: 0;
    font-family: $mainFont;
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
  }
}

/* create workspace */

.workspace-main {
  max-width: 840px;
  width: 840px;
  margin: 0 auto;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  padding: 20px;
  height: 386px;
}
.create-title {
  text-align: center;
  margin: 0 0 100px 0;
  font-size: 24px;
  color: #494949;
  font-weight: 400;
}
.workspace-profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-flex-flow: row wrap;
  .upload-image {
    border-radius: 10px;
  }
}
.workspace-upload-image {
  flex: 0.1;
  flex-direction: column;
  //margin-right: 25px;
}
.workspace-name {
  flex: 0.4;
  padding-top: 38px;
  margin-left: 20px;
}
.upload-image {
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 10px;
}
.upload-image p {
  color: #999999;
  font-size: 14px;
  margin: 0;
  text-align: center;
  padding: 41px 0;
}
.upload-image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 50px;
  cursor: pointer;
}
.workspace-name input[type="text"] {
  width: 300px;
  border: 0;
  border-bottom: 1px solid rgba(174, 174, 174, 0.25);
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 5px;
  color: #494949;
  font-family: $mainFont;
  &::-webkit-input-placeholder {
    opacity: 0.75;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeaeae;
  }
}
.proceed-btn {
  text-align: center;
  margin-top: 50px;
}
.proceed-btn button {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  width: 150px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #dadada;
  border: none;
  font-family: $mainFont;
  pointer-events: none;
}
.active-proceed {
  background-color: #0090e9 !important;
  color: #ffffff;
  pointer-events: auto !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  &.no-shadow {
    box-shadow: none;
    height: 43px;
  }
}
.proceed-btn p {
  color: #757575;
  font-size: 16px;
  margin: 70px 0 0 0;
}
.proceed-btn p a {
  color: #0090e9;
  text-decoration: none;
  font-weight: 600;
}

/* adduser */

.addusers-workspace {
  text-align: left;
  padding: 20px 90px 40px 90px;
}
.adduser-label {
  font-family: $mainFont;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
}
.adduser-access {
  font-family: $mainFont;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
  display: block;
}
.adduser-superadmins {
  margin-bottom: 20px;
}
.adduser-admins {
  margin-bottom: 20px;
}
.adduser-members {
  margin-bottom: 20px;
}
.proceed-btn {
  text-align: center;
  margin-top: 50px;
}
.active-proceed {
  background-color: #0090e9 !important;
  color: #ffffff;
  pointer-events: auto !important;
}
.proceed-btn p {
  color: #757575;
  font-size: 16px;
  margin: 70px 0 0 0;
}
.addUser-btn {
  text-align: center;
  margin-bottom: 20px;
}
.addUser-btn button {
  font-weight: 500;
  font-size: 18px;
  width: 190px;
  height: 43px;
  color: #fff;
  cursor: pointer;
  object-fit: contain;
  border-radius: 10px;
  background-color: #dadada;
  border: none;
  font-family: $mainFont;
  pointer-events: none;
}
.user-workspace {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  max-width: 133px;
  min-width: 133px;
  width: auto;
  margin-right: 40px;
}
.user-add-action {
  font-size: 16px;
  font-family: $mainFont;
  color: #0090e9;
  font-weight: 500;
  position: relative;
  z-index: 99999;
  &:hover {
    text-decoration: underline;
  }
}
.pending-container {
  margin-bottom: 20px;
}
.join-title-pending {
  height: 28px;
  font-family: roboto;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #494949;
  margin: 0 0 20px 0;
}
.user-access-subtitle {
  font: normal 11px/22px $mainFont;
  color: $controlLabelColor;
}

.find-container {
  background-size: cover !important;
  height: 100%;
  width: 100%;
}
.body-container-find {
  background-color: #fff;
  height: 100%;
}
.loader-image-div {
  position: relative;
  height: 100%;
  top: -100px;
  left: 0;
  div {
    .loader-holder {
      .loader-content {
        display: none;
      }
    }
  }
}

// New css for Bugs in design

.user-img-delete {
  width: 13px;
  height: 13px;
  position: absolute;
  right: 0px;
  top: -4px;
  cursor: pointer;
  display: none;
}

.addUser-btn.for-adding-project {
  text-align: center;
  margin-bottom: 45px;
}

.user-img-icon img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.edit-board-search-control {
  color: #494949;
  font-size: 15px;
  &.proj-search-box {
    width: 80%;
  }
}

.user-details-holder.user-workspace {
  .user-name.add-user-ws {
    color: #808080;
  }
  .user-email.add-user-ws {
    color: #aeaeae;
  }
  &.invite-email {
    .user-email.add-user-ws {
      color: #808080;
    }
  }
}

.user-email.add-user-ws {
  font-size: 15px;
  color: #808080;
  height: 100%;
}
.user-details-holder.user-workspace.invite-email {
  height: 100%;
  padding: 15px 0 0 0;
}

.workspace-upload-image {
  .upload-image img {
    border-radius: 10px;
  }
}
.finish-profile-upload {
  .upload-image img {
    border-radius: 50%;
  }
}

.payment-subscription-alert {
  font: normal 14px/16px $mainFont;
  color: $topNaviColor;
  margin: 20px auto;
  text-align: center;
}
@media screen and (max-height: 650px) {
  .container-find {
    min-height: 340px;
    &.more-height {
      // height: 420px;
      height: auto;
    }
  }
  .from-nav-bar-container-find {
    height: 340px;
    &.more-height {
      // height: 400px;
      height: auto;
    }
  }
  .join-header {
    margin-top: 10px;
  }
  .searched-workspace {
    height: 100px;
    &.more-height {
      // height: 215px;
      height: auto;
    }
  }
  .from-navbar-searched-workspace {
    height: 100px;
    &.more-height {
      // height: 195px;
      height: auto;
    }
  }
  .join-workspace-list-items {
    margin-bottom: 10px;
  }
}
