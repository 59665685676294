@import "variable.scss";

.arch-center-tt-container {
  width: calc(100% - 30px);
  margin: 40px 0 0 30px;
  position: relative;
  height: calc(100vh - 130px);
  overflow-y: auto;
  &.with-no-scroll {
    overflow: hidden;
    .arch-categ-outer-container {
      width: 99%;
      height: inherit;
      .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
        margin-right: 0;
      }
    }
  }
  &::-webkit-scrollbar {
    margin-top: 10px;
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(153, 153, 153, 0.5);
    border-radius: 3px;
    margin-right: 3px;
  }
}
.arch-categ-outer-container {
  width: 100%;
}
.project-dasboard-title {
  font: normal 17px $mainFont;
  color: $wlUserColor;
}
.arch-categ-container {
  max-width: calc(100% - 10px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 30px 0;
  .project-dash-card {
    &.archive-center {
      &:nth-child(3n + 3) {
        margin-right: 0 !important;
      }
    }
  }
  .checklist-content-container .project-percentage md-progress-linear {
    margin: 8px 10px 0 0;
  }
  .archive-edit-wrap {
    width: 30px;
    height: 20px;
  }
}
.arch-categ-holder {
  display: flex;
  // justify-content: flex-start;
  flex-flow: wrap;
  align-items: center;
  .project-dash-card {
    //
  }
  &.all-archived {
    flex-wrap: wrap;
    width: 100%;
    .mCustomScrollBox {
      width: 100%;
      .mCSB_container_wrapper,
      .mCSB_container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
      }
    }
    height: calc(100vh - 130px);
    .project-dash-card,
    .comm-act-card,
    .checklist-card-main-container {
      margin-bottom: 20px;
    }
  }
}
.arch-categ-heading {
  font: normal 14px $mainFont;
  color: $whiteColor;
  margin: 0 0 10px 0;
}
.arch-view-more-holder {
  font: bold 14px $mainFont;
  background: url(../img/svg/archive-view-more.svg) no-repeat right center;
  padding: 0 30px 0 0;
  margin: 0 0 0 34px;
  opacity: 0.75;
  color: $whiteColor;
  &.checklist-viewall {
    position: relative;
    top: -10px;
  }
  &.projects-viewall {
    position: relative;
    top: -13px;
  }
  &:hover {
    opacity: 1;
  }
}
.comm-act-card {
  width: 300px;
  background-color: #fff;
  margin: 10px 20px 10px 0;
  padding: 12px 0 1px;
  float: left;
  border-radius: 8px;
  min-height: 175px;
  max-height: 180px;
  // &.archive-center {
  //     // &:nth-child(3n+3) {
  //     //     margin-right: 0 !important
  //     // }
  // }
  &.show-more {
    min-height: 175px;
  }
}
.comm-act-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  &.checklist {
    padding: 10px 0 0 0;
    .options-activity {
      justify-content: flex-end;
      width: 100%;
      &.comm-opts {
        width: auto;
      }
    }
  }
  &.project {
    padding: 0;
    margin: 0;
    .options-activity {
      justify-content: flex-end;
      width: 100%;
    }
  }
}
.comm-header-wrap {
  display: flex;
}
.comm-client-logo {
  display: flex;
  flex-direction: row;
  margin-left: 12px;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  img {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  .sb-avatar__text {
    width: 30px !important;
    height: 30px !important;
    border-radius: 4px;
    div {
      font-size: 14px !important;
      font-family: roboto;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.comm-wrap-star-options {
  display: flex;
  align-items: center;
}
.comm-option-icon {
  position: relative;
  margin-left: 10px;
}
.comm-dot-holder {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: rgba(216, 216, 216, 0.2);
  }
}
.comm-options-block {
  width: 153px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: $whiteColor;
  position: absolute;
  padding: 10px 0px;
  z-index: 999;
}
.comm-option-list {
  font: normal 14px $mainFont;
  color: $successMsg;
  height: 30px;
  padding: 5px 10px;
  &.comm-per-del {
    color: #ef5350;
  }
  &:hover {
    background: rgba(153, 153, 153, 0.15);
  }
}
.comm-act-body {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 18px;
  width: 90%;
}
.comm-act-title {
  font-size: 17px;
  margin-left: 12px;
  font: normal 17px $mainFont;
  color: $successMsg;
  width: 249px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.comm-proj-title {
  font: normal 14px $mainFont;
  margin-left: 12px;
  color: $topNaviColor;
  height: 19px;
  width: 249px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.comm-date-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 31px;
  justify-content: space-between;
}
.comm-duration-wrap {
  display: flex;
  margin-top: 3px;
}
.comm-act-due-on {
  display: flex;
  flex-grow: 1;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 400;
  font-family: roboto;
  margin-right: 10px;
  color: #999999;
  &.comm-act-due-on-delayed {
    color: #ef5350;
  }
}
.comm-planned-upcoming {
  font-size: 12px;
}
.comm-yes-delayed {
  color: #ef5350;
}
.comm-not-delayed {
  color: #999999;
}
.checklist-card-main-container {
  width: 300px;
  height: 115px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.25);
  margin: 0 24px 20px 0;
  &.archive-center {
    // &:nth-child(3n + 3) {
    //   margin-right: 0 !important;
    // }
  }
}
.checklist-top-container {
  width: 300px;
  height: 94px;
  border-radius: 10px;
  background: $whiteColor;
}
.checklist-bottom-container {
  width: 300px;
  padding: 0 20px 0 12px;
  box-sizing: border-box;
  height: 21px;
  font: normal 10px/21px $mainFont;
  color: $whiteColor;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .left-col {
    color: #d8d8d8;
  }
}
.checklist-content-container {
  width: 100%;
  padding: 0 37px 0 30px;
  box-sizing: border-box;
  &.comm-content-container {
    padding: 0 15px 0 15px;
  }
  .project-percentage {
    md-progress-linear {
      margin: 8px 5px 0 0;
    }
  }
}
.checklist-card-title {
  font: normal 17px $mainFont;
  color: $wlUserColor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.comm-card-title {
    font: normal 15px $mainFont;
    .truncate-txt {
      height: 44px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: inherit;
      -webkit-line-clamp: 2;
      max-width: 100%;
    }
  }
  .count {
    color: $attachmentColor;
    font: normal 12px $mainFont;
    margin-left: 5px;
  }
  .truncate-txt {
    max-width: 200px;
    width: auto;
  }
}
.project-cards-dash {
  &.archived-projects {
    padding: 10px 10px 0px 22px;
    .project-dashboard-card {
      padding-top: 5px;
      .img-container {
        padding: 0;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .project-desc-right {
      width: calc(100% - 66px);
    }
  }
}
.archived-card {
  cursor: auto !important;
}
.header-left {
  &.all-archived {
    margin-right: 45px;
    img {
      margin: 0 15px 0 0;
    }
  }
}
.header-workload-wrap {
  &.archive-project {
    padding: 40px 24px 23px 30px;
  }
}
.comm-filter-control-tt-container {
  &.archive-project {
    margin-bottom: 22px;
    justify-content: flex-start;
    display: flex;
  }
  .comm-filter-btn.apply {
    width: 100px;
    height: 30px;
    border-radius: 10px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #fff;
    font: 500 15px roboto;
    text-align: center;
    color: #fff;
    &:hover {
      width: 100px;
      height: 30px;
      border-radius: 10px;
      background: transparent !important;
      box-shadow: none;
      border: 1px solid #fff;
      font: 500 15px roboto;
      text-align: center;
      color: #fff;
    }
  }
}
.comm-filter-control-container {
  &.archive-project {
    width: 214px;
    justify-content: flex-start;
    margin-right: 30px;
    display: flex;
    padding: 2px 0;
    .comm-filter-control-holder {
      width: calc(100% - 55px) !important;
      color: #fff;
      font: normal 13px roboto;
      padding: 0 0 0 8px;
      border: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      height: 25px;
      border-bottom: 0 !important;
      .workflow-change-ddown-text {
        color: #fff;
        line-height: 23px;
      }
      .workflow-change-ddown {
        min-width: 136px;
        min-height: 48px;
        max-height: 256px;
        overflow-y: auto;
        padding: 0;
        .ScrollbarsCustom-Wrapper {
          bottom: 0px !important;
          .project-options {
            height: 29px;
            padding: 0 15px;
            font: normal 15px/29px roboto;
            color: #757575;
          }
        }
      }
    }
    .comm-filter-control-title {
      width: 65px;
      line-height: 25px;

      color: #fff;
      margin: 0 5px 0 0;
    }
  }
  &.archive-activity {
    width: 220px !important;
    justify-content: flex-start;
    margin-right: 30px;
    .comm-filter-control-holder {
      width: calc(100% - 60px) !important;
    }
  }
}
.not-found-message {
  font: 600 18px $mainFont;
  color: $whiteColor;
  position: absolute;
  left: 46%;
  top: 46%;
  transform: translate(-50%, -50%);
}
.not-delayed {
  color: $graphDdownHover;
  display: flex;
  align-items: center;
  > span {
    margin-left: 5px;
  }
}

.show-more.content {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-left {
  font-family: roboto;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.board-wrap {
  .heading h1 {
    font-family: roboto;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  a h1 {
    font-family: roboto;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}

@media screen and (max-width: 1280px) {
  .arch-view-more-holder {
    &.nav-expanded {
      margin: 0 0 0 15px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .comm-act-card,
  .project-dash-card,
  .checklist-card-main-container {
    margin-right: 15px !important;
  }

  .all-archived {
    .project-dash-card,
    .comm-act-card,
    .checklist-card-main-container {
      margin-bottom: 15px !important;
    }
  }
}
.checklist-content-container {
  .progress_bar {
    display: flex;
    align-items: baseline;
    .checklist-progress-bar {
      margin: 0;
      width: 90%;
      display: inline-block;
    }
    .completed-percentage-text {
      display: inline;
      position: relative;
      top: 3px;
      margin-left: 7px;
      color: #555;
      font: normal 12px/14px roboto;
    }
  }
}

.dnd-lists-checklists {
  .additem-container {
    .add-item-input {
      margin: 0;
      padding: 0;
      img.check-round {
        margin: 5px 0 0 0;
      }
    }
  }
}
#arch-categ-outer-container-scroll {
  overflow: scroll;
  height: calc(100% - 35px);
  &::-webkit-scrollbar {
    margin-top: 10px;
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(153, 153, 153, 0.5);
    border-radius: 3px;
  }
}
.arch-categ-container {
  width: 100%;
}

div.infinite-scroll-component__outerdiv {
  &::-webkit-scrollbar-thumb {
    background: red !important;
  }
}

.no-checklist-found {
  font: 600 18px roboto;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.workflow-change-ddown-holder {
  width: 140px;
  height: 26px;
  position: relative;
  background: #f7f7f7;
  border-radius: 5px;
  padding: 0 0 0 8px;
  box-sizing: border-box;
  cursor: pointer;
  .workflow-change-ddown-text {
    opacity: 1;
  }
  &:after {
    content: "";
    opacity: 1;
    position: absolute;
    right: 8px;
    top: 50%;
    width: 10px;
    height: 6px;
    transform: translateY(-50%);
    background: url(../img/svg/filter-down-arrow.svg) no-repeat center center;
  }
  &.proj-filter-ddown:after {
    background: url(../img/svg/filter-down-arrow-arc.svg) no-repeat center
      center;
  }
  &.clicked {
    &:after {
      transform: rotate(180deg) translateY(3px);
    }
  }
  &.disabled {
    .workflow-change-ddown-text {
      opacity: 0.5;
    }
    &:after {
      opacity: 0.5;
    }
  }
}
