@import "variable.scss";

.workspace-settings {
  width: 100%;

  .popup-body-area {
    height: calc(100vh - 260px);
    overflow-y: scroll;
  }

  .organization-container-loader {
    position: absolute;
    height: 38px;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
}

.workspace-settings-edit-user-profile-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 50px;
}

// .workspace-settings-edit-all-user-container {
//   width: 100%;
//   background: #f9f9f9;
//   height: 100%;
// }

.workspace-settings-edit-user-img {
  cursor: pointer;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .add-logo {
    cursor: pointer;
    width: inherit;
    height: inherit;
    font-family: Roboto;
    font-size: 11px;
    color: #757575;
    background-color: #f0f0f0;
    text-align: center;
    padding-top: 40%;
    border-radius: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .update-logo {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 80px;
    height: 80px;
    background: rgba(42, 152, 240, 0.9);
    font: normal 12px/16px $mainFont;
    color: #ffffff;
    text-align: center;
    padding: 25px 13px;
    box-sizing: border-box;
    border-radius: 10px;
  }

  &:hover .update-logo {
    transition: all 0.3s linear;
    display: block;
    cursor: pointer;
  }
}

.workspace-settings-edit-user-name {
  width: 300px;
  margin: 0 0 0 0px;
}

.workspace-settings-edit-user-name input {
  width: 100%;
  height: 29px;
  border: 0;
  border-bottom: 2px solid #d6d6d6;
  background: none;
  font: 500 22px $mainFont;
  letter-spacing: normal;
  color: #626262;
  padding-bottom: 4px;
  &:focus {
    border-bottom: 2px solid #d6d6d6;
  }
}

.workspace-settings-user-type-section {
  width: 100%;
  border-bottom: 2px solid #d6d6d6;
}

.workspace-settings-user-type-section-user {
  width: 100%;
  border-bottom: 2px solid #d6d6d6;
  padding: 35px 0;
}

.settings-weekly-working {
  display: flex;
  align-items: center;

  label {
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
  }

  input {
    width: 35px;
    height: 38px;
    border-radius: 10px;
    border: solid 0.5px #8ac8ef;
    background-color: #ebf7ff;
    text-align: center;
    margin-left: 62px;
    margin-right: 10px;
    font-family: $mainFont;
  }

  span {
    width: 22px;
    height: 20px;
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #494949;
  }
}

.settings-weekly-hours {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 20px;

  label {
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
  }

  input {
    width: 35px;
    height: 38px;
    border-radius: 10px;
    border: solid 0.5px #8ac8ef;
    background-color: #ebf7ff;
    text-align: center;
    margin-left: 77px;
    margin-right: 10px;
  }

  span {
    width: 22px;
    height: 20px;
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #494949;
  }
}

.days-hrs {
  padding-left: 50px;
}

.owner-wrap {
  display: flex;
  padding-left: 50px;
  align-items: center;

  .user-position {
    margin-right: 65px;
    width: 120px;
    font: normal 15px $mainFont;
    color: #757575;

    p {
      font-size: 13px;
      color: #ababab;
    }
  }

  .user-row-ddown {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
  }

  .user-img-holder {
    position: relative;
  }
}

.divider-height {
  height: 10px;
}

.add-team {
  display: flex;
  align-items: center;
  padding-left: 50px;

  label {
    width: 100px;
    margin-right: 71px;
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;
  }
}

.add-first {
  margin-bottom: 27px;
}

.add-team-job {
  padding: 25px 0;
  border-bottom: 1px solid #d6d6d6;
}

.add-team-input:focus,
.add-jobs-input:focus {
  border: none;
}

.validaton-fail {
  position: absolute;
  left: 25px;
  bottom: -5px;
  color: $danger_gtd;
  font-family: roboto;
  font-size: 12px;
}

.changes-saved {
  position: absolute;
  left: 25px;
  bottom: -5px;
  color: $success_gtd;
  font-family: roboto;
  font-size: 12px;
}

.done-button {
  font: 500 18px $mainFont;
  color: #fff;
  margin: 25px 0 26px 50px;
  cursor: pointer;
  float: left;
  background-color: #0090e9;
  width: 120px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  border: none;
}

button[disabled].settings-disabled {
  cursor: auto;
  color: $controlLabelColor !important;
  background-color: #dadada !important;
}

.add-team {
  .add-team-input,
  .add-jobs-input {
    border: none;
    border: 0;
    height: 32px;
    line-height: 32px;
    padding: 0;
  }

  .md-chips {
    border-bottom: 1px solid #d6d6d6;
    padding: 0 0 5px 0 !important;
    width: 600px;
    height: 44px;
    display: flex;

    .md-chip {
      margin-bottom: 0;
      background-color: #f4f4f4;
      color: #494949;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      height: 38px;
      padding-top: 8px;
      align-items: end;
      border-radius: 10px;
      margin-right: 15px;

      .md-chip-content span {
        font: normal 15px $mainFont !important;
        color: #494949;
      }
    }

    .md-chip-input-container input {
      color: #000 !important;
      font-family: $mainFont;
      font-size: 15px;
    }
  }

  /*   .md-chips {
      padding: 0 !important;
      border-bottom: 0;
      md-icon{
       opacity: .50;
      }
  } */
}

.remove-access {
  width: 145px;
  height: 76px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(214, 214, 214, 0.5);
  background-color: #ffffff;
  padding: 15px;
  position: absolute;
  z-index: 9;

  .owner-access {
    width: 97px;
    height: 20px;
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;

    &:hover {
      color: #018fe9;
    }
  }

  .remove-member {
    width: 115px;
    height: 20px;
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #757575;

    &:hover {
      color: #018fe9;
    }
  }
}

.ppl-settings-control-outer-holder {
  margin-top: 0 !important;
  height: 80% !important;
  padding-bottom: 0 !important;
  .ppl-settings-control-holder:first-child {
    margin-bottom: 0;
    padding: 50px 0;
  }
}
.workspace-setting-holder-container {
  border: 0;
  height: calc(100vh - 194px) !important;
}

.button-div.workspace-settings {
  // margin-bottom: 25px;
}

.restrict-txt, .days-txt {
  font-size: 12px;
  color: #808080;
}

.restricted-days-input-box {
  border: 1px solid #dadada;
  margin-left: 10px;
  border-radius: 4px;
  width: 46px;
  height: 28px;
  &:focus-within {
    border-color: #0090E9;
  }
  input {
    border: none;
    border-bottom: none;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 4px;
  }
}
