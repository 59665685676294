@import "variable.scss";
.settings-tab-container {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .settings-tab {
    width: 100%;
    height: 81px;
    border-bottom: 1px solid $brdrColor;
    .inner-tabs {
      width: 100%;
      height: 81px;
    }
  }
}

.settings-tab-holder {
  padding: 0 0 0 30px;
  margin: 0;
  li {
    float: left;
    list-style: none;
    font: normal 18px/90px $mainFont;
    color: #757575;
    width: 150px;
    height: 83px;
    margin-right: 10px;
    text-align: center;
    position: relative;
    &::after {
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0px;
      background: transparent;
      border-radius: 2px;
    }
    &.active {
      color: $settingsBlue;
      &::after {
        background: $settingsBlue;
      }
    }
  }
}

button[disabled].done-link-button {
  cursor: auto;
  color: $controlLabelColor;
}
