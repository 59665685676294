@import "variable.scss";

.cus-autocomp-ddown-list {
  width: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  &.add-act-proj-ddown {
    height: 51px;
    justify-content: flex-start;
    .img-container {
      width: 35px;
      height: 35px;
      border-radius: 5px;
      margin-right: 15px;
      img {
        border-radius: 5px;
        object-fit: cover;
        width: 35px;
        height: 35px;
      }
      .sb-avatar__text {
        width: 35px !important;
        height: 35px !important;
        border-radius: 5px;
      }
    }
  }
}

.checkbox-activity {
  display: flex;
  .checkBoxInput-parentSpan {
    padding: 0;

    &.checked {
      svg {
        color: #0090e9;
      }
    }
  }
  .labelStyles {
    margin-left: 5px;
    width: 226px;
    height: 18px;
    font-family: roboto;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #494949;
    cursor: pointer;
    &.edit {
      cursor: auto;
    }
  }
}
.md-select-icon {
  display: block;
  width: 10px;
  margin: 4px 8px 0 0;
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    top: auto;
    transform: none;
    background: url(../img/svg/md-select-arrow.svg) no-repeat center center;
  }
}

.act-overview {
  color: #808080;
  margin-top: 6px;
  margin-left: 5px;
  background: url(../img/svg/act-overview.svg) no-repeat 105px center;
  background-size: 10px;
  cursor: pointer;
  width: 120px;
}

.proj-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 6px;
  top: 12px;
  cursor: pointer;
  z-index: 9;
  &.rotate {
    transform: rotate(180deg);
    right: 17px;
    top: 14px;
  }
}

.add-act-project-search {
  width: 100%;
  background-color: #fff;
  height: 40px;
  border: none;
  margin-top: 10px;
  background-image: url(../img/svg/settings-search-icon1.svg);
  background-repeat: no-repeat;
  padding-left: 43px;
  background-position: 3%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #808080;
  font-size: 15px;
  box-sizing: border-box;

  &::-webkit-input-placeholder {
    color: #aeaeae;
  }
}

.duration-time-holder {
  display: flex;
  align-items: center;
  margin-top: 16px;
  &.comp-act {
    justify-content: space-between;
  }
}

.act-duration-user-holder {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  .sb-avatar__text {
    width: 30px !important;
    height: 30px !important;
    > div {
      font-size: 14px !important;
    }
  }
}

.add-act-date-range-holder {
  cursor: pointer;
}

.add-act-picker {
  width: auto !important;
  height: auto !important;
  .ranges {
    margin-top: 8px;
    margin-bottom: 8px;
    li.active {
      background-color: #ffffff;
      color: inherit;
      background: url(../img/svg/tick-black.svg) no-repeat right center;
      background-position-x: 120px;
      &:hover {
        background-color: #eee;
      }
    }
  }
  .drp-calendar.left {
    border-left: 0 !important;
  }
  .drp-buttons {
    padding: 15px 15px;
    padding-top: 0;
  }
}

.act-time-holder {
  margin-left: 40px;
  margin-top: 15px;
  &.one-user {
    margin-left: 70px;
  }
  .rd-tt-time-area {
    top: 0;
    .time-planned {
      margin-right: 15px;
      &.for-completed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        .per-user-day {
          font-size: 11px;
        }
      }
    }
  }
}

.act-time-inner-holder {
  display: flex;
  border: 1px solid #dadada;
  border-radius: 5px;
  height: 45px;
  width: 148px;
  justify-content: space-between;
  align-items: center;
  &:focus-within {
    border: 1px solid #0090e9;
  }
  .rd-hours-area {
    margin: 0;
    input {
      border-bottom: none !important;
    }
  }
}

.total-indiv-time-holder {
  margin-left: 40.6%;
  font-size: 11px;
  color: #808080;
}

.act-total-date-time-holder {
  color: #808080;
  .total-duration {
    margin-bottom: 10px;
  }
}

.add-act-btn-holder {
  .circular-progress {
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}

.act-more-op-holder {
  margin-left: 10px;
  .apc-dot-holder {
    margin: 0;
    .apc-options-block {
      left: 0;
    }
  }
}

.add-more-comp {
  cursor: pointer;
  font-size: 11px;
  margin-top: -10px;
  width: fit-content;
}

.activity-type-down {
  width: 100% !important;
  &.edit-report-act-types {
    top: auto !important;
  }
  .activityTagsInput {
    max-height: 234px !important;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: lighten($topNaviColor, 20%);
      border-radius: 2px;
      cursor: pointer;
    }
  }
  .activity-tags-ui {
    padding: 0;
    margin-bottom: 0;
    li {
      overflow: hidden;
      height: 32px;
      color: #757575;
      font: normal 15px/29px roboto;
      padding: 0 0 0 15px;
      cursor: pointer;
      transition: background 0.15s linear;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        background: rgba(153, 153, 153, 0.2);
      }
    }
  }
}

.act-att-holder {
  position: fixed; // new activity CSS
  .attach-files-dropdown-container {
    top: auto;
  }
}

.description-box {
  &.date-input {
    input {
      cursor: pointer;
      caret-color: transparent;
      height: inherit;
      padding:0px 36px 0px 14px;
      // color: transparent;
      // text-shadow: 0 0 0 black;
      // &::placeholder {
      //   color: currentColor;
      // }
    }
  }
  fieldset {
    // border-radius: 4px 4px 0 0;
    border-width: 1px !important;
    // border-bottom: none;
  }
  &.with-attachment {
    fieldset {
      border-bottom: none;
      border-radius: 4px 4px 0 0;
    }
  }
}

.comp-act-att {
  height: 16px;
  width: 16px;
  white-space: nowrap;
  font-size: 11px;
  position: relative;
  cursor: pointer;
}

.edit-report-picker-single {
  width: auto !important;
  height: auto !important;
}

.textfield-focused {
  > label {
    color: #0090e9 !important;
  }
  fieldset {
    border-color: #0090e9 !important;
  }
}

.act-title-ddown-holder {
  > div {
    position: relative;
  }
  .edit-board-ddown {
    top: auto;
  }
}
