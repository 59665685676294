@import "variable.scss";
.profile-billing {
  height: 100%;
}

.user-profile-container {
  background: #818080 !important;
  background-image: none !important;
  background-size: cover !important;
  padding: 0;
}
.gtd_client_container {
  .profile-main-title {
    clear: both;
    overflow: hidden;
    padding: 5px 24px 20px 24px;
    h1 {
      margin: 0;
      font-size: 24px;
      color: #ffffff;
      float: left;
      padding: 0 45px 0 0;
    }
    .profile-billing-active {
      float: left;
      padding: 0 0px 0 0px;
      color: #ffffff;
    }
    .tab_activity {
      float: left;
      font-size: 15px;
      padding: 5px 0px 4px 0;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.profile-billing-wrapper {
  position: relative;
  height: calc(100% - 155px);
  background: #ffffff;
  &.profile-wrapper {
    height: calc(100% - 108px);
    // width: 99.4%;
    // overflow-y: auto;
    // &::-webkit-scrollbar {
    //   width: 4px;
    //   background: transparent;
    // }
    // &::-webkit-scrollbar-thumb {
    //   width: 4px;
    //   background: lighten($topNaviColor, 20%);
    //   border-radius: 2px;
    // }
  }
  .profile-form {
    max-width: 620px;
    margin: 0 auto;
    padding-top: 34px;
    form {
      padding: 20px 0 30px 0;
    }
    .profile-pic {
      border-bottom: 1px solid rgba(117, 117, 117, 0.25);
      clear: both;
      overflow: hidden;
      height: 104px;
      padding: 0 47px;
      .img-wrap {
        position: relative;
        .img {
          float: left;
          width: 80px;
          height: 80px;
          margin-right: 25px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            cursor: pointer;
            object-fit: cover;
          }
          .sb-avatar.sb-avatar--text {
            .sb-avatar__text {
              width: 80px !important;
              height: 80px !important;
              div {
                font-size: 30px !important;
              }
            }
          }
        }
        .update_img {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          border-radius: 50%;
          width: 80px;
          height: 80px;

          background: rgba(42, 152, 240, 0.9);
          font: normal 12px/16px $mainFont;
          color: #ffffff;
          text-align: center;
          padding: 25px 13px;
          border-radius: 10px;
        }
        &:hover .update_img {
          transition: all 0.3s linear;
          display: block;
          cursor: pointer;
          border-radius: 50%;
        }
      }
      .title-desc {
        float: left;
        width: calc(100% - 280px);
        padding: 20px 0 0 0;

        h1 {
          color: #494949;
          font-size: 17px;
          font-family: $mainFont;
          padding: 0;
          margin: 0;
          line-height: 20px;
          margin-bottom: 5px;
        }
        p {
          color: #757575;
          font-size: 13px;
          margin: 0;
          line-height: 15px;
        }
      }
      .delete-account {
        float: right;
        clear: both;
        color: #adadad;
        font-size: 13px;
        padding-bottom: 5px;
        //display: none;
      }
    }
    .profile-details {
      max-width: 524px;
      margin: 0 auto;
      padding: 32px 0 0 0;
      label {
        color: #757575;
        font-size: 16px;
        font-weight: 400;
        margin-right: 35px;
        width: 134px;
        float: left;
        text-align: left;
        margin-bottom: 0;
        font-family: roboto;
      }
      input {
        border: 0;
        // border-bottom: 1px solid rgba(174, 174, 174, 0.25);
        padding-bottom: 5px;
        width: calc(100% - 175px);
        font-size: 16px;
        color: #494949;
        float: left;
        background: transparent;
        font-family: roboto;
      }
      .name_input {
        border-bottom: 1px solid rgba(174, 174, 174, 0.25);
      }
      .cmn-label {
        clear: both;
        overflow: hidden;
        padding-bottom: 20px;
        //display: flex;
      }
      .email {
        input {
          color: #cccccc;
          &::-webkit-input-placeholder {
            color: #cccccc;
          }
        }
      }
      .password {
        position: relative;
        button {
          color: #0090e9;
          font-size: 13px;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 7px;
          border: 0;
          background: transparent;
          right: 0;
          &:hover {
            background: transparent !important;
          }
        }
        input {
          color: #dfdfdf;
        }
      }
      .background_button {
        // position: relative;
        display: flex;
        > span {
          max-width: 290px;
          color: #dfdfdf;
          font-family: roboto;
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        > div {
          margin-left: auto;
        }
        button {
          color: #0090e9;
          font-size: 13px;
          margin: 0;
          margin-left: auto;
          padding: 0;
          // position: absolute;
          top: 3px;
          border: 0;
          background: transparent;
        }
        .randonmize_button {
          right: 0px;
          &:hover {
            background: transparent !important;
          }
        }
        .change_button {
          right: 80px;
          &:hover {
            background: transparent !important;
          }
        }
      }
      .dynamic-bg {
        position: relative;
        h2 {
          position: absolute;
          top: 7px;
          right: 0;
          color: #0090e9;
          font-size: 13px;
          margin: 0;
          padding: 0;
        }
      }
      .profile-save-btn {
        max-width: 160px;
        margin: 0 auto;
        background: #e3e3e3;
        border-radius: 10px;
        margin-top: 25px;
        button {
          font-size: 16px;
          color: #ffffff;
          background: #e3e3e3;
          padding: 9px 23px;
          border: 0;
          border-radius: 10px;
          width: 160px;

          &.active {
            background-color: #0090e9;
          }
        }
      }
      .save-changes {
        text-align: center;
        color: green;
        font-size: 12px;
        margin: 0px;
        margin-left: 18%;
      }
      p {
        margin: 0 0 0 168px;
        color: #ef5350;
        font-size: 10px;
        display: flex;
        position: absolute;
        margin-top: 30px;
      }
    }
  }
}

.dialog_wrapper {
  border-radius: 10px;
  #dialog_title_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px 10px 0px 10px;
    margin: auto;
    width: 96%;
    height: 52px;
    .header-text {
      font-size: 17px;
      color: #494949;
      margin: 0;
      padding: 0;
      float: left;
      font-family: roboto;
    }
    button:hover {
      background: transparent !important;
      // opacity: 0.5;
    }
    img {
      padding-top: 2px;
    }
    #confirm_top_button {
      display: flex;
      right: 20px;
      bottom: 12px;
      font-family: Roboto, Helvetica Neue, sans-serif;
      border: 0;
      color: #cccccc;
      font-size: 15px;
      background: transparent;
      font-weight: 500;
      &.password-done {
        color: #0090e9 !important;
      }
    }
  }
}

.pop-up-password {
  width: 340px !important;
  height: 280px;
  &.cannot-start-timer {
    width: 480px !important;
    height: auto;
    span {
      font-size: 13px;
    }
  }
  &.google {
    width: 430px !important;
    // height: 146px;
    height: auto;
    span {
      font-size: 13px;
    }
  }
  &.invite-expire {
    width: 536px !important;
    height: 170px;
    span {
      font-size: 14px;
    }
  }
  &.email-already-registered {
    width: 410px !important;
    height: 152px;
    span {
      font-size: 13px;
    }
    .act-submit-btn-holder {
      margin: 15px 20px 20px 0 !important;
    }
  }
  .change-password-pop {
    padding: 0px 10px 34px 10px;
    height: 100%;
    .cng-pswd-title {
      border-bottom: 1px solid rgba(174, 174, 174, 0.25);
      overflow: hidden;
      clear: both;
      padding-bottom: 15px;
      padding-left: 10px;
      .change-password-btn {
        float: left;
        button {
          border: 0;
          color: #cccccc;
          font-size: 15px;
          background: transparent;
          margin-right: 19px;
          padding: 0;
          font-weight: 500;
        }
        .active {
          color: #0090e9;
        }
      }
      .close-icon {
        float: left;
        margin-right: 10px;
      }
    }
    h1 {
      font-size: 17px;
      color: #494949;
      margin: 0;
      padding: 0;
      float: left;
      font-family: roboto;
      font-weight: 500;
    }
    .password-done-btn {
      float: right;
    }
  }
  .password-main {
    padding: 0 10px;
    md-input-container {
      margin: 25px 0 0 0;
      width: 100%;
      height: 30px;
      label {
        margin: 0;
        color: rgba(73, 73, 73, 0.5);
        font-weight: 400;
        font-size: 15px;
      }
      input {
        font-size: 17px;
        color: #808080;
        font-family: $mainFont;
      }
    }
    .md-input-focused {
      label {
        color: rgba(73, 73, 73, 0.5);
        font-weight: 400;
        font-size: 15px;
      }
      input {
        border-color: rgba(174, 174, 174, 0.2);
        border-bottom-width: 1px;
      }
    }
    p {
      color: #ef5350;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.profile-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profile-main {
  position: relative;
}
.google-img {
  img {
    cursor: default !important;
  }
}
.password-inner {
  height: 65px;
  // .old-password{
  // 	margin-top: 15px;
  // }
  > div {
    margin-top: 8px;
  }
  label {
    font-size: 16px;
    letter-spacing: 0.01px;
    color: rgba(73, 73, 73, 0.5) !important;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 8px;
  }
  > div > div::before {
    color: #808080 !important;
    border-bottom: 1px solid #aeaeae !important;
    transition: none !important;
    opacity: 0.25;
  }
  > div > div::after {
    color: #808080 !important;
    border-bottom: 1px solid #aeaeae !important;
    transform: none !important;
    opacity: 0.25;
  }
  > div > div > input {
    color: #494949 !important;
    height: 23px;
  }
  .inputStyles {
    //border: none;
    //border-bottom: 1px solid rgba(174, 174, 174, 0.25);
    width: 100%;
    //margin: 25px 0 0 0;
    //width: 100%;
    height: 30px;
    font-family: roboto;
    font-size: 15px;
    font-weight: 400;
  }
  ::placeholder {
    color: rgba(73, 73, 73, 0.5);
  }
}
.validaton-fail {
  margin: 10px;
  display: flex;
  justify-content: space-around;
  &.password-err {
    left: 10px;
    // bottom: 14px;
    bottom: auto;
    margin-top: 14px;
  }
  &.old-password-err {
    left: 10px;
    // bottom: 134px;
    bottom: auto;
    margin-top: 14px;
  }
  &.new-password-err {
    left: 10px;
    // bottom: 74px;
    bottom: auto;
    margin-top: 14px;
  }
}

.success-msg {
  font: normal 12px/15px roboto;
  color: green !important;
  position: relative;
  left: 50%;
  top: initial !important;
  white-space: nowrap;
  display: flex;
  justify-content: space-around;
}

.fb-profile {
  float: right;
  padding-top: 20px;
}

.unsplash-wrapper {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
}

.unsplash-container {
  width: 700px;
  height: 480px;
  border-radius: 10px;
  background-color: #ffffff;

  z-index: 99999;
  margin: 0 auto;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .unsplash-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 10px;
    border-bottom: 2px solid;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    margin-bottom: 24px;
    p {
      margin: 0;
      font: normal 17px $mainFont;
      color: #494949;
    }
    img {
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
  .search {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      opacity: 0.5;
      margin-right: 15px;
    }
    input {
      width: 626px;
      border: none;
      border-bottom: 2px solid;
      border-bottom-color: rgba(231, 231, 231, 0.5);
      color: #000;
      font: normal 16px $mainFont;
      &::-webkit-input-placeholder {
        color: #999999;
      }
    }
  }
  .unsplash-img-container {
    height: 261px;
    width: 102.2%;
    //overflow: scroll;
    .randomize-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 42px;
      background: #e1f3ff;
      width: 124px;
      height: 126px;
      border-radius: 4px;
      margin: 0 10px 10px 0;
      float: left;
      cursor: pointer;
      &.active {
        border: solid 2px #0090e9;
      }
      img {
        width: 40px;
        height: 40px;
      }
      div {
        font-weight: 600;
        margin-top: 12px;
        color: #0090e9;
        font-size: 12px;
      }
    }
    .img-container {
      width: 124px;
      height: 126px;
      border-radius: 4px;
      //border: solid 2px #0090e9;
      margin: 0 10px 10px 0;
      float: left;
      &:nth-child(5n + 5) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        cursor: pointer;
      }
      &.active {
        border: solid 2px #0090e9;
      }
    }
    .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
      width: 3px;
    }
  }
  #unsplash-img-container-scroll {
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 3px;
      background: #999999;
      border-radius: 3px;
      padding-right: 10px;
    }
  }
  .save-button {
    width: 120px;
    height: 40px;
    background: #0090e9;
    border-radius: 10px;
    text-align: center;
    font: 500 18px/40px roboto;
    color: #fff;
    box-shadow: none;
    border: 0;
    float: right;
    margin-top: 25px;
    cursor: pointer;

    &.disabled {
      background: #e3e3e3;
    }
  }

  .unsplash-container-loader {
    position: absolute;
    height: 38px;
    color: #ffffff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}

.change-bg-box {
  position: absolute;
  bottom: 10px;
  // right: 21%;
  width: 178px;
  height: 103px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  .change-bg-head {
    padding: 15px 0px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .choose-from {
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #808080;
    }
    .hr-line {
      width: 100px;
      height: 1px;
      background: #f0f0f0;
    }
  }
  .change-bg-body {
    // padding-left: 10px;
    display: flex;
    height: 48%;
    flex-direction: column;
    justify-content: space-between;
    .change-bg-option {
      display: flex;
      align-items: center;
      width: inherit !important;
      margin: 0 !important;
      cursor: pointer;
      padding: 5px 0 5px 10px;
      &:hover {
        background-color: rgba(153, 153, 153, 0.15);
      }
      > img {
        width: 16px;
        height: 16px;
      }
      > span {
        margin-left: 10px;
        color: #757575;
        font-size: 14px;
      }
    }
  }
}
