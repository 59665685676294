@import "variable.scss";
.dashboard-main-card {
  height: calc(100% - 100px);
  .dashboard-block {
    padding: 39px 1px 0 30px;
    height: 100%;
    .dash-outer {
      display: flex;
      .dash-inner {
        display: flex;
        flex-direction: column;
        .over-individual-holder {
          display: flex;
          .eff-over-holder,
          .individual-eff-holder {
            width: 340px;
            height: 360px;
            border-radius: 7.5px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            margin-right: 15px;
            // &.personal {
            //   height: 325px;
            // }
            .over-upper {
              padding: 15px;
              &.personal {
                padding-bottom: 19.5px;
              }
              .no-data {
                display: flex;
                height: 300px;
                justify-content: center;
                align-items: center;
              }
              .title-date-holder {
                display: flex;
                justify-content: space-between;
                .over-title {
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #303030;
                }
                .date-range {
                  width: 186px;
                  height: 20px;
                  border-radius: 7px;
                  background-color: #f9f9f9;
                  .date-img {
                    width: 240px;
                    background: url(../img/svg/calendar-flow.svg) no-repeat
                      right center;
                    background-size: 12px;
                    cursor: pointer;
                    background-position-x: 171px;
                    padding-left: 6px;
                    color: #757575;
                    font-size: 11px;
                  }
                }
              }
              .table-holder {
                margin-top: 6px;
                height: 270px;
                .ScrollbarsCustom {
                  width: 326px !important;
                  .ScrollbarsCustom-TrackY {
                    height: 100% !important;
                    top: 0 !important;
                  }
                }
                table {
                  width: 310px;
                  th {
                    color: #757575;
                    font-size: 12px;
                    font-weight: normal;
                    height: 36px;
                    cursor: pointer;
                  }
                  tbody {
                    tr {
                      border-bottom: 1px solid #dde2e7;
                      td {
                        font-size: 12px;
                        height: 46px;
                        img {
                          width: 30px;
                          height: 30px;
                          border-radius: 50%;
                        }
                        .sb-avatar--text {
                          height: auto !important;
                          width: auto !important;
                        }
                        .sb-avatar__text {
                          height: 30px !important;
                          width: 30px !important;
                          > div {
                            font-family: roboto;
                            > span > span {
                              margin-left: 0;
                              color: #ffffff;
                            }
                          }
                        }
                      }
                      .emp-img-name {
                        display: flex;
                        align-items: center;
                        width: 100px;
                        span {
                          margin-left: 15px;
                          color: #494949;
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }
              }
              .bill-non-bill-holder {
                display: flex;
                margin-top: 20px;
                .billable {
                  max-width: 96px;
                  margin-right: 30px;
                  &.extra-margin {
                    width: 91px;
                    // margin-right: 43px;
                  }
                  &.no-margin {
                    margin-right: 0;
                  }
                  .hrs {
                    font-size: 17px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #494949;
                  }
                  .bill {
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #494949;
                  }
                }
              }
              .curr-status-holder {
                margin-top: 22px;
                font-size: 11px;
              }
            }
            .hr-line {
              height: 1px;
              background-color: #f0f0f0;
            }
            .over-lower {
              padding: 13px 0 0 15px;
              .arrow-holder {
                margin-left: 7px;
                .sort-img {
                  width: 5px;
                  &.reverse {
                    transform: rotate(180deg) translateY(0%);
                  }
                }
              }
              .team-proj-tab-holder {
                margin-right: 15px;
                .team-tab {
                  margin-left: 15px;
                  font-size: 11px;
                  cursor: pointer;
                  &.active {
                    background: #ffffff;
                    font-weight: 500;
                    color: $settingsBlue;
                    // border-bottom: 1px solid #018fe9;
                    // padding-bottom: 1px;
                  }
                }
              }
              .team-proj-data-holer {
                margin-top: 20px;
                height: 92px;
                &.personal {
                  height: 125px;
                }
                .no-data-to-show {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 50px;
                  margin-right: 15px;
                }
                .effort-name {
                  margin-bottom: 22px;
                  font-size: 12px;
                  &.personal {
                    margin-bottom: 20px;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                  span {
                    font-weight: 600;
                  }
                }
                .ScrollbarsCustom-TrackY {
                  height: 100% !important;
                  top: 0 !important;
                }
              }
            }
          }
        }
        .proj-access-req-holder {
          width: 600px;
          height: 270px;
          border-radius: 7.5px;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          margin-right: 15px;
          margin-top: 15px;
          .no-data {
            display: flex;
            height: 200px;
            justify-content: center;
            align-items: center;
          }
          .title-date-holder {
            padding: 18px 15px 15px;
            .over-title {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #303030;
              > span {
                font-size: 11px;
                color: #757575;
                margin-left: 10px;
              }
            }
          }
          .table-holder {
            height: 210px;
            padding-left: 15px;
            .ScrollbarsCustom {
              // width: 300px !important;
              height: 188px !important;
              .ScrollbarsCustom-TrackY {
                height: 100% !important;
                top: 0 !important;
              }
            }
            table {
              width: 100%;
              thead {
                tr {
                  // border-bottom: 1px solid #dde2e7;
                }
              }
              th {
                color: #757575;
                font-size: 12px;
                font-weight: normal;
                height: 22px;
                cursor: pointer;
                &.actions {
                  cursor: auto;
                }
              }
              tbody {
                tr {
                  border-bottom: 1px solid #dde2e7;
                  &:hover {
                    .tick-cross-holder {
                      visibility: visible;
                    }
                  }
                  td {
                    font-size: 12px;
                    height: 46px;
                    .tick-cross-holder {
                      visibility: hidden;
                    }
                    .sb-avatar--text {
                      height: auto !important;
                      width: auto !important;
                      margin-right: 10px;
                    }
                    .sb-avatar__text {
                      height: 30px !important;
                      width: 30px !important;
                      > div {
                        font-family: roboto;
                        > span > span {
                          margin-left: 0;
                          color: #ffffff;
                        }
                      }
                    }
                    img {
                      width: 14px;
                      height: 10px;
                      margin-right: 14px;
                      cursor: pointer;
                    }
                  }
                  .emp-img-name {
                    width: 198px;
                    > div {
                      display: flex;
                      align-items: center;
                    }
                    img {
                      width: 30px;
                      height: 30px;
                      object-fit: cover;
                      border-radius: 50%;
                      cursor: auto;
                    }
                    span {
                      margin-left: 15px;
                      color: #494949;
                      font-size: 10.5px;
                    }
                  }
                  .proj-img-name {
                    width: 210px;
                    > div {
                      display: flex;
                      align-items: center;
                    }
                    img {
                      width: 30px;
                      height: 30px;
                      object-fit: cover;
                      cursor: auto;
                      border-radius: 5px;
                    }
                    .sb-avatar__text {
                      border-radius: 5px;
                    }
                  }
                }
              }
            }
          }
        }
        .missed-time-sheet-holder {
          width: 340px;
          height: 360px;
          border-radius: 7.5px;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          margin-right: 15px;
          .title-date-holder {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            padding-bottom: 6px;
            .over-title {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #303030;
              > span {
                font-size: 11px;
                color: #757575;
                margin-left: 5px;
              }
            }
            .date-range {
              width: 104px;
              height: 20px;
              border-radius: 7.5px;
              background-color: #f9f9f9;
              .date-img {
                width: 102px;
                background: url(../img/svg/calendar-flow.svg) no-repeat right
                  center;
                background-size: 12px;
                cursor: pointer;
                background-position-x: 87px;
                padding-left: 6px;
                color: #757575;
                font-size: 11px;
              }
            }
          }
          .no-data {
            display: flex;
            height: 288px;
            justify-content: center;
            align-items: center;
          }
          .table-holder {
            height: 310px;
            padding-left: 15px;
            // padding-top: 10px;
            .ScrollbarsCustom {
              height: 266px !important;
              .ScrollbarsCustom-TrackY {
                height: 100% !important;
                top: 0 !important;
              }
            }
            table {
              width: 310px;
              th {
                color: #757575;
                font-size: 12px;
                font-weight: normal;
                height: 36px;
                cursor: pointer;
              }
              tbody {
                tr {
                  border-bottom: 1px solid #dde2e7;
                  td {
                    font-size: 12px;
                    height: 46px;
                    img {
                      width: 30px;
                      height: 30px;
                      object-fit: cover;
                      border-radius: 50%;
                    }
                    .sb-avatar--text {
                      height: auto !important;
                      width: auto !important;
                    }
                    .sb-avatar__text {
                      height: 30px !important;
                      width: 30px !important;
                      > div {
                        font-family: roboto;
                        > span > span {
                          margin-left: 0;
                          color: #ffffff;
                        }
                      }
                    }
                  }
                  .emp-img-name {
                    display: flex;
                    align-items: center;
                    width: 98px;
                    span {
                      margin-left: 24px;
                      color: #494949;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
        .proj-status-card-holder {
          width: 436px;
          height: 270px;
          border-radius: 7.5px;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          margin-right: 15px;
          margin-top: 15px;
          .no-data {
            display: flex;
            height: 200px;
            justify-content: center;
            align-items: center;
          }
          &.personal {
            width: 340px;
            height: 360px;
            margin-top: 0;
            .ScrollbarsCustom {
              height: 240px !important;
            }
            #container {
              > div {
                overflow: inherit !important;
              }
              .highcharts-color-0 {
                // width: 4px;
                fill: #5e62f3 !important;
                tspan {
                  color: #494949;
                  font-weight: normal;
                  font-size: 11px;
                }
                rect {
                  fill: #5e62f3 !important;
                }
              }
              .highcharts-color-1 {
                // width: 4px;
                fill: #d5dff8 !important;
                tspan {
                  color: #494949;
                  font-weight: normal;
                  font-size: 11px;
                }
                rect {
                  fill: #d5dff8 !important;
                }
              }
            }
            .no-data {
              height: 260px;
            }
          }
          .ScrollbarsCustom {
            height: 206px !important;
            .ScrollbarsCustom-TrackY {
              height: 100% !important;
              top: 0 !important;
            }
            .ScrollbarsCustom-TrackX {
              height: 4px !important;
              background: none !important;
            }
          }
          #container {
            > div {
              overflow: inherit !important;
            }
            .highcharts-color-0 {
              width: 10px;
              fill: #194d6e !important;
              rect {
                fill: #194d6e !important;
              }
            }
            .highcharts-color-1 {
              width: 11px;
              fill: #ed524f !important;
              rect {
                fill: #ed524f !important;
              }
            }
          }
          .title-date-holder {
            display: flex;
            justify-content: space-between;
            padding: 18px 15px 15px;
            .over-title {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #303030;
              > span {
                font-size: 11px;
                color: #757575;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.proj-access-status-holder {
  display: flex;
  flex-direction: row;
}

.cancel-req-btn {
  border-radius: 7.5px;
  border: solid 0.7px #ef5350;
  color: #ef5350;
  background: #fff3f2;
  width: 93px;
  height: 22px;
  white-space: nowrap;
  &:hover {
    background: #fff3f2 !important;
  }
  &:focus {
    border: solid 0.7px #ef5350;
  }
}

.req-again-btn {
  border-radius: 7.5px;
  border: solid 0.7px #0090e9;
  color: #0090e9;
  background: #e9f5fd;
  width: 93px;
  height: 22px;
  &:hover {
    background: #e9f5fd !important;
  }
  &:focus {
    border: solid 0.7px #0090e9;
  }
}

.dash-picker {
  width: auto !important;
  .ranges {
    margin-top: 8px;
    li.active {
      background-color: #ffffff;
      color: inherit;
      background: url(../img/svg/tick-black.svg) no-repeat right center;
      background-position-x: 120px;
      &:hover {
        background-color: #eee;
      }
    }
  }
  .drp-calendar.left {
    border-left: 0 !important;
  }
}

.dash-picker-single {
  width: auto !important;
  height: auto !important;
  .ranges {
    width: 124px;
    margin-top: 8px;
    li.active {
      background-color: #ffffff;
      color: inherit;
      background: url(../img/svg/tick-black.svg) no-repeat right center;
      background-position-x: 105px;
      &:hover {
        background-color: #eee;
      }
    }
  }
  .drp-calendar.left {
    border-left: 0 !important;
  }
}

.proj-status-tooltip {
  width: 125px;
  height: 70px;
}

.highcharts-legend-item {
  text {
    color: #494949 !important;
    fill: #494949 !important;
    cursor: auto !important;
    font-weight: normal !important;
    font-size: 11px !important;
  }
}

.performer-percent {
  display: flex;
  justify-content: center;
  font-size: 11px;
}

.highcharts-scrolling {
  height: 206px !important;
  &::-webkit-scrollbar {
    height: 6px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    background: rgba(153, 153, 153, 0.5);
    border-radius: 2px;
  }
}
