@import "variable.scss";



  .box {
    width: 380px;
    height: 140px;
    border-radius: 10px;
    background-color: #0090e9 !important;
    color: #fff;
   
    position: relative;
    margin: 40px;
    
    
  }

  .box.arrow-top {
    margin-top: 40px;
  }

  .box.arrow-top:after {
    content: " ";
    position: absolute;
    left: 50px;
    top: -15px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 15px solid #0090e9;
  }

  .box.arrow-right:after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid #0090e9;
    border-bottom: 15px solid transparent;
  }
  
  .box.arrow-bottom:after {
    content: " ";
    position: absolute;
    right: 52px;
    bottom: -15px;
    border-top: 15px solid #0090e9;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;
  }

  .box.arrow-bottom-left:after {
    content: " ";
    position: absolute;
    right: 300px;
    bottom: -15px;
    border-top: 15px solid #0090e9;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: none;
  }
  
  .box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 15px;
    border-top: 15px solid transparent;
    border-right: 15px solid #0090e9;
    border-left: none;
    border-bottom: 15px solid transparent;
  }

  .helper {
    
    width: 500px;
    box-shadow:none !important;
    

   
  }

  .previousButton{
    padding-right: 20px; 
    cursor: pointer;
    font-family: Roboto;
   }

   .nextButton{
    padding-right: 20px; 
    cursor: pointer;
    font-family: Roboto;
   }

   
  .prevnextButton{
     height: 15px;
    font-size: 13px;
    font-weight: 500;
    float: right;
  }

  .arrow-bottom .contentData{
    font-family: Roboto;
    font-size: 13px;
    width: 325px !important;
    padding:0px 0px 0px 18px;
    text-align: left !important;
    display: inline-block;
  }

  .arrow-bottom .header{
    font-family: Roboto;
    font-size: 13px;
    padding:18px 0px 0px 18px
  }

  .arrow-bottom-left .contentData{
    font-family: Roboto;
    font-size: 13px;
    width: 325px !important;
    padding:0px 0px 0px 18px;
    text-align: left !important;
    display: inline-block;
  }

  .arrow-bottom-left .header{
    font-family: Roboto;
    font-size: 13px;
    padding:18px 0px 0px 18px
  }

  .arrow-top .contentData{
    font-family: Roboto;
    font-size: 13px;
    width: 325px !important;
    padding:0px 0px 0px 18px;
    text-align: left !important;
    display: inline-block;
  }

  .arrow-top .header{
    font-family: Roboto;
    font-size: 13px;
    padding:18px 0px 0px 18px
  }

  .stepCount{
    width: 33px;
    height: 15px;
    opacity: 0.75;
    font-family: Roboto;
    font-size: 13px;
    margin-left: 20px;
  }

  .footerTour{
    margin-top:20px;
  }

  .mainStyle{
    position:fixed;
    left:42%;
     top:21%;
}

.welcomediv{
  position:absolute;
}

.welcomelogo{
  width: 250px;
  height: 145px;
  left:45px;
 position:absolute;
}

.welcomemessage{
  position:absolute;
  top:200px;
  left:-71px;
  width: 413px;
  height: 35px;
  font-family: Roboto;
  font-size: 30px;
  display: inline-block;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.welcomequote{
  position:absolute;
  top:250px;
  left:-19px;
  width: 300px;
  height: 18px;
  font-family: Roboto;
  font-size: 15px;
}

.welcometour{
  position:absolute;
  top:310px;
  left:15px;
  width: 200px;
  height: 65px;
  border-radius: 10px;
  background-color: #0090e9;
  display: inline-flex;
  cursor: pointer;
} 

.welcometourname{
  
  display: inline-block;
  width: 144px;
  height: 29px;
  font-family: Roboto;
  font-size: 25px;
  font-weight: 500;
  color:white;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 14px;
  margin-left: 10px;
}

.welcometourarrow{
  margin-top: 25px;
  margin-right: 10px;
}

.skipClass{
    background: url(../img/svg/delete_grey.svg) no-repeat center;
    // width: 26px;
    width: 12px;
    color: #f4f4f4 ;
    height: auto;
    fill: currentColor;
    border-radius: 10px;
    margin-top: 14px;
    margin-right: 14px; 
    float: right;
    cursor: pointer;


}

#tooltip {

  width: 85px;
  height: 20px;
  border-radius: 5px;
  left:320px;
  top:-10px;
  background-color:#505050;
  text-align: center;
}
  

  