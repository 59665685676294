@import "variable.scss";

.log-service {
  height: 30px;
  font-family: $mainFont;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #808080;
  margin: 10px 0 25px 0;
  p {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
  span {
    color: #0090e9;
    font-size: 11px;
  }
}

.reg-butt {
  margin: 50px 0 0 0 !important;
}

.cus-reg {
  margin: 103px 0 0 0;
}

.regis {
  margin-top: 63px !important;
}

.signIn-container {
  font-family: $mainFont;
}

.download-text {
  color: #999999;
  font-size: 14px;
  font-weight: normal;
  margin-top: 10px;
  font-family: $mainFont;
  a {
    color: #0090e9 !important;
    &:hover {
      color: #0090e9 !important;
    }
  }
}

.info-input {
  position: relative;
  img {
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
  }
}

.info-detail {
  width: 217px;
  // height: 50px;
  border-radius: 3px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 10px 10px;
  position: absolute;
  left: 235px;
  z-index: 999;
  top: 31px;
  p {
    margin: 0;
    height: 30px;
    font-family: $mainFont;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #808080;
  }
}

//Verify classes

.verify-account {
  width: 840px;
  // height: 309px;
  border-radius: 10px;
  border: solid 1px #d6d6d6;
  margin: 0 auto;
  .main-title-verify {
    font-family: $mainFont;
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
    margin: 40px 0 10px 0;
  }
  .title-verify {
    font-family: $mainFont;
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #999999;
    // margin: 50px 0 25px 0;
    margin-bottom: 35px;
  }
  .content-verify {
    font-family: roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
    margin-bottom: 50px;
    width: 80%;
    margin: 0 auto;
  }
  .separator-send-verify {
    width: 32%;
    margin: 55px auto;
    background-color: #d6d6d6;
    /* background: #dddd; */
    border: none;
    height: 1px;
  }
  .border-line {
    width: 300px;
    height: 2px;
    border: solid 1px #d6d6d6;
    margin: 0 auto;
  }
  .not-received {
    font-family: $mainFont;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
    margin: 15px 0 30px 0;
    span {
      font-weight: 600;
    }
    p {
      font-weight: 600;
      color: #0090e9;
      width: fit-content;
      letter-spacing: normal;
      margin: 0 auto;
      margin-top: 38px;
      text-decoration: underline;
    }
  }
}

.resend-success {
  width: fit-content;
  margin: auto;
  text-align: center;
  position: relative;
  animation: fadeIn ease-in 0.6s;
  &.login-view {
    margin-bottom: 5%;
  }
  &.show-success {
    animation: fadeOut ease 1s;
  }
  .resend-text {
    padding: 21px 27px;
    font-family: $mainFont;
    font-size: 16px;
    color: #fff;
    border-radius: 10px;
    background-color: #70b824;
  }
  span {
    font-weight: 600;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  // 0% {
  //   opacity: 1;
  // }
  100% {
    opacity: 0;
  }
}

.google-not-registered {
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #757575;
  margin-top: 194px;
  font-family: $mainFont;
}
.not-registered {
  font-family: $mainFont;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin: 20px 0 90px 0;
}

.password-viewer {
  position: absolute;
  top: 10px;
  right: 0px;
}
.view-password-holder {
  position: relative;
}

.eye-not-seen {
  background-image: url("../img/svg/ic-visibility-off.svg");
  background-repeat: no-repeat;
  width: 23px;
  height: 20px;
  cursor: pointer;
}
.eye-seen {
  background-image: url("../img/svg/ic-visibility.svg");
  background-repeat: no-repeat;
  width: 23px;
  height: 20px;
  cursor: pointer;
}

.resize-pass {
  margin-top: -15px !important;
  font: normal 14px $mainFont !important;
}

.password-success {
  margin-bottom: 40px !important;
  height: 36px !important;
  line-height: 1.3;
}

.register-successfull {
  width: 840px;
  height: 492px;
  border-radius: 10px;
  border: solid 1px #d6d6d6;
  margin: 0 auto;
  margin-top: -3px;
  margin-bottom: 80px;
  .title-sucess {
    margin: 50px 0 25px 0;
    font-family: $mainFont;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #494949;
  }
  .comple-profile {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #757575;
    margin-bottom: 79px;
  }
  .profile-register {
    width: 477px;
    height: 151px;
    margin: 0 auto;
    display: flex;
    position: relative;
    z-index: 1;
    .left-profile {
      .img-profile {
        width: 100px;
        height: 100px;
        background-color: #f0f0f0;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        span {
          position: absolute;
          top: 40px;
          right: 16px;
          color: #999999;
          font-family: $mainFont;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.01px;
        }
      }
      .upload-image {
        border-radius: 50%;
      }
    }
    .right-profile {
      margin-left: 60px;
    }
  }
  .profile-continue {
    margin-top: 60px;
    span {
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      font-family: $mainFont;
    }
  }
}

.input-hold {
  height: 60px;
}
.finish-profile-input {
  border: none;
  width: 315px;
  height: 35px;
  border-bottom: 1px solid #d6d6d6;
  font: normal 18px $mainFont;
  color: #000000;
  &::-webkit-input-placeholder {
    color: #aeaeae;
    font-weight: normal;
  }
  &:focus {
    border-bottom: 1px solid #d6d6d6;
  }
}
.img-added-holder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background-color: #f0f0f0;
  img {
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.input-hold {
  button {
    display: none;
  }
  md-autocomplete {
    color: #000;
    .md-whiteframe-z1 {
      box-shadow: none;
    }
    input:not(.md-input) {
      height: 35px;
      border-bottom: 1px solid #d6d6d6;
      font: normal 18px $mainFont;
      padding: 0;
      &::-webkit-input-placeholder {
        color: #aeaeae;

        font-weight: normal;
      }
    }
  }
}

.nw-att-loading-holder {
  &.profile {
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}
.finish-profile-input-wrapper {
  input {
    border: none;
    width: 315px;
    height: 35px;
    border-bottom: 1px solid #d6d6d6;
    font: normal 18px $mainFont;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    // background-image: url("../img/svg/triangle-reverse.svg");
    // background-repeat: no-repeat;
    // background-position: center right 12px;
    &::-webkit-input-placeholder {
      color: #aeaeae;
      font-weight: normal;
    }
  }
  hr {
    display: none;
  }
}

#country-menu-list {
  background-color: #fff;
  overflow-y: scroll;
  max-height: 164px;
  // box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
  //   0 2px 4px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  // width: 285px;
  margin-top: 2px;
  //margin-top: -20px;
  margin-left: -3px;
  border-radius: 10px;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  z-index: 99999;

  li {
    overflow: hidden;
    margin-left: -35px;
    padding: 0 8px;
    //line-height: 29px;
    //height: 29px;
    color: $panelHeading;
    font: normal 15px/29px $mainFont;
    &:hover {
      background: rgba(153, 153, 153, 0.2);
      cursor: pointer;
    }
    span {
      font: normal 15px $mainFont;
    }
  }
}
#country-menu-list::-webkit-scrollbar {
  width: 4px;
  background: transparent;
  border: 0;
  margin: 0 20px 0 0;
  box-sizing: border-box;
}
#country-menu-list::-webkit-scrollbar-thumb {
  width: 8px;
  background: $topNaviColor;
  border-radius: 5px;
}

.video-time-content {
  color: #808080;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* text-align: center; */
  /* color: #808080; */
  /* margin-bottom: 10px; */
  height: 32px;
}

.register-new-logo {
  margin: auto;
  margin-bottom: 30px;
  width: 223px;
  height: 164px;
}

.download-register {
  color: #999999;
  font-family: $mainFont;
  font-weight: bold;
  a {
    color: #0090e9;
    font-size: 18px;
    &:hover {
      color: #0090e9;
    }
  }
}

.ng-verify-email {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
