@import "variable.scss";

.container-act {
  background-size: cover !important;
  padding: 0 0 0 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  .contain {
    .rightbar {
      top: 0;
    }
    .notifyStyle {
      top: 0;
    }
  }
}
.contain {
  margin: 0 0 10px 0;
}

.activity-create-container {
  width: calc(100% - 5px);
  height: calc(100% - 103px);
  margin: 42px 0 0 0;
  overflow: scroll;
  &#activity-container-scroll-div {
    overflow-y: scroll;
  }

  .left_sbar_scroll {
    padding: 0;
    height: 100%;
    background-color: #dfeef6;
  }

  .right_sbar_scroll {
    width: 100%;
  }

  .dashboard_nav {
    display: block;
    flex-direction: column;
    background: #dfeef6;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    .title {
      font-size: 24px;
      color: $font_light;
      padding-top: 13px;
      margin-left: 17px;
    }

    .performance {
      width: 100%;
      height: calc(100% - 107px);
      background-color: #dfeef6;

      .username {
        display: flex;
        font-size: 24px;
        font-weight: bold;
        margin-left: 17px;
        color: $font_dark;
        margin-top: 20px;
      }

      .green_border {
        border-top: 2px solid $success_gtd;
      }

      .red_border {
        border-left: 1px solid $border_box_gtd;
        border-top: 2px solid $danger_gtd;
        border-bottom: 1px solid $border_box_gtd;
        border-right: 1px solid $border_box_gtd;
      }

      .green {
        color: $success_gtd;
      }

      .red {
        color: $danger_gtd;
      }

      .performance_card_common {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        margin-left: 20px;
        margin-right: 20px;
        padding: 12px 0 5px;
        border: 1px solid $border_box_gtd;

        &:first-child {
          margin-right: 0 !important;
        }

        .count {
          font-size: 42px;
          color: $font_dark;
          line-height: 1;
        }

        .completed_value {
          font-size: 42px;
          color: $success_gtd;
          line-height: 1;
        }

        .count_title {
          font-size: 13px;
          color: $font_dark;
          padding-top: 4px;
        }
      }

      .performance_card_yesterday {
        display: flex;
        flex-direction: row;
        margin: 15px 20px;
        padding: 29px 30px 29px 23px;

        .left {
          display: flex;
          align-items: center;

          img {
            max-height: 48px;
            max-width: 48px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin-left: 16px;

          .right_title {
            font-size: 15px;
            color: $font_light;
          }

          .time {
            font-size: 15px;
            font-weight: bold;
            color: $font_dark;
          }

          .remarks {
            font-size: 15px;
          }
        }
      }

      .performance_card_today {
        @extend .performance_card_yesterday;
      }

      .activity_count {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;

        .upcoming_activity {
          @extend .performance_card_common;
        }
        .count {
          @extend .count;
        }
        .count_title {
          @extend .count_title;
        }
        .completed_activity {
          @extend .upcoming_activity;
        }
      }

      .efficiency_title {
        margin-left: 20px;
        font-size: 20px;
        color: $font_dark;
      }

      .efficiency {
        display: flex;
        flex-direction: row;
        padding-bottom: 40px;

        .week_efficiency {
          &:first-child {
            margin-right: 0 !important;
          }
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          background-color: #ffffff;
          margin-left: 20px;
          margin-right: 20px;
          padding: 12px 10px 7px;
          border: 1px solid $border_box_gtd;

          .count {
            font-size: 24px;
          }

          .completed {
            font-size: 24px;
            color: $success_gtd;
          }

          .count_title {
            font-size: 13px;
            color: $font_dark;
          }
        }

        .month_efficiency {
          @extend .week_efficiency;
        }
      }
    }
  }

  .activity-container {
    display: flex;
    background-size: cover !important;
    padding: 0 0 0 0;
    width: 100%;

    .heading {
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: bold;
      color: $snow_gtd;
      margin-left: 20px;
    }
    .create-activity-scroll {
      padding-right: 0;
      padding-left: 30px;
      width: 100%;
      .activity-column {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        // overflow: scroll;
        // overflow-x: hidden;
        .title {
          font-size: 24px;
          margin-left: 0;
          color: $snow_gtd;

          display: flex;
          align-items: center;
          padding-top: 0;
          .activity-title {
            font-size: 24px;
            font-family: $mainFont;
            margin: 0 45px 0 0;
            color: #ffffff;
          }
          .tab-activity {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin-right: 5px;
            font-family: $mainFont;
          }
          .active-activity {
            width: 60px;
          }
          .archive-activity {
            width: 77px;
            margin-right: 25px;
          }
          .active-tab {
            background-color: rgba(255, 255, 255, 0.2);
            pointer-events: none;
            padding: 4px;
            border-radius: 13px;
          }
          .searchActivity {
            border: none;
            border-bottom: 1px solid #d6d6d6;
            background: transparent;
            color: #fff;
            margin-left: 10px;
            font-size: 15px;
            width: 400px;
            padding-left: 0px;
            font-family: $mainFont;
            line-height: 1;
            padding-bottom: 5px;
            &::-webkit-input-placeholder {
              color: #fff !important;
              opacity: 0.5;
              font-size: 15px;
              font-family: $mainFont;
            }
          }
        }

        .act-card {
          width: 300px;
          background-color: $whiteColor;
          margin-right: 24px;
          margin-bottom: 24px;
          padding: 12px 0 1px;
          float: left;
          border-radius: 8px;
          min-height: 175px;
          max-height: 180px;
          &:nth-of-type(3n + 3) {
            margin-right: 0;
          }

          .act-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 10px;
            .header-wrap {
              display: flex;
              align-items: center;
              .client-logo {
                display: flex;
                flex-direction: row;
                margin-left: 12px;
                width: 30px;
                height: 30px;
                margin-right: 15px;
                img {
                  border-radius: 4px;
                  object-fit: cover;
                  width: 100%;
                }
                .sb-avatar--text {
                  .sb-avatar__text {
                    border-radius: 4px;
                    width: 30px !important;
                    height: 30px !important;
                    > div > span > span {
                      font-size: 13.7px;
                      font-family: roboto;
                      font-weight: 500;
                      color: #ffffff;
                    }
                  }

                  img {
                    border-radius: 4px;
                    max-height: 100%;
                    max-width: 100%;
                    object-fit: contain;
                  }
                }
              }

              .people {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  margin-right: -10px;
                  border: 2px solid #fff;
                }

                .border {
                  border: 2px solid #0090e9;
                  z-index: 1;
                }
              }
            }
            p {
              font-size: 12px;
              color: #808080;
              font-family: $sideFont;
              margin: 0 0 0 10px;
              line-height: 28px;
            }
            .wrap-star-options {
              display: flex;
              align-items: center;
              margin-top: 0px;
              .menu-options {
                margin: 0;
              }
            }
            .menu-options {
              .archive {
                height: 14px;
                width: 14px;
                margin-right: 10px;
              }
              .edit {
                height: 14px;
                width: 14px;
                margin-right: 0;
              }
            }
            .option-icon {
              position: relative;
              cursor: pointer;
              .dot-holder {
                width: 19px;
                height: 19px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  background: #f0f0f0;
                }
              }
              margin-left: 10px;
              .options-block {
                min-width: 153px;
                border-radius: 10px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
                background-color: #ffffff;
                position: absolute;
                left: 0;
                top: 22px;
                padding: 10px 0px;
                z-index: 999;
                .act-menu-option {
                  font-family: $mainFont;
                  font-size: 14px;
                  font-weight: normal;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: normal;
                  letter-spacing: 0px;
                  color: #494949;
                  height: 30px;
                  padding: 5px 10px;
                  &:hover {
                    background: rgba(153, 153, 153, 0.15);
                  }
                }
                .deleteflow-box {
                  margin-left: 10px;
                  margin-right: 10px;
                  > p {
                    line-height: normal;
                    margin: 0 0 10px;
                    font-size: 13px;
                    color: #555;
                  }
                }
              }
            }
          }

          .act-body {
            display: flex;
            flex-direction: column;
            margin-top: 25px;
            margin-bottom: 18px;
            width: 90%;
            cursor: pointer;
            .act-title {
              font-size: 17px;
              margin-left: 12px;
              font: normal 17px $sideFont;
              color: #494949;
              width: 249px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              white-space: nowrap;
            }

            .proj-title {
              font: normal 14px $sideFont;
              margin-left: 12px;
              color: #999999;
              height: 19px;
              width: 249px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              white-space: nowrap;
            }
            .custom-tip {
              position: absolute;
              top: 0;
              left: 0;
              padding: 0 0 0 0px;
              width: 500px;
              font-size: 10px;
            }
          }

          .date-time {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 31px;
            justify-content: space-between;
            padding-left: 12px; // addded for new activity design
            .duration-wrap {
              display: flex;
              margin-top: 3px;
              .planned-upcoming {
                font-size: 12px;
                span {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  img {
                    // margin-right: 3px;
                  }
                }
                .not-delayed {
                  color: #999999;
                  display: none; // addded for new activity design
                }
                .yes-delayed {
                  color: #ef5350;
                  cursor: default; // addded for new activity design
                  > span {
                    // margin-left: 5px; // commented for new activity design
                  }
                }
              }
              .separator {
                font-size: 11px;
                margin-right: 5px;
              }
            }
            .act-due-on-delayed {
              display: flex;
              flex-grow: 1;
              // margin-left: 12px; // commented for new activity design
              font-size: 12px;
              font-weight: 400;
              color: #ef5350;
              font-family: $sideFont;
              margin-right: 10px;
              margin-right: 5px; // addded for new activity design
              cursor: default; // addded for new activity design
            }

            .act-due-on-on-target {
              display: flex;
              flex-grow: 1;
              margin-left: 12px;
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              font-family: $sideFont;
              margin-right: 6px;
              display: none; // new activity design
            }

            .timer {
              display: flex;
              flex-direction: row;
              align-items: center;

              timer {
                flex-grow: 0;
                font-size: 17px;
                font-weight: 400;
                margin: 0;
                padding-right: 9px;
                color: #505050;
                font-family: $sideFont;
              }

              .timer-button {
                img {
                  flex-grow: 2;
                  height: 35px;
                  margin-right: 12px;
                }
              }
            }
          }

          .act-footer {
            display: flex;
            flex-direction: row;
            font-family: $sideFont;
            margin-top: 21px;
            margin-left: 12px;
            align-items: center;

            .comment-count {
              display: flex;
              flex-grow: 3;
              align-items: center;
              font-size: 12px;
              font-family: $sideFont;
              color: #999999;
              img {
                width: 15px;
                height: 15px;
                margin-right: 3px;
              }
            }

            .checklist-count {
              display: flex;
              flex-grow: 3;
              align-items: center;
              margin-left: 30px;
              font-size: 12px;
              font-family: $sideFont;
              color: #999999;

              img {
                width: 15px;
                height: 15px;
                margin-right: 3px;
              }
            }

            .like-count {
              display: flex;
              flex-grow: 3;
              align-items: center;
              margin-left: 30px;
              font-size: 12px;
              font-family: $sideFont;
              color: #999999;

              img {
                width: 15px;
                height: 15px;
                margin-right: 3px;
              }
            }

            .attachment-count {
              display: flex;
              flex-grow: 3;
              align-items: center;
              margin-left: 30px;
              font-size: 12px;
              font-family: $sideFont;
              color: #999999;

              img {
                width: 15px;
                height: 15px;
                margin-right: 3px;
              }
            }
          }
        }
      }
    }

    .create_activity_column {
      .create_activity_form {
        display: flex;
        flex-direction: row;
        //background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        position: fixed;
        top: 77px;
        height: calc(100% - 77px);
        z-index: 9;
        //opacity: 0.5 ;
        right: 15px;
        padding-right: 0;
        .navigation {
          position: relative;
          left: -40px;
          top: 45%;
          z-index: 999;
          width: 3%;

          .normal {
            width: 36px;
          }

          .rotate {
            width: 36px;
            -ms-transform: rotate(180deg);
            /* IE 9 */
            -webkit-transform: rotate(180deg);
            /* Chrome, Safari, Opera */
            transform: rotate(180deg);
          }
        }

        form {
          display: flex;
          flex-direction: column;
          width: 96%;
          padding: 10px 0 60px;

          .form_control {
            width: 100%;
            margin: 10px 0 10px auto;
          }

          .title {
            font-size: 24px;
            color: $snow_gtd;
            padding-bottom: 16px;
          }

          .act_category {
            display: flex;
            flex-direction: row;

            input[type="radio"] {
              opacity: 0;
              position: absolute;
            }

            input[type="radio"]:checked + em {
              width: 16px;
              height: 16px;
              border: 4px solid $primary_gtd;
            }

            input[type="radio"] + em {
              width: 16px;
              height: 16px;
              border: 2px solid #ffffff;
            }

            .act_radio em {
              width: 18px;
              height: 18px;
              display: block;
              border: 1px solid #9d9e9f;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 2px;
            }

            .act_radio label {
              color: $snow_gtd;
              padding: 0 0 0 26px;
              font-size: 16px;
            }

            .act_radio {
              position: relative;
              margin: 0 16px 0 0;
            }
          }

          .selectize-input {
            border: none;
            background: none !important;
            border-radius: 0;
            box-shadow: none !important;
            border-bottom: 1px solid #e4e4e6;
            color: #fff !important;
            font-size: 18px;

            @include placeholder {
              font-size: 16px !important;
              color: #fff !important;
              opacity: 0.5;
            }

            &.disabled {
              opacity: 0.5;
            }

            input {
              width: 120px !important;
              font-size: 18px !important;
              color: #fff !important;
              height: 20px;
            }
          }

          .act-title {
            box-shadow: none;
            background: none;
            border: none;
            border-radius: 0;
            padding: 7px 8px 6px;
            border-bottom: 1px solid #e4e4e6;
            font-size: 16px !important;
            color: #fff !important;
          }

          @include placeholder {
            font-size: 16px !important;
            color: #fff !important ;
            opacity: 0.5;
          }

          .due_on {
            cursor: pointer;

            .act_due_on {
              @extend .act-title;
            }

            span {
              position: absolute;
              right: 0;
              top: 12px;

              img {
                cursor: pointer;
                width: 18px;
                height: 25px;
              }
            }
          }

          .duration {
            position: relative;

            input::-webkit-inner-spin-button,
            input::-webkit-outer-spin-button {
              /* display: none; <- Crashes Chrome on hover */
              -webkit-appearance: none;
              margin: 0;
              /* <-- Apparently some margin are still there even though it's hidden */
            }

            .act_duration {
              @extend .act-title;
            }

            span {
              position: absolute;
              right: 0;
              top: 12px;

              img {
                width: 24px;
                height: 24px;
              }
            }
          }
          .tags_contain {
            width: 100%;
            margin: 0px 0 10px auto;
            .tags_contain_input {
              box-shadow: none;
              background: none;
              border: none;
              border-radius: 0;
              padding: 7px 8px 6px;
              border-bottom: 1px solid #e4e4e6;
              font-size: 16px !important;
              color: #fff !important;
              .input_tag {
                background: none;
                border: none;
                //width: 100%;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 0;
              }
              .arrow {
                content: " ";
                position: absolute;
                top: 418px;
                right: 20px;
                width: 14px;
                height: 11px;
                border-style: none;
                border-width: 5px 5px 0;
                background-image: url(../img/svg/dropdown.svg);
                background-position: 0 0;
                display: block;
                background-repeat: no-repeat;
              }
              .active_arrow {
                transform: rotate(180deg);
                content: " ";
                position: absolute;
                top: 418px;
                right: 20px;
                width: 14px;
                height: 11px;
                border-style: none;
                border-width: 5px 5px 0;
                background-image: url(../img/svg/dropdown.svg);
                background-position: 0 0;
                display: block;
                background-repeat: no-repeat;
              }
            }
            .tags_dd {
              .add_tags {
                color: #555;
                width: 323px;
                padding: 10px;
                border: none;

                &::placeholder {
                  color: #555 !important;
                }
              }
              .tags_list {
                width: 343px;
                top: 37px;
                left: 0px;
                visibility: visible;
                background-color: #fff;
                padding: 10px 10px;
                height: 40px;
                .delete {
                  float: right;
                  height: 17px;
                  width: 17px;
                }
              }
            }
          }
          .selectize-control.multi .selectize-input [data-value] {
            text-shadow: 0 1px 0 rgba(0, 51, 83, 0.3);
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            background-color: #fff;
            background-image: -moz-linear-gradient(top, #1da7ee, #178ee9);
            background-image: -webkit-gradient(
              linear,
              0 0,
              0 100%,
              from(#1da7ee),
              to(#178ee9)
            );
            background-image: -webkit-linear-gradient(top, #1da7ee, #178ee9);
            background-image: -o-linear-gradient(top, #1da7ee, #178ee9);
            background-image: linear-gradient(to bottom, #fff, #fff);
            /* background-repeat: repeat-x; */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=  '#ff1da7ee', endColorstr='#ff178ee9', GradientType=0);
            -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2),
              inset 0 1px rgba(255, 255, 255, 0.03);
            box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2),
              inset 0 1px rgba(255, 255, 255, 0.03);
          }

          .selectize-control.multi .selectize-input > div {
            cursor: pointer;
            margin: 0 3px 3px 0;
            padding: 2px 6px;
            background: #fff;
            color: #000;
            border: 1px solid #fff;
          }

          .activity_create_btn_group {
            display: flex;
            flex-direction: row;

            .activity_create_btn {
              width: 151px;
              height: 44px;
              border-radius: 2px;
              background-color: #2fb6fc;
              border: none;
              color: #fff;
              font-size: 16px;
              font-weight: bold;
              margin-top: 8px;
              margin-left: 5px;
              border-radius: 8px;
            }

            .activity_create_btn[disabled] {
              background-color: #cccccc;
            }
          }
        }
      }

      .unslpash-credits {
        font-size: 12px;
        color: #fff;
        bottom: 0;
        position: fixed;
        right: 0;
        padding: 10px;
        z-index: 99;
        color: rgba(255, 255, 255, 0.25);
        a {
          color: #ffffff;
          font-size: 12px;
        }
        a:hover {
          text-decoration: underline;
          color: #ffffff;
        }
      }
    }
  }
}

md-select:not([disabled]) {
  &:focus {
    .md-select-value {
      border-bottom-color: rgba(42, 152, 240, 1);
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.md-input-container {
  &.md-input-focused:not(.md-input-has-value) {
    md-select {
      .md-select-value {
        color: rgba(42, 152, 240, 1);

        &.md-select-placeholder {
          color: rgba(42, 152, 240, 1);
        }
      }
    }
  }
}

.md-input-focused:not(.md-input-invalid) {
  &.md-input-focused {
    label {
      color: rgba(42, 152, 240, 1);
    }
  }
}

md-select-menu {
  md-content {
    md-option[selected] {
      &:focus {
        color: rgba(42, 152, 240, 1);
      }
    }
  }

  &.md-default-theme {
    md-content {
      md-option[selected] {
        color: rgba(42, 152, 240, 1);
      }
    }
  }
}

.md-checkbox-enabled[selected] {
  color: rgba(42, 152, 240, 1);

  .md-icon {
    background-color: rgba(42, 152, 240, 1);
    color: rgba(42, 152, 240, 1);
  }
}

.selectize-control {
  &.single {
    .selectize-input {
      &.dropdown-active {
        &:after {
          margin-left: 0;
          border-width: 0 0 0 0;
          border-color: transparent;
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Chrome, Safari, Opera */
          transform: rotate(180deg);
          top: 16px;
        }
      }

      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 18px;
        right: 5px;
        /* margin-top: -3px; */
        width: 14px;
        height: 11px;
        border-style: none;
        border-width: 5px 5px 0 5px;
        /* border-color: #808080 transparent transparent transparent; */
        // background-image: url("../images/dropdown.svg");
        background-image: url("../img/svg/board_arrow.svg");
        background-position: 0 0;
        display: block;
        background-repeat: no-repeat;
      }
    }

    .selectize-input.disabled {
      opacity: 0.5;
    }
  }
}

md-select {
  margin: 20px 0;
}
/*My Activity*/

.activity_filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.activity-scroll {
  width: 100%;
  height: calc(100% - 226px);
}

.activity_grouped_view {
  &:last-child {
    margin-bottom: 30px;
  }

  .heading {
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    background-color: white;
  }
}

.activity_card_container {
  display: flex;
  flex-flow: row wrap;
  background-color: #ffffff;
  justify-content: flex-start;

  .activity_card {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    width: 390px;
    height: 148px;
    background-color: #f5f5f5;
    margin: 15px;
    border-radius: 10px;

    .card_left {
      display: flex;
      flex-grow: 1;
      background-color: #f5f5f5;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 10px;
      width: 88px;

      .logo {
        display: flex;
        flex-grow: 8;
        align-items: flex-start;

        img {
          max-width: 80px;
          padding: 10px;
          background-color: #fff;
          margin-left: 10px;
          margin-top: 10px;
          border-radius: 5px;
        }
      }

      .due_date {
        display: flex;
        align-items: center;
        padding-left: 10px;
      }

      .due_time {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 10px;
        flex-grow: 1;
      }
    }

    .card_right {
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      flex-grow: 5;
      border-radius: 10px;
      width: 190px;

      .title_row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .title {
          font-size: large;
          font-weight: bold;
          color: #383838;
          padding-top: 5px;
          width: 90%;
        }

        img {
          padding: 7px;
        }
      }

      .desc {
        flex-grow: 4;
        font-size: 13px;
        color: #919191;
      }

      .stats {
        flex-grow: 3;
        display: flex;
        flex-direction: row;

        .comment {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 10px;

          .count {
            color: #858585;
            padding-left: 5px;
            padding-bottom: 2px;
            font-size: 14px;
          }

          img {
            max-width: 16px;
            -webkit-filter: grayscale(100%);
            /* Chrome, Safari, Opera */
            filter: grayscale(100%);
          }
        }

        .checklist {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 10px;

          .count {
            color: #858585;
            padding-left: 5px;
            padding-bottom: 2px;
            font-size: 14px;
          }

          img {
            max-width: 16px;
            -webkit-filter: grayscale(100%);
            /* Chrome, Safari, Opera */
            filter: grayscale(100%);
          }
        }
      }

      .bottom {
        display: flex;
        flex-grow: 7;
        flex-direction: row;

        .people {
          display: flex;
          align-items: center;
          margin-left: -12px;

          img {
            width: 28px;
            border-radius: 50%;
          }
        }

        label {
          display: flex;
          align-items: center;
        }

        .timer {
          display: flex;
          flex-grow: 1;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-right: 20px;

          .time_taken_image {
            img {
              width: 24px;
              height: 24px;
              padding-right: 5px;
            }
          }

          .time_taken {
            padding-right: 8px;
          }

          .timer_stopped {
            padding: 5px;
            color: #747474;
            font-size: 18px;
          }

          .timer_running {
            padding: 5px;
            color: #747474;
            font-size: 18px;

            timer {
              span {
                font-size: 24px;
                font-weight: bold;
              }
            }
          }

          .timer-button {
            border-radius: 50%;
            height: 36px;

            &.start {
              background-color: #2b96ee;
            }

            img {
              max-width: 36px;
            }

            img:focus {
              border: none;
              outline: none;
            }
            &.stop {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.panel-body {
  word-wrap: break-all;
  text-overflow: ellipsis;
}

.create_activity-parent {
  height: inherit !important;
}
.create_activity {
  max-width: 700px !important;
  width: 700px !important;
  min-height: 365px;
  max-height: none !important;
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  padding: 0 10px;
  max-height: none;
  overflow-y: inherit !important;

  .selectdemoSelectHeader md-select-menu {
    margin: 75px 0 0 0 !important;
  }
}
.create_activity_dialog {
  width: 640px;
  height: 380px;
  padding: 15px;
}

.completed_duration {
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
}

.activity_duration {
  margin-top: 50px;
}

.create_activity_btn:disabled {
  background: #2a98f0;
  color: #ffffff;
}

button.md-fab.md-button.md-ink-ripple {
  background: #2a98f0;
}

button.md-fab.md-button.md-ink-ripple:hover {
  background: #2a98f0;
}
/*My Activity*/
.alert-p {
  font-size: 16px;
}
.my_activity_filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  md-input-container {
    padding-right: 25px;
  }

  .reset_filter {
    width: 90px;
    height: 20px;
    background-color: $primary_gtd !important;
    margin: 20px;
  }
}

md-progress-circular {
  margin: 0 auto;
}

.progress_circular {
  display: block;
  width: 1200px;
  margin: 0 auto;
  top: 100px;
}

.md-primary.md-raised.timesheet_description_btn.md-button.md-ink-ripple {
  background-color: #2a98f0;
  color: #ffffff;
}

.md-primary.md-raised.timesheet_description_btn.md-button.md-ink-ripple:hover {
  /*background-color: #2A98F0 !important;*/
  color: #ffffff;
}

.md-primary.md-raised.timesheet_description_btn.md-button.md-ink-ripple:disabled {
  background-color: #cccccc;
  color: #ffffff;
}
.timer-description {
  width: calc(100% - 4px);
  min-height: 190px;
  max-height: none !important; // new act CSS
  margin: 4px 0;
  border-radius: 10px;
  background-color: #ffffff;
  overflow-x: hidden !important ;
  div {
    .timesheet-description-dialog {
      div {
        // margin-top: 25px; commented for new activity design
        p.timer-info-msg {
          margin-bottom: 25px;
        }
        .time-spent-yest {
          width: 52%;
        }
      }
    }
  }
  .split-time-container {
    margin-top: 30px;
  }
  &.from-activity-popup-top {
    width: 556px;
    min-height: 190px;
    // max-height: 270px;
    .header-text {
      width: 500px !important;
    }
    div {
      .timesheet-description-dialog {
        div {
          // margin-top: 25px; commented for new activity design
          p.timer-info-msg {
            margin-bottom: 25px;
          }
          .time-spent-yest {
            width: 100%;
          }
        }
      }
    }
    .previous-details .description-details-container .description-content {
      max-width: 410px;
    }
  }

  &::-webkit-scrollbar {
    margin-top: 10px;
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(153, 153, 153, 0.5);
    border-radius: 3px;
    margin-right: 3px;
  }
  .header-wrap {
    width: 97%;
    height: 52px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin: 0 10px;
    .header-text {
      display: flex;
      align-items: center;
      width: 600px;
      //new
      justify-content: space-between;
      width: 640px;
      .header-title-inner {
        display: flex;
        width: 490px;
        align-items: center;
      }
      .timer-activity {
        color: #505050;
        text-align: end;
      }

      span {
        font-family: $mainFont;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #999999;
        margin-left: 10px;
        min-width: 20%;
        max-width: 40%;
      }
      .sb-avatar {
        width: 20px !important;
        height: 20px !important;
        margin-right: 10px;
        span {
          color: $whiteColor;
          margin: 0;
          font-size: 11px;
        }
        .sb-avatar__text {
          width: 100% !important;
          height: 100% !important;
          div {
            font-size: 13.7px !important;
            width: 20px;
            height: 16px;
            font-family: Roboto;
            font-size: 13.7px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $whiteColor;
          }
          border-radius: 4px;
        }
      }
    }
    h2 {
      font-family: $mainFont;
      font-size: 17px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #494949;
      margin: 0;
      width: unset !important;
      max-width: 50%;
    }
    .done-cancel {
      display: flex;
      align-items: center;

      .pop-close {
        margin-left: 20px;
        opacity: 0.7;
      }
    }
  }
  .previous {
    color: #999999;
    padding-left: 21px;
    padding-top: 15px;
    font-family: $mainFont;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .previous-details {
    .description-details-container {
      font-family: $mainFont;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757575;
      margin: 5px 20px 0 0;
      display: flex;
      justify-content: space-between;

      .description-content {
        font-size: 14px;
        font-weight: normal;
        max-width: 570px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        .description-dots {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          border: 1px solid #d6d6d6;
          display: inline-block;
          background: #d6d6d6;
          border-radius: 10px;
          margin: 0 8px 0 0;
        }
      }
      .description-timer {
        font-size: 13px;
        font-weight: 500;
        color: #999999;
      }
    }
  }
}
.timesheet-description-dialog {
  margin: 20px 20px 0px 21px;
  .enter-details {
    font-family: $sideFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #494949;
    width: 100%;
    box-sizing: border-box;

    //  border: none;
    //  border-bottom: 1px solid #e3e3e3;
    &::-webkit-input-placeholder {
      font-family: $sideFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #494949;
      opacity: 0.25;
    }
    border: 0px solid #e3e3e3;
    background: #f8f8f8 !important;
    transition: unset !important;
    border-radius: 6px;
    padding: 10px;
    -webkit-rtl-ordering: none;
    resize: none;
    overflow: auto;
  }
}

.my-activity-create-dialog {
  width: auto;
  height: auto;
  padding: 15px;
  overflow: auto;

  .md-datetime-lose-focus > input {
    border-width: 0 0 1px 0 !important;
    border-style: solid !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
  }

  .md-datetime-lose-focus > label {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .completed_date_range {
    // padding-bottom: 20px;
    margin-bottom: 10px;
    .dat-input {
      border-top: none;
      border-right: none;
      border-left: none;
      border-image: initial;
      background: none;
      box-shadow: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-left: 3px;
    }
    input {
      width: 190px;
    }
    .moment-picker .moment-picker-container {
      width: 17.5em;
    }
  }

  .create_activity_completed_tag {
    margin-top: 8px;
  }

  md-radio-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  md-input-container {
    height: 30px;
    margin-top: 25px;
  }

  md-contact-chips {
    height: 30px;
    margin-top: 25px;
  }

  .md-chips:not(.md-readonly) {
    cursor: text;
    font-size: 13px;
    margin-bottom: 8px;
  }

  md-dialog-actions.layout-row {
    margin-top: 30px;
  }

  md-chips {
    font-size: 15px !important;
  }
}

.my-activity-create-dialog md-radio-group {
  padding-bottom: 10px;
}
/* My Activity - Checklist */

.checklist_dialog {
  width: 400px;
  height: 400px;
  padding-left: 20px;
  padding-top: 5px;

  .checklist {
    display: flex;
    width: 100%;
    padding-bottom: 10px;

    .left {
      flex-direction: column;
      display: flex;
      padding-left: 2px;
      padding-right: 2px;
    }

    .middle {
      flex-grow: 5;

      .edit_title {
        display: inline-block;
        position: relative;
        padding-top: 18px;
        margin: 0;
        vertical-align: middle;
        min-width: 14px;
      }

      .title {
        justify-content: center;
      }
    }

    .required {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 5px;

      .link {
        color: #818181;
        font-weight: bold;
        font-size: 13px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 5px;
    }
  }

  .strike {
    text-decoration: line-through;
    color: #818181;
  }
}
/* My Activity - RequiredType */

.required_type {
  .required_valid {
    width: 350px;
  }

  .required_invalid {
    animation: invalid 0.5s;
    -webkit-animation: invalid 0.5s;
    box-shadow: 0 0 5px 1px #f43841;
  }
}
/* My Activity - Comments */

.comments_dialog_box {
  overflow: hidden;
  width: 400px;
  min-height: 100px;
  padding-top: 5px;
  padding-left: 10px;

  .comment {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 10px;

    .left {
      display: flex;
      padding-left: 5px;
      padding-right: 7px;
      flex-grow: 0;
      flex-shrink: 0;
      height: 54px;

      .profile_pic {
        display: flex;
        max-width: 60px;
        max-height: 60px;
        border-radius: 50%;
      }
    }

    .middle {
      display: flex;
      flex-direction: column;
      flex-grow: 9;

      .profile {
        display: flex;
        flex-direction: row;
        padding-bottom: 5px;

        .name {
          color: #818181;
          font-weight: bold;
        }

        .timestamp {
          padding-left: 4px;
          color: #818181;
        }
      }

      .title {
        font-size: 14px;
      }

      .edit_title {
        display: inline-block;
        position: relative;
        padding: 2px;
        margin: 0;
        vertical-align: middle;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 5px;
      justify-content: center;
    }
  }

  .comment_reply {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 10px;
    padding-left: 20px;

    .left {
      display: flex;
      padding-left: 5px;
      padding-right: 7px;
      flex-grow: 0;
      flex-shrink: 0;
      height: 45px;

      .profile_pic {
        display: flex;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
      }
    }

    .middle {
      display: flex;
      flex-direction: column;
      flex-grow: 6;

      .profile {
        display: flex;
        flex-direction: row;
        padding-bottom: 5px;

        .name {
          color: #818181;
          font-weight: bold;
        }

        .timestamp {
          padding-left: 4px;
          color: #818181;
        }
      }

      .title {
        font-size: 14px;
      }

      .edit_title {
        display: inline-block;
        position: relative;
        padding: 2px;
        margin: 0;
        vertical-align: middle;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 5px;
      justify-content: center;
    }
  }
}

.comment_spacing {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.comments_dialog_bottom {
  width: 400px;
  min-height: 60px;
  flex-direction: row;

  md-input-container {
    margin-bottom: 0;
  }
}
/*Activity Log*/

.activity_log_filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  md-input-container {
    padding-right: 25px;
  }

  .reset_filter {
    width: 90px;
    height: 24px;
    background-color: $primary_gtd !important;
    // margin: 20px;
  }
}

.archived_grouped_view {
  .heading {
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    background-color: white;
  }
}

.activity_log_container {
  display: flex;
  flex-flow: row wrap;
  background-color: #ffffff;
  justify-content: flex-start;
  margin-left: 20px;

  .activity_card {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    width: 390px;
    height: 148px;
    background-color: #f5f5f5;
    margin: 15px;
    border-radius: 10px;

    .card_left {
      display: flex;
      flex-grow: 1;
      background-color: #f5f5f5;
      flex-direction: column;
      justify-content: flex-start;
      border-radius: 10px;
      width: 88px;

      .logo {
        display: flex;
        flex-grow: 8;
        align-items: flex-start;

        img {
          max-width: 80px;
          padding: 10px;
          background-color: #fff;
          margin-left: 10px;
          margin-top: 10px;
          border-radius: 5px;
        }
      }

      .due_date {
        display: flex;
        align-items: center;
        padding-left: 10px;
      }

      .due_time {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 10px;
        flex-grow: 1;
      }
    }

    .card_right {
      display: flex;
      flex-direction: column;
      background-color: #f5f5f5;
      flex-grow: 5;
      border-radius: 10px;
      width: 190px;

      .title_row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .title {
          font-size: large;
          font-weight: bold;
          color: #383838;
          padding-top: 5px;
          width: 90%;
        }

        img {
          padding: 7px;
        }
      }

      .desc {
        flex-grow: 4;
        font-size: 13px;
        color: #919191;
      }

      .stats {
        flex-grow: 3;
        display: flex;
        flex-direction: row;

        .comment {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 10px;

          .count {
            color: #858585;
            padding-left: 5px;
            padding-bottom: 2px;
            font-size: 14px;
          }

          img {
            max-width: 16px;
          }
        }

        .checklist {
          display: flex;
          flex-direction: row;
          align-items: center;

          .count {
            color: #858585;
            padding-left: 5px;
            padding-bottom: 2px;
            font-size: 14px;
          }

          img {
            max-width: 16px;
            -webkit-filter: grayscale(100%);
            /* Chrome, Safari, Opera */
            filter: grayscale(100%);
          }
        }
      }

      .bottom {
        display: flex;
        flex-grow: 6;
        flex-direction: row;

        .people {
          display: flex;
          align-items: center;
          margin-left: -12px;

          img {
            width: 28px;
            border-radius: 50%;
          }
        }

        .completed_image {
          display: flex;
          align-items: center;
          flex-grow: 1;
          justify-content: flex-end;

          img {
            max-width: 24px;
            max-height: 24px;
            align-items: center;
            margin-left: -4px;
          }
        }

        .completed_on {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 1;
          padding-left: 5px;
          .date {
            align-items: center;
          }
          .time {
            align-items: center;
          }
        }

        .time_taken_image {
          display: flex;
          align-items: center;

          img {
            max-width: 24px;
            max-height: 24px;
            align-items: center;
          }
        }

        .time_taken {
          display: flex;
          align-items: center;
          flex-grow: 3;
          padding-left: 5px;
        }

        .restore {
          display: flex;
          flex-grow: 1;
          align-items: center;

          img {
            max-width: 36px;
            max-height: 36px;
            align-items: center;
          }

          img:focus {
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}

.activity-create-container
  .activity-container
  .create_activity_column
  .create_activity_form
  form
  .selectize-input
  .disabled {
  opacity: 0.6;
}

.create-activity-container {
  width: 285px;
  position: fixed;
  right: 24px;
  top: 115px;
  z-index: 9;

  &.show_form {
    right: 24px;
    transition: all 1s ease-in;
  }

  &.hide_form {
    right: -309px !important;
    transition: all 1s ease-out;
  }
}

.create-activity-holder {
  width: 100%;
}

.activity-toggler {
  width: 20px;
  height: 20px;
  right: 20px;
  top: 123px;
  position: fixed;
  cursor: pointer;
  background: url(../img/svg/activity-toggler.svg) no-repeat center center;
  z-index: 10;
  transition: all 0.3s linear;
  &.rotate {
    transform: rotate(-180deg);
    transition: all 0.5s linear;
  }
}

.add-act-heading {
  font: normal 20px/31px $mainFont;
  color: $whiteColor;
  margin-left: 140px;
}

.act-tab-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 25px 0 0 0;
}
.act-tab {
  font: normal 15px/24px $mainFont;
  color: $whiteColor;
  cursor: pointer;
  margin: 0 20px 0 0;
  position: relative;
  &.active {
    font-weight: 500;
    -webkit-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background: $whiteColor;
    }
  }
}
.selectize-control {
  height: 26px;
}

.selectize-control.multi {
  .selectize-input {
    border: 0;
    box-shadow: none;
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;
    padding: 0 2px;
    height: 26px;

    &.has-items {
      padding: 0 2px;
    }
  }
}
.selectize-control.single {
  .selectize-input {
    border: 0;
    box-shadow: none;
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0;
    height: 26px;
    padding: 0 2px;
    width: 100% !important;
    &:after {
      top: 11px;
      right: 0;
    }

    &.dropdown-active {
      background: none;

      // &:after {
      //     top:12px;
      //     right:0px;
      // }
      &:after {
        top: 6px;
        right: 6px;
      }
      // &:before {
      //     height:0px;

      // }
      input {
        height: 19px;
      }
    }

    &.input-active {
      background: none;
      &:after {
        top: 11px;
        right: 0px;
      }
      &.dropdown-active {
        &:after {
          top: 6px;
          right: 6px;
        }
      }
    }

    input,
    .item {
      font: normal 15px $sideFont;
      color: $whiteColor;
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.6);
        font-size: 13px;
      }
    }
  }
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  font: normal 15px $mainFont;
  color: rgba(255, 255, 255, 0.7);
  width: 100% !important;
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}

.selectize-dropdown {
  margin: 2px 0 0 0;
  border-radius: 10px;
  overflow: hidden;
}

.selectize-dropdown-content {
  width: 100%;
  margin: 4px 0;
  max-height: 300px;
}

.selectize-dropdown-content .option {
  color: $panelHeading;
  font: normal 15px/29px $mainFont;
  padding: 0 0 0 15px;
  &.active {
    background: rgba(153, 153, 153, 0.2);
  }
}

.selectize-dropdown-content::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

.selectize-dropdown-content::-webkit-scrollbar-thumb {
  width: 4px;
  background: $topNaviColor;
  border-radius: 2px;
}
.selectize-dropdown.single {
  border: 0;
}

.activity-input-control {
  width: 100%;
  border: 0;
  height: 26px;
  padding: 0 2px;
  box-sizing: border-box;
  border: 0;
  box-shadow: none;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font: normal 15px $mainFont;
  color: $whiteColor;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-weight: normal;
    font-size: 13px;
    font-family: $sideFont;
  }
}

.activity-control-holder {
  width: 100%;
  margin: 30px 0 0 0;
}
.start-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.completedStart {
  width: 100%;
}
.completedEnd {
  width: 100%;
  margin-left: 0;
}
.calender-icon-holder {
  position: absolute;
  top: 0px;
  right: 0;
  width: 16px;
  height: 15px;
}
.duration-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 2px;
}

.activity-title {
  font: normal 13px $mainFont;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  input {
    height: inherit;
  }
  > div > input {
    // padding: 18.5px 36px 18.5px 14px;
    padding:0px 36px 0px 14px;
  }
}

.activity-project,
.activity-act-type {
  input {
    height: inherit;
    padding:0px 36px 0px 14px;
  }
}

.perday {
  position: absolute;
  top: 15px;
  right: 56px;
  font: normal 10px $mainFont;
}
.act-hours-min-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 172px;
}
.act-hm-holder {
  width: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    -moz-appearance: textfield;
  }
}
.act-hm-control {
  width: 61px;
}

input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.activity-btn {
  width: 108px;
  height: 30px;
  border-radius: 10px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: none;
  padding: 0;
  font: normal 15px $mainFont;
  color: rgba(255, 255, 255, 0.7);
  &.clear {
    border: 0;
    width: auto;
    height: auto;
    margin: 0 51px 0 0;
    opacity: 0.5;
    cursor: default;
  }
}
.activity-btn.clear {
  color: #ffffff;
}
.btn-active {
  border: 1px solid $whiteColor;
  color: $whiteColor;
}
.btm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activity-create-holder {
  width: calc(100% - 5px);
  display: flex;
  height: 100%;
  overflow-y: auto;
}

.activity-create-holder::-webkit-scrollbar {
  width: 3px;
  background: transparent;
}

.activity-create-holder::-webkit-scrollbar-thumb {
  width: 3px;
  background: $topNaviColor;
  border-radius: 1px;
}

.archive-edit-wrap {
  display: flex;
}

.star-wrap {
  //margin-left:10px;
  height: 0;
  width: 0;
  transition: width 0.1s linear;
}
.hours_planned {
  position: absolute;
  top: -6px;
  color: rgba(73, 73, 73, 0.5);
  font: normal 10px $mainFont;
}
.wop:hover .star-wrap {
  margin-left: 10px;
  height: 14px;
  width: 14px;
  transition: width 0.1s linear;
}

// .priority .menu-options img {
//   height: 14px;
//   width: 14px;
// }
.priority .star {
  margin-right: 0 !important;
}

.selectize-control.multi .selectize-input [data-value],
.selectize-control.multi .selectize-input [data-value].active {
  background: none;
  box-shadow: none;
  font: normal 15px $mainFont;
  border: 0;
  color: rgba(255, 255, 255, 0.7);
}
.selectize-input.focus.input-active.dropdown-active {
  &::before {
    height: 0;
  }
}
html {
  overflow-y: auto !important;
}
.md-chips .md-chip-input-container:not(:first-child),
.md-chips .md-chip-input-container:not(:first-child) {
  margin: 0 5px 0 0;
}
.md-chips md-chip {
  margin: 0 5px 0 0;
  padding: 0 10px;
  background: $whiteColor;
  line-height: 19px;
  height: 23px;
}
.md-chips {
  padding: 0 0 2px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  &.md-focused {
    box-shadow: none !important;
  }
}
md-chips.md-default-theme .md-chips,
md-chips .md-chips {
  box-shadow: none;
}
.md-chips md-chip .md-chip-remove-container {
  right: 5px;
}

.md-chips md-chip .md-chip-remove {
  width: 15px;
  height: 23px;
}
.md-chips md-chip .md-chip-remove md-icon {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}
.md-chips md-chip .md-chip-content {
  span {
    font: normal 10px $mainFont !important;
    color: $successMsg;
  }
}
.md-chips .md-chip-input-container {
  margin: 0;
  max-width: 80px;
}
.md-chips .md-chip-input-container input {
  height: 23px;
  line-height: 23px;
  font-family: $mainFont;
  &::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6) !important;
    font-weight: normal;
    font-size: 13px;
    font-family: $sideFont;
  }
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6) !important;
    font-weight: normal;
    font-size: 13px;
    font-family: $sideFont;
  }
}
.md-chips .md-chip-input-container md-autocomplete,
.md-chips .md-chip-input-container md-autocomplete-wrap {
  height: 23px;
  &:-moz-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  &::-moz-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}

md-chips.md-default-theme .md-chips .md-chip-input-container input,
md-chips .md-chips .md-chip-input-container input {
  color: $whiteColor;
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  width: 285px;
  margin-top: 2px;
  margin-left: -3px;
  border-radius: 10px;
}
.md-autocomplete-suggestions li {
  overflow: hidden;
  padding: 0 15px;
  line-height: 29px;
  height: 29px;
  color: $panelHeading;
  font: normal 15px/29px $mainFont;
  padding: 0 0 0 15px;
  &:hover {
    background: rgba(153, 153, 153, 0.2);
  }
  span {
    font: normal 15px $mainFont;
  }
}
.md-virtual-repeat-container .md-virtual-repeat-scroller {
  width: calc(100% - 5px);
  margin: 0;
}
.md-virtual-repeat-container .md-virtual-repeat-scroller::-webkit-scrollbar {
  width: 4px;
  background: transparent;
  border: 0;
  margin: 0 4px 0 0;
  box-sizing: border-box;
}

.md-virtual-repeat-container
  .md-virtual-repeat-scroller::-webkit-scrollbar-thumb {
  width: 8px;
  background: $topNaviColor;
  border-radius: 5px;
}

.members {
  label {
    display: none;
  }
  md-input-container {
    margin: 0;
    padding: 0;
    width: 100%;
    md-select {
      color: rgba(255, 255, 255, 0.7);
      height: 31px;
    }
  }
  .md-select-value {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.user-search {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 15px;
  top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectdemoSelectHeader .demo-select-header {
  height: 40px;
}

md-select-menu {
  border-radius: 10px;
}

.selectdemoSelectHeader .demo-header-searchbox {
  padding: 0 0 0 30px !important;
}

md-option .md-text {
  padding: 0 0 0 0;
  margin: 0;
}
md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding: 0 15px;
}
.user-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.user-img-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background: #ccc;
  margin-right: 15px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.user-name {
  font: normal 15px $mainFont;
  color: #808080;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
  right: 15px;
  left: auto;
}
md-select-value {
  .user-img-icon {
    display: none;
  }
  .user-name {
    color: $whiteColor;
  }
  .user-row {
    width: auto;
  }
}

.md-checkbox-enabled.md-default-theme[selected] .md-icon:after,
.md-checkbox-enabled[selected] .md-icon:after {
  border-color: $successMsg;
}
.md-checkbox-enabled[selected] .md-icon {
  background-color: transparent;
  color: transparent;
}
md-select-menu[multiple] md-option.md-checkbox-enabled .md-icon {
  border: 0;
}

.moment-picker .moment-picker-container:after,
.moment-picker .moment-picker-container:before,
.moment-picker .moment-picker-container:after,
.moment-picker .moment-picker-container:after {
  content: none;
}
md-select-value .user-name,
md-select-value .user-row {
  display: inline;
}
md-select.md-default-theme .md-select-value.md-select-placeholder,
md-select .md-select-value.md-select-placeholder {
  color: rgba(255, 255, 255, 0.7);
  font: normal 15px $mainFont;
}

.md-select-value .md-select-icon {
  display: none;
}
md-select:not([disabled]):focus .md-select-value {
  border-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.7);
  border-bottom-width: 1px;
}
md-input-container.md-input-focused:not([md-no-float])
  .md-select-placeholder
  span:first-child {
  transform: none;
  color: rgba(255, 255, 255, 0.7);
}
.moment-picker th,
.moment-picker td {
  border-radius: 10px;
  &:hover {
    background: $calenderHover;
  }
}
.moment-picker td.today {
  background: $blueBg;
  color: $whiteColor;
}
.moment-picker td.selected {
  background: $blueBg;
  color: $whiteColor;
}

.value-selected {
  .moment-picker td.today {
    color: $font_dark;
    background: transparent;
  }
}

.create-activity-holder {
  .moment-picker td.selected {
    background: transparent;
    color: #404040;
    text-shadow: none;
    &.today {
      background: $blueBg;
      color: $whiteColor;
    }
  }

  &.value-selected {
    .moment-picker td.today {
      color: $font_dark;
      background: transparent;
    }

    .moment-picker td.selected {
      background: $blueBg;
      color: $whiteColor;
    }
  }
}
.alert_act {
  width: 200px;
  height: 120px;
  background: #fff;
  position: absolute;
  left: 125px;
  border-radius: 3px;
  border: 1px solid #d6dadc;
  border-bottom-color: #c4c9cc;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 99;
  border-radius: 10px;
  text-align: center;
  .alert_body {
    padding: 10px;
    p {
      font-size: 14px;
      font-family: $sideFont;
      color: #505050;
      margin-bottom: 20px;
    }
    .Got_it {
      font-size: 15px;
      background: #0090e9;
      text-align: center;
      color: #fff;
      border: none;
      border-radius: 10px;
    }
  }
}

.value-added {
  color: $whiteColor;
  opacity: unset !important ;
  cursor: pointer !important;
}
.moment-picker th {
  font: bold 14px $sideFontBold;
  color: $successMsg;
  &:hover {
    background: transparent;
  }
}
.moment-picker .month-view .moment-picker-specific-views th {
  font: bold 12px $sideFontBold;
}
.moment-picker td {
  height: 31px;
  line-height: 31px;
  font: normal 14px $sideFont;
}
.empty_priority {
  display: flex;
  .empty_card {
    width: 300px;
    height: 175px;
    opacity: 0.75;
    border-radius: 10px;
    background-color: #ffffff;
  }
}
.title {
  font-size: 24px;
  margin-left: 20px;
  color: $snow_gtd;
  padding-top: 16px;
  display: flex;
  align-items: center;

  .activity-title {
    font-size: 24px;
    font-family: $mainFont;
    margin: 0 40px 0 0;
  }
  .tab-activity {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-right: 20px;
    font-family: $mainFont;
  }

  .active-tab {
    pointer-events: none;
  }
  .searchActivity {
    border: none;
    border-bottom: solid 1px #d6d6d6;
    background: transparent;
    color: #fff;
    margin-left: 10px;
    font-size: 16px;
    width: 400px;
    padding-left: 10px;
    font-family: $mainFont;
    &::-webkit-input-placeholder {
      color: #fff !important;
      opacity: 0.5;
      font-size: 15px;
      font-family: $mainFont;
    }
  }
}

.contain.actvity-class {
  margin-bottom: 50px;
  .board {
    margin-top: 20px !important;
  }
}
.fixed-activity {
  position: fixed;
  top: 88px;
}

.timer-button.stop {
  cursor: pointer;
  .pause {
    background: url("../img/svg/stop_new.svg");
    height: 35px;
    width: 35px;
    background-size: 100% auto;
    border: 0;
    font-size: 0;
    // margin-right: 12px; // commented for new activity design
  }
}

.timer-button.start {
  .play-btn {
    background: url("../img/svg/play_new.svg");
    height: 35px;
    width: 35px;
    background-size: 100% auto;
    border: 0;
    font-size: 0;
    margin-right: 12px;
  }
}
.rest-activity {
  .act-card {
    width: 300px;
    margin-right: 24px;
    margin-bottom: 24px;
    margin-top: 0px !important;
    padding: 12px 0 1px;
    float: left;
    border-radius: 8px;
    min-height: 175px;
    max-height: 180px;
  }
  .scroll-activity {
    opacity: 0.9;
    display: inline-block;

    &.scrolledClass {
      opacity: 1;
      transition: opacity 0.4s ease-in-out;
      -moz-transition: opacity 0.4s ease-in-out;
      -webkit-transition: opacity 0.4s ease-in-out;
    }
  }
}
.edit-link {
  font: 500 18px $mainFontSemiBold;
  color: #fff;
  cursor: default;
  border: none;
  background-color: transparent;
  width: 120px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  float: right;
  span {
    width: 39px;
    height: 21px;
    line-height: normal;
    letter-spacing: normal;
  }
}
.edit_active {
  cursor: pointer;
  background-color: #0090e9;
}
.hoursPlanned {
  text-align: center;
  font-size: 18px;
  -moz-appearance: textfield;
}
.demo-header-searchbox {
  &::-webkit-input-placeholder {
    opacity: 0.5;
  }
}
.selectdemoSelectHeader {
  z-index: 999;
  top: 245px !important;
  &.edit-activity {
    top: 106px !important;
  }
  &.af-member {
    top: 300px !important;
  }
}
.mem-completed-dropdown {
  .selectdemoSelectHeader {
    top: 185px !important;
    &.edit-activity {
      top: 106px !important;
    }
    &.af-member {
      top: 300px !important;
    }
  }
}
.activity-title-container {
  .act_title {
    margin-left: 0;
  }
}
.act-title {
  font-size: 17px;
  margin-left: 12px;
  font: normal 17px $sideFont;
  color: #757575;
  width: 249px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.priority-class {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .act-card {
    margin-top: 0;
  }
}

.empty-card-clue {
  width: 300px;
  height: 175px;
  margin-left: 0;
  border-radius: 10px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 24px;
  position: relative;
  cursor: pointer;
  &:nth-child(3) {
    margin-right: 0;
  }
  &:hover {
    .add-activity-clue-card {
      display: block;
    }
  }
  .card-content {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    span {
      color: #fff;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  .card-content-3 {
    display: flex;
    position: absolute;
    height: 38px;
    left: 32%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    span {
      color: #fff;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
}

.progress_circular_description {
  margin: 0 10px 0 0;
}
.create_activity {
  .re-title-right-area {
    justify-content: space-around;
  }
  .popup-close {
    margin-left: 10px;
  }
  .header-wrap {
    .header-text {
      font: normal 17px roboto !important;
    }
  }
}
.activity-loading-holder {
  padding-left: 10px;
  md-progress-circular path {
    stroke: rgb(255, 255, 255);
  }
}
.empty-project-holder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.6);
  font-family: $sideFont;
}

.empty-projects {
  border: 0;
  box-shadow: none;
  background: none;
  border-radius: 0;
  height: 26px;
  padding: 0 2px;
  width: 285px;
  color: rgba(255, 255, 255, 0.6);
  font-family: $sideFont;
}

.access-check {
  cursor: pointer;
}

.greyOut {
  cursor: default;
}

.rest-wrapper {
  max-width: 1080px;
  min-width: 900px;
  .infinite-scroll-component {
    overflow: inherit !important;
  }
}

.start-end {
  .moment-picker:nth-child(2) .moment-picker-container {
    right: 0;
    margin-left: auto;
    margin-right: -0.5em;
  }
}

.custom_moment {
  .moment-picker .moment-picker-container {
    top: auto;
    bottom: 100%;
    margin-top: auto;
    margin-bottom: 4px;
  }
}
.people-wrap {
  display: flex;
}
.scroll_display {
  display: none;
}

.timer-activity {
  flex-grow: 0;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  padding-right: 9px;
  color: #505050;
  font-family: $mainFont;
}
.options-activity {
  display: flex;
}
.priority-class {
  .act-body {
    position: relative;
  }
}
.rest-activity {
  .act-body {
    position: relative;
  }
}
.cursor-styling {
  cursor: auto;
}
md-input-container.md-input-has-value
  .md-select-value
  > span:not(.md-select-icon) {
  transform: translate3d(0, 0, 0);
}
.disable-projects {
  pointer-events: none;
  .cus-autocomp-control {
    &::-webkit-input-placeholder {
      color: rgba(73, 73, 73, 0.2);
    }
  }
}

.activity-container-loader {
  position: absolute;
  height: 38px;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
}
md-toast {
  top: 5px !important;
}
.create-activity-toast {
  width: fit-content !important;
  min-width: 120px;
  right: 0;
  right: -100px !important;
  .md-toast-content {
    width: fit-content !important;
  }
}
.md-toast-content {
  background-color: #fff !important;
  color: #808080 !important;
  border-radius: 10px !important;
  padding: 20px !important;
  height: 66px !important;
  .md-toast-text {
    background: url(../img/svg/archive-successful.svg) no-repeat left center !important;
    display: block;
    padding: 10px 10px 10px 40px !important;
    margin: 0 !important;
    font-family: $mainFont;
  }
  button.md-button {
    &:hover,
    &:focus,
    &:active {
      background: transparent !important;
      span {
        background: transparent !important;
        color: #ffffff !important;
      }
    }
  }
  .md-button {
    color: #0090e9 !important;
    border: 1px solid #0090e9 !important;
    border-radius: 10px !important;
    height: 20px !important;
    background: transparent !important;
    font-weight: 400 !important;
    font-family: $mainFont;
    text-transform: none !important;
    font-size: 15px !important;
    span {
      display: block;
      padding: 6px 20px !important;
    }
    &:hover {
      color: #ffffff !important;
      span {
        background: transparent !important;
        color: #ffffff !important;
      }
      background: transparent !important;
    }
  }
}
.progress_circular_edit {
  margin-right: 20px;
  margin-top: 10px;
}
.activity-column {
  .general-page-search-icon {
    display: flex;
  }
  .general-page-search-box-holder {
    margin-top: -6px;
  }
  .comm-close-search {
    top: 58%;
  }
}
.edit-activity {
  width: 100% !important;
  height: auto;
}
md-checkbox:not([disabled]).md-primary.md-checked .md-icon {
  border-radius: 5px;
  background-color: #0090e9;
}
md-checkbox .md-icon {
  border-radius: 5px;
}
md-checkbox span {
  width: 226px;
  height: 18px;
  font-family: $mainFont;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #494949;
}
.upcoming-activity-date {
  display: flex;
  justify-content: space-between;
}
.checkbox-activity md-checkbox {
  margin-bottom: 0;
  .md-icon {
    // border:1.5px solid #808080 !important;
    border-radius: 4px;
    &:after {
      border-width: 1.5px;
    }
  }
}
.date-margin {
  margin-left: 30px;
}
.time-planned {
  height: 18px;
  opacity: 0.5;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #494949;
  margin-right: 20px;
}
.edit-activity-save {
  margin: 30px 0;
}
.save-loader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 20px 0;
  .greyedOut {
    float: right;
    width: 120px;
    height: 40px;
    object-fit: contain;
    border-radius: 10px;
    font-size: 18px;
    border: none;
    color: #fff;
    cursor: default;
    background-color: #dadada;
    &.client {
      margin-bottom: 0;
    }
  }
  .done {
    background-color: #0090e9;
    cursor: pointer !important;
  }
  .circular-progress {
    margin-right: 10px;
    margin-top: 22px;
  }
}

.save-loader-client {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 1px 0;
  .greyedOut {
    float: right;
    width: 120px;
    height: 40px;
    object-fit: contain;
    border-radius: 10px;
    font-size: 18px;
    border: none;
    color: #fff;
    cursor: default;
    background-color: #dadada;
    &.client {
      margin-bottom: 0;
    }
  }
  .done {
    background-color: #0090e9;
    cursor: pointer !important;
  }
}
// styles for custom auto complete control starts here
.cus-autocomp-holder,
.cus-autocomp-inner-holder {
  width: 100%;
  position: relative;
  // margin-left: 60px;
}
.cus-autocomp-title {
  font: normal 10px $mainFont;
  color: rgba(73, 73, 73, 0.5);
}
.cus-autocomp-control {
  width: 100%;
  height: 28px;
  border: 0;
  border-bottom: 1px solid $lightBorder;
  margin-top: -4px;
  padding: 0;
  font: normal 15px $mainFont;
  color: $successMsg;
  background: transparent;
  &:focus {
    border-bottom: 1px solid $lightBorder;
  }
  &::-webkit-input-placeholder {
    color: rgba(73, 73, 73, 0.35);
  }
  &.cus-autocomp-control-selected {
    pointer-events: none;
  }
}

.proj-ddown-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  > span {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .proj-arrow {
    transform: rotate(180deg);
  }
}

.cus-autocomp-ddown-holder {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  top: calc(100% + 4px);
  z-index: 10;
  background: $whiteColor;
  min-height: 39px;
  overflow: hidden;
  &.no-display {
    display: none;
  }
  &.proj-ddown-holder {
    width: 92%;
    top: auto;
  }
}
.cus-autocomp-ddown-inner-holder {
  width: 100%;
  max-height: 184px;
  overflow: auto;
  margin: 5px 0;
  &::-webkit-scrollbar {
    margin-top: 10px;
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(153, 153, 153, 0.5);
    border-radius: 2px;
  }

  // md-select[aria-expanded="true"] {
  //   .md-select-value .md-select-icon:after {
  //     transform: rotate(180deg);
  //   }
  // }
  // md-select:not([disabled]):focus md-select-value {
  //   color: $whiteColor;
  // }
  // md-select-value .user-name {
  //   font: normal 13px $mainFont;
  // }
  // md-select[disabled] .md-select-value {
  //   opacity: 1;
  //   span:not(.md-select-icon) {
  //     opacity: 0.5;
  //   }
  //   .md-select-icon {
  //     opacity: 0.5;
  //   }
  // }
}

.group-holder {
  overflow-x: hidden;
}

.cus-autocomp-ddown-list {
  width: 100%;
  height: 29px;
  background: $whiteColor;
  padding: 0 15px;
  font: normal 15px/29px $mainFont;
  color: $panelHeading;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: rgba(153, 153, 153, 0.15);
  }
  &.add-option {
    background: rgba(0, 144, 233, 0.15);
  }
}
.add-client-acomp {
  font: 500 13px $sideFontMedium;
  color: $blueBg;
}
.auto-comp-loader {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 0px;
  z-index: 9;
}
.description-save-btn {
  float: right;
  width: 120px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  font-size: 18px;
  border: none;
  color: $whiteColor;
  background-color: $blueBg;
  margin-top: 20px;

  &.disabled {
    background: #dadada;
    cursor: auto;
  }
}

.input-search-activity {
  display: flex;
  height: 31px;
  justify-content: flex-start;
  align-items: center;
  span {
    position: absolute;
    display: flex;
  }
  .searchArchive {
    border: none;
    border-bottom: 1px solid #d6d6d6;
    background: transparent;
    color: #fff;
    margin-left: 10px;
    font-size: 16px;
    width: 400px;
    padding-left: 0px;
    font-family: $mainFont;
    padding-right: 20px;
    &::-webkit-input-placeholder {
      color: #fff !important;
      opacity: 0.5;
      font-size: 15px;
      font-family: $mainFont;
    }
  }
}

.archived-card-container {
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  display: -webkit-flex;
  align-items: center;

  .archived-card {
    width: 300px;
    min-height: 175px;
    height: 175px;
    background-color: whitesmoke;
    margin: 15px 15px 20px 0;
    border-radius: 8px;

    &:last-child {
      padding-bottom: 30px;
    }
    .card-inner {
      margin: 12px;

      .archived-card-header {
        display: flex;

        justify-content: space-between;

        .logo-ppl-wrap {
          display: flex;

          .client-logo {
            display: flex;
            flex-direction: row;
            width: 30px;
            height: 30px;
            margin-right: 12px;
            img {
              max-height: 100%;
              max-width: 100%;
              object-fit: contain;
            }
          }

          .people {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
              width: 22px;
              height: 22px;
              border-radius: 50%;
              margin-right: -10px;
              border: 2px solid #fff;
            }
            .border {
              border: 2px solid #0090e9;
              z-index: 1;
            }
          }

          p {
            font-size: 12px;
            color: #808080;
            font-family: $sideFont;
            margin: 0 0 0 10px;
            line-height: 28px;
          }

          .menu-options {
            margin-right: 15px;
            align-items: right;
            margin-left: 25px;

            img {
              height: 20px;
              width: 4px;
              margin-top: 14px;
            }
          }
        }
      }

      .archive-body {
        display: flex;
        flex-direction: column;
        margin-top: 28px;
        overflow: hidden;
        .act_title.archived-title {
          margin-left: 0 !important;
        }
        .act-title {
          font-size: 17px;
          font: normal 17px $sideFont;
          color: #757575;
          margin-bottom: 3px;
        }

        .proj-title {
          font: normal 14px $sideFont;
          color: #999999;
          width: 249px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
      }

      .archived-date-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 20px;
        .act-due-on-delayed {
          display: flex;
          flex-grow: 1;
          font-size: 12px;
          font-weight: 400;
          color: #ef5350;
          font-family: $sideFont;
        }

        .act-due-on-on-target {
          display: flex;
          flex-grow: 1;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          font-family: $sideFont;
        }

        .timer {
          display: flex;
          flex-direction: row;
          align-items: center;

          .archived-time-activity {
            flex-grow: 0;
            font-size: 17px;
            font-weight: normal;
            font-family: $sideFont;
          }

          .archived-time-completed {
            flex-grow: 0;
            font-size: 17px;
            font-weight: normal;
            font-family: $sideFont;
          }

          .timer-button {
            img {
              flex-grow: 2;
              height: 44px;
              margin-right: 20px;
            }
          }

          .time-taken-image {
            display: flex;
            align-items: center;
            margin-right: 5px;

            img {
              max-width: 24px;
              max-height: 24px;
              align-items: center;
            }
          }

          .restore {
            display: flex;
            flex-grow: 1;
            align-items: center;
            margin-right: 20px;

            img {
              max-width: 33px;
              max-height: 32px;
              align-items: center;
            }

            img:focus {
              border: none;
              outline: none;
            }
          }
        }
      }

      .archived-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        .comment-count-archive {
          display: flex;
          flex-grow: 3;
          align-items: center;
          font-size: 12px;
          font-family: $sideFont;
          color: #999999;
          img {
            width: 15px;
            height: 15px;
            margin-right: 2px;
          }
        }

        .checklist-count-archive {
          display: flex;
          flex-grow: 3;
          align-items: center;
          margin-left: 30px;
          font-size: 12px;
          font-family: $sideFont;
          color: #999999;

          img {
            width: 15px;
            height: 15px;
            margin-right: 2px;
          }
        }

        .like-count-archive {
          display: flex;
          flex-grow: 3;
          align-items: center;
          margin-left: 30px;
          font-size: 12px;
          font-family: $sideFont;
          color: #999999;

          img {
            width: 15px;
            height: 15px;
            margin-right: 2px;
          }
        }

        .attachment-count-archive {
          display: flex;
          flex-grow: 3;
          align-items: center;
          margin-left: 30px;
          font-size: 12px;
          font-family: $sideFont;
          color: #999999;

          img {
            width: 15px;
            height: 15px;
            margin-right: 2px;
          }
        }
      }
    }
  }
}
.recent-activity {
  font-family: roboto;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.rest-wrapper-archived {
  width: 100%;
  height: auto;
  min-width: 1000px;
  min-height: 500px;
}

.wrap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
  padding-right: 19px;
}
.performa {
  display: flex;
  align-items: center;
}
// styles for custom auto complete control ends here
.timer-block {
  .timer-activity {
    padding-right: 0;
  }
}

.container-act {
  .profile-container {
    top: 38px;
  }
}
.activity-card-holder {
  width: 100%;
  height: calc(100% - 70px);
}
.remove-height {
  height: 0;
}

.timer-reset-container {
  padding: 15px 20px 20px 20px;
}
.reset-content {
  margin-bottom: 23px;
  width: 462px;
  height: 46px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #757575;
}
.time-spent-yest {
  padding: 0;
  font-family: $mainFont;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
  //margin-bottom:20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 52%;
  input {
    border-bottom: 1px solid #d6d6d6 !important;
    height: 28px;

    padding: 0;
    font: normal 15px $mainFont;
    border: none;
    width: 40px;
    text-align: center;
  }
}
.first-row-description {
  margin-bottom: 25px;
}
.time-delay-container {
  opacity: 0.5;
  font-family: $mainFont;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #494949;
  input {
    color: #000 !important;
  }
}
.time-spent-on {
}

.workflow-change-ddown-holder {
  width: 140px;
  height: 25px;
  position: relative;
  background: #f7f7f7;
  border-radius: 10px;
  padding: 0 0 0 8px;
  box-sizing: border-box;
  cursor: pointer;
  &.without-arrow {
    &:after {
      content: "";
      position: absolute;
      right: 8px;
      top: 50%;
      width: 10px;
      height: 6px;
      transform: translateY(-50%);
      background: initial;
    }
  }
  &:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    width: 10px;
    height: 6px;
    transform: translateY(-50%);
    background: url(../img/svg/md-select-arrow.svg) no-repeat center center;
  }
  &.clicked {
    &:after {
      transform: rotate(180deg) translateY(3px);
    }
  }
}

.workflow-change-ddown-text {
  width: 110px;
  height: 26px;
  font: normal 12px/26px $mainFont;
  color: $panelHeading;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.workflow-change-ddown {
  list-style-type: none;
  width: 210px;
  // height: 170px;
  min-height: 50px;
  max-height: 170px;
  border-radius: 10px;
  background: $whiteColor;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  top: 27px;
  z-index: 9;
  padding: 10px 0;
  .mCSB_container_wrapper {
    margin: 0 0 0 0;
  }
  .mCSB_container {
    max-width: 100%;
  }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 2px;
  }
  .mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 10px 0 10px 0;
  }
}
.workflow-change-ddown-inner {
  width: 98%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  height: 100%;
  min-height: 30px;
  max-height: 150px;
  &::-webkit-scrollbar {
    width: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 2px;
    background: lighten($topNaviColor, 20%);
    border-radius: 2px;
    cursor: pointer;
  }
  li {
    list-style-type: none;
    margin: 0;
    padding: 0 30px 0 15px;
    // width: 100%;
    height: 30px;
    font: normal 14px/30px $mainFont;
    color: $successMsg;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      background: rgba(153, 153, 153, 0.15);
    }
    &.active {
      background-color: #fff;
      list-style-type: none;
      margin: 0;
      padding: 0 30px 0 15px;
      // width: 100%;
      height: 30px;
      font: normal 14px/30px $mainFont;
      color: $successMsg;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background: rgba(153, 153, 153, 0.15);
      }
      &::after {
        box-sizing: border-box;
        transform: rotate(45deg);
        position: absolute;
        right: 17px;
        top: 8px;
        display: table;
        width: 5px;
        height: 11px;
        border: 2px solid #494949;
        border-top: 0;
        border-left: 0;
        content: "";
      }
    }
  }
}
.workflow-loader {
  width: 223px;
  height: 167px;
  position: absolute;
  left: 0;
  top: 27px;
  z-index: 9;
  z-index: 10;
  display: flex;
  align-items: center;
}
.activity-page {
  .general-page-search-icon {
    margin: 9px 15px 0 0;
  }
}
.star-icon {
  span {
    width: 14px;
    height: 14px;
    display: block;
    cursor: pointer;
  }
  .rest-star-disabled {
    cursor: default;
  }
}
.add-activity-clue-card {
  width: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: normal 12px/14px $mainFont;
  color: $whiteColor;
  display: none;
}
@media screen and (max-width: 1310px) {
  .empty-card-clue .card-content {
    padding: 0 10px;
    font-family: $mainFont;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--brown-grey);
  }
}

@media screen and (max-width: 1210px) {
  .activity-create-container
    .activity-container
    .create-activity-scroll
    .activity-column
    .act-card {
    width: 270px;
  }
  .act_duration {
    width: 242px;
  }
}

@media screen and (max-height: 770px) {
  .activity-control-holder {
    margin: 25px 0 0 0;
  }
}

// .menu-options {
//   .star-icon {
//     display: inline-block;
//   }

//   .option-icon {
//     display: inline-block;
//   }
// }

.empty-card-wrapper {
  .empty-card-clue {
    display: inline-block;
  }
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  margin-right: 5px;
  width: 4px !important;
  background: none !important;
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background: $graphDdownHover !important;
  }
}

.upcoming-activity-date {
  input {
    border: 0;
    width: 100%;
    border-bottom: 1px solid #e3e3e3 !important;
    height: 28px;
    padding: 0;
    font: normal 15px roboto;
  }
}
.create_activity_wrapper {
  .edit-board-look {
    box-sizing: border-box;
  }
}

.Toastify__progress-bar {
  visibility: hidden;
}

.DayPickerInput {
  height: 0px;
  input::placeholder {
    color: $controlLabelColor;
  }
}

// Day Picker Css Starts Here
.DayPickerInput-OverlayWrapper {
  position: relative;
  // display: none;
  // height: 0 !important;
  top: -340px;
  z-index: 99;
  border-radius: 5px;
  color: #404040;
  // min-width: 15em;
  background: #fff;
  // padding: 4px;
  // border: 1px solid #f0f3f4;
  border-radius: 4px;
  // position: absolute;
  // top: 100%;
  // margin-top: 4px;
  margin-left: -0.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075);
  z-index: 5;
  .DayPickerInput-Overlay {
    color: #000;
    border-radius: 10px !important;
    .DayPicker-Caption {
      text-align: center;
    }

    border-radius: 5px;
    .DayPicker-NavButton--prev {
      float: left;
      position: absolute;
      left: 20px;
      width: 15px;
      margin-left: 4px;
      background-image: url("../img/svg/calendar-arrow-left.svg");
    }
    .DayPicker-NavButton--next {
      width: 17px;
      background-image: url("../img/svg/calendar-arrow-right.svg");
    }
  }
}
.dateField {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0;
  font-weight: 100;
  font-size: 10px;
}

.activityTypeBoardDown {
  position: absolute;
  top: 238px !important;
  left: 25px !important;
  .activityTagsInput {
    max-height: 234px !important;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: lighten($topNaviColor, 20%);
      border-radius: 2px;
      cursor: pointer;
    }
  }
  .activity-tags-ui {
    padding: 0;
    margin-bottom: 0;
    li {
      overflow: hidden;
      height: 29px;
      color: #757575;
      font: normal 15px/29px roboto;
      padding: 0 0 0 15px;
      cursor: pointer;
      transition: background 0.15s linear;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        background: rgba(153, 153, 153, 0.2);
      }
    }
  }
}
.selectedTagsChip {
  position: absolute;
  top: 210px;
  left: 20px;
  margin: 0 5px 0 0;
  padding: 0 10px;
  /* background: #fff; */
  line-height: 19px;
  height: 23px !important;
  background: #757575 !important;
  &.edit-report {
    top: 208px;
    left: 25px;
  }
  span {
    color: #fff;
    font: normal 10px roboto !important;
  }
  .tag-chip-Input-close-Icon {
    width: 15px;
    height: 15px;
  }
}

// Date picker css

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #4a90e2;
  color: #f0f8ff;
}
.DayPicker-Weekday abbr[title] {
  color: #000;
  font: bold 12px robotoBold;
}
.DayPicker-Day--today {
  background: #0090e9;
  color: #fff !important;
  border-radius: 10px !important;
  &:hover {
    background: #0090e9 !important;
    color: #fff !important;
    border-radius: 10px !important;
  }
}
.DayPicker-Day {
  height: 31px;
  line-height: 31px;
  font: normal 14px roboto;
  text-shadow: 0 0px 0 rgba(255, 255, 255, 0.9);
  padding: 0.5em 1em !important;
  &:not(.DayPicker-Day--today):hover {
    border-radius: 10px !important;
    background-color: #eee9e9cc !important;
  }
}
.DayPicker-Caption > div {
  font: bold 14px robotoBold !important;
  color: #494949;
}

.priotized-card-timer-enabled {
  cursor: auto;
}
.activity-create-container {
  &::-webkit-scrollbar {
    overflow: scroll;
    width: 4px;
    padding-top: 60px;
    height: 20px;
    visibility: hidden;
    transition: visibility 0s ease-in 2s;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    opacity: 0;
    width: 4px;
    border-radius: 2px;
    visibility: hidden;
    opacity: 0 !important;
    transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    background: #999999;
  }
  &:hover {
    &::-webkit-scrollbar {
      visibility: visible;
      overflow: scroll;
      width: 4px;
      padding-top: 60px;
      height: 20px;
    }
    &::-webkit-scrollbar-thumb {
      display: block;
      visibility: visible;
      // opacity: 0.2;
      width: 4px;
      border-radius: 2px;
      opacity: 0.4 !important;
      background: #999999;
    }
  }
}

.activity-description {
  .enter-details {
    font-family: $sideFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #494949;
    width: 100%;
    box-sizing: border-box;

    //  border: none;
    //  border-bottom: 1px solid #e3e3e3;
    &::-webkit-input-placeholder {
      font-family: $sideFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0px;
      color: #494949;
      opacity: 0.25;
    }
    border: 0px solid #e3e3e3;
    background: #f8f8f8 !important;
    transition: unset !important;
    border-radius: 6px;
    padding: 10px;
    -webkit-rtl-ordering: none;
    resize: none;
    overflow: auto;
  }
}
