@import 'variable.scss';

.userView{
    margin-bottom: 30px;
    .drop_list{
       border:1px solid #ccc;
       background-color:#fff;
       margin-top:10px;
       border-top:none;
    .mem_list{

        //border:1px solid #ccc;
        padding: 5px 10px 5px 10px;
        margin:0;
        height: 50px;
        border-top: none;
        width: 350px;

        img{
        border-radius:50%;
        width:30px;
        height:30px;
        }
        p{
          margin-top: 8px;
          display: inline-block;
        }

      }
      }
      .user-block{
      margin-top:20px;
   .people{
     margin-bottom: 5px;
      img{
            border-radius:50%;
            width: 40px;
            height: 40px;
        }
        .creator{
          border: 2px solid #0090e9;
         }
         .user_name{
              margin-left:20px;
            }
            .delete{
                width: 20px;
                height: 20px;
                float: right;
            }
      }
   }
}

.add-member-row {
    width:100%;
    margin:19px 0 0 0;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    flex-wrap: wrap;
    &.inside-activity {
        margin: 0;
    }
}
.special-user {
    border:2px solid $blueBg;
}