@import "variable.scss";

.main-container {
  width: 100%;
  .profile-header-wrap {
    height: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    .right-flow-profile {
      display: flex;
      .back-arrow-container {
        width: 200px;
        padding: 42px 0 42px 25px;
        background-color: rgba(255, 255, 255, 0.15);
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 16px;
          margin-right: 10px;
          margin-top: 3px;
        }
        .back-button-profile {
          font-family: roboto;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
        }
      }
      .profile-title {
        padding: 42px 0 42px 30px;
        font-family: roboto;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
      }
    }
  }
  .settings-container {
    background: #fff;
    .settings-tab {
      width: 100%;
      height: 83px;
      border-bottom: 1px solid $brdrColor;
      .inner-tabs {
        width: 100%;
        height: 83px;
      }
    }
  }
}

.settings-tab-container {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.ppl-settings-indv-control-holder {
  width: 357px;
  font: normal 15px roboto;
  color: #494949;
}
.status-control-holder {
  width: 157px;
}
.job-title-control-holder {
  width: fit-content;
}
.settings-tab-holder {
  padding: 0 0 0 30px;
  margin: 0;
  li {
    float: left;
    list-style: none;
    font: normal 18px/90px $mainFont;
    color: #757575;
    background: transparent;
    width: 150px;
    height: 83px;
    margin-right: 20px;
    text-align: center;
    position: relative;
    &::after {
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0px;
      background: transparent;
      border-radius: 2px;
    }
    & active {
      font-weight: 500;
      color: #0090e9 !important;
    }
  }
}

button[disabled].done-link-button {
  cursor: auto;
  color: $controlLabelColor;
}
