.days-container {
  display: flex;
  .days-ws-settings {
    width: 30px;
    height: 30px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    padding-top: 3px;
    margin-right: 10px;
    &.selectedDay {
      background: #f0f0f0;
      background: #018fe9;
      color: white;
      border: none;
      padding-top: 4px;
    }
  }
}
