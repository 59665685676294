$primary_gtd: #2fb6fc; //blue
$danger_gtd :#ff0000; //red
$success_gtd: #00ab30; //green
$warning_gtd: #ffa729;
$info_gtd: #dfeef6;
$font_light: #626464;
$font_dark: #404040;
$font_date_normal: #909090;
$sidekik_gtd: #f8f8fb;
$snow_gtd: #ffffff;
$border_box_gtd: #ebebeb;
$green_gtd: #50932f;
$yello_gtd: #ffb20d;
$tag_bg:#f7f6f6;
$tag_close:#7d7c7c;
$sidebar_head: #13223c;
$sidebar_bg: #293b5a;
$sidebar_hover: #01dad7;
$transparent: rgba(255, 255, 255, 0);
$notify_border: #1b2941;
$font_notify_grey: #818997;
$brdrColor:#d6d6d6;
$topNaviColor:#999;
$mainFont:roboto;
$sideFont:roboto;
$sideFontLight:robotoLight;
$sideFontMedium:robotoMedium;
$sideFontBold:robotoBold;
$controlLabelColor:#aeaeae;
$blueBg:#0090e9;
$whiteColor:#fff;
$panelHeading:#757575;
$successMsg:#494949;
$calenderHover:#f0f0f0;
$mainFontSemiBold:robotoMedium;
$attachmentColor:#808080;
$attachmentBorder:#e8e8e8;
$attachmentHeading:#505050;
$source-light:roboto;
$attachmentBg:#f9f9f9;
$attachmentNotifi:#8b8b8b;
$attachmentError:#ef5350;
$lightBorder:#e3e3e3;
$mdLabel:rgba(73,73,73,0.5);
$reTxtarea:#f8f8f8;
$graphDdownHover:#999999;
$wlUserColor:#868686;
$billingPlanTxtColor:#303030;
$settingsHover : #edf8ff;
$settingsGrey : #8c8c8c;
$settingsDisable : #e2e2e2;
$tagsBg : #f4f4f4;
$scrollBarColor:rgba(153, 153, 153, 0.5);
$successColor:#3edfb5;
$filterCtrlBg:rgba(255,255,255,0.2);
$settingsBlue : #018fe9;
$btnDisable : #dadada;
$actPopupTopBg : #F8F8F8;
$commentBtnDisable : #c0c0c0;
@import "mixin.scss";
