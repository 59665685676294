@import "variable.scss";

.billing-tab {
  height: calc(100% - 190px);
  // overflow-y: scroll;
  // max-height: 75vh;
  .ScrollbarsCustom-Wrapper {
    width: inherit;
  }
  // &::-webkit-scrollbar {
  //   margin-top: 10px;
  //   width: 4px;
  //   background: transparent;
  // }

  // &::-webkit-scrollbar-thumb {
  //   width: 4px;
  //   background: rgba(153, 153, 153, 0.5);
  //   border-radius: 2px;
  // }
}
.pb-tab {
  font: normal 15px $mainFont;
  color: $whiteColor;
  position: relative;
  float: left;
  margin: 8px 20px 0 0;
  cursor: pointer;
  &.active {
    font-weight: 600;
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: $whiteColor;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }
}
.billing-pm-container {
  width: 420px;
  &.confirmation {
    width: 520px;
    .billing-form-inner-holder {
      width: 480px;
    }
  }
  .billing-form-holder {
    width: 100%;
  }
}
.billing-pg-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.billing-bcrumb-holder {
  width: 675px;
  padding: 32px 0 44px;
  margin: 0 auto;
}
.billing-bcrumb-circle-container {
  width: 625px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.billing-bcrumb-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid $brdrColor;
  color: $controlLabelColor;
  font: bold 13px/29px $sideFontBold;
  text-align: center;
  position: relative;
  &:before {
    content: "";
    width: 268px;
    height: 1px;
    background: $brdrColor;
    position: absolute;
    top: 50%;
    left: 29px;
    transform: translateY(-50%);
  }
  &:after {
    content: "";
    width: 0px;
    height: 1px;
    background: $blueBg;
    position: absolute;
    top: 50%;
    left: 29px;
    transform: translateY(-50%);
  }
  &.active {
    border: 1px solid $blueBg;
    color: $blueBg;
    background-color: $whiteColor;
    &:after {
      width: 268px;
      transition: width 0.5s linear;
    }
  }
  &:nth-child(3) {
    &:before {
      content: none;
    }
    &.active {
      &:after {
        content: none;
      }
    }
  }
}
.billing-bcrumb-txt-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 14px auto 0;
}
.billing-bcrumb-txt {
  font: normal 14px/16px $mainFont;
  color: $controlLabelColor;
  text-align: center;
  padding-left: 12px;
  &.active {
    color: $blueBg;
    background-color: transparent;
    font: 500 14px/16px $mainFontSemiBold;
  }
  &:nth-child(2) {
    margin-left: -25px;
  }
  &:nth-child(3) {
    margin-right: 19px;
  }
}
.billing-form-container {
  width: 100%;
}
.biling-pp-container {
  width: 420px;
  margin: 0 auto;
  &.payment {
    min-height: 531px;
  }
  &.confirmation {
    width: 520px;
    min-height: 0;
  }
}
.billing-plan-overview {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: $calenderHover;
  font: normal 14px/40px $mainFont;
  color: $graphDdownHover;
  padding: 0 17px;
  border-radius: 6px;
  span {
    font: 500 14px/40px $sideFontMedium;
    color: $billingPlanTxtColor;
  }
  &.pm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.bill-type {
  font: bold 16px/40px $mainFont;
  color: $attachmentHeading;
}

.bill-amount {
  font: normal 14px/40px $sideFontBold;
  color: $billingPlanTxtColor;
}
.billing-form-holder {
  width: 100%;
  margin: 24px auto 0;
  position: relative;
  border: 1px solid $attachmentBorder;
  border-radius: 6px;
  min-height: 346px;
}
.billing-form-title {
  position: absolute;
  left: 50%;
  top: -13px;
  transform: translateX(-50%);
  font: normal 16px $mainFont;
  color: $attachmentHeading;
  padding: 0 18px;
  background: $whiteColor;
}
.billing-form-inner-holder {
  margin: 33px 20px 0;
  // width: 480px;
}
.billing-form-row {
  width: 100%;
  margin-bottom: 19px;
  &.control {
    margin-bottom: 16px;
  }
  &.pm {
    margin-bottom: 0;
  }
  &.pm-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.billing-headng {
  font: normal 14px/16px $mainFont;
  color: $attachmentColor;
  margin-bottom: 5px;
  &.plan {
    font: normal 14px/24px $mainFont;
    color: $billingPlanTxtColor;
    margin-bottom: 5px;
  }
  &.benefits {
    font-size: 16px;
  }
}
.bil-payingfor-cont-holder {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.bil-pf-left-part {
  font: normal 16px $mainFont;
  color: $attachmentHeading;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-right: 8px;
  span {
    font: normal 20px $sideFontMedium;
    color: $attachmentHeading;
    margin-right: 5px;
  }
}
.bil-pf-right-part {
  font: italic normal 10px $sideFontLight;
  color: $attachmentHeading;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  span {
    font-style: normal;
    margin-right: 8px;
    height: 14px;
    width: 1px;
    background: $attachmentBorder;
  }
  &.note {
    margin-top: 6px;
  }
}
.bil-plan-custom-control-holder {
  width: 180px;
}
.flex-row-sb-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-row-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan-custom-control {
  width: 100%;
  height: 55px;
  background: $attachmentBg;
  border: 1px solid $attachmentBorder;
  border-radius: 6px;
  padding-left: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &.active {
    border: 1px solid $blueBg;
    background: $whiteColor;
    .plan-custom-control-checkbox {
      background: $blueBg;
      border: 0;
      &:after {
        content: "";
        left: 4px;
        top: 4px;
        background: $whiteColor;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        position: absolute;
      }
    }
  }
}
.plan-custom-control-checkbox {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid $attachmentBorder;
  background: $whiteColor;
  margin-right: 10px;
  position: relative;
}
.plan-custom-control-text {
  font: bold 18px $sideFontBold;
  color: $billingPlanTxtColor;
}
.bill-plan-shortnote {
  font: normal 12px/18px $sideFontLight;
  color: $attachmentHeading;
  span {
    font: 500 12px/18px $sideFontMedium;
  }
}
.billing-btn {
  width: 136px;
  height: 36px;
  border-radius: 10px;
  background: $blueBg;
  color: $whiteColor;
  box-shadow: none;
  border: 0;
  font: 500 15px $mainFont;
  &:disabled {
    background-color: #c0c0c0;
  }
  &.confirm {
    width: 98px;
  }
  &.save {
    width: 73px;
  }
  &.cancel {
    display: inline;
    width: auto;
    background: none;
    color: $blueBg;
    margin: 0 20px 0 0;
    &:hover {
      background-color: white;
    }
  }
  &.retry {
    width: 76px;
  }
  &.renew {
    width: 182px;
    display: block;
    margin: 15px auto 0;
  }
  &.reactivate {
    width: 148px;
    display: block;
    margin: 15px auto 0;
  }
}
.billing-benefits-container {
  position: relative;
  left: 104%;
  top: -343px;
}
.billing-benefits-holder {
  margin: 20px 0 0 0;
  width: 280px;
  li {
    list-style-type: none;
    font: normal 14px/20px $mainFont;
    color: $topNaviColor;
    margin: 0 0 12px 0;
    position: relative;
    padding-left: 10px;
    &:before {
      content: "";
      width: 4px;
      height: 4px;
      border: 1px solid $controlLabelColor;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 8px;
    }
    a {
      color: $blueBg;
      font-size: inherit;
    }
  }
}
.bill-faq-container {
  // width: calc(100% - 48px);
  // margin: 20px auto 0;
  margin: 20px auto;
  padding: 11px 0 20px 0;
  &.active {
    // border-top: 1px solid $attachmentBorder;
    background-color: transparent;
  }
}
.bill-faq-heading {
  width: 235px;
  font: 300 16px/19px robotoLight;
  color: #505050;
  padding: 0 12px 15px 20px;
  position: relative;
  cursor: pointer;
  &.billing-history {
    width: 115px;
    margin: 13px 0 15px 0;
    padding-left: 0;
  }
  &:after {
    content: "";
    background: url(../img/svg/faq-arrow.svg) no-repeat center center;
    transform: rotate(180deg);
    width: 9px;
    height: 5px;
    z-index: 1;
    position: absolute;
    left: calc(100% - 10px);
    top: 8px;
    transition: transform 0.4s linear;
  }
  &.active {
    background-color: transparent;
    &:after {
      transform: rotate(0deg);
    }
  }
}
.bill-faq-qa-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fafafa;
  padding: 0 20px 20px 20px;
}
.bill-faq-qa-holder {
  width: 31%;
  margin: 25px 3.5% 0 0;
  &:nth-child(3n + 3) {
    margin-right: 0;
  }
}
.bill-faq-question {
  font: bold 14px/16px $sideFontBold;
  margin-bottom: 7px;
  color: $attachmentColor;
}
.bill-faq-answer {
  font: normal 13px/18px $mainFont;
  color: $attachmentHeading;
}
.billing-pm-control {
  width: 100%;
  height: 37px;
  border: 0;
  border-bottom: 1px solid $brdrColor;
  color: $attachmentHeading;
  font: normal 15px $mainFont;
  &:focus {
    border-bottom: 1px solid $brdrColor;
  }
  &::-webkit-input-placeholder {
    color: $controlLabelColor;
  }
}
.billing-err-holder {
  width: 100%;
  min-height: 22px;
  color: $attachmentError;
  font: normal 13px/16px $mainFont;
}
.billing-stripe-holder {
  width: 100%;
  padding: 9px 0 0 0;
  height: 37px;
  border-bottom: 1px solid $brdrColor;
  .ElementsApp input {
    &::-webkit-input-placeholder {
      color: $controlLabelColor;
    }
  }
}
.billing-small-pm-control-holder {
  width: 180px;
}
.billing-select-control-holder {
  width: 100%;
  height: 37px;
  border: 0;
  border-bottom: 1px solid $brdrColor;
  color: $attachmentHeading;
  position: relative;
  &::after {
    cursor: pointer;
    content: "";
    transform: rotate(180deg);
    width: 9px;
    height: 5px;
    z-index: 1;
    position: absolute;
    left: calc(100% - 20px);
    top: 16px;
    transition: transform 0.4s linear;
    background: url(../img/svg/faq-arrow.svg) no-repeat center center;
  }
}
.billing-select-ddown-holder {
  width: 100%;
  height: 150px;
  background: $whiteColor;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 40px;
  overflow: auto;
  z-index: 2;
}
.billing-select-ddown-list {
  width: 100%;
  height: 29px;
  padding: 0 15px;
  font: normal 15px/29px $mainFont;
  color: $panelHeading;
  cursor: pointer;
  &:hover {
    background: rgba(153, 153, 153, 0.15);
  }
}
.billing-selected-country {
  font: normal 15px/37px $mainFont;
  color: $attachmentHeading;
  cursor: pointer;
  &.placeholder {
    color: $controlLabelColor;
  }
}
.bill-pm-shortnote {
  font: normal 10px/13px $mainFont;
  color: $graphDdownHover;
  padding-bottom: 24px;
}
.billing-plan-for-container {
  width: 100%;
}
.billing-plan-for {
  font: normal 13px/15px $mainFont;
  color: $attachmentColor;
  margin-bottom: 7px;
}
.bill-plan-info {
  width: 100%;
  height: 64px;
  border-radius: 6px;
  border: 1px solid $successColor;
  display: flex;
  justify-content: center;
  align-items: center;
  &.failure {
    border: 1px solid $attachmentError;
  }
  &.renew {
    border: 0px;
    background: $calenderHover;
    color: $graphDdownHover;
  }
}
.bill-plan-info-inner {
  font: 300 15px $sideFontLight;
  color: $billingPlanTxtColor;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bill-plan-info-icon {
  width: 20px;
  height: 20px;
  margin: 0 12px 0 0;
}
.bill-payment-failure-msg {
  font: normal 14px/20px $sideFontLight;
  color: $billingPlanTxtColor;
  width: 295px;
  margin: -20px auto 10px;
  text-align: center;
}
.bill-thanks-subscribe {
  font: normal 14px/16px $sideFontLight;
  color: $billingPlanTxtColor;
  text-align: center;
  margin-bottom: 9px;
  &.annual {
    line-height: 20px;
  }
  .switch-month-msg {
    margin-top: 4px;
  }
  span {
    font: normal 14px/16px $mainFont;
  }
}
.bill-switch-plan-container {
  width: 100%;
}
.bill-plan-renew-container {
  width: 315px;
  margin: -15px auto 0;
  .bill-thanks-subscribe {
    line-height: 20px;
  }
}
.bill-switch-plan {
  font: 500 13px/16px $sideFontMedium;
  color: $blueBg;
  text-align: center;
  cursor: pointer;
  &.sm {
    margin: 24px auto 0;
    width: 150px;
  }
}
.bill-cnf-details-container {
  width: 100%;
  margin: 23px 0 27px 0;
}
.bill-cnf-details-row {
  width: 100%;
  margin: 0 0 34px 0;
  &:nth-child(2) {
    margin: 0;
  }
}
.bill-cnf-heading {
  font: 500 14px/16px $sideFontMedium;
  color: $attachmentColor;
  margin: 0 0 20px 0;
  &.edit-mode {
    margin: 0 0 7px 0;
  }
  &.details-edit-mode {
    margin: 0 0 7px 0;
  }
}
.bill-cnf-edit-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.address {
    align-items: flex-start;
  }
}
.bill-cnf-edit-left {
  width: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bill-cnf-card-icon {
  width: 30px;
  height: 23px;
  margin: 0 12px 0 0;
}
.bill-cnf-card-dots-container {
  width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bill-cnf-card-dots-holder {
  width: 30px;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bill-cnf-card-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $attachmentHeading;
}
.bill-cnf-card-ldigit {
  font: normal 15px $mainFont;
  color: $attachmentHeading;
}
.bill-cnf-address {
  font: normal 15px/20px $mainFont;
  color: $attachmentHeading;
}
.bill-cancel-subscription {
  width: 100%;
  font: normal 12px/26px $mainFont;
  color: $controlLabelColor;
  border-bottom: 1px solid $attachmentBorder;
  cursor: pointer;
  text-align: right;
  padding-right: 5px;
}
.bill-history-container {
  width: 100%;
  margin: 0 0 24px 0;
}
.bill-history-row {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid $attachmentBorder;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0 0 0;
}
.bill-history-date {
  font: normal 13px/15px $sideFontMedium;
  color: $attachmentHeading;
  width: 115px;
}
.bill-history-card {
  width: 212px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bill-history-icon {
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
}
.bill-history-number {
  font: normal 13px/15px $mainFont;
  color: $attachmentHeading;
  margin: 0 5px 0 0;
}
.billing-btn-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bill-cnf-deatails-edit-container {
  width: 100%;
  .billing-small-pm-control-holder {
    width: 230px;
  }
}

//styles by praveen starts here
//switch to annual plan
.switch-plan {
  width: 440px;
  height: 283px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .switch-plan-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
      height: 20px;
      font-family: $mainFont;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #505050;
      margin: 0;
    }
    img {
      cursor: pointer;
    }
  }
  .switch-before {
    p {
      margin: 0 0 13px 0;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #808080;
    }
  }
  .switch-users-price {
    display: flex;
    justify-content: space-between;
    width: 400px;
    height: 40px;
    border-radius: 6px;
    background-color: #f4f4f4;
    padding: 12px;
    margin-bottom: 13px;
    .users {
      height: 16px;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #606060;
      margin: 0;
      span {
        font-weight: 600;
      }
    }
    .price-tag {
      height: 19px;
      font-family: $mainFont;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #303030;
      font-weight: 600;
    }
  }
  .charges {
    height: 40px;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #808080;
    margin-bottom: 24px;
  }
  .switch-button {
    text-align: right;
    .cancel {
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0090e9;
      background: none;
      border: none;
      margin-right: 20px;
    }
    .switch {
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      border-radius: 10px;
      background-color: #0090e9;
      border: none;
      width: 86px;
      height: 36px;
    }
  }
}

// cancel subscription

.cancel-plan {
  width: 430px;
  height: 172px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 18px 0;
    p {
      height: 20px;
      font-family: $mainFont;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #505050;
    }
    img {
      cursor: pointer;
    }
  }
  .downgrade {
    height: 40px;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #808080;
    margin: 0 0 16px 0;
  }
  .features {
    margin: 0 0 18px 0;
    li {
      list-style-type: none;
      font: normal 14px/20px $mainFont;
      color: #303030;
      margin: 0 0 12px 0;
      position: relative;
      padding-left: 10px;
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border: 1px solid #505050;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }
  .resume-switch {
    display: flex;
    justify-content: space-between;
    .resume-plan {
      border: none;
      width: 168px;
      height: 36px;
      border-radius: 10px;
      background-color: #0090e9;
      color: #ffffff;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .switch-free {
      border: none;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0090e9;
      background: none;
    }
  }
}

//Subscription suspended

.subscription-suspended-superadmin {
  width: 430px;
  height: 261px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 18px 0;
    p {
      height: 20px;
      font-family: $mainFont;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #505050;
    }
  }
  .suspended-content {
    width: 389px;
    height: 40px;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #808080;
    margin: 0 0 14px 0;
  }
  .workspace-block {
    display: flex;
    margin: 0 0 20px 0;
    .workspace-img {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      text-align: center;
      background-position: center;
      margin-right: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .workspace-details {
      margin-top: 10px;
      .workspace-name {
        height: 20px;
        font-family: $mainFont;
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #868686;
        margin: 0 0 5px 0;
      }
      .workspace-activities {
        height: 16px;
        font-family: $mainFont;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #aeaeae;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .switch-to-free {
      height: 14px;
      font-family: $mainFont;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #aeaeae;
      margin: 0 0 0 0;
    }
    .subscribe {
      width: 108px;
      border: none;
      height: 36px;
      border-radius: 10px;
      background-color: #0090e9;
      color: #ffffff;
    }
  }
}

//Subscription suspended user

.subscription-suspended-user {
  width: 430px;
  height: 281px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 18px 0;
    p {
      height: 20px;
      font-family: $mainFont;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #505050;
    }
  }
  .suspended-content {
    width: 389px;
    height: 40px;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #808080;
    margin: 0 0 14px 0;
  }
  .workspace-block {
    display: flex;
    margin: 0 0 18px 0;
    .workspace-img {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      text-align: center;
      background-position: center;
      margin-right: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .workspace-details {
      margin-top: 10px;
      .workspace-name {
        height: 20px;
        font-family: $mainFont;
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #868686;
        margin: 0 0 5px 0;
      }
      .workspace-activities {
        height: 16px;
        font-family: $mainFont;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #aeaeae;
      }
    }
  }
  .footer {
    width: 390px;
    border-top: 1px solid #e7e7e7;
    display: flex;
    padding-top: 17px;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #0090e9;
        margin-right: 10px;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
      .user-details {
        .user-name {
          font-family: $mainFont;
          color: #505050;
          margin-bottom: 0px;
          font-size: 14px;
          font-weight: 600;
        }
        .user-role {
          font-family: $mainFont;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #808080;
          margin: 0;
        }
      }
    }
    .right {
      button {
        width: 139px;
        height: 36px;
        border-radius: 10px;
        background-color: #0090e9;
        color: #ffffff;
        font-family: $mainFont;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        border: none;
      }
    }
  }
}

//Remove user
.overlay-billing {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  z-index: 99;
}
.switch-to-free-plan {
  width: 700px;
  border-radius: 10px;
  background-color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  .header {
    padding: 20px 10px 16px 10px;
    width: 680px;
    border-bottom: 2px solid #aeaeae33;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0 0 16px 10px;
    .header-para {
      margin: 0 0 0 0;
      font-family: $mainFont;
      font-size: 17px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #494949;
    }
    .header-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .done {
        margin-right: 20px;
        font-family: $mainFont;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #aeaeae;
        margin-bottom: 0;
      }
    }
  }
  .retain {
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #808080;
    padding: 0px 0px 0 20px;
    margin: 0 0 17px 0px;
  }
  .body {
    padding: 20px;
    background-color: #f9f9f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .superadmin {
      p {
        font: normal 15px $mainFont;
        color: #757575;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      .user-row-ddown {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 10px 0 0 0;
        overflow: hidden;
        .user-img-holder {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          position: relative;
          margin: 0 10px 10px 0;
          img {
            max-width: 31px;
            max-height: 31px;
            border-radius: 50%;
          }
          .workspace-superadmin {
            border: 2px solid #0090e9;
          }
          .user-img-delete {
            width: 13px;
            height: 13px;
            position: absolute;
            right: 0px;
            top: -7px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.user-remove-switch {
  width: 278px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  padding: 10px 14px;
  position: relative;
  z-index: 945;
  top: 10px;
  left: 0;
  .header-remove {
    margin: 0 0 10px 0;
    p {
      margin: 0;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #505050;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(117, 117, 117, 0.3);
    }
  }
  .sure {
    margin-bottom: 10px;
    p {
      margin: 0;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #808080;
    }
  }
  .user-remove {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .left {
      margin-right: 10px;
      width: 35px;
      height: 35px;
      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
      }
    }
    .right {
      .user-name {
        font-family: $mainFont;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #505050;
        margin: 5px 0 0 0;
      }
      .user-role {
        font-family: $mainFont;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #808080;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cancel {
      border: none;
      background: none;
      margin-right: 20px;
      font-size: 15px;
      font-family: $mainFont;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0090e9;
    }
    .remove {
      width: 95px;
      height: 30px;
      object-fit: contain;
      border-radius: 10px;
      background-color: #0090e9;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      border: none;
    }
  }
}

.bill-plan-info-fail {
  width: 100%;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #ffa930;
  display: flex;
  justify-content: center;
  align-items: center;
}

//styles by praveen ends here

@media screen and (max-height: 750px) {
  .billing-select-ddown-holder {
    top: -155px;
  }
}

.no-subscription-warning {
  width: 100%;
  height: 100px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
  padding: 26px 40px 0 30px;
  background: #ff0500;
  font: 500 20px/24px $sideFontMedium;
  color: $whiteColor;
  display: flex;
  justify-content: flex-start;
  .view-plans-btn {
    width: 158px;
    height: 50px;
    font: 500 20px/50px $sideFontMedium;
    background: $whiteColor;
    color: #ff0500;
    border: none;
    text-align: center;
    border-radius: 10px;
    margin-left: 40px;
    &:hover {
      background: $whiteColor;
    }
  }
  .view-plans-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 12px;
    height: 12px;
    z-index: 9;
    display: flex;
    cursor: pointer;
  }
}

.biling-pp-container.step1 {
  .billing-form-holder {
    height: 346px;
  }
}
