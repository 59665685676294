/*Project*/
@import "variable.scss";
@import "clients.scss";

.project-header {
  list-style: none;
  padding: 0 0 0 20px;
  margin-bottom: 17px;

  li {
    font-size: 16px;
    color: $snow_gtd;
    padding: 0 0 0 30px;

    &:nth-child(1) {
      padding-left: 0;
    }
  }
}

.gtd-breadcrumb {
  list-style: none;
  font-size: 24px;
  color: $snow_gtd;
  padding: 21px 0 33px 20px;

  li {
    display: inline;
    text-transform: uppercase;
    background-image: url("../img/svg/breadcrumb.svg");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 32px;

    &:last-child {
      background-image: none;
    }

    a {
      color: $snow_gtd;
      text-decoration: none;
    }
  }

  active {
    color: $snow_gtd;
  }
}
.card-pointer {
  pointer-events: none;
}

.admin {
  width: 100%;
  height: 100%;
}

.image {
  cursor: pointer;
}

.projects-container {
  @extend .gtd_client_container;
  background-size: cover !important;
  background-size: cover !important;
  padding: 0 0 0 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  .project-scroll {
    width: 100%;
    overflow: auto;
    height: calc(100% - 186px);

    .cards-container {
      padding-left: 0;

      .project-cards {
        background-color: #ffffff;

        .title_text {
          padding: 15px 0 31px;
          font-weight: 300;
          //cursor: initial;

          .md-title {
            font-size: 18px;
            color: $font_light;
            text-transform: none;
          }
        }
        .title_pointer {
          padding: 15px 0 31px;
          font-weight: 300;
          cursor: pointer;

          .md-title {
            font-size: 18px;
            color: $font_light;
            text-transform: none;
          }
        }
        .project-edit {
          margin-right: 10px;
        }
      }

      .card-content {
        text-align: left;
        font-size: 12px;
        color: #aeaeae;
        .project-hours-planned {
          color: #aeaeae;
        }
        .img-container {
          width: 100px;
          margin: 0;
          height: 50px;
          position: relative;
          background-size: contain;
          background-repeat: no-repeat;
        }

        md-card._md {
          padding: 10px 0 47px;
          overflow: auto;
          width: 248px;
        }

        &:last-child {
          margin-bottom: 10px;
        }
      }

      .progress-bar {
        background-color: $snow_gtd;

        .progress-status {
          padding-top: 12px;

          span {
            font-size: 14px;
            color: #aeaeae;

            &:last-child {
              float: right;
            }
          }
        }
      }

      .md-fab {
        position: absolute;
        @include icon_position(10px, auto, 10px, auto);

        .gtd_icon_menu {
          background-image: url("../img/svg/hamburger_menu.svg");
          @include icon_position(4px, auto, 3px, auto);
          @extend .icon_sm;
        }
      }

      .add-cards-container {
        background-color: rgba(255, 255, 255, 0.2);
        width: 285px;
        display: inline-block;
        padding: 72px 20px;
        margin: 10px;
        position: relative;
        float: left;
        margin-bottom: 40px;
        p {
          margin: 0 0 0 10px;
          color: #fff;
          font-family: $mainFont;
          font-size: 15px;
        }

        .card-content {
          display: flex;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0 0 0 -4px;
        }
      }
    }
  }
}

md-dialog {
  width: 420px;
  border-radius: 10px;
}

.md-dialog-container,
md-dialog {
}

.project-create-form {
  max-height: 100% !important;
  .form-container {
    padding: 30px 20px;
    overflow: auto;
    p {
      font-size: 15px;
      color: #757575;
    }
    .gtd_color_group {
      clear: both;

      p {
        font-size: 14px;
        color: $font_light;
      }

      .form-group {
        margin-bottom: 15px;
        padding: 0 10px 0 0;
      }

      .gtd_form_color {
        float: left;
        width: 18%;

        .gtd_form-control {
          width: 100%;
          border-radius: 0;
          padding: 9px 12px;
          border: 1px solid $border_box_gtd;
        }

        .low {
          border-right: 4px solid $success_gtd;
        }

        .normal {
          border-right: 4px solid $yello_gtd;
        }

        .critical {
          border-right: 4px solid $danger_gtd;
        }
      }
    }
    .superadmin-members {
      clear: both;
      overflow: hidden;
      .user-img-holder {
        float: left;
      }
    }
  }
  .md-button.md-raised:not([disabled]) {
    background: #0090e9 !important;
  }
}

.create_project_dialog {
  width: auto;
  height: auto;
  padding: 0;

  p {
    font-size: 15px;
    color: $font_light;
  }

  md-input-container {
    height: 30px;
  }

  md-dialog-actions {
    display: flex;
    justify-content: flex-end;
  }
}

.tag-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.autocomplete-template {
  .left-panel {
    float: left;

    img {
      width: 48px;
      height: 48px;
      vertical-align: middle;
    }
  }

  .right-panel {
    float: left;
    margin-left: 5px;
  }

  span {
    padding-right: 5px;

    &:first-child {
      font-size: 16px;
    }

    &:nth-child(2) {
      font-size: 14px;
      color: gray;
    }

    &:last-child {
      display: block;
      font-size: 14px;
      font-style: italic;
    }
  }
}

.projectmodal {
  tags-input {
    display: block;

    *,
    :after,
    :before {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    .host {
      position: relative;
      margin-top: 5px;
      margin-bottom: 5px;
      height: auto;

      &:active {
        outline: 0;
      }
    }

    .tags {
      -moz-appearance: none;
      -webkit-appearance: none;
      padding: 1px;
      overflow: hidden;
      word-wrap: break-word;
      cursor: text;
      border: 0;
      height: 100%;
      min-width: auto;
      max-width: none;
      background: transparent;
      box-shadow: none;
      input {
        padding: 12px;
        border: none;
        background: url("../img/svg/user-add.svg") no-repeat;
        width: 35% !important;
        cursor: pointer;
        margin: 0 !important;
        height: 40px;
        &:focus {
          background: none;
          cursor: text;
        }

        &:active {
          background: none;
        }
      }

      .tag-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      .tag-item {
        margin: 2px;
        padding: 8px 12px;
        display: inline-block;
        float: left;
        font-size: 14px;
        line-height: 25px;
        margin: 0 10px 10px 0;
        border: 1px solid $border_box_gtd;
        background-color: $tag_bg;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        font-size: 0;
        position: relative;

        &.selected {
          background: -webkit-linear-gradient(
            top,
            #febbbb 0,
            #fe9090 45%,
            #ff5c5c 100%
          );
          background: linear-gradient(
            to bottom,
            #febbbb 0,
            #fe9090 45%,
            #ff5c5c 100%
          );
        }

        .remove-button {
          margin: 0 0 0 5px;
          padding: 0;
          border: none;
          background: 0 0;
          cursor: pointer;
          vertical-align: middle;
          font: 100 14px Arial, sans-serif;
          color: $tag_close;
          text-decoration: none;
          position: absolute;
          top: -6px;
          right: -4px;
        }
      }

      .input.invalid-tag,
      .tag-item .remove-button:active {
        color: $danger_gtd;
      }

      .input {
        outline: 0;
        margin: 2px;
        float: left;
        font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;

        &::-ms-clear {
          display: none;
        }
      }
    }
    .focused {
      input {
        color: #757575;
        border-bottom: 1px solid #808088;
        padding: 0 0 5px 0;
      }
    }

    &.ng-invalid .tags {
      -webkit-box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
      -moz-box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
      box-shadow: 0 0 3px 1px rgba(255, 0, 0, 0.6);
    }

    &[disabled] {
      .host:focus {
        outline: 0;
      }

      .tags {
        background-color: #eee;
        cursor: default;

        .tag-item {
          opacity: 0.65;
          background: -webkit-linear-gradient(
            top,
            #f0f9ff 0,
            rgba(203, 235, 255, 0.75) 47%,
            rgba(161, 219, 255, 0.62) 100%
          );
          background: linear-gradient(
            to bottom,
            #f0f9ff 0,
            rgba(203, 235, 255, 0.75) 47%,
            rgba(161, 219, 255, 0.62) 100%
          );

          .remove-button {
            cursor: pointer;

            &:active {
              color: #585858;
            }
          }
        }

        .input {
          background-color: #eee;
          cursor: default;
        }
      }
    }

    .autocomplete {
      margin-top: 5px;
      position: absolute;
      padding: 5px 0;
      z-index: 999;
      width: 100%;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

      .suggestion-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        max-height: 280px;
        overflow-y: auto;
        position: relative;
      }

      .suggestion-item {
        padding: 5px 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #000;
        background-color: #fff;

        &.selected {
          color: #fff;
          background-color: #0097cf;

          em {
            color: #fff;
            background-color: #0097cf;
          }
        }

        em {
          font: normal 700 16px "Helvetica Neue", Helvetica, Arial, sans-serif;
          color: #000;
          background-color: #fff;
        }
      }
    }
  }
}
// project reports css

.project-status-container {
  //background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../img/svg/background.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

  .report_body {
    background-color: #ffffff;
    height: calc(100% - 235px);
    width: 98%;
    margin: 0 auto;
  }

  h2 {
    margin: 0;
    padding: 20px 0 18px 20px;
    color: $snow_gtd;
    text-transform: uppercase;
    font-size: 22px;
  }
  .alert {
    outline: 0;
    width: 98%;
    margin: 0 auto;
    border-radius: 0;
    margin-bottom: 10px;
  }
  .report-header {
    .project_name {
      margin: 0;
      color: $snow_gtd;
      //text-transform: uppercase;
      font-size: 18px;
    }
  }
  .report_body {
    .charts {
      h3 {
        font-size: 20px;
        text-align: left;
        color: $font_dark;
      }
    }
    .bar_chart {
      h3 {
        font-size: 20px;
        text-align: left;
        margin-left: 20px;
        color: $font_dark;
      }
    }
  }
}

.admin.project-admin {
  font-family: roboto;
  .add-cards-container {
    width: 300px !important;
    height: 115px !important;
    border-radius: 10px;
    margin: 0 !important;
    padding: 0 !important;
    .card-content {
      margin: 49px 0;
    }
  }
  .project-cards {
    margin: 0 24px 24px 0;
    width: 300px;
    float: left;
    position: relative;
    padding: 25px 12px;
    border-radius: 10px;
    height: 115px;
    .menu_options {
      position: absolute;
      top: 10px;
      right: 0;
      width: 21%;
      display: inline-block;
      .project-arch {
        margin-right: 10px;
      }
    }
    .card-content {
      overflow: hidden;
      clear: both;
      .img-container {
        float: left;
        width: 65px;
        margin-right: 12px;
        padding: 10px;
        background-size: 75% auto;
        height: 65px;
        background-position: center;
        .sb-avatar--text {
          .sb-avatar__text {
            width: 45px !important;
            height: 45px !important;
            border-radius: 5px !important;
          }
        }
      }
      .project-desc-right {
        float: left;
        width: calc(100% - 77px);
        .title_text {
          padding: 4px 0 5px 0;
          .md-title {
            font-size: 17px;
            color: #868686;
          }
        }
        .progress-bar {
          .progress-status {
            padding-top: 5px;
          }
        }
      }
    }
  }
}

.project-percentage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    font-size: 13px;
    color: #555;
  }
  .project-progressbar {
    width: 100%;
    margin-left: 5px;
    > div > div {
      background-color: #3edfb5;
    }
    &.more-time {
      > div > div {
        background-color: #ef5350;
      }
    }
  }
  md-progress-linear {
    margin: 8px 0 0 5px;
  }
}
/*Popup*/
.project-popup {
  width: 700px !important;
  max-width: 700px;
  max-height: 100%;
  form {
    .md-toolbar-tools {
      width: 100%;
      margin: 0 auto;
      border-bottom: 1px solid rgba(174, 174, 174, 0.25);
      padding: 0;
      height: auto;
      h2 {
        font-size: 17px;
      }
      .proj-title {
        padding-bottom: 15px;
        padding-left: 10px;
      }
      .new-project-name {
        width: 100%;
      }
      .proj-right-pop {
        line-height: 1;
        img {
          margin: -4px 0px 0 0;
        }
      }
      .done-btn {
        float: left;

        .md-button:not([disabled]).md-fab.md-focused,
        .md-button:not([disabled]).md-raised.md-focused {
          box-shadow: none;
        }
        .md-button {
          box-shadow: none;
          background: none;
          text-transform: none;
          &.md-focused {
            box-shadow: none;
            background: none;
          }
        }
        md-dialog-actions {
          min-height: auto;
          padding: 0 20px 0 0;
        }
        span {
          background: none !important;
        }
        .md-ripple-container {
          display: none;
          background: none !important;
        }
        button {
          border: 0;
          background-color: transparent !important;
          padding: 0;
          color: #0090e9 !important;
          font-size: 15px;
          font-weight: 600;
          box-shadow: none;
          min-height: auto;
          line-height: initial;
          min-width: auto;
          span {
            border: 0;
            background: transparent;
            padding: 0;
            color: $blueBg;
            font: 500 15px $mainFont;
            text-transform: none;
          }
          .md-ripple-container {
            background: none !important;
          }
        }
      }
    }
    .form-container {
      padding: 0;
      overflow: visible;
      .create_project_dialog {
      }
      .edit-all-user-container {
        min-height: auto;
      }
    }
    .form-container {
      background: #f9f9f9;
      padding: 20px 20px 10px 20px;
      &.projects {
        padding-top: 0;
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
.proj-poup-right {
  .md-input-focused {
    input {
      border-bottom: 1px solid rgba(174, 174, 174, 0.25) !important;
    }
  }
  label {
    color: rgba(73, 73, 73, 0.35) !important;
    font-weight: 400;
    font-size: 15px;
    margin: 0;
  }
  input {
    &::-webkit-input-placeholder {
      color: rgba(73, 73, 73, 0.35);
    }
  }
  md-input-container {
    margin: 0px 45px 0 0;
    width: 125px;
    display: block;
    padding: 0;
    float: left;
    .md-errors-spacer {
    }
    input {
      font-size: 15px;
      border-color: rgba(174, 174, 174, 0.25);
      &::-webkit-input-placeholder {
        color: red !important;
        font-size: 20px;
      }
    }
  }
  .proj-name {
    margin: 0;
    width: 100%;
    input {
      width: 100%;
      height: auto;
      font-size: 15px;
      border-color: rgba(174, 174, 174, 0.25);
    }
  }
}
.form-wrapper {
  padding: 15px 10px 5px;
  .project-top-pop {
    padding: 25px 15px 0px 15px;
    clear: both;
    .project-pop-image {
      float: left;
      width: 100px;
      margin-right: 25px;
      height: 100px;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
    .proj-poup-right {
      float: left;
      width: calc(100% - 125px);
      // margin-top: 15px;
      .md-input-focused {
        label {
          color: rgba(73, 73, 73, 0.5);
        }
        .md-input {
          color: #494949;
        }
      }
    }
  }
  .project-description {
    font-family: roboto;
    md-input-container.proj-type {
      width: 100%;
      margin: 0;
    }
    textarea {
      resize: none;
      background: #f8f8f8;
      width: 100%;
      min-height: 76px;
      border: none;
      border-radius: 6px;
      padding: 10px;
      &::-webkit-input-placeholder {
        color: rgba(73, 73, 73, 0.35);
      }
    }
  }
  .project-description {
    clear: both;
    width: 100%;
    margin-bottom: 0;
    label {
      padding-left: 10px !important;
      padding-top: 5px;
      color: rgba(73, 73, 73, 0.35) !important;
      font-weight: 400;
      font-size: 14px;
      margin: 0;
    }
  }
}

.projects-container {
  .project-wrapper {
    padding: 38px 24px 40px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .project-wrapper-inner {
      clear: both;
      overflow: hidden;

      .common-filter {
        float: right;
        h1 {
          padding: 0;
          float: left;
        }
      }
    }
    .project-client {
      padding: 0px 0 0px 0;
      float: left;
      display: flex;
    }
  }
  .admin.project-admin {
    padding: 0 24px;
  }
  .gtd-breadcrumb {
    padding: 0;
    float: left;
    margin-bottom: 0;
    width: 100px;
    margin-right: 45px;
    li {
      a {
        font-size: 24px;
        text-transform: capitalize;
      }
    }
  }
  .project-header {
    padding: 0;
    clear: both;
  }
  md-switch {
    margin: 0;
    float: left;
  }
}

md-progress-linear {
  height: 2px;
  .md-container {
    height: 2px;
    background: #f0f0f0 !important;
  }

  .md-bar {
    background-color: #3edfb5 !important;
  }
}
md-progress-linear.md-warn {
  .md-bar2 {
    background-color: #ef5350 !important;
  }
}
.cli-progress-bar {
  .perce {
    color: #aeaeae;
  }
}

.project-client {
  .tab_activity {
    float: left;
    font-size: 14px;
    color: #ffffff;
    padding: 4px 0px 4px 0;
    margin-right: 5px;
    height: 30px;
    width: 60px;
    text-align: center;
    margin-top: 4px;
    &:last-child {
      margin-right: 0;
    }
  }
  .last-child {
    margin-right: 24px;
    width: 77px;
  }
  .active_tab {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    pointer-events: none;
    padding: 4px;
    border-radius: 13px;
    height: 27px;
  }
  .serachicon {
    padding: 5px 15px 5px 25px;
  }
  .searchActivity {
    margin: 5px 0 0 0;
    &::-webkit-input-placeholder {
      color: #fff !important;
      opacity: 0.5;
      font-size: 15px;
      font-family: $mainFont;
    }
  }
  .gtd-breadcrumb {
    li {
      // padding-right: 45px;
    }
  }
}

.proj-poup-right {
  .input-group-addon {
    display: none;
  }
  .moment-picker .moment-picker-input {
    box-shadow: none;
    background: none;
    &:focus {
      border-color: rgba(174, 174, 174, 0.25);
    }
  }

  md-input-container .md-input {
    padding: 0 2px;
    background: none;
    border-top: 0;
    box-shadow: none;
    border-color: rgba(174, 174, 174, 0.25) !important;
    -moz-appearance: textfield;
  }
}
.padd-all-zero {
  padding: 0;
}

md-dialog.project-popup {
  max-height: none;
  overflow: visible;
  form {
    overflow: visible;
  }
}

.project-pop-image {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .update_img {
    position: absolute;
    display: none;
    height: 100%;
    width: 100%;
    background: rgba(0, 144, 233, 0.9);
    transition: ease-in 10s;
    border-radius: 10px;
    color: #ffffff;
    padding: 40px 12px;
  }
  &:hover .update_img {
    transition: all 0.3s linear;
    display: block;
    cursor: pointer;
  }
}

.project-desc-right {
  .progress_status {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: -5px 0 0 0;
    > span {
      font-size: 13px;
      color: #555;
    }
  }
  .project-dashboard-title {
    font: normal 17px roboto;
    color: #868686;
  }
}
.project-dashboard-main-card {
  height: calc(100% - 120px);
  // .projects-height {
  //   height: 688px;
  //   .project-land-scroll {
  //     width: 100%;
  //   }
  // }
}
.project-dashboard {
  padding: 39px 1px 0 30px;
  height: 100%;

  h6 {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    max-width: 60%;
    margin: 0 0 16px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }

  .tags-block {
    margin-bottom: 15px;
  }
}

.project-dashboard-cards-wrapper {
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  display: -webkit-flex;
  align-items: center;
}
.project-dash-card {
  width: 300px;
  height: 115px;
  background: #ffffff;
  margin-right: 25px;
  border-radius: 10px;
  margin-bottom: 25px;
}
.project-dashboard-card {
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  display: -webkit-flex;
  align-items: center;
  padding-top: 10px;
  cursor: pointer;
  //width: max-content;
  .img-container {
    height: 65px;
    width: 65px;
    padding: 10px;
    margin-right: 12px;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position: center;
    > div {
      height: 45px !important;
      width: 45px !important;
    }
    .sb-avatar--text {
      .sb-avatar__text {
        width: 45px !important;
        height: 45px !important;
        border-radius: 5px;
        > div > span > span {
          font-size: 20.6px;
          font-family: roboto;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }

    .project-desc-right {
      width: calc(100% - 77px);
      .project-dasboard-title {
        font-size: 17px;
        color: #868686;
      }
      .project-hours-planned {
      }
      .progress_bar {
        color: #aeaeae;
      }
    }
  }
  .project-cards-dash {
    padding: 20px;
    position: relative;
    .menu_options {
      position: absolute;
      top: 10px;
      right: 0;
      //width: 19%;
      display: inline-block;
      .project-edit {
        margin-left: 10px;
      }
      .options-block {
        width: 153px;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
        background-color: #ffffff;
        position: fixed;
        padding: 10px 0px;
        z-index: 999;
        div {
          font-family: roboto;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0px;
          color: #999999;
          height: 30px;
          padding: 5px 10px;
          &:hover {
            background: rgba(153, 153, 153, 0.5);
          }
        }
      }
    }
  }
}

.project-cards-dash {
  padding: 20px;
  position: relative;
  .menu_options {
    position: absolute;
    top: 10px;
    right: 0;
    //width: 19%;
    display: inline-block;
    .project-edit {
      margin-left: 10px;
    }
    .options-block {
      min-width: 153px;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
      background-color: #ffffff;
      position: absolute;
      padding: 9px 0px;
      z-index: 999;
      .proj-menu-option {
        font-family: roboto;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #494949;
        height: 30px;
        padding: 5px 10px;
        cursor: pointer;
        &:hover {
          background: rgba(153, 153, 153, 0.15);
        }
      }
      .deleteflow-box {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 5px;
      }
    }
  }
}
.dot-holder-pd {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  cursor: pointer;
  &:hover {
    background: rgb(240, 240, 240);
  }
}

.unarchive-landing {
  margin-left: 22px;
}

.project-land-scroll {
  > div:first-child {
    height: inherit;
  }
}
