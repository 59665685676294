@import "variable.scss";

.wl-tt-container {
  width: 100%;
  height: 100%;
  background-position: center center;
  padding: 0;
  .contain.actvity-class {
    margin-bottom: 16px;
  }
}
.wl-top-row {
  width: calc(100% - 24px);
  margin: 10px 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.wl-top-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.general-page-heading {
  font: normal 24px/31px $mainFont;
  color: $whiteColor;
  margin: 0 25px 0 0;
}
.general-page-search-holder {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 430px;
  height: 31px;
  &.kanban {
    height: 28px;
    .general-page-search-icon {
      margin-top: 2px;
    }
    .general-page-search-box-holder {
      margin-top: -1px;
    }
  }
}
.general-page-search-icon {
  width: 15px;
  height: 15px;
  margin: 6px 15px 0 0;
  img {
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
}
.general-page-search-box-holder {
  width: 400px;
  position: relative;
}
.general-page-search-box {
  width: 100%;
  font: normal 15px $mainFont;
  color: $whiteColor;
  border: 0;
  border-bottom: 1px solid $whiteColor;
  height: 31px;
  background: none;
  padding-right: 20px;
  &.kanban {
    height: 28px;
  }
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.75);
  }
}
.change-view-container {
  margin: 0 0 0 127px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
}
.change-view-icon-holder {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
.change-view-text {
  font: 500 15px/20px $mainFontSemiBold;
  color: $whiteColor;
}
.view-by-holder {
  margin: 0 0 0 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .graph-ddown-container {
    top: 25px;
    bottom: auto;
    left: 0;
  }
}
.view-by-icon {
  margin: 0 10px 0 0;
  transition: transform 0.3s linear;
  &.open {
    transform: rotate(180deg) translateY(-2px);
  }
}
.view-by-text {
  font: normal 16px/20px $mainFont;
  color: $whiteColor;
  position: relative;
  span {
    font: 500 16px/20px $mainFontSemiBold;
  }
}
.wl-card {
  width: 300px;
  height: 115px;
  margin: 0 24px 24px 0;
  background: $whiteColor;
  border-radius: 10px;
  float: left;
}
.wl-filter-description {
  font: normal 16px/20px $mainFont;
  color: $whiteColor;
  margin: 24px 0 0 24px;
  &.wl-board {
    margin: -4px 0 15px 24px;
  }
}
.wl-tile-container {
  width: calc(100% - 28px);
  height: calc(100% - 230px);
  overflow: auto;
  margin: 15px 0 0 24px;
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: $topNaviColor;
    border-radius: 2px;
  }
  &.list-view {
    .wl-card {
      width: 624px;
      margin-right: 0;
      float: none;
    }
    .wl-user-name-holder {
      width: 520px;
    }
    .wl-user-percent-base {
      width: 100%;
    }
    .wl-user-hours-taken {
      &.top {
        display: block;
        margin: 0;
      }
      &.bottom {
        display: none;
      }
    }
    .wl-hours-dot {
      display: block;
    }
  }
}
.wl-user-details-holder {
  width: 100%;
  height: 115px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 22px;
}
.wl-user-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
.wl-user-name-holder {
  width: 200px;
}
.wl-user-name {
  font: normal 17px/20px $sideFont;
  color: $wlUserColor;
  float: left;
}
.wl-user-percent-holder {
  width: 100%;
  margin: 4px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wl-user-percent-text {
  font: normal 12px/14px $sideFont;
  color: $controlLabelColor;
}
.wl-user-percent-base {
  width: 100%;
  height: 2px;
  border-radius: 1.5px;
  background: $calenderHover;
  position: relative;
}
.wl-user-percent-top {
  position: absolute;
  left: 0;
  top: 0;
  height: 2px;
  border-radius: 1.5px;
}
.wl-user-hours-taken {
  font: normal 12px/14px $sideFont;
  color: $controlLabelColor;
  margin: 5px 0 0 0;
  &.top {
    font: normal 12px/20px $sideFont;
    float: left;
    display: none;
  }
  &.bottom {
    float: right;
    display: block;
  }
}
.wl-hours-dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: $controlLabelColor;
  float: left;
  margin: 10px 5px 0 5px;
  display: none;
}
.gtd_projectAccess {
  background-size: cover !important;
  padding: 0 0 0 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  .project_block {
    &.workload-block {
      width: 100%;
      height: calc(100% - 220px);
      padding: 0 0 0 24px;
      position: relative;
    }
  }
}
.comm-close-search {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  margin: 0;
}
.comm-search-holder {
  width: 400px;
  position: relative;
}
.comm-search-input {
  height: 28px;
}
.wl-filter-description {
  &.not-found {
    font: normal 20px $mainFont;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.user_search {
  display: flex;
  position: relative;
}
.gtd_projectAccess .project_block.workload-block .tags_block.wl {
  margin-left: 0;
}
.workload-view .fc-unthemed tbody {
  border: none !important;
}
.workload-view .fc-unthemed .fc-row {
  border: none !important;
}
@media screen and (max-width: 1450px) {
  .general-page-search-holder {
    width: 300px;
  }
  .general-page-search-box-holder {
    width: 270px;
  }
}
@media screen and (max-width: 1310px) {
  .gtd_projectAccess .project_block.workload-block .tags_block .act_card {
    width: 275px;
  }
  .general-page-search-holder {
    width: 250px;
  }
  .general-page-search-box-holder {
    width: 270px;
  }
}
// workload css
.workload-view {
  background-color: transparent !important;
  .ppl-settings-container {
    width: 100%;
    height: calc(100vh - 175px);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    position: relative;
  }
  .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
    height: 100%;
    padding-bottom: 0;
  }
  .fc {
    height: 100% !important;
  }
  .fc-widget-content {
    height: 100% !important;
  }
  .fc-view-container {
    height: calc(100% - 50px);
    overflow: hidden;
  }
  .mCSB_container_wrapper {
    margin-bottom: 10px;
  }
  .fc-scroller > .fc-day-grid {
    height: 100% !important;
  }
  .fc-basicWeek-view {
    height: 100%;
  }
  .fc-view,
  .fc-view > table {
    height: 100%;
  }
  .fc-day-grid-container {
    height: 100% !important;
  }
  .ppl-settings-control-container {
    width: calc(100% - 280px);
    height: calc(100vh - 122px);
    cursor: pointer;
  }
  .ppl-search-control {
    width: 185px;
    height: 31px;
    border-radius: 10px;
    background-color: rgba(214, 214, 214, 0.2);
    border: none;
    color: $snow_gtd;
    padding-left: 35px;
    &::-webkit-input-placeholder {
      color: #ffffff;
    }
  }
  .fc-row .fc-content-skeleton tbody td {
    border: none;
  }
  .fc-today {
    background-color: transparent;
  }
  .fc-bg {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .ppl-settings-contact-container {
    border: none;
    width: 280px;
    padding-bottom: 0;
    // background-color: rgba(255, 255, 255, 0.05);
  }
  .ppl-search-control-holder {
    width: 240px;
    margin: 20px 0 14px 20px;
  }
  .ppl-settings-search-area-wl {
    //height:102px;
    padding: 1px;
    background-color: rgba(153, 153, 153, 0.1);
    border: none;
    display: block;
  }
  .ppl-details-container {
    width: 100%;
    height: calc(100% - 35px);
    background-color: rgba(255, 255, 255, 0.03);
  }
  .ppl-search-icon-holder {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 10px;
    top: 53%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ppl-details-holder {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 0 0 0 20px;
    height: 60px;
    &.active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .ppl-details-img-holder {
    width: 40px;
    height: 40px;
  }
  .ppl-job-details-holder {
    margin: 0 0 0 20px;
    .ppl-detail-name {
      font: 500 15px/21px robotoMedium;
      margin: 0 0 1px 0;
      color: $snow_gtd;
    }
  }
  .ppl-clear-icon-holder {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 10px;
    top: 49%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 11px;
      height: 11px;
    }
  }
  .fc-toolbar {
    text-align: center;
    margin-bottom: 0 !important;
    height: 50px;
    // background-color: rgba(255, 255, 255, 0.05);
    padding: 12px 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .view-kanban-btn-holder {
    width: 140px;
    margin-left: 15px;
    .view-workload-btn {
      width: 100%;
      white-space: nowrap;
      &:not([disabled]):hover {
        width: 100%;
      }
    }
  }
  .fc-control-holder {
    display: flex;
    align-items: center;
  }
  .fc-head {
    border-left: none !important;
    border-right: none !important;
    .fc-widget-header {
      border-left: none !important;
      border-right: none !important;
    }
  }
  .fc-row {
    thead {
      background-color: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.15);
      font-family: $mainFont;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $snow_gtd;
    }
    tr {
      height: 50px;
      th {
        vertical-align: middle !important;
        border: none;
      }
    }
    .fc-content {
      height: 38px;
      border-radius: 2px;
      border: solid 1px #e3e3e3;
      background-color: $snow_gtd;
      position: relative;
      border: none !important;
      display: flex;
      justify-content: space-between;
      width: 99%;
      align-items: center;
      padding: 0 10px;
      border-radius: 5px;
      box-sizing: border-box;
      .fc-title {
        height: 18px;
        font-family: $mainFont;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #757575;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
      }
      .fc-hrs {
        font-size: 15px;
        color: #aeaeae;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
  }
  .fc-event[href],
  .fc-event.fc-draggable {
    border: none !important;
  }
  .fc-event {
    border: 1px solid $snow_gtd;
    background-color: $snow_gtd;
  }
  .fc-left {
    margin-left: calc(100% - 372px);
    h2 {
      height: 16px;
      font-family: $mainFont;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.34px;
      color: $snow_gtd;
      width: 140px;
    }
  }
  .fc-right {
    margin-left: 40px;
    .fc-today-button {
      width: 71px;
      height: 29px;
      border-radius: 10px;
      border: solid 1px $snow_gtd;
      background: none;
      font-family: $mainFont;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.29px;
      color: $snow_gtd;
      text-transform: capitalize;
    }
    .fc-button-group {
      .fc-next-button {
        width: 25px;
        height: 25px;
        background-color: rgba(189, 189, 189, 0.2);
        margin-left: 10px;
        .fc-icon {
          position: relative;
          top: -0.1em;
          margin: 0 0.2em;
          vertical-align: middle;
          right: 8px;
        }
      }
      .fc-prev-button {
        width: 25px;
        height: 25px;
        background-color: rgba(189, 189, 189, 0.2);
        margin-left: 10px;
        .fc-icon {
          position: relative;
          top: -0.1em;
          margin: 0 0.2em;
          vertical-align: middle;
          right: 5px;
        }
      }
      .fc-state-default {
        background-color: rgba(189, 189, 189, 0.2);
        background-image: none;
        border-radius: 50%;
      }
    }
  }
  .fc-unthemed th,
  .fc-unthemed td,
  .fc-unthemed thead,
  .fc-unthemed tbody,
  .fc-unthemed .fc-divider,
  .fc-unthemed .fc-row,
  .fc-unthemed .fc-content,
  .fc-unthemed .fc-popover {
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}
.workload-view {
  .fc-icon-left-single-arrow:after {
    content: "\02039";
    font-weight: 100;
    font-size: 200%;
    top: -7%;
    color: $snow_gtd;
  }
  .fc-icon-right-single-arrow:after {
    content: "\0203A";
    font-weight: 100;
    font-size: 200%;
    top: -7%;
    color: $snow_gtd;
  }
}
.workload-view .fc-unthemed td {
  border-left: 0px;
}
.fc-unthemed .fc-row {
  border-left: 0px;
  border-right: none !important;
}

.header-workload-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 24px 40px 30px;
  .header-left {
    font-family: $mainFont;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $snow_gtd;
  }
}

.bg-workload {
  padding: 0 0 0 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  background-size: cover !important;
}

.workload-view {
  .month-cal {
    .fc-widget-content {
      height: 25% !important;
      thead {
        border-bottom: none !important;
        background-color: rgba(255, 255, 255, 0) !important;
        tr {
          height: 10px;
        }
        .fc-day-number {
          border-bottom: none;
        }
      }
    }
  }
}

.month-button {
  position: absolute;
  top: 12px;
  left: 300px;
  border-radius: 10px;
  border: solid 1px $snow_gtd;
  background: none;
  width: 65px;
  padding: 5px;
  opacity: 0.5;
  color: $snow_gtd;
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  z-index: 99;
}

.day-button {
  position: absolute;
  top: 13px;
  left: 462px;
  border-radius: 10px;
  width: 60px;
  padding: 5px;
  border: solid 1px $snow_gtd;
  background: none;
  color: $snow_gtd;
  opacity: 0.5;
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  z-index: 99;
}

.week-button {
  position: absolute;
  top: 13px;
  left: 384px;
  border-radius: 10px;
  width: 60px;
  padding: 5px;
  border: solid 1px $snow_gtd;
  background: none;
  color: $snow_gtd;
  opacity: 0.5;
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  z-index: 99;
}

.title-wl-holder {
  background-image: url(../img/svg/board_arrow.svg);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 6px;
  color: $snow_gtd;
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  margin-right: 25px;
  text-align: right;
  padding-right: 21px;
  &.clicked {
    background-image: url(../img/svg/arrow_up.svg);
  }
}

.wl-project-container {
  padding-top: 20px;
  position: relative;
}

.project-list-container-wl {
  width: 250px;
  min-height: 220px !important;
  background: $snow_gtd;
  border-radius: 10px;
  position: absolute;
  top: 41px;
  right: 0;
  height: 75px;
  z-index: 1;
  padding: 10px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  .ScrollbarsCustom {
    // height: 200px !important;
    height: 174px !important;
  }
}

.view-kanban-button {
  position: absolute;
  top: 13px;
  left: 600px;
  border-radius: 10px;
  //width: 60px;
  padding: 5px;
  border: solid 1px $snow_gtd;
  background: none;
  color: $snow_gtd;
  opacity: 0.5;
  font-family: roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  z-index: 99;
  width: 129px;
}

.project-wl-list {
  font-size: 14px;
  padding: 5px 39px 5px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: rgba(153, 153, 153, 0.15);
  }
}

.search-wl-wrap {
  margin-bottom: 5px;
  border: none;
  border-bottom: 1px solid rgba(174, 174, 174, 0.25);
  display: flex;
  align-items: center;
  padding-left: 10px;
  img {
    margin-bottom: 4px;
  }
  input {
    border: none;
    color: #868686;
    font-size: 15px;
    margin-left: 5px;
    padding-bottom: 5px;
    &::-webkit-input-placeholder {
      color: #aeaeae;
    }
    width: 90%;
  }
}

.repeat-wl {
  height: auto;
}

.selected-project-wl {
  background-image: url("../img/svg/tick-black.svg");
  background-repeat: no-repeat, repeat;
  background-position: 220px;
}

.hours-hours {
  font-family: $mainFont;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $snow_gtd;
}

.active-selected {
  background: $snow_gtd;
  color: #000;
  font-weight: 600;
}

.people-search {
  display: flex;
}
.ppl-setting-caleander-header-container {
  // background-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(153, 153, 153, 0.1);
  color: $snow_gtd;
  flex-grow: 8;
  .fc-toolbar-outer-div {
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    .fc-toolbar {
      .wl-selected-date {
        // margin-left: 50%;
        min-width: 150px;
      }
    }
  }
}

.today-button {
  button {
    border-radius: 10px;
    height: 25px;
    width: 71px;
    border: 1px solid $snow_gtd;
    color: $snow_gtd;
    background-color: transparent;
    &:not([disabled]):hover {
      // background-color: transparent !important;
      background: rgba(255, 255, 255, 0.25);
      border: 1px rgba(255, 255, 255, 0.25);
      height: 25px;
    }
  }
}

.wl-day-selector {
  width: 14px;
  height: 14px;
  // background: aquamarine;
  margin-left: 40px;
  margin-right: 30px;
  .DayPickerInput {
    border: none;
    width: 0;
    height: 0;
    z-index: 0;
  }
  .DayPickerInput-OverlayWrapper {
    top: 8px;
    right: 329px;
  }
  img {
    width: 14px;
    height: 14px !important;
    cursor: pointer;
  }
  input {
    // visibility: hidden;
    border: none;
    background: transparent;
    width: 0;
    height: 0;
  }
}

.combo-week-and-day-changer {
  display: flex;
  margin: 0 40px;
  img {
    cursor: pointer;
  }
  .format-selector {
    margin: 0 10px;
    position: relative;

    .title-wl-holder {
      margin-right: 0;
      // position: relative;
      top: 0px;
      background-color: rgba(214, 214, 214, 0.2);
      border-radius: 10px;
      width: 75px;
      font-size: 12px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      background-position-x: 90%;
      background-position-y: 10px;
    }
    > div:not(.title-wl-holder) {
      // position: relative;
      top: -35px;
      left: 65px;
      .project-list-container-wl {
        .ScrollbarsCustom {
          height: 125px !important;
          .ScrollbarsCustom-Wrapper {
            bottom: 0 !important;
          }
        }

        .repeat-wl {
          text-align: left;
          height: 90px;
          .selected-project-wl {
            background-position: 120px;
          }
        }
        width: 140px;
        color: #555;
        &.cal-view-ddown-container-wl {
          min-height: auto !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
          top: 30px;
          left: 0;
        }
      }
    }
  }
}

.caleander-date-days {
  display: flex;
  // background-color: rgba(255, 255, 255, 0.05);
  /* flex-wrap: wrap; */
  justify-content: space-between;
  padding: 19px 0;
  .Week-days {
    height: 14px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    width: calc(100% / 7);
    .week-day-name {
      width: 22px;
      height: 14px;
      font-family: Roboto;
      margin-right: 10px;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      &.selected {
        margin-right: 4px;
      }
    }
    .weeknumber {
      position: relative;
      left: -35px;
      font-weight: bold;
    }
    .week-day-date {
      width: 18px;
      height: 18px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      &.selected {
        background-color: $snow_gtd;
        border-radius: 50%;
        padding: 5px 6.56px;
        color: #494949;
      }
    }
  }
}

.bottom-section.display-caleander {
  display: flex;
  width: 100%;
  .activity-backlog-section {
    width: 180px;
    // background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
    // background-color: rgba(255, 255, 255, 0.1);
    transition: width 0.3s ease-in-out;
    .activity-backlog-section-header {
      padding: 15px;
      height: 44px;
      background-color: rgba(0, 0, 0, 0.15);
      span {
        width: 86px;
        height: 14px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $snow_gtd;
        transition: width 0.6s linear;
      }
      img {
        float: right;
        position: relative;
        top: 3px;
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        -o-transition-property: -o-transform;
        transition-property: transform;
        cursor: pointer;
      }
    }

    .activity-backlog-section-body {
      height: 100%;
      padding: 15px;
      background-color: rgba(153, 153, 153, 0.15);
      .add-activity-button {
        button {
          width: 100%;
          height: 38px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius: 2px;
          border: solid 1px $lightBorder;
          background-color: $snow_gtd;
          transition: width 0.3s ease-in-out;
          span {
            width: 80px;
            height: 18px;
            font-family: Roboto;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #0090e9;
          }
          img {
            width: 9px;
            height: 10px;
            object-fit: contain;
            margin-right: 10px;
            margin-left: -22px;
          }
        }
      }
    }
    &.is-collapsed {
      width: 50px;
      .activity-backlog-section-header {
        span {
          display: none;
          width: 0;
        }
        img {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }
      }
      .activity-backlog-section-body {
        .add-activity-button {
          display: none;
          width: 0;
        }
      }
    }
  }
  .user-workload-outer-div {
    width: 230px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: width 0.3s ease-in-out;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100% - 49px);
    &::-webkit-scrollbar {
      margin-top: 10px;
      width: 4px;
      background: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        width: 4px;
        background: rgba(153, 153, 153, 0.5);
        border-radius: 3px;
        margin-right: 3px;
      }
    }

    .user-workload {
      display: flex;
      padding: 20px;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
      .user-workload-image {
        width: 40px;
        height: 40px;
        > div > div {
          width: 40px !important;
          height: 40px !important;
          > div > span {
            font-size: 16px !important;
            font-weight: 500;
          }
        }

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .user-workload-name {
        position: relative;
        top: 4px;
        width: 68%;
        height: 14px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $snow_gtd;
        margin-left: 15px;
        .first-name {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.selected {
        background-color: rgba(255, 255, 255, 0.25);
      }
    }
    &.is-collapsed {
      width: 100px;
      // height: calc(100% - 38px);
      .user-workload {
        display: block;
        text-align: center;
        &:hover {
          background-color: rgba(255, 255, 255, 0.15);
        }
        // margin-bottom: 15px;
        .user-workload-image {
          margin: 0 auto;
        }
        .user-workload-name {
          margin: 10px 0 0 0;
          top: 0;
          width: auto;
        }
        &.selected {
          background-color: rgba(255, 255, 255, 0.25);
        }
      }
    }
  }
  .workload-main-div {
    width: calc(100% - 280px);
    background: rgba(255, 255, 255, 0.15);
    .fake-grid-div-outer {
      display: flex;
      height: 100%;
      flex-wrap: wrap;
      .fake-grid-div {
        height: 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        width: calc(100% / 7);
        &.month {
          height: calc(100% / 5);
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          span {
            // width: 7px;
            height: 14px;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #ffff;
            float: right;
            position: relative;
            top: 5px;
            right: 5px;
          }
        }
        &.day {
          width: 100%;
        }
      }
    }
    .activity-display-outer-div {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      overflow-y: auto;
      .activity-display-div {
        div.react-draggable {
          border: "solid 1px #e3e3e3";
          cursor: pointer;
          span.activity-name-workload {
            max-width: calc(100% - 42px);
            height: 20px;
            font-family: Roboto;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $successMsg;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          span.activity-hours-workload {
            margin-left: 5px;
            width: 26px;
            height: 18px;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $controlLabelColor;
          }
          span.activity-star-icon {
            position: absolute;
            // top: 10px;
            right: 10px;
            width: 10px;
            height: 15px;
          }
          &.activity-on-time {
            // border-bottom: 2px solid #70b824;
          }
          &.activity-delayed {
            // border-bottom: 2px solid #ef5350;
          }
          .project-wl-status-holder {
            width: 100%;
            height: 3px;
            border-radius: 0 0 2px 2px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 5;
            background: #ef5350;
          }
        }
        &.activity-display-div-month {
          div.react-draggable {
            span.activity-name-workload {
              height: 14px;
              font-family: Roboto;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            }
            span.activity-hours-workload {
              width: 14px;
              height: 14px;
              font-family: Roboto;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            }
            span.activity-star-icon {
              position: absolute;
              top: 3px;
              right: 3px;
            }
          }
        }
      }
    }
  }
}

.hover-activity {
  width: 106px;
  height: 38px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  align-items: center;
  display: flex;
}

.content-holder {
  background-color: #ffffff;
  position: absolute;
  user-select: auto;
  touch-action: none;
  height: 38px;
  display: flex;
  top: 0px;
  left: 0px;

  align-items: center;
  justify-content: left;
  background: rgb(255, 255, 255);

  border-radius: 2px;
  transform: translate(5px, 10px);
  max-width: 9.0072e15px;
  max-height: 9.0072e15px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.content-holder-daily {
  width: 142px;
  padding: 0px 32px;
}

.content-holder-weekely {
  width: 102px;
  padding: 0px 13px;
}

.workLoadRemainingwidth {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.workLoadRemainingwidthWeekely {
  height: 100%;
  width: 100px;
  cursor: pointer;
  float: left;
  padding: 20px;
  position: absolute;
  cursor: pointer;
}

.workload-project-group {
  padding-top: 20px;
  width: 100%;
}

.project0backlog-group-display {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - 125px);

  &::-webkit-scrollbar {
    margin-top: 10px;
    width: 4px;
    background: transparent;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: rgba(153, 153, 153, 0.5);
      border-radius: 3px;
      margin-right: 3px;
    }
  }
}

.workload-project-group-display {
  height: 13px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.backlog-project-activity-display {
  width: 100%;
  height: 38px;
  border-radius: 2px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
}

.backlog-project-activity-display-group {
  padding-top: 6px;
  width: 100%;
}

.workload-project-group-activity {
  padding-top: 5px;
  cursor: pointer;
}
.backlog-project-activity-display-title {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #757575;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 8px;
  display: block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.workload-showActivity {
  // width: 270px;
  width: auto;
  height: 125px;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  z-index: 101;
  position: fixed;
}

.workload-act-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
  .workload-act-wrap {
    padding: 10px 10px 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    .workload-act-title {
      width: 172px;
      height: 20px;
      font-family: Roboto;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #494949;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .workload-activity-star-icon {
      width: 13px;
      height: 13px;
      object-fit: contain;
    }
  }

  > .workload-proj-title {
    width: 120px;
    height: 25px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #999999;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.workload-act-body {
  margin-top: 8px;
  width: 57px;
  height: 23px;
  border-radius: 10px;
  background-color: #f4f4f4;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .workload-act-body-tag {
    width: 37px;
    height: 13px;
    font-family: Roboto;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #494949;
    padding: 6px;
  }
}

.workload-date-time {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  // margin-top: 2px;
  margin-top: 30px; //added for new activity
  align-items: center; //added for new activity
  height: 20px; //added for new activity
  .worload-timer {
    // margin-top: 6px; //commented for new activity design
    color: #999999;
    display: flex; //added for new activity
    align-items: center; //added for new activity
    img {
      margin-right: 5px;
    }
  }
  .worload-date {
    // display: inline-flex;
    // margin-top: 2px;
    display: flex;
    align-items: center;
    margin-left: 30px;
    img {
      margin-right: 5px;
    }
    .workload-time {
      // margin-top: 6px; //commented for new activity design
    }
  }
}

.mainInputElement {
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;

  .workload-main-search-conatiner {
    width: 210px;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    margin-top: 10px;
    cursor: pointer;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 20;
    .add-multi-member-bottom-holder {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid rgba(174, 174, 174, 0.25);
      padding: 10px;
      width: 100%;
      cursor: pointer !important;
      box-sizing: border-box;
      .add-multi-member-cancel {
        margin-right: 20px;
        font: normal 15px roboto;
        color: #0090e9;
      }
      .add-multi-member-confirm {
        width: 74px;
        height: 30px;
        border-radius: 10px;
        background-color: #0090e9;
        border: none;
        color: #fff;
        font: normal 15px roboto;
        &:disabled {
          background-color: #dadada;
        }
      }
    }
    .workload-search-header {
      height: 40px;
      padding-top: 10px;
      .workload-search-header-title {
        padding: 10px;
        width: 58px;
        height: 16px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #494949;
      }
    }
    .workload-search-data-container {
      max-height: 200px;
      overflow-x: hidden;
      padding-top: 5px;
      cursor: pointer;
    }
    .workload-search-data-container::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    .workload-search-data-container::-webkit-scrollbar-thumb {
      width: 4px;
      background: lighten($topNaviColor, 20%);
      border-radius: 2px;
    }
    .hr-line {
      width: 175px;
      height: 1px;
      opacity: 0.25;
      background-color: #aeaeae;
      margin-left: 10px;
    }
  }
}

.workload-search-data-list {
  height: 40px;
  padding: 0 0 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  &:hover {
    background: lighten($topNaviColor, 35%);
  }
}
