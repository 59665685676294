@import "variable.scss";

/*Client*/

.add-cards-container {
  background-color: rgba(255, 255, 255, 0.2);
  width: 248px;
  padding: 51px 20px;
  margin: 10px;
  position: relative;
  float: left;
  margin-bottom: 40px;
}

.cards-wrapper {
  margin-left: 24px;
  width: calc(100% - 24px);
  display: block;
}
.division-workspace {
  padding: 10px 10px 15px 10px;
}
.cards-container-board {
  float: left;
  .boards-cards {
    background-color: #ffffff;
    width: 300px;

    margin: 0 24px 24px 0;
    position: relative;
    border-radius: 8px;
    height: 120px;
    .menu_options {
      display: flex;
      justify-content: flex-end;
      margin-right: 5px;
      height: 18px;
      img {
        width: 14px;
        height: auto;
      }
      .icon-org {
        width: 14px;
        height: 14px;
      }
    }
    .md-title {
      color: #868686;
      text-transform: none;
      font: 400 17px $sideFont;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 177px;
      display: block;
    }
    .activities-num {
      text-align: left;
      font: normal 14px $sideFont;
      color: #aeaeae;
    }
  }
  .card-content {
    text-align: center;
    display: flex;
    padding-top: 12px;
    padding-left: 12px;
    .client_name {
      text-align: left;
      font-weight: 400;
    }
    .img-container {
      width: 45px;
      height: 45px;
      margin-right: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    md-card._md {
      padding: 10px 0 47px;
      overflow: auto;
      width: 248px;
    }
  }
  // .client_edit {
  //     position: absolute;
  //     @include icon_position(10px,auto,10px,auto);
  //     margin: 10px 10px 20px 20px;
  //     .gtd_icon_info {
  //         background-image: url("../images/info.svg");
  //         @include icon_position(18px,auto,3px,auto);
  //         @extend .icon_bg;
  //         right: 28px;
  //         top: 11px;
  //     }
  //     .gtd_icon_menu {
  //         background-image: url("../images/hamburger_menu.svg");
  //         @include icon_position(4px,auto,3px,auto);
  //         @extend .icon_sm;
  //     }
  // }
}

.board-wrap {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 31px;
  .img {
    width: 15px;
    height: 15px;
  }
  .searchActivity {
    border: none;
    border-bottom: solid 1px #d6d6d6;
    background: transparent;
    color: #fff;
    margin-left: 10px;
    font-size: 15px;
    width: 400px;
    padding-left: 0px;
    padding-bottom: 3px;
    font-family: $mainFont;
    &::-webkit-input-placeholder {
      color: #fff !important;
      opacity: 0.5;
      font-size: 15px;
      font-family: $mainFont;
    }
  }
}

.client-scroll {
  height: calc(100% - 230px);
  width: 100%;
  .mCSB_container_wrapper {
    margin-right: 8px !important;
  }
  &.wspace-card-container {
    .mCSB_container_wrapper > .mCSB_container {
      max-width: 100%;
    }
  }
}
.workspace-scroll {
  height: calc(100% - 230px);
  width: 100%;
  .mCSB_container_wrapper {
    margin-right: 8px !important;
  }
  &.wspace-card-container {
    .mCSB_container_wrapper > .mCSB_container {
      max-width: 100%;
    }
  }
}

.edit-dialogue {
  width: 700px !important;
  max-width: 700px;
  max-height: initial;
  overflow: visible;
  border-radius: 10px;
  box-shadow: none;
  md-dialog {
    max-width: 700px;
    width: 700px !important;
  }
}

.create-client-dialog {
  width: 100%;
  height: auto;
  .image {
    margin-left: 10px;
    cursor: pointer;
    width: 90px;
    height: 85px;
  }
  .title {
    width: 93%;
    margin: 10px 0 0 20px;
    height: 45px;
    text-transform: capitalize;
  }
  .members {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px 20px 0px;
  }
  .checkbox {
    float: left;
  }
  .actions {
    width: 100px;
    height: auto;
  }
  .search {
    width: 350px;
    height: 50px;
    border: 1px solid #2fb6fc;
    border-radius: 0;
  }
  .mem_list {
    padding: 5px 10px 5px 10px;
    margin: 0;
    height: 50px;
    border-top: none;
    width: 350px;
    p {
      margin-top: 8px;
      display: inline-block;
    }
  }
  .admin_mem {
    margin: 10px 0 0px 25px;
    position: relative;
    .usersOnboard {
      float: left;
      position: relative;
      .cross {
        position: absolute;
        top: -6px;
        right: 2px;
      }
    }
    .owner {
      position: absolute;
      top: 65px;
      width: 30px;
      height: 9px;
      font-size: 10px;
      font-weight: 300;
      color: #464545;
    }
    .admin_members {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      display: inline-block;
      margin: 0 10px 0 0px;
    }
  }
}

.gtd-organization-container {
  height: 100vh;
  width: 100%;
  background-size: cover !important;
  position: relative;
  display: flex;
  h1 {
    margin: 0;
    font-size: 24px;
    color: $snow_gtd;
    margin-right: 45px;
    font-family: $mainFont;
  }
  .board-join {
    margin-bottom: 0 !important;
    margin-top: 5px;
    a {
      margin: 0 !important;
      font-size: 16px;
      color: $snow_gtd;
      margin-right: 45px;
      font-family: $mainFont;
    }
  }
}
.details-group {
  //padding-top:10px;
  padding-left: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f0f0f0;
  .identifier-names {
    font-size: 12px;
    color: #aeaeae;
    margin-right: 25px;
  }
}
.details-wrap {
  padding: 5px;
}
.empty-workspace-card {
  .boards-cards {
    background-color: rgba(255, 255, 255, 0.2) !important;
    p {
      display: flex;
      position: absolute;
      height: 38px;
      left: 33%;
      top: 56%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      color: rgb(255, 255, 255);
      font-family: $mainFont;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
}
// .client_edit {
//     position: absolute;
//     @include icon_position(20px,auto,20px,auto);
//     .gtd_icon_menu {
//         background-image: url("../images/hamburger_menu.svg");
//         @include icon_position(4px,auto,3px,auto);
//         @extend .icon_sm;
//     }
// }

button.md-fab.md-button.md-ink-ripple {
  bottom: 0;
  position: fixed;
  right: 0;
  margin-bottom: 20px;
  margin-right: 25px;
  background: #2a98f0;
}

.md_loading_icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
}

.create_project_dialog {
  width: auto;
  height: auto;
  padding: 30px 0 0 0;
  p {
    font-size: 14px;
    color: $font_light;
  }
  md-input-container {
    height: 30px;
  }
  md-dialog-actions {
    display: flex;
    justify-content: flex-end;
  }
}

md-toolbar {
  min-height: auto;
}

md-toolbar:not(.md-menu-toolbar) {
  background: $whiteColor;
  color: $successMsg;
  border-radius: 10px 10px 0 0;
}

.md-toolbar-tools {
  width: calc(100% - 20px);
  margin: 0 auto;
  border-bottom: 1px solid lighten($controlLabelColor, 25%);
  height: 52px;
  max-height: none;
  padding: 0;
}

.layout-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-title {
  font: normal 17px $mainFont;
  color: $successMsg;
  margin: 0 0 0 10px;
}

.edit-right-top-area {
  margin: 0 10px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.popup-close-holder {
  width: 10px;
  height: 10px;
}

.done-link {
  font: 500 18px $mainFontSemiBold;
  color: $whiteColor;
  margin: 70px 0 20px 0;
  cursor: pointer;
  float: right;
  width: 120px;
  height: 40px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #0090e9;
  border: none;
}

.done-link-button {
  font: 500 15px $mainFontSemiBold;
  color: $blueBg;
  margin: 0 20px 0 0;
  cursor: pointer;
  background-color: white;
  border-color: white;
}
.background-white {
  background-color: white !important;
}

.popup-body-area {
  width: 100%;
}

.edit-user-profile-container {
  width: 100%;
  padding: 25px 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.edit-user-img {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .update_img {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 100px;
    height: 100px;
    background: rgba(42, 152, 240, 0.9);
    font: normal 12px/16px $mainFont;
    color: #ffffff;
    text-align: center;
    padding: 40px 13px;
    border-radius: 10px;
  }
  &:hover .update_img {
    transition: all 0.3s linear;
    display: block;
    cursor: pointer;
  }
}

.edit-user-name {
  width: 300px;
  margin: 0 0 0 25px;
  input {
    width: 100%;
    height: 25px;
    border: 0;
    border-bottom: 1px solid lighten($controlLabelColor, 25%);
    background: none;
    font: normal 15px roboto;
    color: $successMsg;
    &::placeholder {
      color: rgba(73, 73, 73, 0.35);
    }
  }
}

.edit-all-user-container {
  width: 100%;
  padding: 0 20px;
  background: #f9f9f9;
  min-height: 414px;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.user-position {
  font: normal 15px $mainFont;
  color: $panelHeading;
  span {
    font: normal 13px $sideFont;
    color: #999999;
  }
}

.user-row-ddown {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 5px 0 0 0;
}

.user-img-holder {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  margin: 0 10px 0 0;

  &.add-user-avatar {
    width: 30px;
    height: 30px;
  }

  &.add-user-img-holder {
    width: 30px;
    height: 30px;
  }

  &.super-admin {
    border: 2px solid $blueBg;
  }
  .user-inner-holder {
    width: 35px;
    height: 35px;
    &.not-wp-user {
      opacity: 0.5;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  &:hover {
    .user-img-delete {
      display: block;
    }
  }

  &.workspace-owner {
    border: 2px solid $blueBg;
    margin: 0 10px 10px 0;
    .user-inner-holder {
      width: 31px;
      height: 31px;
    }
    .sb-avatar {
      .sb-avatar__text {
        width: 31px !important;
        height: 31px !important;
        div {
          font-size: 15px !important;
        }
      }
    }
    .user-img-delete {
      // display: none; //commented for new activity design
      top: -7px; // added for new activity design
    }
  }

  &.workspace-member {
    margin: 0 10px 10px 0;
    .sb-avatar {
      .sb-avatar__text {
        width: 35px !important;
        height: 35px !important;
        cursor: default;
        div {
          font-size: 15px !important;
        }
      }
    }
  }

  &.add-activity-avatar {
    margin: 0 10px 10px 0;
    .sb-avatar {
      .sb-avatar__text {
        width: 35px !important;
        height: 35px !important;
        cursor: default;
        div {
          font-size: 15px !important;
        }
      }
    }
  }
  &.edit-report-img {
    margin: 0;
    .sb-avatar {
      .sb-avatar__text {
        width: 35px !important;
        height: 35px !important;
      }
    }
  }
}

.user-img-holder {
  .sb-avatar {
    .sb-avatar__text {
      width: 31px !important;
      height: 31px !important;
      div {
        font-size: 15px !important;
      }
    }
  }
}
.user_img_holder {
  .sb-avatar {
    .sb-avatar__text {
      width: 35px !important;
      height: 35px !important;
      div {
        font-size: 15px !important;
      }
    }
  }
}

.user-img-delete {
  width: 13px;
  height: 13px;
  position: absolute;
  right: -2px;
  top: -2px;
  cursor: pointer;
  display: none;
}

.user-type-section {
  margin: 20px 0 0 0;
  width: 100%;
  min-height: 70px;
}

.user-add {
  width: 35px;
  height: 35px;
  margin: 0 10px 10px 0;
  position: relative;
  cursor: pointer;
}

.user-profile {
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  border: solid 2px #ffffff;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.profile-container {
  font-family: $mainFont;
  width: 320px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 78px;
  right: 10px;
  z-index: 9;
  overflow: hidden;
  .profile-header {
    color: #000;
    display: flex;
    margin: 16px 20px 5px 20px;
    padding: 0 0 16px 0;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    img {
      width: 48px;
      height: 48px;
      background-color: #ffffff;
      border: solid 2px #ffffff;
      border-radius: 50%;
      margin-right: 15px;
      object-fit: cover;
    }
    .sb-avatar {
      .sb-avatar__text {
        width: 45px !important;
        height: 45px !important;
        background-color: #ffffff;
        border: solid 2px #ffffff;
        border-radius: 50%;
        margin-right: 15px;
        div {
          font-size: 17px !important;
          span {
            padding-top: 1px;
            padding-left: 0.5px;
          }
        }
      }
    }
    .user-name {
      font-size: 17px;
      text-align: left;
      color: #494949;
      .user-email {
        font-size: 12px;
        text-align: left;
        color: #5a5a5a;
      }
    }
  }
  .profile-body {
    color: #494949;
    font-size: 17px;
    p {
      margin: 0;
      cursor: pointer;
      a {
        color: #494949;
        height: 52px;
        margin: 0;
        padding: 0 20px;
        align-items: center;
        display: flex;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

.edit-board-ddown {
  position: absolute;
  left: 0;
  top: 45px;
  width: 285px;
  max-height: 346px;
  overflow: auto;
  background: $whiteColor;
  border-radius: 10px;
  z-index: 9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: auto;

  &.activity-popup-ddown {
    top: 30px;
  }
  &.owner-change-ddown {
    top: 40px;
    left: auto;
  }

  .confirmation-box-remove {
    padding: 10px;

    .user-name {
      margin: 5px 0;
      font: medium 15px roboto;
      color: #505050;
    }
    .divider-remove-box {
      background-color: #dadada;
      height: 2px;
      border: 0;
    }
    .message-remove-box {
      font: normal 13px roboto;
    }
    .button-box {
      float: right;
      margin-bottom: 10px;

      .cancel-button-remove-box {
        margin-right: 5px;
        padding: 5px 10px;
        background-color: transparent;
        border: 0px;
        color: #0090e9;
        font: normal 15px roboto;
        &:hover {
          background-color: transparent !important;
        }
      }

      .confirm-button-remove-box {
        margin-right: 5px;
        padding: 5px 10px;
        background-color: #ef5350;
        border: 0px;
        color: #fff;
        border-radius: 10px;
        font: normal 15px roboto;
        &:hover {
          background-color: #ef5350 !important;
        }
      }
    }
  }
  .add-multi-member-bottom-holder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid rgba(174, 174, 174, 0.25);
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    .add-multi-member-cancel {
      margin-right: 20px;
      font: normal 15px roboto;
      color: #0090e9;
    }
    .add-multi-member-confirm {
      width: 74px;
      height: 30px;
      border-radius: 10px;
      background-color: #0090e9;
      border: none;
      color: #fff;
      font: normal 15px roboto;
      &:disabled {
        background-color: #dadada;
      }
    }
  }
}

.remove-popup {
  width: 258px;
  top: 20px;
  left: 25px;
  .confirmation-box-remove {
    padding: 9px;
    .user-name {
      font-family: roboto;
      font-size: 15px;
      font-weight: 500;
    }
    .divider-remove-box {
      height: 1px;
    }
    .message-remove-box {
      color: #808080;
      margin-bottom: 20px;
    }
    .button-box {
      .cancel-button-remove-box {
        margin-right: 10px;
      }
      .confirm-button-remove-box {
        margin-right: 0px;
        padding: 6px 20px;
      }
    }
  }
}

.Edit-board-ddown {
  position: absolute;
  // left: 18px;
  right: 58px;
  top: 30px;
  width: 285px;
  height: 149px;
  overflow: auto;
  background: $whiteColor;
  border-radius: 10px;
  z-index: 9;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  cursor: auto;
  &.rm-user-act {
    right: auto;
    width: 320px;
    .messageremove-box {
      width: auto;
    }
  }
  &.edit {
    top: 40px;
  }
  &.rm-user-wp {
    width: 300px;
    height: 170px;
  }

  .confirmation-box-remove {
    padding: 10px;

    .user-name {
      margin: 5px 0;
      // font: medium 15px roboto;
      color: #505050;
      font-weight: 600;
    }
    .divider-remove-box {
      background-color: #dadada;
      height: 1px;
      border: 0;
    }
    .message-remove-box {
      font: normal 13px roboto;
    }
    .button-box {
      float: right;
      margin-bottom: 10px;

      .cancel-button-remove-box {
        margin-right: 5px;
        padding: 5px 10px;
        background-color: transparent;
        border: 0px;
        color: #0090e9;
        font: normal 15px roboto;
        &:hover {
          background-color: transparent !important;
        }
      }

      .confirm-button-remove-box {
        width: 96px;
        height: 30px;
        margin-right: 4px;
        // padding: 5px 10px;
        background-color: #ef5350;
        border: 0px;
        color: #fff;
        border-radius: 10px;
        font: normal 15px roboto;
        &:hover {
          background-color: #ef5350 !important;
        }
      }
    }
  }
  .add-multi-member-bottom-holder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid rgba(174, 174, 174, 0.25);
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    .add-multi-member-cancel {
      margin-right: 20px;
      font: normal 15px roboto;
      color: #0090e9;
    }
    .add-multi-member-confirm {
      width: 74px;
      height: 30px;
      border-radius: 10px;
      background-color: #0090e9;
      border: none;
      color: #fff;
      font: normal 15px roboto;
      &:disabled {
        background-color: #dadada;
      }
    }
  }
}
.position {
  left: 62%;
  width: 258px;
  height: 149px;
}
.edit-board-search-holder {
  width: 100%;
  height: 42px;
  overflow: hidden;
  position: relative;
  // border-bottom: 1px solid#ddd;
  // margin-bottom: 5px;
  border-radius: 10px 10px 0 0;
  &.assign-proj-search {
    margin-bottom: 0;
  }
}

.add-register-search-holder {
  margin-bottom: 0px;
  border-bottom: 0;
}

.edit-board-search-control {
  width: 100%;
  border: 0;
  // border-bottom: 2px solid lighten($controlLabelColor, 25%);
  height: 41px;
  border-radius: 10px 10px 0 0;
  padding: 0 0 0 45px;
  font: normal 15px $mainFont;
  color: $controlLabelColor;
  &.kanban {
    width: 240px;
    // border-bottom: 1px solid rgba(174, 174, 174, 0.25);
    height: 41px;
    &.list {
      border-bottom: 1px solid rgba(174, 174, 174, 0.25);
    }
  }
  &::-webkit-input-placeholder {
    color: $controlLabelColor;
  }
  &.project {
    // border-bottom: 2px solid lighten($controlLabelColor, 25%);
  }
}

.add-register-search {
  width: 84%;
}

.edit-search-icon-holder {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 15px;
  top: 51%;
  transform: translateY(-50%);
  display: flex;
  img {
    width: fit-content;
  }
}

.search-data-container {
  width: 100%;
  max-height: 175px;
  overflow-x: hidden;
  margin: 0 0 0 0;
  &.more-list {
    width: calc(100% - 5px);
    border-top: 1px solid #ddd;
  }
  &.inside-activity {
    max-height: 130px;
    &.first-time-user {
      max-height: 90px;
    }
  }
  &.proj-data-container {
    max-height: 183px;
  }
  .no-result-found {
    height: 183px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
  }
}

.search-data-container::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

.search-data-container::-webkit-scrollbar-thumb {
  width: 4px;
  background: lighten($topNaviColor, 20%);
  border-radius: 2px;
}

.search-data-list {
  width: 100%;
  height: 61px;
  padding: 0 0 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  > div > div > div {
    width: 35px !important;
    height: 35px !important;
    font-size: 15px;
    border-radius: 0;
    padding-bottom: 4px;
    padding-right: 4px;
    > div {
      font-size: 14px !important;
    }
  }
  &:hover {
    background: lighten($topNaviColor, 35%);
    .kanban-remove-user {
      display: flex;
    }
  }
}

.add-user-list {
  > div > div > div {
    padding-bottom: 0px;
    padding-right: 0px;
    border-radius: 50%;
  }
}

.tick-check {
  display: none;
}

.tick-check + label {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0;
  cursor: pointer;
}

.tick-check:checked + label {
  &:after {
    box-sizing: border-box;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    right: 17px;
    top: 50%;
    display: table;
    width: 5px;
    height: 11px;
    border: 2px solid #494949;
    border-top: 0;
    border-left: 0;
    content: "";
  }
}

.user-details-holder {
  .user-name {
    max-width: 180px;
    height: 21px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    color: #494949;
    &.kanban {
      height: auto;
    }
    &.proj-access {
      height: 22px;
    }
  }
}
.user-name {
  &.add-user-ws {
    white-space: nowrap;
    max-width: 133px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.user-email {
  color: #acacac;
  line-height: 19px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.add-user-ws {
    white-space: nowrap;
    max-width: 133px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.invite {
    font: normal 15px/30px $mainFont;
  }
}

.org-icons-holder {
  width: auto;
}

.mgn-left-15px {
  margin-left: 15px;
}

.width-100percent {
  width: 100%;
}

.org-progress-holder {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
}

.project-pop-image {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  .update_img {
    position: absolute;
    display: none;
  }
  &:hover .update_img {
    transition: all 0.3s linear;
    display: block;
    cursor: pointer;
  }
}
.disable-area {
  pointer-events: none;
}
//Workspace Upgrade
.workspace-upgrade-members {
  width: 258px;
  height: 145px;
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  .upgrade-container {
    padding: 14px 10px 10px;
    h1 {
      font-size: 15px;
      font-weight: $mainFontSemiBold;
      color: #505050;
      margin: 0;
      padding: 0 0 10px 0;
      border-bottom: 1px solid rgba(117, 117, 117, 0.3);
    }
    .upgrade-plan-desc {
      padding: 10px 0 0px 0;
    }
    p {
      font-size: 14px;
      color: #757575;
    }
    .upgrade-buttons {
      float: right;
      .btn {
        float: left;
        font-size: 15px;
      }
      .cancel-btn {
        background: none;
        border: 0;
        color: #0090e9;
      }
      .upgrade-btn {
        background: #0090e9;
        border-radius: 10px;
        color: #ffffff;
        border-color: #0090e9;
        padding: 6px 20px;
      }
    }
  }
}
//End of Workspace upgrade
//more than five user
.five-users {
  border-top: 1px solid rgba(174, 174, 174, 0.3);
  padding: 8px 10px 10px 0;
  clear: both;
  overflow: hidden;
  .five-user-buttons {
    float: right;
    .btn {
      float: left;
      font-size: 15px;
    }
    .cancel-btn {
      background: none;
      border: 0;
      color: #0090e9;
    }
    .upgrade-btn {
      background: #0090e9;
      border-radius: 10px;
      color: #ffffff;
      border-color: #0090e9;
      padding: 6px 20px;
    }
  }
}
//More than 100 user
.hundred-users {
  border-top: 1px solid rgba(174, 174, 174, 0.3);
  padding: 8px 10px 10px 15px;
  clear: both;
  overflow: hidden;
  p {
    font-size: 14px;
    color: #808080;
    font-weight: 400;
    span {
      font-weight: 600;
      color: #303030;
    }
  }
  .admin-confirm {
    .user-img-holder {
      width: 100%;
      overflow: hidden;
    }
    .admin-img {
      float: left;
      width: 35px;
      margin-right: 10px;
    }
    .admin-name {
      float: left;
      width: calc(100% - 50px);
      input {
        border: 0;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .country-phone-ext {
    .country-phone {
      .more-user-phone {
        border: 0;
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }
  .hundered-user-buttons {
    float: right;
    .btn {
      float: left;
      font-size: 15px;
    }
    .cancel-btn {
      background: none;
      border: 0;
      color: #0090e9;
    }
    .upgrade-btn {
      background: #0090e9;
      border-radius: 10px;
      color: #ffffff;
      border-color: #0090e9;
      padding: 6px 20px;
    }
  }
}
.workspace-scroll {
  height: calc(100% - 120px);
}
.pending-requests-wrapper {
  width: 100%;
  display: block;
  float: left;
  margin-left: 24px;
  opacity: 0.75;
  .pending-container {
    margin-top: 25px;
    .join-workspace-list-container {
      float: left;
      .join-workspace-items {
        float: left;
        background-color: whitesmoke;
        width: 300px;
        padding: 10px;
        margin: 0 24px 24px 0;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        height: 115px;
        .join-left-side-workspace {
          margin: 28px 0 27px 6px;
          display: flex;
          position: relative;
          .join-user-img-container {
            width: 42px;
            height: 42px;
            margin-right: 24px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
          .join-user-details {
            .user-name {
              color: #aeaeae;
              text-transform: none;
              font: 400 17px roboto;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 177px;
              display: block;
            }
          }
          .resend-invite {
            position: absolute;
            top: -25px;
            right: 0;
            button {
              background: none;
              border: none;
              color: #0090e9;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.notification-count-badge {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: red;
  position: absolute;
  right: 11px;
  top: 90px;
  .notification-count {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    font-family: $mainFont;
  }
}

.workspace-toast {
  width: 500px !important;
  right: 0;

  .md-toast-content {
    width: 500px !important;
  }
}
@media screen and (max-height: 900px) {
  .small-screen {
    top: auto;
    bottom: 45px;
  }
}

.organization-container-loader {
  position: absolute;
  height: 38px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.clearfix {
  clear: both;
}

.notification-count-badge-dropdown {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: red;
  position: absolute;
  right: 11px;
  top: 60px;
  .notification-count {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    font-family: $mainFont;
  }
}

.md-avatar-img {
  width: 100%;
}

.profile-section {
  width: 100%;
}

.profile-header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  .profile-container {
    top: 78px;
  }
  .right-flow-profile {
    display: flex;
    .profile-back-container {
      width: 200px !important;
      height: 110px;
      padding: 42px 0 42px 25px;
      background-color: rgba(255, 255, 255, 0.15) !important;
      display: flex;
      align-items: center;
    }
    .back-arrow-container {
      height: 110px;
      padding: 42px 0 42px 25px;
      display: flex;
      align-items: center;
      background-color: inherit;
      width: 221px;

      img {
        width: 20px;
        height: 16px;
        margin-right: 10px;
        margin-top: 3px;
      }
      .back-button-profile {
        font-family: $mainFont;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        font-size: 20px;
        padding-left: 8px;
      }
      .back-from-profile {
        font-family: roboto;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
      }
    }
    .profile-title {
      padding: 42px 0 42px 30px;
      font-family: roboto;
      font-size: 20px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
    }
  }
}

.user-profile-parent {
  height: 100%;
  background: #fff;
}
