@import "variable.scss";
@import "app.scss";
/*Client*/
.add-cards-container {
     .card-content{
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0 0 0 0;
            white-space: nowrap;
            p{
            margin:0 0 0 10px;
            color:#fff;
            font-family:$mainFont;
            font-size:15px;
            }
        }
}

.cards-container {
  padding-left: 12px;

  .client-cards {
    background-color: #ffffff;
    width: 248px;
    display: inline-block;
    padding: 41px 20px 30px;
    margin: 10px;
    position: relative;
    float: left;

    .md-title {
      font-size: 16px;
      color: $font_light;
      text-transform: none;
    }
  }

  .card-content {
    text-align: center;

    .client-name {
      padding-top: 13px;
      .md-input-focused{
        input.md-input{
          border-color: #aeaeae !important;
          border-width: 1px;
        }
        
      }
    }

    .img-container {
      width: 100px;
      margin: 0 auto;
      height: 50px;
      background-size: contain;
      background-repeat: no-repeat;
      text-align: center;
      background-position: center;

      img {
        width: 100%;
      }
    }

    md-card._md {
      padding: 10px 0 47px;
      overflow: auto;
      width: 248px;
    }
  }

  .client-edit {
    position: absolute;
    @include icon_position(-35px, auto, 10px,auto);

    .gtd_icon_info {
      background-image: url("../img/svg/info.svg");
      @include icon_position(18px, auto, 3px, auto);
      @extend .icon_bg;
      right: 28px;
      top: 11px;
    }

    .gtd_icon_menu {
      background-image: url("../img/svg/hamburger_menu.svg");
      @include icon_position(4px, auto, 3px, auto);
      @extend .icon_sm;
    }
  }
  .fa-plus{
    font-size: 20px; 
    padding: 18px 15px; 
    color: #ffffff;
  }
}
.client-scroll.client-card-main{
  width: 100%;
  padding-left: 12px;
}
.client-card-main{
  width: 100%;
  height: calc(100% - 182px)!important;

  .mCSB_container_wrapper {
    margin-right: 8px !important;
  }
}

.create-client-dialog {
  width: 440px;
  height: 340px;
  padding: 15px;
  z-index: 999;
  

  .image {
    margin-left: 20px;
    cursor: pointer;
  }

  .title {
    margin-top: 30px;
    margin-left: 20px;
    width: 93%;
    margin-bottom: 0;
    height: 45px;
    text-transform: capitalize;
  }

  .button_div {
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;

    .button_active {
      background: $primary_gtd;
      font-family: "Open Sans";
      color: #ffffff;
      border: none;
      font-size: 16px;
      text-decoration: none;
      width: 25%;
      height: 40px;
      float: right;
      margin-right: 0;
    }

    .button_inactive {
      background: #cccccc;
      font-family: "Open Sans";
      color: #ffffff;
      border: none;
      font-size: 16px;
      text-decoration: none;
      width: 25%;
      height: 40px;
      float: right;
      margin-right: 0;
    }
  }
}

.gtd_client_container {
  height: 100%;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("") no-repeat;
  background-size: cover !important;

  h1 {
    margin: 0;
    font-size: 24px;
    color: $snow_gtd;
    padding: 30px 0 0 20px;
  }

  .gtd-form-group {
    width: 500px;
    padding: 20px 0 20px 22px;
    @include placeholder {
        color: $snow_gtd;
        }

    .gtd_form-control {
      // background: rgba(238, 238, 238, 0.1);
         width: 100%;
      // padding: 9px 20px;
      // outline: none;
        border: none;
        font-size: 15px;
    color: $snow_gtd;
    }
  }
}

.client-edit {
  position: absolute;
  @include icon_position(20px, auto, 20px,auto);

  .gtd_icon_menu {
    background-image: url("../img/svg/hamburger_menu.svg");
    @include icon_position(4px, auto, 3px, auto);
    @extend .icon_sm;
  }
}
md-dialog {
    //max-width: 460px;
    width: 700px !important;
}



button.md-fab.md-button.md-ink-ripple {
  bottom: 0;
  position: fixed;
  right: 0;
  margin-bottom: 20px;
  margin-right: 25px;
  background: #2A98F0;
}

.md_loading_icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
}
.create_project_dialog {
  width: auto;
  height: auto;
  padding: 30px 0 0 0;
  p{
    font-size: 14px;
    color: $font_light;
  }
  md-input-container {
    height: 30px;
  }

  md-dialog-actions {
    display: flex;
    justify-content: flex-end;
  }
}



.add-cards-container.empty-card{
    width: 300px ;
    height: 115px;
    margin-left: 0 ;
    border-radius: 10px;
    margin:0;
}
.cards-container.clients-wrapper{
  .client-cards{
    width: 300px;
    height: 115px;
    margin: 0 24px 24px 0px;
    border-radius: 10px;
    padding: 25px 12px;
    .client-edit{
      position: absolute;
      right: 0;
      top: 10px;
      width: 22%;
      img{
        margin-left: 10px;
      }
    }
    .card-content{
      .img-container{
        float: left;
        width: 65px;
        padding: 10px;
        background-size: 75% auto;
        height: 65px;
        margin-right: 12px;
      }
      .client-name{
        float: left;
        width: calc(100% - 77px);
        text-align: left;
        padding: 10px 0 0 0;
        .md-title{
          font-size: 17px;
          font-family: roboto;
          font-weight: 400;
          color: #868686;
        }
        
      }
      #client-progress{
        width:100%;
        background: #f0f0f0;  
        #client-bar{
          width: 20%;
          height: 2px;
          background-color: #3edfb5;
          text-align: center;
          line-height: 30px;
          color: white;
        }
      }
    }
  }
}
.client-main {
  clear:  both;
  overflow:  hidden;
  padding: 5px 24px 20px 24px;
  h1{
    float: left;
    padding: 0 45px 0 0;
  }
  .client-search{
    float: left;
    padding: 0;
    input{
      padding:0px !important;  
      background: transparent;
      border-bottom: 1px solid #d6d6d6 !important;
      padding: 0 0 5px 0 !important;
      &::-webkit-input-placeholder{
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
  .cli-arc-active {
    float:  left;
    padding: 0 0px 0 0px;
    color:#ffffff;
    display:flex;
    md-switch{
      margin:0;
    }
}
}
.gtd_client_container {
  padding: 0px 0 20px 0;
}
.client-progress_status{
  float: right;
  font-size: 12px;
  color: #aeaeae;
}
.client-popup {
  width: 700px !important;
  max-width: 700px !important;
  border-radius: 10px;
  form {
    padding: 15px 20px 20px 10px;
    .md-toolbar-tools{
      width: 100%;
      height: auto;
      border-bottom: 1px solid rgba(174, 174, 174, 0.25);
      padding: 0 0 10px 15px;
      h2{
        color: #494949;
        font-size: 17px;
      }
      .right-side-wrpper{
        clear:both;
        overflow: hidden;
        .client-update{
          float: left;
          margin-right: 20px;
          button{
            border: 0;
            background: transparent;
            padding: 0;
            color: #aeaeae;
            font-size: 15px;
            font-weight: 600;
          }
          .button_active{
          color: #0090e9;
          }
        }
        .close-icon {
          float: left;
        }   
      }
    }
    .create-client-dialog{
      display: block;
      padding: 25px 0 0 15px;
      .title{
        margin: 0;
        margin: 0;
        width: 100%;
        padding: 0;
        height: auto;
        font-size: 15px;
        label{
          font-size: 15px;
          font-weight: 400;
        }
        input{
          line-height: 0.1;
          padding: 0 0 5px 0;   
          border-color: rgba(174, 174, 174, 0.25);
          border-width: 0 0 1px !important;
        }
      }
      .md-input-has-value label, .md-input-focused label{
        color: rgba(73, 73, 73, 0.5);
        font-size: 10px;
        margin: 0;
      }
        .client-logo{
          width: 100px;
          height: 100px;
          float: left;
          margin-right: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
          .update_img{
            position: absolute;
            display: none;
            height: 100%;
            width: 100%;
            background: rgba(0, 144, 233, 0.9);
            transition: ease-in 10s;
            border-radius: 10px;
            color: #ffffff;
            padding: 40px 12px;
          }
          &:hover .update_img{
            transition: all 0.3s linear;
           display:block;
           cursor:pointer;
             }
        }
        .client-name{
          width: calc(100% - 130px);
          float: left;
          padding-top: 40px;    
        }
    }
  } 
  
}
md-progress-linear{
  margin:8px 0 0 5px;
}
.no-projects{
  display: flex;
  .truncate-txt{
    max-width: 105px;
    width:auto;
  }
  .client-projects_length{
    padding: 3px 0;
  }
  span{
    font-size:14px;
    color: #aeaeae;
  }
  .seperator{
    padding: 4px 3px;
    font-size: 14px;
    color: #aeaeae
  }
}
.cli-progress-bar{
  display: flex;
}
.client-hours{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: -5px 0 0 0;
  span{
    color: #aeaeae;
  }
}
.client-main{
  .tab_activity {
    float: left;
    font-size: 15px;
    padding:5px 0px 4px 0;
    margin-right: 20px;
    height: 30px;
    &:last-child{
      margin-right: 40px;
    }
  }
  .active_tab{
    font-weight: 600;
    border-bottom: 1px solid #fff;
  }
  .serachicon{
    padding: 5px 15px 5px 25px;
  }
  .searchActivity{
    margin: 3px 0  0 0;
    &::-webkit-input-placeholder{
      color: #fff !important;
      opacity: 0.5;
      font-size: 15px;
      font-family:$mainFont;
      }
  }
}

.common-filter{
  float: right;
  h1{
    padding: 0;
  }
}

md-progress-circular path {
  stroke: $blueBg;
}

.general-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 99;
}
.default_logo{
cursor:pointer;
}
.client-update.add-client-button{
  button{
    
    float: right;
    color: #fff;
    border: 0;
    border-radius: 10px;
    padding: 9px 43px;
    font-size: 15px;
  }
  .button_active{
    background: #0090e9;
  }
}