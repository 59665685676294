@import "variable.scss";

pl-settings-container {
  width: 100%;
  height: calc(100vh - 262px);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
}
.ppl-settings-loader-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ppl-settings-contact-container {
  width: 342px;
  border-right: 1px solid $brdrColor;
  padding-bottom: 30px;
}
.ppl-settings-control-container {
  width: calc(100% - 342px);
  height: calc(100vh - 196px);
  // overflow-y: scroll;
}

.project-form {
  overflow: scroll;
}

.ppl-settings-control-inner-holder {
  width: 100%;
  height: 100%;
}
.ppl-settings-search-area {
  width: 100%;
  height: 64px;
  padding: 0 0 0 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $brdrColor;
}
.ppl-settings-add-holder {
  width: 42px;
  height: 42px;
  position: relative;
  z-index: 99;
}
.ppl-search-control-holder {
  // width: 210px;
  // height: 42px;
  // margin: 0 0 0 30px;
  position: relative;
}
.ppl-search-icon-holder {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ppl-clear-icon-holder {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ppl-search-control {
  width: 100%;
  height: 42px;
  border: 0;

  padding: 0 20px 0 30px;
  font: normal 15px/90px $mainFont;
  color: $controlLabelColor;
  &::-webkit-input-placeholder {
    color: $controlLabelColor;
  }
}
.ppl-details-holder {
  width: 341px;
  height: 81px;
  background: $whiteColor;
  padding: 0 0 0 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    background: $settingsHover;
  }
  &.active {
    background: $settingsHover;
  }
}

.ppl-details-container {
  width: 100%;
  height: calc(100% - 70px);
}
.ppl-job-details-holder {
  margin: 0 0 0 30px;
}
.ppl-detail-name {
  font: 500 15px/21px $sideFontMedium;
  margin: 0 0 4px 0;
  color: $successMsg;
}
.ppl-detail-job-title {
  font: normal 12px/16px $mainFont;
  color: $settingsGrey;
  margin: 0 0 1px 0;
}
.ppl-detail-role {
  font: normal 12px/16px $mainFont;
  color: $settingsBlue;
  &.owner {
    color: #5da600;
  }
}
.ppl-settings-control-outer-holder {
  width: 100%;
  //height: calc(100% - 70px);
  box-sizing: border-box;
  margin: 70px 0 0 0;
  padding: 0 0 25px 85px;
  border-bottom: 1px solid $brdrColor;
  // overflow-x: hidden;
  //overflow-y: scroll;
}
.Ppl-settings-control-outer-holder {
  width: 100%;
  //height: calc(100% - 70px);
  box-sizing: border-box;
  margin: 39px 0 0 0;
  padding: 0 0 0 60px;
  border-bottom: 1px solid $brdrColor;
  // overflow-x: hidden;
  //overflow-y: scroll;
}
.ppl-settings-control-holder {
  width: 100%;
  margin: 0 0 32px 0;
  min-height: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ppl-settings-control-title {
  width: 216px;
  font: normal 15px $mainFont;
  color: $panelHeading;
}
.ppl-settings-indv-control-holder {
  width: 260px;
  font: normal 15px $mainFont;
  color: $successMsg;
  &.align-horizontal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.ppl-settings-input {
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 1px solid $brdrColor;
  font: normal 15px $mainFont;
  color: $successMsg;
}
.ppl-settings-radio-holder {
  width: 50%;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    font: normal 15px/20px $mainFont;
    position: relative;
    padding-right: 28px;
    cursor: pointer;
    display: inline-block;
    color: $successMsg;
    margin-bottom: 0;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    right: 0;
    top: 4px;
    width: 16px;
    height: 16px;
    border: 1px solid $successMsg;
    border-radius: 50%;
    background: #fff;
  }
  [type="radio"]:checked + label:before {
    border: 1px solid $settingsBlue;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 8px;
    height: 8px;
    background: $settingsBlue;
    position: absolute;
    top: 8px;
    right: 4px;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
  }
}
.ppl-settings-ddown-holder {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid $brdrColor;
  font: normal 16px/30px $mainFont;
  color: $successMsg;
  background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right center;
  position: relative;
  cursor: pointer;
}

.ppl-settings-ddown {
  width: 100%;
  height: 134px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(214, 214, 214, 0.5);
  // border-top: 1px solid rgba(214, 214, 214, 0.12);
  background-color: $whiteColor;
  left: 0;
  top: 43px;
  z-index: 99;
  &.more-than-limit {
    .ppl-settings-ddown-list {
      &:last-child {
        // border-radius: 0 0 10px 10px;
      }
    }
  }
}
.ppl-settings-ddown-list {
  width: 100%;
  height: 29px;
  font: normal 15px/29px $mainFont;
  color: $panelHeading;
  padding: 0 0 0 15px;
  &:hover {
    background: rgba(153, 153, 153, 0.15);
  }
  &:first-child {
    // border-radius: 10px 10px 0 0;
  }
}
.ppl-settings-switch-holder {
  width: 37px;
}
.ppl-settings-switch {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: $settingsDisable;
  position: relative;
  transition: background 0.2s linear;
  cursor: pointer;
  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $whiteColor;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: left 0.2s linear;
  }
  &.on {
    background: $settingsBlue;
    transition: background 0.2s linear;
    &::before {
      left: calc(100% - 18px);
      transition: left 0.2s linear;
    }
  }
}
.ppl-settings-billing-control-holder {
  width: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.ppl-settings-billing-control {
  width: 100px;
  height: 38px;
  // border-radius: 10px;
  border: 1px solid #8ac8ef;
  //background: #ebf7ff;
  font: normal 15px $mainFont;
  color: $successMsg;
  //padding: 0 15px;
}
.ppl-settings-billing-unit {
  font: normal 15px $mainFont;
  color: $successMsg;
  margin: 0 11px 0 0;
}
.disabled {
  pointer-events: none;
  .ppl-settings-billing-control {
    border: 1px solid #e1e1e1;
    background: #f0f0f0;
    font: normal 15px #cccccc;
  }
  .ppl-settings-billing-unit {
    color: #cccccc;
  }
  .ppl-settings-control-title {
    color: #cccccc;
  }
}
.prj-access-tt-container {
  width: 88%;
  // max-width: 870px;
  height: 287px;
  margin: 50px 0 50px 85px;
  // border: 1px solid $brdrColor;
  // border-radius: 10px;
}
.prj-access-header {
  font: normal 17px/23px $mainFont;
  color: #1a173b;
  //margin: 23px 0 0 25px;
  span {
    margin-left: 10px;
    width: 210px;
    height: 16px;
    font-family: roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #999999;
  }
}
.settings-btn {
  width: 120px;
  height: 40px;
  background: $settingsBlue;
  border-radius: 10px;
  font: normal 18px/40px $mainFont;
  color: $whiteColor;
  border: 0;
  &.prj-access-save {
    margin: 0 0 0 85px;
  }
  &[disabled] {
    background: $btnDisable;
  }
}
.prj-access-table-container {
  width: calc(100% - 25px);
  height: 228px;
  margin: 11px 0 0 25px;
}
.prj-access-table {
  width: 98%;
  border: 0;
  thead {
    tr {
      // border-bottom: 1px solid $brdrColor;
    }
    td {
      font: normal 15px $mainFont;
      color: #999999;
      height: 30px;
    }
  }
  tbody {
    tr {
      // border-bottom: 1px solid $brdrColor;
      &:last-child {
        .prj-access-role-ddown {
          // top: -50px;
          // left: calc(100% + 10px);
        }
      }
    }
    td {
      height: 66px;
    }
    &.many-rows {
      tr {
        &:last-child {
          border: 0;
        }
      }
    }
  }
}
.prj-access-prj-name {
  font: normal 15px $mainFont;
  color: #354052;
  margin: 0;
}
.prj-access-role-holder {
  font: normal 15px/20px $mainFont;
  color: $successMsg;
  padding: 0 20px 0 0;
  background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right center;
  // position: relative;
  display: inline-block;
}
.prj-access-role-ddown {
  position: absolute;
  // left: calc(100% - 10px);
  // top: 22px;
  width: 90px;
  padding: 15px;
  background: $whiteColor;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  border-top: 1px solid rgba(214, 214, 214, 0.14);
  z-index: 9;
}
.prj-access-role-ddown-list {
  font: normal 15px/28px $mainFont;
  color: $panelHeading;
  cursor: pointer;
}
.widget-tags-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.widget-tags-holder {
  padding: 10px 12px;
  background: $tagsBg;
  margin: 0 8px 8px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  &:last-child {
    // margin: 0 0 8px 0;
  }

  span {
    font: normal 15px/18px $mainFont;
    color: $successMsg;
    // margin: 0 16px 0 0;
    white-space: nowrap;
  }
  img {
    margin-left: 10px;
  }
}
.people-settings-success {
  font: normal 12px/16px $mainFont;
  color: #6ec500;
  margin: 5px 0 0 0;
}
.ppl-settings-add {
  width: 536px;
  height: 230px;
  border-radius: 10px;
  border: solid 0.3px #dfdfdf;
  background-color: #ffffff;
  z-index: 2;
  position: absolute;
  margin-top: 10px;
  .ppl-settings-add-inner {
    //padding: 40px 40px 10px 40px;
    //height: 95px;
    .input-add-container {
      margin-left: 20px;
      margin-top: 38px;
    }
    .err-add {
      font-family: $mainFont;
      font-size: 12px;
      margin-top: 2px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ef5350;
    }
    input {
      width: 292px;
      border: none;
      border-bottom: solid 1px #d6d6d6;
      font: normal 15px $mainFont;
      color: #cccccc;

      &::-webkit-input-placeholder {
        color: #cccccc;
      }
    }
  }
  .invi-button {
    margin: 0 40px 0 0;
    float: right;
    width: 125px;
    height: 40px;
    border-radius: 10px;
    background-color: #0090e9;
    border: none;
    color: #fff;
    font: normal 18px $mainFont;
  }
}
.ppl-settings-error-msg {
  font: normal 13px/18px $mainFont;
  left: 0;
  top: calc(100% + 2px);
  position: absolute;
  z-index: 9;
  width: 220px;
  color: $attachmentError;
}
.people-settings-no-project {
  font: normal 15px/40px $mainFont;
  color: $successMsg;
  width: 100%;
  text-align: center;
  margin: 20px 0 0 0;
}

.ppl-error-invite {
  font-family: $mainFont;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ef5350;
  margin: 2px 0 0 0;
}
.project-settings-add {
  height: 205px;
}
.project-settings-first {
  //margin-bottom:30px;
  color: #494949 !important;
  &::-webkit-input-placeholder {
    color: #cccccc;
  }
}
.proj-des-settings {
  width: 598px;
  min-height: 90px;
  border-radius: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: $mainFont;
  color: #494949;
  font-size: 15px;
  background-color: #ffffff;
  resize: none;
  padding: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  // word-break: break-all;
  &:focus {
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
  &::-webkit-input-placeholder {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: $mainFont;
    font-size: 15px;
    color: #aeaeae;
  }
}
.prj-access-role-holder-team {
  font: normal 15px/20px $mainFont;
  color: #494949;
  padding: 0 20px 0 0;
  position: relative;
  display: inline-block;
}

.add-team-project {
  md-chips {
    border-bottom: 1px solid #d6d6d6;
    padding: 0 0 5px 0 !important;
    width: 600px;
    height: 44px;
    md-chip {
      margin-bottom: 0;
      background-color: #f4f4f4;
      color: #494949;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      height: 38px;
      padding-top: 8px;
      margin-top: 3px;
      .md-chip-content span {
        font: normal 15px $mainFont !important;
        color: #494949;
      }
    }
    .md-chip-input-container input {
      color: #000 !important;
      font-family: $mainFont;
      font-size: 15px;
    }
  }
  .md-chips {
    padding: 0 0 5px 0 !important;
    border-bottom: 0;
    width: 500px;
    border-bottom: 1px solid #d6d6d6;

    md-icon {
      opacity: 0.5;
    }
  }
}

.project-container-border {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 40px;
}
.project-set {
  margin: 50px 0 25px 85px;
}
.proj-set-client {
  .md-select-value {
    min-width: 245px;
  }
}

.project-settings-img {
  position: relative;

  .project-settings-update {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 144, 233, 0.9);
    transition: ease-in 10s;
    border-radius: 10px;
    color: #ffffff;
    display: none;
    top: 0;
    padding: 9px;
  }
  &:hover .project-settings-update {
    transition: all 0.3s linear;
    display: block;
    cursor: pointer;
  }
}

.prj-title-wrapper {
  display: flex;
  justify-content: space-between;

  .prj-addmember {
    font-size: 15px;
    color: #0090e9;
    font-family: $mainFont;
    // margin: 23px 25px 0 0;
    font-weight: 500;
    position: absolute;
    right: 60px;
    &:hover {
      //font-weight: bold;
      cursor: pointer;
    }
  }
}

.assign-ppl-ddown {
  top: 28px !important;
  left: -187px !important;
}

.add-proj-setting {
  height: 40px;
  background: #0090e9;
  border-radius: 10px;
  color: #fff;
  border: 0;
  float: right;
  font-size: 18px;
  width: 120px;
  font-family: $mainFont;
  margin-right: 10px;
}

.no-members {
  text-align: center;
  font-size: 18px;
  margin-top: 100px;
}

.no-icon {
  md-icon {
    display: none;
  }
}

.proj-settings-save {
  margin-bottom: 28px;
}

.ppl-settings-control-holder {
  .cus-autocomp-holder {
    display: flex;
    .ppl-settings-control-title {
      width: 273px;
    }
    .cus-autocomp-control {
      width: 157px;
      border: 1px solid #d6d6d6;
      background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right
        center;
      position: relative;
      border-radius: 10px;
      padding: 7px 10px;
      background-position-x: 137px;
      height: 32px;
      &::-webkit-input-placeholder {
        color: $controlLabelColor;
      }
    }

    .cus-autocomp-ddown-holder {
      width: 157px;
    }
  }
}

.project-settings-no-results {
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
}

.update-logo {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 80px;
  height: 80px;
  background: rgba(42, 152, 240, 0.9);
  font: normal 12px/16px roboto;
  color: #ffffff;
  text-align: center;
  padding: 25px 13px;
  box-sizing: border-box;
  border-radius: 10px;
  //background: rgba(0, 144, 233, 0.9);
}

.project-setting-main-container {
  .img-container {
    width: 65px;
    height: 65px;
    border-radius: 10px;
    .add-logo {
      cursor: pointer;
      width: inherit;
      height: inherit;
      font-family: Roboto;
      font-size: 10px;
      color: #757575;
      background-color: #f0f0f0;
      text-align: center;
      padding-top: 39%;
      border-radius: 10px;
    }
    img {
      border-radius: 10px;
      width: 65px;
      height: 65px;
      cursor: pointer;
      object-fit: cover;
    }
    &:hover {
      //opacity: 0.2;
      // background-color: rgba(0, 144, 233, 0.9);
      position: relative;
      .update-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        border-radius: 10px;
        background-color: rgba(0, 144, 233, 0.9);
        height: 100%;
        width: 100%;
        color: white;
        cursor: pointer;
      }
      // img {
      //   display: none;
      // }
    }
  }
  .add-project-logo {
    &:hover {
      background-color: #fff;
    }
  }
  md-input-container {
    margin: 0;
  }
  .ppl-settings-ddown-holder {
    width: fit-content;
    height: 32px;
    border: 1px solid #d6d6d6;
    font: normal 15px/30px $mainFont;
    color: #494949;
    background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right
      center;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
  }
  .status-holder {
    min-width: 157px;
    background-position: right 10px bottom 50%;
    // margin-left: -4px;
    cursor: pointer;
    //width: 130px;
    //padding: 7px 10px;
    //background-position-x: 135px;
  }
  .project-estimated {
    width: 80px;
    border: none;
    border-bottom: 1px solid #d6d6d6;
    text-align: left;
    line-height: 26px;
    font-size: 15px;
    color: $successMsg;
    &::-webkit-input-placeholder {
      color: $controlLabelColor;
    }
  }
  .proj-budget {
    width: 100px;
  }
  .project-estimated-dollar {
    color: #757575;
  }
  .ppl-settings-billing-unit {
    margin: 0 5px 0 0;
  }
  .add-projectSettings-container {
    // width: 343px;
    height: 66px;
    background-color: #0090e9;
    .add-projectSettings-wrap {
      display: flex;
      align-items: center;
      padding: 21px 40px;
      cursor: pointer;
      img {
        margin-bottom: 2px;
      }
      .add-projectSettings-title {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        font-family: $mainFont;
        margin-left: 15px;
        margin-top: -1px;
      }
    }
  }
  .project-settings-add {
    width: 536px;
    height: 230px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    padding: 10px;
  }
  .add-project-title-header {
    border-bottom: 1px solid rgba(174, 174, 174, 0.25);
    .wrap-img-projSet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 8px 14px 5px;
      img {
        cursor: pointer;
      }
    }
    .add-new {
      height: 20px;
      font-family: $mainFont;
      font-size: 17px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.01px;
      color: #505050;
    }
  }
  .project-image-name-wrap {
    display: flex;
    // align-items: center;
    padding: 20px 20px 2px 20px;
  }
  .project-settings-img-add {
    width: 100px;
    height: 100px;
  }
  .project-ppl-search {
    margin: 0 !important;
    display: flex;
    width: 100%;
    align-items: center;
  }
  .project-filter-container {
  }
  .select-filter {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    font-family: $mainFont;
    color: #494949;
    background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right
      center;
    padding-right: 20px;
    cursor: pointer;
  }

  .select-filter-open {
    background: url(../img/svg/settings-dropdown-arrow-up.svg) no-repeat right
      center;
  }

  .project-all-options {
    width: 177px;
    //height: 165px;
    background: #fff;
    padding: 10px 0;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #757575;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 25px;
    z-index: 99;
    p {
      margin: 0;
      padding: 7px 10px;
      cursor: pointer;
      line-height: 16px;
      &.tick-settings {
        &:hover {
          background: rgba(153, 153, 153, 0.15) url(../img/svg/checkmark.svg)
            no-repeat 153px center !important;
        }
      }
      &:first-child {
        //padding: 0 10px 5px 10px;
      }
      &:last-child {
        //padding: 5px 10px 0 10px;
      }
      &:hover {
        background: rgba(153, 153, 153, 0.15);
      }
    }
  }
  .ppl-settings-search-area {
    // padding-left: 30px !important;
    // padding-top: 20px;
  }
  .ppl-search-control {
    //padding: 0 20px 20px 30px !important;
    border: none;
  }
  .ppl-clear-icon-holder {
    top: 50%;
    margin-right: 20px;
  }
  .cus-autocomp-holder {
    display: flex;
    .ppl-settings-control-title {
      width: 273px;
    }
  }
  .engagement-type-project {
    background-position: right 10px bottom 50%;
    padding: 0 50px 0 10px;
    .proj-settings-ddown {
      height: auto;
      top: 32px;
      height: fit-content;
      min-height: 40px;
      max-height: 200px;
    }
  }
}

////////////////////////

.ppl-settings-container {
  width: 100%;
  height: calc(100vh - 194px);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
}
.ppl-settings-loader-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ppl-settings-contact-container {
  width: 342px;
  border-right: 1px solid $brdrColor;
  .ppl-details-container {
  }
}

.ppl-settings-control-container {
  width: calc(100% - 342px);
  height: calc(100vh - 192px);
  .ScrollbarsCustom-Wrapper {
    width: inherit;
  }
}

.ppl-settings-control-inner-holder {
  width: 100%;
  height: 100%;
}
.ppl-settings-search-area {
  width: auto;
  height: 64px;
  padding: 0 20px 0 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $brdrColor;
}
.ppl-settings-add-holder {
  width: 42px;
  height: 42px;
  position: relative;
  z-index: 99;
}
.ppl-search-control-holder {
  // width: 210px;
  //  height: 42px;
  // margin: 0 0 0 30px;
  position: relative;
}
.ppl-search-icon-holder {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ppl-clear-icon-holder {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ppl-search-control {
  width: 100%;
  height: 42px;
  border: 0;
  //border-bottom: 1px solid $brdrColor;
  padding: 0 20px 0 15px;
  font: normal 15px/90px $mainFont;
  color: $controlLabelColor;
  &::-webkit-input-placeholder {
    color: $controlLabelColor;
  }
}
.ppl-details-holder {
  // width: auto;
  position: relative;
  height: 81px;
  background: $whiteColor;
  padding: 0 0 0 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $settingsHover;
  }
  &.active {
    background: $settingsHover;
  }
  .apc-dot-holder {
    position: absolute;
    top: 16px;
    right: 8px;
  }
  .apc-options-block {
    right: 0;
    &.one-item {
      top: 21 !important;
    }
    &.last-item {
      top: -85px;
    }
  }
  &:last-child {
    .apc-options-block {
      // top: -85px;
    }
  }
}
.ppl-details-img-holder {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  &.creater-class {
    border: 2px solid #0090e9;
    width: 35px;
    height: 35px;
  }
}

.project-img {
  border-radius: 5px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ppl-details-container {
  width: 100%;
  height: calc(100% - 98px);
}
.ppl-job-details-holder {
  margin: 0 0 0 20px;
  max-width: 190px;
}
.ppl-detail-name {
  font: 500 17px/20px $sideFontMedium;
  margin: 0 0 4px 0;
  color: $successMsg;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 220px;
  font-weight: normal;
}
.ppl-detail-job-title {
  font: normal 14px/16px $mainFont;
  color: #acacac;
  margin: 0 0 1px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 220px;
}
.ppl-detail-role {
  font: normal 12px/16px $mainFont;
  color: $settingsBlue;
  &.owner {
    color: #5da600;
  }
}
.ppl-settings-control-outer-holder {
  width: 100%;
  //height: calc(100% - 70px);
  margin: 70px 0 0 0;
  padding: 0 0 39px 60px;
  border-bottom: 1px solid $brdrColor;
}
.ppl-settings-control-holder {
  width: 100%;
  margin: 0 0 32px 0;
  min-height: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ppl-settings-control-title {
  width: 216px;
  font: normal 15px $mainFont;
  color: $panelHeading;
  &.wp-restrict-ts {
    width: 202px;
    margin-right: 14px;
  }
}
.ppl-settings-indv-control-holder {
  width: 265px;
  font: normal 15px $mainFont;
  color: $successMsg;
  &.align-horizontal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &.wsp-ddown-holder {
    > div {
      width: fit-content;
      position: relative;
    }
  }
  &.wp-ts-switch {
    width: 50px;
  }
}
.ppl-settings-input {
  width: 100%;
  height: 30px;
  border: 0;
  border-bottom: 1px solid $brdrColor;
  font: normal 15px $mainFont;
  color: $successMsg;
}
.ppl-settings-radio-holder {
  width: 50%;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    font: normal 15px/20px $mainFont;
    position: relative;
    padding-right: 28px;
    cursor: pointer;
    display: inline-block;
    color: $successMsg;
    margin-bottom: 0;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    right: 0;
    top: 4px;
    width: 16px;
    height: 16px;
    border: 1px solid $successMsg;
    border-radius: 50%;
    background: #fff;
  }
  [type="radio"]:checked + label:before {
    border: 1px solid $settingsBlue;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 8px;
    height: 8px;

    background: $settingsBlue;
    position: absolute;
    top: 8px;
    right: 4px;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
  }

  &.disabled {
    color: #ffffff;
  }
}
.ppl-settings-ddown-holder {
  min-width: 100px;
  display: inline-block;
  height: 32px;
  border: 1px solid $brdrColor;
  font: normal 15px/30px $mainFont;
  color: #494949;
  background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right center;
  position: relative;
  border-radius: 10px;
  padding: 1px 10px;
  background-position-x: 240px;
  cursor: pointer;
  &.currency {
    width: 105px;
    background-position-x: 85px;
  }
}
.ppl-settings-ddown {
  // width: 177px;
  height: fit-content;
  min-height: 40px;
  max-height: 200px;
  // overflow: hidden;
  // overflow-y: auto;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  // border-top: 1px solid rgba(214, 214, 214, 0.12);
  background-color: $whiteColor;
  left: 0;
  top: 28px;
  z-index: 99;
  padding: 10px 0;
  box-sizing: border-box;
  &.more-than-limit {
    .ppl-settings-ddown-list {
      &:last-child {
        // border-radius: 0 0 10px 10px;
      }
    }
  }
  .ppl-settings-ddown-inner-holder {
    max-height: 174px;
    overflow: hidden;
    overflow-y: auto;
    &.wsp {
      max-height: 118px;
    }
    &::-webkit-scrollbar {
      margin-top: 10px;
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: rgba(153, 153, 153, 0.5);
      border-radius: 2px;
    }
  }
}

.proj-settings-ddown {
  height: 134px;
}

.wsp-settings-ddown {
  height: 136px;
  top: 32px;
}

.ppl-settings-ddown-list {
  width: auto;
  height: 29px;
  font: normal 15px/29px $mainFont;
  color: $panelHeading;
  padding: 0 0 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    background: rgba(153, 153, 153, 0.15);
    // width: auto;
    cursor: pointer;
  }
  &:first-child {
    // border-radius: 10px 10px 0 0;
  }
}
.ppl-settings-switch-holder {
  width: 37px;
}
.ppl-settings-switch {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: $settingsDisable;
  position: relative;
  transition: background 0.2s linear;
  &::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $whiteColor;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: left 0.2s linear;
  }
  &.on {
    background: $settingsBlue;
    transition: background 0.2s linear;
    &::before {
      left: calc(100% - 18px);
      transition: left 0.2s linear;
    }
  }
}
.ppl-settings-billing-control-holder {
  width: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.ppl-settings-billing-control {
  width: 100px;
  height: 38px;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  font: normal 15px $mainFont;
  color: $successMsg;
  &:focus {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
  //padding: 0 15px;
  &::-webkit-input-placeholder {
    color: $controlLabelColor;
  }
}
.ppl-settings-billing-unit {
  font: normal 15px $mainFont;
  color: $successMsg;
  margin: 0 11px 0 0;
}
.disabled {
  pointer-events: none;
  .ppl-settings-billing-control {
    color: #aeaeae;
    border: none;
    background: none;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    &::-webkit-input-placeholder {
      color: #fff;
    }
  }
  .ppl-settings-billing-unit {
    color: #cccccc;
  }
  .ppl-settings-control-title {
    color: #cccccc;
  }
}
.prj-access-tt-container {
  width: 88%;
  // max-width: 870px;
  height: 287px;
  margin: 39px 0 50px 60px;
  //border: 1px solid $brdrColor;
  //border-radius: 10px;
}
.prj-access-header {
  font: normal 17px/23px $mainFont;
  color: #1a173b;
  // margin: 23px 0 0 25px;
}

.proj-ddown {
  .ppl-search-box {
    width: 80%;
    height: 40px;
    &:focus {
      // border-bottom: 2px solid #eeeeee;
    }
  }
  .ppl-search-container {
    // width: 280px;
    max-height: 183px;
    .ppl-list {
      // width: 271px;
      height: 61px;
      .user-name {
        line-height: 20px;
        color: #494949;
        font: normal 17px roboto;
      }
      .user-email {
        font: normal 14px/16px roboto;
        color: #acacac;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .check-selected {
        left: 8px;
      }
    }
  }
  .project-assign-hr {
    height: 1px;
    background-color: #aeaeae;
    //margin-bottom: 10px;
    opacity: 0.25;
  }
  .project-assign-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 49px;
    width: 100%;
    //padding-right: 10px;
  }
  .project-assign-cancel {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0090e9;
    background-color: white;
    border: none;
    padding-right: 0px;
    margin-right: 20px;
    &:hover {
      background: white !important;
      border: none;
    }
  }
  .project-assign-confirm {
    margin-right: 10px;
    width: 94px;
    height: 30px;
    font-size: 15px;
    line-height: normal;
    font-weight: 500;
    outline: none !important;
    border: none !important;
    background-color: #0090e9;
    &:focus {
      outline: none;
      background-color: #0090e9;
    }
    &:active {
      background-color: #0090e9;
    }
    &:active:focus {
      background-color: #0090e9;
    }
  }
}

.proj-ddown {
  top: 28px;
  left: -187px;
  width: 285px;
}

.settings-btn {
  margin-bottom: 39px;
  width: 120px;
  height: 40px;
  background: $blueBg;
  border-radius: 10px;
  font: normal 18px/24px $mainFont;
  color: $whiteColor;
  border: 0;
  &.prj-access-save {
    margin: 0 0 0 85px;
  }
  &[disabled] {
    background: $btnDisable;
  }
}
.prj-access-table-container {
  width: calc(100% - 25px);
  height: 228px;
  margin: 40px 0 0 0;
}
.prj-access-table {
  width: 98%;
  border: 0;
  thead {
    tr {
      // border-bottom: 1px solid $brdrColor;
    }
    td {
      font: normal 15px $mainFont;
      font-weight: 500;
      color: #999999;
      height: 30px;
      padding-bottom: 18px;
    }
  }
  tbody {
    tr {
      // border-bottom: 1px solid $brdrColor;
      &:last-child {
        td:nth-child(2) {
          > div {
            position: absolute;
            margin-top: -10px;
          }
        }
        .prj-access-role-ddown,
        .rectangle {
          top: -85px;
          // left: calc(100% - 76px);
          &.less-top {
            top: -55px;
          }
        }
        .access-ddown {
          top: auto;
        }
      }
    }
    td {
      height: 62px;
      overflow: hidden;
      max-width: 200px;
      text-overflow: ellipsis;
      > div {
        width: fit-content;
      }
      p {
        width: 90%;
        overflow: hidden;
      }
    }
    &.many-rows {
      tr {
        &:last-child {
          border: 0;
        }
      }
    }
  }
}
.prj-access-prj-name {
  font: normal 15px $mainFont;
  color: #354052;
  margin: 0;
}
.prj-access-role-holder {
  font: normal 15px/20px $mainFont;
  color: $successMsg;
  padding: 0 20px 0 0;
  background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right center;
  // position: relative;
  display: inline-block;
  cursor: pointer;
  &.no-down-arrow {
    background: none;
    cursor: auto;
  }
}
.prj-access-role-ddown {
  position: absolute;
  // left: calc(100% - 75px);
  // top: 22px;
  width: 155px;
  padding: 10px 0;
  background: $whiteColor;
  box-shadow: 0 4px 8px 0 rgba(214, 214, 214, 0.5);
  border-radius: 10px;
  border-top: 1px solid rgba(214, 214, 214, 0.14);
  z-index: 9;
  &.access-ddown {
    width: auto;
    min-width: 155px;
  }
}
.prj-access-role-ddown-list {
  font: normal 14px/28px $mainFont;
  color: $panelHeading;
  padding-left: 10px;
  &.access-ddown-list {
    padding-right: 10px;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}
// .widget-tags-container {
//   width: 100%;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   height: 38px;
// }
// .widget-tags-holder {
//   padding: 10px 15px;
//   background: $tagsBg;
//   // margin: 0 15px 0 0;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   border-radius: 10px;
//   &:last-child {
//     margin: 0;
//   }
//   span {
//     font: normal 13px/18px $mainFont;
//     color: $successMsg;
//     // margin: 0 16px 0 0;
//   }
//   img {
//     margin-left: 10px;
//   }
// }
.people-settings-success {
  font: normal 12px/16px $mainFont;
  color: #6ec500;
  margin: 5px 0 0 0;
}
.ppl-settings-add {
  width: 375px;
  height: 154px;
  border-radius: 10px;
  // box-shadow: 0 4px 8px 0 rgba(214, 214, 214, 0.5);
  border: solid 0.3px #dfdfdf;
  background-color: #ffffff;
  z-index: 2;
  position: absolute;
  margin-top: 10px;
  .ppl-settings-add-inner {
    padding: 40px 40px 10px 40px;
    height: 95px;
    input {
      width: 300px;
      border: none;
      border-bottom: solid 2px rgba(174, 174, 174, 0.25);
      padding-bottom: 4px;
      font: normal 15px $mainFont;
      color: #cccccc;
      &::-webkit-input-placeholder {
        color: #cccccc;
      }
    }
  }
  .invi-button {
    margin: 0 40px 0 0;
    float: right;
    width: 125px;
    height: 40px;
    border-radius: 10px;
    background-color: #0090e9;
    border: none;
    color: #fff;
    font: normal 18px $mainFont;
  }
}
.ppl-settings-error-msg {
  font: normal 13px/18px $mainFont;
  left: 0;
  top: calc(100% + 2px);
  position: absolute;
  z-index: 9;
  width: 220px;
  color: $attachmentError;
}
.people-settings-no-project {
  font: normal 15px/40px $mainFont;
  color: $successMsg;
  width: 100%;
  text-align: center;
  margin: 20px 0 0 0;
}

.ppl-error-invite {
  font-family: $mainFont;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ef5350;
  margin: 2px 0 0 0;
}

.add-people-settings {
  height: 66px;
  width: 342px;
  background-color: #0090e9;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    font-family: $mainFont;
    letter-spacing: normal;
    color: #fff;
    margin-left: 15px;
  }
}
.stepper-wrapper {
  display: flex;
  width: 105px;
  height: 32px;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 5px 15px 6px 15px;
}

.ppl-add-invite-ppl {
  width: 536px;
  // height: 111px;
  border-radius: 10px;
  background-color: #ffffff;

  padding: 15px 0px;
  border: 1px solid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .ppl-add-invite-ppl-list-right-side {
    &.cross-button {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background: #d5d5d5;
      }
      margin-right: 0;
      img {
        width: 10px;
        height: 10px;
      }
    }
    float: right;
    margin-right: 10px;
  }
}

.ppl-add-invite-ppl-header {
  border-bottom: 2px solid #aeaeae40;
  margin: 0 10px;
  padding: 0 10px 14px 10px;
  display: flex;
  justify-content: space-between;
  font-family: $mainFont;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #505050;
  img {
    cursor: pointer;
  }
}
.search-ppl-invite {
  padding: 10px 10px 23px 20px;
  display: flex;
  align-items: center;
  position: relative;
  .ppl-suggestions-ddown-container {
    position: absolute;
    top: 45px;
    left: 50px;
    width: 464px;
    background: white;
    border-radius: 10px;
    z-index: 1;
    padding: 16px 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    .ppl-suggestions-ddown {
      max-height: 186px;
      width: 462px;
      overflow: auto;
      &::-webkit-scrollbar {
        margin-top: 10px;
        width: 4px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        background: rgba(153, 153, 153, 0.5);
        border-radius: 3px;
        margin-right: 3px;
      }
    }
  }
  .ppl-email-tick-holder {
    width: 32px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
    img {
      width: 10px;
      height: 8px;
    }
  }
  img {
    height: 16px;
  }
}
.ppl-add-invite-ppl-search {
  margin-left: 15px;
  border: none;
  font-family: $mainFont;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #494949;
  width: 100%;
  &::-webkit-input-placeholder {
    color: #999999;
  }
}
.ppl-add-invite-ppl-list {
  // width: 524px;
  // height: 147px;
  max-height: 248px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 10px;
  &::-webkit-scrollbar {
    margin-top: 10px;
    width: 4px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(153, 153, 153, 0.5);
    border-radius: 3px;
    margin-right: 3px;
  }
  .ScrollbarsCustom-Wrapper {
    width: 100%;
  }
  .mCSB_scrollTools .mCSB_dragger {
    left: 5px;
  }
}
.ppl-add-invite-ppl-list-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 30px;
  // padding: 0 10px;
  padding: 8.5px 31px 8.5px 20px;
  &:hover {
    background: #f0f0f0;
  }
}

.ppl-add-invite-ppl-list-left-side {
  display: flex;
  align-items: center;
  &.suggestion-left-side {
    padding: 8.5px 30px;
    cursor: pointer;
    &:hover {
      background: #f0f0f0;
    }
  }
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.ppl-add-invite-ppl-list-user-details {
  margin-left: 20px;
  .user-name {
    // height: 20px;
    margin: 0;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #505050;
    margin-bottom: 3px;
  }
  .user-domain {
    font: normal 14px $mainFont;
    color: #acacac;
    margin: 0;
  }
}
.ppl-add-invite-ppl-list-right-side {
  button {
    width: 100px;
    height: 35px;
    object-fit: contain;
    border-radius: 10px;
    background: #0090e9;
    border: none;
    color: #ffffff;
    font-family: $mainFont;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }
}

.ppl-add-invite-ppl-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
}

.ppl-remove-member {
  background: none;
  border: none;
  float: right;
  margin-right: 60px;
  font-family: $mainFont;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #999999;
  cursor: pointer;
  margin-bottom: 44px;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.ppl-settings-ddown {
  // position: relative;
  .delconfirm {
    top: -135px;
    right: 75px;
  }
}

.ppl-remove-member-container {
  & > div {
    position: relative;
    // right: 190px;
    // top: -15px;
    .edit-board-ddown {
      width: 258px;
      top: 47px;
      left: 28%;
      width: 258px;
      height: 149px;
      border-radius: 10px;
      .confirmation-box-remove {
        .divider-remove-box {
          height: 1px;
          color: #dadada;
        }
      }
    }
  }
}

.all-people-filter {
  font: normal 15px/30px $mainFont;
  color: $successMsg;
  background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right center;
  padding-right: 20px;
  cursor: pointer;
  &.select-filter-open {
    background: url(../img/svg/settings-dropdown-arrow-up.svg) no-repeat right
      center;
  }
  .ppl-settings-ddown {
    height: fit-content;
    width: 177px;
    max-height: fit-content;
  }
}

.clear-settings-ppl {
  left: 278px;
}

.minus-plus-settings {
  .minus {
    padding-top: 2px;
    display: flex;
    width: 10px;
    cursor: pointer;
  }
  .day-hours-settings {
    margin: 0 auto;
  }
  .plus {
    cursor: pointer;
  }
}

.settings-input-area {
  border: none;
  width: 244px;
  border-bottom: 1px solid #d6d6d6;
  color: $successMsg;
  line-height: 25px;
  font-size: 15px;
  &:focus {
    border-bottom: 1px solid #d6d6d6;
  }
  &::-webkit-input-placeholder {
    color: $controlLabelColor;
  }
}
.align-horizontal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.input-group {
  .date-settings {
    background-color: transparent !important;
  }
  .form-control {
    box-shadow: none;
    &:focus {
      border-color: transparent;
      box-shadow: none;
      border-bottom: 1px solid #d6d6d6;
    }
  }
}

.project-setting-main-container {
  .no-icon md-icon {
    display: block;
  }
  .md-chips md-icon {
    opacity: 0.5;
  }
  md-chips md-chip {
    &:hover {
      background: #505050;
      color: #ffffff;

      .md-chip-content span {
        color: #ffffff;
      }
    }
    &:hover {
      svg {
        color: #ffffff;
      }
    }
  }
  md-chip {
    &:hover {
      md-icon {
        opacity: 1 !important;
      }
    }
  }
  .ppl-settings-add-inner {
    padding: 0 !important;
    .img-container img {
      border-radius: 10px;
      width: 100px;
      height: 100px;
    }
  }
  .ppl-details-container {
    width: 345px;
    height: calc(100% - 98px);
    overflow-x: hidden;
    overflow-y: scroll;
    // scroll-margin-right: 7px;
    .project-scroll > div {
      height: 100%;
    }
    .trackYVisible {
      // width: 101.5% !important;
      .ScrollbarsCustom-Wrapper {
        // right: 16px !important;
        width: inherit;
        .ScrollbarsCustom-Content {
          padding-bottom: 0 !important;
        }
      }
      .ScrollbarsCustom-TrackY {
        // margin-left: 7px !important;
        // margin-right: 7px !important;
        right: 5px;
      }
    }
    &::-webkit-scrollbar {
      margin-top: 10px;
      width: 4px;
      background: transparent;
      margin-right: 7px;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: $scrollBarColor;
      border-radius: 2px;
      margin-right: 7px;
    }
  }
  .ppl-settings-control-container {
    width: calc(100% - 342px);
    height: calc(100vh - 192px);
    overflow: scroll;
    .settings-form-scroll {
      > div:first-child {
        height: inherit;
      }
    }
    &::-webkit-scrollbar {
      margin-top: 10px;
      width: 4px;
      height: 4px;
      background: transparent;
      margin-right: 7px;
      :window-inactive {
        background: #5da600;
      }
    }
    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      width: 2px;
      background: $scrollBarColor;
      border-radius: 2px;
      margin-right: 7px;
    }
  }
}
.settings-team {
  width: 157px !important;
  .ppl-settings-ddown-holder {
    background-position-x: 135px !important;
    cursor: pointer;
  }
}
.del-ppl-settings {
  right: 61px !important;
  top: 37px !important;
}

.settings-noresults {
  height: 100%;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #aeaeae;
}

.wrap-search-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.project-filter-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-settings-project {
  img {
    cursor: pointer;
    padding-top: 4px;
  }
}

.wrap-input-cross {
  display: flex;
  width: 100%;
  align-items: center;
}

.kanban-project-settings {
  .md-chips md-chip .md-chip-remove md-icon {
    display: none;
  }
}

.group-holder-settings {
  width: 245px !important;
}

.projStatus-holder {
  .ppl-settings-ddown {
    top: 32px !important ;
  }
}

.tick-settings {
  background: url(../img/svg/checkmark.svg) no-repeat 153px center !important;
  z-index: 99999;
}

.tick-settingsppl {
  background: url(../img/svg/checkmark.svg) no-repeat 153px center !important;
  z-index: 99999;
  &:hover {
    background: rgba(153, 153, 153, 0.15) url(../img/svg/checkmark.svg)
      no-repeat 153px center !important;
  }
}
.team-ddown {
  top: 37px;
  // left: 60px;
}

.img-ppl-container {
  .sb-avatar__text {
    width: 65px !important;
    height: 65px !important;
    border-radius: 50%;
  }
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.kanban-project-settings {
  .md-chips.md-removable md-chip {
    padding-right: 3px;
  }
}

.hide-mgs-ps {
  display: none;
}

.no-results-ps-msg {
  display: block;
  top: 50%;
  left: 50%;
  //transform: translate(-50%, -50%);
  position: absolute;
}

.update-logo-container {
  :hover {
    .update-logo {
      display: block;
    }
  }
}

.cus-autocomp-holders {
  display: flex;
  &.wp-create-date {
    font: normal 15px roboto;
    color: #494949;
  }
  // .ppl-settings-control-title {
  //   width: 273px;
  // }
  .cus-autocomp-control {
    width: 125px;
    border: 1px solid #d6d6d6;
    background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right
      center;
    position: relative;
    border-radius: 10px;
    // padding: 7px 18px 7px 10px;
    padding-left: 10px;
    padding-right: 20px;
    background-position: right 10px bottom 50%;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::-webkit-input-placeholder {
      color: $controlLabelColor;
    }
  }

  .cus-autocomp-control-grp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    min-width: 157px;
    max-width: 244px;
    border: 1px solid #d6d6d6;
    border-radius: 10px;
    // padding: 7px 18px 7px 10px;
    padding-left: 10px;
    // padding-right: 10px;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::-webkit-input-placeholder {
      color: $controlLabelColor;
    }
  }

  .grp-select {
    width: 84%;
    height: 25px;
    border: none;
    color: #494949;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ddown-icon-holder {
    cursor: pointer;
    height: 30px;
    img {
      padding-top: 12px;
      padding-right: 10px;
    }
  }

  .date-picker {
    width: 197px;
    background: url(../img/svg/calendar-flow.svg) no-repeat right center;
    background-size: 14px;
    cursor: pointer;
    background-position-x: 172px;
    padding-left: 11px;
    padding-top: 5px;
    color: #505050;
    font-size: 15px;
    margin-top: auto;
    font-family: $mainFont;
  }

  .cus-autocomp-ddown-holder {
    min-width: 157px;
    max-width: 244px;
    width: 157px;
    top: calc(100% + 2px);
  }
  .cus-autocomp-ddown-list {
    width: 100%;
    height: 29px;
    background: #fff;
    padding: 0 15px;
    font: normal 15px/29px roboto;
    color: #757575;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.selected {
      background: rgba(153, 153, 153, 0.15) !important;
    }
    &:hover {
      background: rgba(153, 153, 153, 0.15) !important;
    }
  }
}

.input-chips {
  width: 500px;
  display: flex;
  flex-flow: row wrap;
  min-height: 50px;
  margin-bottom: -2px;
  box-sizing: border-box;
  &.access-level {
    width: auto;
  }
  > div {
    height: 38px;
    border-radius: 10px;
    transition: none;
    display: inline-flex;
    margin: 0 8px 8px 0;
    box-sizing: border-box;

    > .inputEdit {
      border-radius: 10px;
      min-width: 130px;
      height: 38px;
      border: solid 1px rgba(0, 0, 0, 0.1);
      display: flex;

      input {
        width: 130px;

        background-color: white !important;
        border-radius: 10px;
        margin: 2px;
        padding-left: 8px;
        border: 0px;
      }

      .svgHolder {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        margin-top: 5px;
        .editable {
          background: url(../img/svg/checkmark.svg) no-repeat center;
          cursor: pointer;
        }
        &:hover {
          color: white !important;
          background-color: #f0f0f0 !important;
        }
      }
      &:hover,
      &:focus {
        background-color: white !important;
        color: white;
      }
    }

    > .readOnlyButton {
      font: normal 15px roboto !important;
      padding-left: 15px;
      padding-top: 7px;
      cursor: pointer;
      width: 81px;
      height: 38px;
      border-radius: 10px;
      border: solid 1px rgba(0, 0, 0, 0.1);

      &:hover {
        border: solid 1px #0090e9;
        color: #0090e9;
      }
    }

    > .readOnlyItem {
      cursor: pointer;
      height: 39px;
      background-color: #f4f4f4;
      border-radius: 10px;
      transition: none;
      display: inline-flex;
      border: 0px;
      margin: 0 8px 8px 0;
      box-sizing: border-box;
      cursor: pointer;
      width: auto;
      margin: 0px;
      padding: 0px;
      box-shadow: none;
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: #505050 !important;
        color: white;
        > span {
          color: #fff;
        }
        > svg {
          color: #505050;
          background-color: #505050 !important;
        }
      }
      > span {
        font: normal 15px roboto !important;
        border-radius: 10px;
        color: #494949;
        width: auto;
        padding-left: 12px;
        padding-top: 9px;

        &:hover {
          color: #fff;
        }
      }

      > .read-only {
        background: url(../img/svg/delete_grey.svg) no-repeat center;
        // width: 26px;
        width: 10px;
        color: #f4f4f4;
        height: auto;
        fill: currentColor;
        border-radius: 10px;
        margin-left: 10px;
        margin-right: 10px;
        &:focus &:hover {
          color: #505050 !important;
          background-color: #505050 !important;
        }
      }
    }
  }
}

.chip-input {
  width: 500px;
  &.widget {
    padding-top: 16px !important;
    width: auto;
  }
  > div {
    &:hover::before,
    &::before,
    &::after {
      transform: none;
      height: 0px !important;
      border-bottom: none !important;
      border-bottom: 1px solid #d6d6d6 !important;
      background-color: #d6d6d6 !important;
    }
    > div {
      height: 38px;
      background-color: #f4f4f4 !important;
      border-radius: 10px;
      transition: none;
      &:hover,
      &:focus {
        background-color: #505050 !important;
        color: white;
        > span {
          color: #fff;
        }

        > svg {
          color: #505050;
        }
      }
      &:active {
        box-shadow: none;
        background-color: #505050 !important;
      }
      > span {
        font: normal 15px roboto !important;
        color: #494949;

        &:hover {
          color: #fff;
        }
      }
      > svg {
        background: url(../img/svg/delete_grey.svg) no-repeat center;
        width: 26px;
        color: #f4f4f4;
        &:hover {
          color: #505050;
        }
      }
      > input {
        background-color: white;
        height: 100%;
        width: 100%;
        color: #505050;
        &:hover,
        &:focus {
          background-color: white;
          // height: 100%;
          width: 100%;
        }
      }
      &:last-child {
        &:hover,
        &:focus {
          background-color: white;
          color: #505050;
        }
      }
    }
  }
}

.Toastify__toast-container--top-right {
  top: 98px;
  right: 30px;
  width: auto;
}

.Toastify__toast--default {
  background: #494949;
  color: #ffffff;
}

.Toastify__toast-body {
  cursor: default;
  margin: 0 0px 0 12px;
  background: url("../img/svg/archive-successful.svg") no-repeat left center;
  text-align: center;
  padding: 18px 0 18px 42px;
  font-size: 14px;
}

.Toastify__toast {
  border-radius: 10px;
  margin-right: 0px;
  min-height: 66px;
  width: auto;
  cursor: default;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.check-selected {
  left: 18px;
}

// .WAMuiChipInput-underline-15:before,
// .WAMuiChipInput-inkbar-12:after {
//   background-color: #d6d6d6 !important;
// }
.imagePlus {
  margin-left: 40px;
  cursor: pointer;
}
.searchBar {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #494949;
}
.bigger {
  width: 200px;
}

.nothing-to-show {
  width: 100%;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #aeaeae;
  // margin-top: 50%;
}
.rectangle {
  position: absolute;
  width: 160px;
  // padding-left: 10px;
  padding-top: 10px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(214, 214, 214, 0.5);
  border-radius: 10px;
  border-top: 1px solid rgba(214, 214, 214, 0.14);
  z-index: 9;
  height: 77px;
  font-size: 14px;
  &.less-top {
    height: 49px;
  }
}
.rectangle-ddown-list {
  font: normal 15px/28px $mainFont;
  color: $panelHeading;
}
.user-add-action {
  font-size: 16px;
  font-family: $mainFont;
  color: #0090e9;
  font-weight: 500;
  position: relative;
  z-index: 99999;
  &:hover {
    text-decoration: underline;
  }
}
.check-select {
  left: 18px;
}
.ppl-settings-dropdown-holder {
  min-width: 157px;
  // max-width: 244px;
  height: 32px;
  display: inline-block;
  border: 1px solid $brdrColor;
  font: normal 15px/30px $mainFont;
  color: #494949;
  background: url(../img/svg/settings-dropdown-arrow.svg) no-repeat right center;
  position: relative;
  border-radius: 10px;
  padding: 1px 10px;
  background-position: right 10px bottom 45%;
  cursor: pointer;
  padding-right: 50px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  white-space: nowrap;
}
.big {
  padding-right: 50px;
}
.setPosition {
  top: -270px;
}
// .ScrollbarsCustom-Wrapper {
//   bottom: 35px !important;
// }

.ppl-details-container {
  .ScrollbarsCustom-TrackX {
    display: none;
  }
}

.ScrollbarsCustom {
  left: 0 !important;
}
#special {
  right: -10px !important;
  > div:nth-child(2) {
    // height: 88% !important;
  }
}
.CustomStyle {
  // margin-bottom: 20px;
  width: 328px;
  // height: 84% !important;
  left: 10px;
  > .ScrollbarsCustom-Wrapper {
    width: inherit;
  }
}
.owner-ps {
  font: normal 15px/20px $mainFont;
}
.workspacesettings-success-message {
  position: relative;
  left: 0;
}
.workspace-setting-holder-container {
  height: 100%;
}
.settings-container {
  height: 100vh;
}
.workspace-settings-edit-user-name {
  margin: 0;
}

.messageremove-box {
  width: 225px;
  height: 36px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.17px;
  color: #808080;
  margin-top: 12px;
  margin-bottom: 12px;
  &.msg-rm-wp {
    width: 280px;
    height: 60px;
  }
}
.ScrollbarsCustom-Content {
  // padding-bottom: 30px !important;
}

.ppl-add-invite-ppl-list-user-img {
  width: 35px;
  height: 35px;
  padding-left: 0;
  padding-top: 1px;
  .sb-avatar {
    width: 35px !important;
    height: 35px !important;
  }
  .sb-avatar--text {
    .sb-avatar__text {
      width: 35px !important;
      height: 35px !important;
      div {
        font-size: 14px !important;
      }
    }
  }
}
.ppl-details-holder {
  .ppl-details-img-holder {
    .sb-avatar {
      .sb-avatar__text {
        border-radius: 4.3px;
        width: 35px !important;
        height: 35px !important;
        > div > span {
          padding-top: 0px;
          text-align: center;
          > span {
            font-size: 16px !important;
            font-family: roboto;
            font-weight: 500;
            color: #ffffff !important;
            padding: 10px;
          }
        }
      }
    }
    &.creater-class {
      .sb-avatar {
        .sb-avatar__text {
          width: 31px !important;
          height: 31px !important;
        }
      }
    }
  }
}
