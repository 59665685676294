@import "variable.scss";

.projectflow-container {
  margin: 20px 0 20px 30px;

  .projectflow-container-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    .left-flow {
      display: flex;
      color: #fff;
      align-items: center;

      h4 {
        margin: 0 10px 0 0;
      }
    }

    .right-flow {
      display: flex;
      color: #fff;
      align-items: center;

      .users-list {
        max-width: 200px;
        margin-left: 20px;
        display: flex;
        .add-users {
          height: 24px;
          display: flex;

          .add-users-img {
            width: 24px;
            height: 24px;
            cursor: pointer;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .users_flow {
            width: 285px;
            position: absolute;
            background-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            top: 38px;
            border-radius: 10px;
            z-index: 999;
            max-height: 330px;
            right: 24px;

            .flow-scroll {
              height: calc(100vh - 95px);
              max-height: 225px;
            }

            .search_board {
              width: 100%;
              background-color: #fff;
              height: 40px;
              border: none;
              border-bottom: 1px solid rgba(174, 174, 174, 0.25);
              background-image: url(../img/svg/Search.svg);
              background-repeat: no-repeat;
              padding-left: 43px;
              background-position: 5.5%;
              border-radius: 10px;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              color: #808080;
              font-size: 15px;
              box-sizing: border-box;

              &::-webkit-input-placeholder {
                color: #aeaeae;
              }
            }

            .board-list {
              height: calc(100% - 55px);
              width: calc(100% - 6px);
            }

            .board-list::-webkit-scrollbar {
              margin-top: 10px;
              width: 4px;
              background: transparent;
            }

            .board-list::-webkit-scrollbar-thumb {
              width: 4px;
              background: $topNaviColor;
              border-radius: 2px;
            }

            .boards-cards {
              background-color: #ffffff;
              width: 285px;
              display: inline-block;
              position: relative;
              float: left;
              border-radius: 8px;
              overflow: hidden;
              height: 43px;

              .menu_options {
                display: flex;
                justify-content: flex-end;

                img {
                  width: 14px;
                  height: auto;
                }
              }

              .md-title {
                font-size: 15px;
                color: $font_light;
                text-transform: none;
                font-family: $sideFont;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1;
              }

              .md-title-email {
                font-size: 13px;
                color: #aeaeae;
                font-family: $sideFont;
              }

              .activities-num {
                text-align: left;
                font-family: $sideFont;
              }

              .card-content {
                display: flex;
                padding: 4px 0 4px 15px;
                align-items: center;

                .client-name {
                  text-align: left;
                  width: calc(100% - 50px);
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  .md-title {
                    color: #868686 !important;
                  }
                }

                .workspace {
                  text-align: left;
                  padding-top: 22px;

                  .md-title {
                    color: #868686 !important;
                  }
                }

                .img-container {
                  width: 30px;
                  height: 30px;
                  background-size: contain;
                  background-repeat: no-repeat;
                  text-align: center;
                  background-position: center;
                  margin-right: 12px;
                  display: flex;
                  justify-content: flex-start;
                  float: left;

                  img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                  }
                }

                md-card._md {
                  padding: 10px 0 47px;
                  overflow: auto;
                  width: 248px;
                }

                &:hover {
                  background-color: #f0f0f0;
                }
              }
            }

            .button {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              border-top: 1px solid rgba(174, 174, 174, 0.25);
              // margin-top: 10px;
              padding: 10px;

              .cancel {
                margin-right: 20px;
                font-family: $mainFont;
                font-size: 15px;
                font-weight: normal;
                color: #0090e9;
                cursor: pointer;
              }

              .save {
                width: 74px;
                height: 30px;
                border-radius: 10px;
                background-color: #0090e9;
                border: none;
                color: #fff;
                font-family: $mainFont;
                font-size: 15px;
                font-weight: normal;

                &[disabled] {
                  background: $btnDisable;
                }
              }
            }
          }
        }
      }
    }
  }
}

.projectflow-kanban-container {
  width: 100%;
  height: calc(100vh - 180px);
  position: relative;
  overflow-y: hidden;
  .ScrollbarsCustom-Wrapper {
    height: 92%;
  }
  .ScrollbarsCustom-TrackX {
    height: 5px !important;
    background: transparent !important;
    .ScrollbarsCustom-Thumb {
      background: rgba(153, 153, 153, 0.5) !important;
    }
  }
}

.kanban-wrapper {
  width: 100%;
  //height: 100%;
  height: calc(100% - 21px);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.kanban-board-holder {
  height: 100%;
  // width: calc(100% - 230px);
  min-height: 42px;
  position: relative;
  margin: 0;
  padding: 0;
  &.uncateg {
    width: 340px;

    .kanban-board {
      min-height: 226px;
    }
  }
}

.add-workflow-container {
  position: relative;
}

.add-workflow {
  width: 230px;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.25);
  position: relative;
  cursor: pointer;
  p {
    display: flex;
    position: absolute;
    left: 37%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    margin: 0;
    font-family: $mainFont;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
}

.kanban-board {
  width: 330px;
  max-height: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.25);
  padding: 15px 0 10px 10px;
  margin-right: 10px;
  list-style-type: none;
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  .add-activity {
    color: #fff;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 15px;
    font-family: $mainFont;
    height: 20px;

    p {
      margin: 0;
    }

    .img-container {
      width: 13px;
      height: 13px;
      object-fit: contain;
      margin-right: 8px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.kanban-board-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  font-family: $mainFont;
  margin-bottom: 15px;

  .header-right {
    margin-right: 8px;
    position: relative;

    .flow-options {
      position: absolute;
      width: 227px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
      border: solid 0.3px #dfdfdf;
      background-color: #ffffff;
      color: $successMsg;
      border-radius: 10px;
      padding: 15px 0px;
      font-size: 15px;
      font-family: $mainFont;
      z-index: 99;
      margin-top: 8px;

      p {
        // margin-top: 0;
        margin: 0;
        padding: 5px 10px;
        &:hover {
          background: rgba(153, 153, 153, 0.15);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      .flow-edit-box {
        padding: 5px 10px;
      }
    }
  }
}

.flow-card-container {
  width: 100%;
  height: 135px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-top: 10px;
  padding: 12px;
  position: relative;
  cursor: pointer;
  &:first-child {
    margin-top: 0px;
  }
}

.users-flow {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 22px;
  overflow: hidden;

  .img-holder {
    width: 20px;
    height: 20px;
    overflow: hidden;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.not-wp-user {
      opacity: 0.5;
    }
    .sb-avatar {
      .sb-avatar__text {
        width: 20px !important;
        height: 20px !important;
        > div {
          font-size: 10px !important;
        }
      }
    }

    &.owner {
      border: 1px solid $blueBg;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.add-workflow-box {
  width: 230px;
  height: 125px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.3px #dfdfdf;
  background-color: #ffffff;
  margin-right: 10px;
  padding: 10px 15px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  .title-addflow {
    margin-bottom: 10px;

    p {
      width: 124px;
      height: 20px;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #757575;
      margin-bottom: 14px;
    }

    input {
      width: 200px;
      border: none;
      border-bottom: solid 1px rgba(174, 174, 174, 0.25);
      font-family: $mainFont;
      font-size: 15px;
      padding-bottom: 7px;

      &::-webkit-input-placeholder {
        color: #aeaeae;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .cancel {
      margin-right: 20px;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      color: #0090e9;
      cursor: pointer;
    }

    .save {
      width: 74px;
      height: 30px;
      border-radius: 10px;
      background-color: #0090e9;
      border: none;
      color: #fff;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;

      &[disabled] {
        background-color: $btnDisable;
      }
    }
  }
}

.deleteflow-box {
  padding: 10px 15px;
  width: 207px;
  height: 85px;
  border-radius: 5px;
  border: solid 1px rgba(151, 151, 151, 0.27);
  margin: auto;
  margin-top: 5px;
  > p {
    padding: 0 !important;
    &:hover {
      background: #fff !important;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;

    .cancel {
      margin-right: 20px;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      color: #0090e9;
      cursor: pointer;
    }

    .save {
      width: 74px;
      height: 30px;
      border-radius: 10px;
      background-color: #ef5350;
      border: none;
      color: #fff;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;

      &[disabled] {
        background-color: $btnDisable;
      }
    }
  }
}

.flow-edit-box {
  input {
    width: 200px;
    border: none;
    border-bottom: solid 1px rgba(174, 174, 174, 0.25);
    font-family: roboto;
    font-size: 15px;
    padding-bottom: 7px;

    &::-webkit-input-placeholder {
      color: #aeaeae;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    .cancel {
      margin-right: 20px;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;
      color: #0090e9;
      cursor: pointer;
    }

    .save {
      width: 74px;
      height: 30px;
      border-radius: 10px;
      background-color: #0090e9;
      border: none;
      color: #fff;
      font-family: $mainFont;
      font-size: 15px;
      font-weight: normal;

      &[disabled] {
        background-color: $btnDisable;
      }
    }
  }
}

.flow-oval {
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  img {
    // padding: 2px 0 0 0;
    // width: 22px;
    // height: 15px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:focus {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.options-background {
  background-color: rgba(0, 0, 0, 0.3);
}

.flow-list {
  margin-right: 15px;
  position: relative;

  .flow-names {
    font-family: $mainFont;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    color: #ffffff;
    background-image: url(../img/svg/board_arrow.svg);
    background-repeat: no-repeat;
    padding-right: 15px;
    background-position: right;
    cursor: pointer;
  }

  .list-drop {
    background-image: url(../img/svg/arrow_up.svg);
  }
}
.title-list-inner {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 2px 0 0;
}
.flow-list-holder {
  width: 250px;
  max-height: 172px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.3px #dfdfdf;
  background-color: #e3e3e3;
  position: absolute;
  padding: 10px 0px 0 10px;
  z-index: 999;
  margin-top: 8px;
  right: 0;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 2px;
  }

  .collapsed-cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 0 10px;
    width: 230px;
    box-sizing: border-box;

    .title-list {
      font: normal 15px $mainFont;
      color: #494949;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span {
        font-size: 12px;
      }
    }

    .flow-expand {
      opacity: 0.5;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &:hover {
      .flow-expand {
        opacity: 1;
      }
    }
  }
}

.flow-card-tag {
  float: left;
  padding: 0 15px;
  height: 23px;
  border-radius: 12px;
  background-color: #f4f4f4;

  span {
    font: normal 11px/23px $mainFont;
    font-size: 11px;
    color: #494949;
  }
}

.add-workflow-title {
  width: 124px;
  height: 20px;
  font: normal 15px $mainFont;
  color: #757575;
  margin-bottom: 14px;
}

.kanban-content-area {
  max-width: calc(100% - 5px);
  position: relative;
  min-height: 15px;
  max-height: calc(100% - 56px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: rgba(255, 255, 255, 0.35);
    border-radius: 2px;
  }

  &.uncateg {
    overflow: auto;
    width: calc(100% - 8px);
  }
}

.kanban-content-area-holder {
  width: calc(100% - 5px);
  min-height: 15px;
}

.projectflow-kanban-container {
  .mCSB_container {
    height: 100%;
  }

  .mCSB_2_scrollbar_vertical {
    display: none !important;
  }

  .mCSB_container_wrapper {
    min-height: 145px;
  }

  .kanban-content-area {
    .mCSB_container {
      height: auto;
      left: 0 !important;
    }

    .mCSB_scrollTools .mCSB_dragger {
      width: 4px;
      background: rgba(255, 255, 255, 0.75);
      left: 6px;
      border-radius: 3px;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
      width: 4px;
      background: transparent;
    }

    .mCSB_scrollTools a + .mCSB_draggerContainer {
      margin: 10px 0;
    }
  }
}

.dndPlaceholder {
  background-color: rgba(255, 255, 255, 0.25);
  display: block;
  height: 100%;
  float: left;
  min-height: 42px;
  width: 330px;
  margin-right: 10px;
}

.kanban-content-area {
  .dndPlaceholder {
    width: 300px;
    float: none;
    height: 135px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    margin: 10px 0 0 0;
  }

  .mCSB_inside > .mCSB_container {
    margin-right: 10px;
  }
}

.flow-card-title {
  font: normal 17px $mainFont;
  color: #494949;
  width: 100%;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flow-card-title-holder {
  width: 210px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-height: 23px;
}

.flow-card-date {
  display: flex;
  // margin: 10px 0;
  margin: 10px 0 35px; // for new activity design

  .flow-time {
    margin-right: 20px;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }

  .calendar {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }

  .time {
    color: #505050;
    font: normal 12px $mainFont;
  }
}

.pw-activity-edit-container {
  width: 48px;
  height: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: right;
}

.pw-activity-edit-holder {
  width: 16px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.edit {
    margin-left: 20px;
  }
}

.checkbox-activity {
  md-checkbox[disabled] {
    cursor: default;
    opacity: 0.3;
  }
}

.users-list-img {
  height: 24px;
  margin-left: 5px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.users-list-img-img {
  height: 24px !important;
  width: 24px !important;
  border-radius: 50%;
}

.users-list-img-holder {
  height: 24px !important;
  width: 24px !important;
  border-radius: 50%;
  position: relative;
  margin: 0 5px 0 0;
  cursor: pointer;
  .sb-avatar > .sb-avatar__text {
    height: 24px !important;
    width: 24px !important;
    > div > span {
      font-size: 15px;
    }
  }
}

.proj-user-container {
  height: calc(100vh - 95px) !important;
  max-height: 225px !important;
}

.dndDraggingSource {
  display: none;
}

.user-list-more {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(1, 143, 233, 0.76);
  font: 500 10px/24px $sideFontMedium;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.trun-flow {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}

.anchor-flow {
  display: flex;
  justify-content: flex-end;

  p {
    margin-left: 10px;
  }
}

.workflow-prj-name-holder {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  border-radius: 10px;
  font: normal 13px/22px $mainFont;
  height: 25px;
  padding: 2px 22px 2px 10px;
  background: rgba(255, 255, 255, 0.2) url("../img/svg/board_arrow.svg")
    no-repeat calc(100% - 10px) center;

  &.active-drop {
    background: rgba(255, 255, 255, 0.2) url("../img/svg/arrow_up.svg")
      no-repeat calc(100% - 10px) center;
  }
}

.usersin-list-container {
  top: 30px !important;
  right: 0 !important;

  .usersin-list {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 0;

    .user-details-holder {
      text-align: left;
    }
    &.kanban {
      box-shadow: none;
      border-radius: 0;
      width: calc(100% - 5px);
      margin-bottom: 10px;
      .sb-avatar > .sb-avatar__text {
        width: 34px !important;
        height: 34px !important;
      }
    }
  }
}

.worflow-name-title {
  font-size: 15px;
  max-width: 200px;
  padding-right: 5px;
}

.num-activties {
  font-size: 12px;
  max-width: 90px;
}

.projectflow-results {
  margin-top: 100px !important;
  font: normal 15px $mainFont;
  color: #808080;
}

.projectflow-results-users {
  margin-top: 80px !important;
  font: normal 15px $mainFont;
  color: #808080;
}

.kanban-board {
  &.backlog {
    .mCSB_container_wrapper {
      min-height: 68px;
    }

    .dndPlaceholder {
      height: 68px;
    }

    .flow-card-container {
      height: 68px;
      background-color: rgba(0, 0, 0, 0.25);

      &.without-moved-from {
        height: 47px;
      }
    }

    .kanban-content-area {
      min-height: 15px;
    }

    .card-min-height {
      min-height: 83px;
    }

    .kanban-content-area-holder {
      min-height: 15px;
    }

    .pw-activity-edit-container,
    .flow-card-tag,
    .flow-card-date,
    .users-flow {
      display: none;
    }

    .flow-card-title-holder {
      position: relative;
      z-index: 5;
    }

    .delete-workflow-trigger {
      display: none;
    }
  }
}

.project-wf-status-holder {
  width: 100%;
  height: 6px;
  border-radius: 0 0 10px 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 5;
  background: #ef5350;
}

.uncateg::-webkit-scrollbar {
  width: 4px;
  background: transparent;
  padding-left: 4px !important;
  cursor: pointer !important;
}

.uncateg::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 2px;
}

.proj-workflow-res {
  top: 50% !important;
}

.uncat-height {
  min-height: 15px;
}

.backlog-bg-holder {
  width: 100%;
  height: 47px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: $whiteColor;
  border-radius: 10px;
}

.backlog-details {
  width: 100%;
  height: 20px;
  font: normal 10px/20px $mainFont;
  color: #d8d8d8;
  padding: 6px 0 0 0;
  span {
    font: 500 10px/20px $sideFontMedium;
    color: $whiteColor;
  }
}

.wf-prj-noresults {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal 14px $mainFont;
  color: #000;
}

.workspace-scroll {
  &.prj-wf {
    position: relative;

    .mCSB_container {
      height: 100%;
    }
  }
}

.wf-project-list {
  width: 100%;
  height: 43px;
  cursor: pointer;
}

.remaining-users-count {
  width: 20px;
  height: 20px;
  overflow: hidden;
  margin-right: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal 12px roboto;
}

.card-min-height {
  min-height: 145px;
}

.projectflow-container-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px 24px 20px 0;
  position: relative;
  .left-flow {
    display: flex;
    align-items: center;
    h4 {
      margin: 0 10px 0 0;
      font: normal 13px $mainFont;
      color: #ffffff;
    }
  }
}

.color-info {
  margin: 0 10px 0 0;
  position: relative;
  height: 15px;

  .color-description {
    width: 273px;
    height: 94px;
    border-radius: 3px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 10px;
    position: absolute;
    margin-top: 10px;
    z-index: 9;

    img,
    section {
      width: 25px;
      height: 5px;
      object-fit: contain;
      border-radius: 2.5px;
      margin-right: 10px;
      border: 0px;
      display: inline-block;
      &.green {
        background-color: #70b824;
      }

      &.yellow {
        background-color: #ffa428;
      }

      &.red {
        background-color: #ef5350;
      }

      &.grey {
        margin-right: 7px;
      }
    }

    span {
      font-family: $mainFont;
      font-size: 10px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #505050;
    }
  }
}

.board {
  margin: 0 !important;

  &.project-workflow {
    position: relative;
    width: 160px;
    margin: 0 20px 0 0 !important;

    .flow {
      position: fixed;
      top: 140px;
      left: auto;
    }
  }

  .flow {
    width: 285px;
    height: calc(100% - 140px);
    position: absolute;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    top: 167px;
    border-radius: 10px;
    z-index: 999;
    max-height: 268px;
    left: 39px;

    .workspace-scroll {
      height: calc(100% - 50px);
    }

    .search_board {
      width: 100%;
      background-color: #fff;
      height: 40px;
      border: none;
      border-bottom: 1px solid rgba(174, 174, 174, 0.25);
      background-image: url(../img/svg/Search.svg);
      background-repeat: no-repeat;
      padding-left: 43px;
      background-position: 5.5%;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      color: #808080;
      font-size: 15px;
      box-sizing: border-box;

      &::-webkit-input-placeholder {
        color: #aeaeae;
      }
    }

    .board-list {
      height: 100%;
      width: calc(100% - 6px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .board-list::-webkit-scrollbar {
      margin-top: 10px;
      width: 4px;
      background: transparent;
    }

    .board-list::-webkit-scrollbar-thumb {
      width: 4px;
      background: $topNaviColor;
      border-radius: 2px;
    }

    .boards-cards {
      background-color: #ffffff;
      width: 278px;
      display: inline-block;
      position: relative;
      float: left;
      overflow: hidden;
      height: 43px;
      .menu_options {
        display: flex;
        justify-content: flex-end;
        img {
          width: 14px;
          height: auto;
        }
      }

      .md-title {
        font-size: 15px;
        color: $font_light;
        text-transform: none;
        font-family: $sideFont;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .activities-num {
        text-align: left;
        font-family: $sideFont;
      }

      .card-content {
        display: flex;
        padding: 6px 0 3px 15px;
        align-items: center;

        .client-name {
          text-align: left;
          width: calc(100% - 50px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          .md-title {
            color: $successMsg !important;
          }
        }

        .workspace {
          text-align: left;
          padding-top: 22px;

          .md-title {
            color: $successMsg !important;
          }
        }

        .img-container {
          width: 30px;
          height: 30px;
          background-size: contain;
          background-repeat: no-repeat;
          text-align: center;
          background-position: center;
          margin-right: 12px;
          display: flex;
          justify-content: flex-start;
          float: left;
          border-radius: 50%;
          > div > div {
            width: 30px !important;
            height: 30px !important;
            border-radius: 4px !important;
            > div > span {
              position: relative;
              left: 5.5px;
              top: 1.3px;
            }
          }
          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        md-card._md {
          padding: 10px 0 47px;
          overflow: auto;
          width: 248px;
        }
      }

      &:hover {
        background-color: rgba(153, 153, 153, 0.15);
      }
    }
  }

  .selected-project {
    background-image: url("../img/svg/tick-black.svg");
    background-repeat: no-repeat, repeat;
    background-position-x: 90%;
    background-position-y: 50%;
  }
}

.header-second {
  padding: 0 !important;
}

.projects-ddrop {
  font-family: $mainFont;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  padding: 0 5px 0 0;
}

.header-left-prj-wf {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.activity-options-pw {
  .option-icon {
    position: relative;
    cursor: pointer;
    .dot-holder {
      width: 19px;
      height: 19px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: rgba(216, 216, 216, 0.2);
      }
    }

    margin-left: 10px;

    .options-block {
      width: 153px;
      border-radius: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
      background-color: #ffffff;
      position: absolute;
      padding: 10px 0px;
      z-index: 999;
      right: 0;
      top: 20px;
      div {
        font-family: $mainFont;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: $successMsg;
        height: 30px;
        padding: 5px 10px;

        &:hover {
          background: rgba(153, 153, 153, 0.15);
        }
      }
    }
  }
}
.kanban-proj-title-area {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: -58px;
  z-index: 5;
}

.kanban-proj-title {
  font: normal 20px $mainFont;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $whiteColor;
  margin: 0 0 2px 0;
}
.left-flow-project {
  display: flex;
  align-items: center;
}
.clear {
  clear: both;
}
.kanban-link {
  cursor: pointer;
}
.kanban-remove-user {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 11px;
  height: 11px;
  z-index: 5;
  display: none;
}
.add-users-dropdown-holder {
  width: 24px;
  height: 24px;
}
.view-workload-btn {
  padding: 0 15px;
  font: normal 13px/25px $mainFont;
  color: $whiteColor;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  margin: 0 40px 0 0;
  background: transparent;
  white-space: nowrap;
  &:hover {
    background: transparent;
  }
  &:not([disabled]) {
    &:hover {
      // background: transparent;
      background: rgba(255, 255, 255, 0.25);
      border: none;
      height: 27px;
      width: 132.34px;
    }
  }
  &:focus {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
}
.add-activity-trigger {
  cursor: pointer;
}
