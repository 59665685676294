@import "variable.scss";

.activity-create-container {
  .dpheader {
    width: 95%;
    margin-bottom: 20px;
    .Deprioritized-activities {
      // width: 158px;
      display: inline-block;
      height: 19px;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $whiteColor;
    }
    .rest-activity {
      margin-top: 10px;
    }
    .SortByHeader {
      // display: inline-block;
      float: right;
      display: flex;
      margin-right: 78px; 
      span {
        /* display: inline-flex; */
        margin-right: 5px;
        /* width: 41px; */
        /* height: 15px; */
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #fff;
        /* top: 7px; */
        padding: 3px 0;
      }
      .workflow-change-ddown-holder {
        width: 160px;
        background-color: rgba(255, 255, 255, 0.2);
        height: 25px;
        border-radius: 10px;
        .workflow-change-ddown-text {
          height: 100%;
          font-family: Roboto;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          padding: 3px 0;
          color: #fff;
        }
        .workflow-change-ddown {
          height: 140px;

          li {
            display: flex;
            height: 16px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0px;
            padding: 7px 10px;
            color: var(--brownish-grey);
          }
          width: 160px;
        }

        &:after {
          content: "";
          position: absolute;
          right: 8px;
          top: 50%;
          width: 10px;
          height: 6px;
          transform: translateY(-50%);
          background: url(../img/svg/board_arrow.svg) no-repeat center center;
        }
        &.clicked {
          &:after {
            transform: rotate(180deg) translateY(3px);
          }
        }
      }
    }
  }
}
